<div class="container-fluid">
    <app-page-title title="Portlets" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <!-- row -->
    <div class="row">
        <div class="col-lg-4" *ngFor="let color of cardColor">
            <app-portlet title="Card title" color="{{color.color}}" text="{{color.text}}"
                (onContentRefresh)="contentRefresh()" headerClass="h5 m-0 p-3">
                <div class="card-body">
                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3
                    wolf
                    moon officia aute,
                    non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf
                    moon
                    tempor, sunt aliqua
                    put a bird on it squid single-origin coffee nulla assumenda shoreditch et.
                </div>
            </app-portlet>
        </div>
    </div><!-- end row -->
</div>