import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {

    showMobileMenu = false;
    isShow = false;
    enable = true;

    refershListCamera = false;

    constructor(
        private router: Router,
        private translate: TranslateService
    ) {

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                // console.log('route', val.url);
                if (val.url.includes('/list-camera') || val.url === '/') {
                    if (val.url.includes('?')) {
                        this.refershListCamera = true;
                    }
                    this.isShow = true;
                } else {
                    this.isShow = false;
                }

                if (val.url === '/account/login') {
                    this.enable = false;
                }
            }
        });

    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        document.body.classList.remove('authentication-bg');
    }

    useLanguage(language) {
        this.translate.use(language.id);
    }

    /**
     * on settings button clicked from topbar
     */
    onSettingsButtonClicked() {
        document.body.classList.toggle('right-bar-enabled');
    }

    /**
     * On mobile toggle button clicked
     */
    onToggleMobileMenu() {
        this.showMobileMenu = !this.showMobileMenu;
    }

    componentAdded(e: any) {
        // console.log('componentAdded', e);
    }

    componentRemoved(e: any) {
        // console.log('componentRemoved', e);
    }
}
