<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-5">
        <div class="card">

          <div class="card-body p-4">
            <!-- Logo -->
            <div class="text-center w-75 m-auto">
              <a routerLink="/list-camera">
                <img src="../../../../assets/images/logo-icon/logo-aivis-dark.png" alt="" height="28">
              </a>
              <p class="text-muted mb-4 mt-3">Enter your username address and password to access admin panel</p>
            </div>

            <form class="needs-validation" name="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>

              <app-ui-preloader [display]="loading"></app-ui-preloader>

              <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

              <div class="form-group mb-3">
                <label for="username">Username</label>

                <input type="username" formControlName="username" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.username.errors }" id="username" placeholder="username" />

                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                  <div *ngIf="f.username.errors.required">username is required</div>
<!--                  <div *ngIf="f.username.errors.username">username must be a valid username address</div>-->
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="password">Password</label>

                <input type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="password" placeholder="Password" />

                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div>

<!--              <div class="form-group">-->
<!--                <div class="custom-control custom-switch">-->
<!--                  <input type="checkbox" class="custom-control-input" id="customSwitch" (change)="rememberMe($event)">-->
<!--                  <label class="custom-control-label" for="customSwitch">Remember me</label>-->
<!--                </div>-->
<!--              </div>-->

              <div class="form-group mb-0 text-center">
                <button class="btn btn-{{environmentStorage ? 'primary' : 'danger'}} btn-block"
                        type="submit" [disabled]="!environmentStorage">
                  {{environmentStorage ? 'Log in' : 'Server error, please refresh this page and try again'}}
                </button>
              </div>
            </form>

<!--            <p class="mt-3 text-muted"><strong>Username:</strong> minton@coderthemes.com, <strong>Password:</strong> test-->
<!--            </p>-->
          </div> <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p>
              <a routerLink="/account/reset-password" class="text-muted ml-1">Forgot your password?</a>
            </p>
            <p class="text-muted">Don't have an account? <a routerLink="/account/signup" class="text-muted ml-1"><b>Sign
                  Up</b></a>
            </p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
  2021 © Aivis - <span class="text-primary">Stay in the corner, control your world</span>
</footer>