import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CommandURL} from '../../shared/constant/ManageURLCommand';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(private http: HttpClient) { }

  getPersonalInfo() {
    return this.http.get<any>(CommandURL.GET_PERSONAL_INFO_URL);
  }

  updatePersonalInfo(json) {
    return this.http.post<any>(CommandURL.UPDATE_PERSONAL_INFO_URL, json);
  }

  updateEmail(email: string) {
    return this.http.post(CommandURL.UPDATE_EMAIL_INFO_URL, {email});
  }

  updatePhone(phone: string) {
    return this.http.post(CommandURL.UPDATE_PHONE_INFO_URL, {phone});
  }

  getTokenVerifyByEmail(token: string) {
    return this.http.post(CommandURL.VERIFY_EMAIL_TOKEN_URL, {token});
  }

  getTokenVerifyByPhone(token: string) {
    return this.http.post(CommandURL.VERIFY_PHONE_TOKEN_URL, {token});
  }

}
