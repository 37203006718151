import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';
import { TimePipePipe } from './pipe/time-pipe.pipe';
import {DebounceClickDirective} from './directive/debounce-click.directive';
import { StatusIrPipePipe } from './pipe/status-ir-pipe.pipe';

@NgModule({
    declarations: [TimePipePipe, DebounceClickDirective, StatusIrPipePipe],
    imports: [
        CommonModule,
        UIModule
    ],
    exports: [
        TimePipePipe, DebounceClickDirective, StatusIrPipePipe
    ]
})
export class SharedModule { }
