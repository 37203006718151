<div class="row">
    <div class="col-12">
        <div class="card-box">
            <div class="row mb-3">
                <div class="col-6">
                    <h5>Model: <span class="text-success">{{model}}</span></h5>
                </div>

                <div class="col-6">
                    <h5>{{'version.current' | translate}}: <span class="text-primary">{{version}}</span></h5>
                </div>
            </div>

            <h4 class="header-title">{{'version.list' | translate}}</h4>
            <!--            <p class="sub-header">-->
            <!--                Use one of two modifier classes to make <code>&lt;thead&gt;</code>s appear light or dark gray.-->
            <!--            </p>-->

            <div class="table-responsive">
                <table class="table mb-0">
                    <thead class="thead-light">
                    <tr>
                        <th>#</th>
                        <th>{{'version.firmware' | translate}}</th>
                        <th>{{'action' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of firmwareVersionList; let i = index;">
                        <th scope="row">{{i + 1}}</th>
                        <td>{{item}}</td>
                        <td>
                            <button (click)="updateFirmware(item)" class="btn btn-sm btn-danger">
                                {{'update' | translate}}
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->

        </div> <!-- end card-box -->
    </div> <!-- end col -->
</div>
