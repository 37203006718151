import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-camera-view',
  templateUrl: './list-camera-view.component.html',
  styleUrls: ['./list-camera-view.component.scss']
})
export class ListCameraViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
