<div class="container-fluid">
  <app-page-title title="Weather Icons" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <h4 class="header-title mb-3">26 New 2.0 Icons</h4>
        <div class="row icons-list-demo">
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-cloudy-high"></i> wi wi-day-cloudy-high
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moonrise"></i>wi wi-moonrise
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-na"></i>wi wi-na
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-volcano"></i>wi wi-volcano
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-light-wind"></i>wi wi-day-light-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moonset"></i>wi wi-moonset
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-flood"></i>wi wi-flood
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-train"></i>wi wi-train
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-sleet"></i>wi wi-day-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-sleet"></i>wi wi-night-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-sandstorm"></i>wi wi-sandstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-small-craft-advisory"></i>wi wi-small-craft-advisory
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-haze"></i>wi wi-day-haze
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-sleet"></i>wi wi-night-alt-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-tsunami"></i>wi wi-tsunami
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-gale-warning"></i>wi wi-gale-warning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-cloudy-high"></i>wi wi-night-cloudy-high
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-raindrop"></i>wi wi-raindrop
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-earthquake"></i>wi wi-earthquake
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-storm-warning"></i>wi wi-storm-warning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-partly-cloudy"></i>wi wi-night-alt-partly-cloudy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-barometer"></i>wi wi-barometer
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-fire"></i>wi wi-fire
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-hurricane-warning"></i>wi wi-hurricane-warning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-sleet"></i>wi wi-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-humidity"></i>wi wi-humidity
          </div>
        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Daytime Icons</h4>
        <div class="row icons-list-demo">
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-sunny"></i> wi wi-day-sunny
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-cloudy"></i> wi wi-day-cloudy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-cloudy-gusts"></i> wi wi-day-cloudy-gusts
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-cloudy-windy"></i> wi wi-day-cloudy-windy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-fog"></i> wi wi-day-fog
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-hail"></i> wi wi-day-hail
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-haze"></i> wi wi-day-haze
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-lightning"></i> wi wi-day-lightning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-rain"></i> wi wi-day-rain
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-rain-mix"></i> wi wi-day-rain-mix
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-rain-wind"></i> wi wi-day-rain-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-showers"></i> wi wi-day-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-sleet"></i> wi wi-day-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-sleet-storm"></i> wi wi-day-sleet-storm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-snow"></i> wi wi-day-snow
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-snow-thunderstorm"></i> wi wi-day-snow-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-snow-wind"></i> wi wi-day-snow-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-sprinkle"></i> wi wi-day-sprinkle
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-storm-showers"></i> wi wi-day-storm-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-sunny-overcast"></i> wi wi-day-sunny-overcast
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-thunderstorm"></i> wi wi-day-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-windy"></i> wi wi-day-windy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-solar-eclipse"></i> wi wi-solar-eclipse
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-hot"></i> wi wi-hot
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-cloudy-high"></i> wi wi-day-cloudy-high
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-day-light-wind"></i> wi wi-day-light-wind
          </div>

        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Nighttime Icons</h4>
        <div class="row icons-list-demo">

          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-clear"></i> wi wi-night-clear
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-cloudy"></i> wi wi-night-alt-cloudy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-cloudy-gusts"></i> wi wi-night-alt-cloudy-gusts
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-cloudy-windy"></i> wi wi-night-alt-cloudy-windy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-hail"></i> wi wi-night-alt-hail
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-lightning"></i> wi wi-night-alt-lightning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-rain"></i> wi wi-night-alt-rain
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-rain-mix"></i> wi wi-night-alt-rain-mix
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-rain-wind"></i> wi wi-night-alt-rain-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-showers"></i> wi wi-night-alt-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-sleet"></i> wi wi-night-alt-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-sleet-storm"></i> wi wi-night-alt-sleet-storm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-snow"></i> wi wi-night-alt-snow
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-snow-thunderstorm"></i> wi wi-night-alt-snow-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-snow-wind"></i> wi wi-night-alt-snow-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-sprinkle"></i> wi wi-night-alt-sprinkle
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-storm-showers"></i> wi wi-night-alt-storm-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-thunderstorm"></i> wi wi-night-alt-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-cloudy"></i> wi wi-night-cloudy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-cloudy-gusts"></i> wi wi-night-cloudy-gusts
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-cloudy-windy"></i> wi wi-night-cloudy-windy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-fog"></i> wi wi-night-fog
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-hail"></i> wi wi-night-hail
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-lightning"></i> wi wi-night-lightning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-partly-cloudy"></i> wi wi-night-partly-cloudy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-rain"></i> wi wi-night-rain
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-rain-mix"></i> wi wi-night-rain-mix
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-rain-wind"></i> wi wi-night-rain-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-showers"></i> wi wi-night-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-sleet"></i> wi wi-night-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-sleet-storm"></i> wi wi-night-sleet-storm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-snow"></i> wi wi-night-snow
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-snow-thunderstorm"></i> wi wi-night-snow-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-snow-wind"></i> wi wi-night-snow-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-sprinkle"></i> wi wi-night-sprinkle
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-storm-showers"></i> wi wi-night-storm-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-thunderstorm"></i> wi wi-night-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-lunar-eclipse"></i> wi wi-lunar-eclipse
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-stars"></i> wi wi-stars
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-storm-showers"></i> wi wi-storm-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-thunderstorm"></i> wi wi-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-cloudy-high"></i> wi wi-night-alt-cloudy-high
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-cloudy-high"></i> wi wi-night-cloudy-high
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-night-alt-partly-cloudy"></i> wi wi-night-alt-partly-cloudy
          </div>

        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Neutral Icons</h4>
        <div class="row icons-list-demo">

          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-cloud"></i> wi wi-cloud
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-cloudy"></i> wi wi-cloudy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-cloudy-gusts"></i> wi wi-cloudy-gusts
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-cloudy-windy"></i> wi wi-cloudy-windy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-fog"></i> wi wi-fog
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-hail"></i> wi wi-hail
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-rain"></i> wi wi-rain
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-rain-mix"></i> wi wi-rain-mix
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-rain-wind"></i> wi wi-rain-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-showers"></i> wi wi-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-sleet"></i> wi wi-sleet
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-snow"></i> wi wi-snow
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-sprinkle"></i> wi wi-sprinkle
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-storm-showers"></i> wi wi-storm-showers
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-thunderstorm"></i> wi wi-thunderstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-snow-wind"></i> wi wi-snow-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-snow"></i> wi wi-snow
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-smog"></i> wi wi-smog
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-smoke"></i> wi wi-smoke
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-lightning"></i> wi wi-lightning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-raindrops"></i> wi wi-raindrops
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-raindrop"></i> wi wi-raindrop
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-dust"></i> wi wi-dust
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-snowflake-cold"></i> wi wi-snowflake-cold
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-windy"></i> wi wi-windy
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-strong-wind"></i> wi wi-strong-wind
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-sandstorm"></i> wi wi-sandstorm
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-earthquake"></i> wi wi-earthquake
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-fire"></i> wi wi-fire
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-flood"></i> wi wi-flood
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-meteor"></i> wi wi-meteor
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-tsunami"></i> wi wi-tsunami
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-volcano"></i> wi wi-volcano
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-hurricane"></i> wi wi-hurricane
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-tornado"></i> wi wi-tornado
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-small-craft-advisory"></i> wi wi-small-craft-advisory
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-gale-warning"></i> wi wi-gale-warning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-storm-warning"></i> wi wi-storm-warning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-hurricane-warning"></i> wi wi-hurricane-warning
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-wind-direction"></i> wi wi-wind-direction
          </div>
        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Miscellaneous Icons</h4>
        <div class="row icons-list-demo">

          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-alien"></i> wi wi-alien
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-celsius"></i> wi wi-celsius
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-fahrenheit"></i> wi wi-fahrenheit
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-degrees"></i> wi wi-degrees
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-thermometer"></i> wi wi-thermometer
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-thermometer-exterior"></i> wi wi-thermometer-exterior
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-thermometer-internal"></i> wi wi-thermometer-internal
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-cloud-down"></i> wi wi-cloud-down
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-cloud-up"></i> wi wi-cloud-up
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-cloud-refresh"></i> wi wi-cloud-refresh
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-horizon"></i> wi wi-horizon
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-horizon-alt"></i> wi wi-horizon-alt
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-sunrise"></i> wi wi-sunrise
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-sunset"></i> wi wi-sunset
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moonrise"></i> wi wi-moonrise
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moonset"></i> wi wi-moonset
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-refresh"></i> wi wi-refresh
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-refresh-alt"></i> wi wi-refresh-alt
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-umbrella"></i> wi wi-umbrella
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-barometer"></i> wi wi-barometer
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-humidity"></i> wi wi-humidity
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-na"></i> wi wi-na
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-train"></i> wi wi-train
          </div>
        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Moon Phases Icons</h4>
        <div class="alert alert-success">
          The moons are split into 28 icons, to correspond neatly with the 28 day moon cycle. There is a primary
          set and alternate set. The primary set is meant to be interpreted as: where there are pixels, that is the
          illuminated part of the moon. The alternate set is meant to be interpreted as: where there are pixels,
          that is the shadowed part of the moon.
        </div>
        <div class="row icons-list-demo">

          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-new"></i> wi wi-moon-new
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-cresent-1"></i> wi wi-moon-waxing-cresent-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-cresent-2"></i> wi wi-moon-waxing-cresent-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-cresent-3"></i> wi wi-moon-waxing-cresent-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-cresent-4"></i> wi wi-moon-waxing-cresent-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-cresent-5"></i> wi wi-moon-waxing-cresent-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-cresent-6"></i> wi wi-moon-waxing-cresent-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-first-quarter"></i> wi wi-moon-first-quarter
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-gibbous-1"></i> wi wi-moon-waxing-gibbous-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-gibbous-2"></i> wi wi-moon-waxing-gibbous-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-gibbous-3"></i> wi wi-moon-waxing-gibbous-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-gibbous-4"></i> wi wi-moon-waxing-gibbous-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-gibbous-5"></i> wi wi-moon-waxing-gibbous-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waxing-gibbous-6"></i> wi wi-moon-waxing-gibbous-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-full"></i> wi wi-moon-full
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-gibbous-1"></i> wi wi-moon-waning-gibbous-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-gibbous-2"></i> wi wi-moon-waning-gibbous-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-gibbous-3"></i> wi wi-moon-waning-gibbous-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-gibbous-4"></i> wi wi-moon-waning-gibbous-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-gibbous-5"></i> wi wi-moon-waning-gibbous-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-gibbous-6"></i> wi wi-moon-waning-gibbous-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-third-quarter"></i> wi wi-moon-third-quarter
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-crescent-1"></i> wi wi-moon-waning-crescent-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-crescent-2"></i> wi wi-moon-waning-crescent-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-crescent-3"></i> wi wi-moon-waning-crescent-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-crescent-4"></i> wi wi-moon-waning-crescent-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-crescent-5"></i> wi wi-moon-waning-crescent-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-waning-crescent-6"></i> wi wi-moon-waning-crescent-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-new"></i> wi wi-moon-alt-new
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-cresent-1"></i> wi wi-moon-alt-waxing-cresent-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-cresent-2"></i> wi wi-moon-alt-waxing-cresent-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-cresent-3"></i> wi wi-moon-alt-waxing-cresent-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-cresent-4"></i> wi wi-moon-alt-waxing-cresent-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-cresent-5"></i> wi wi-moon-alt-waxing-cresent-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-cresent-6"></i> wi wi-moon-alt-waxing-cresent-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-first-quarter"></i> wi wi-moon-alt-first-quarter
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-gibbous-1"></i> wi wi-moon-alt-waxing-gibbous-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-gibbous-2"></i> wi wi-moon-alt-waxing-gibbous-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-gibbous-3"></i> wi wi-moon-alt-waxing-gibbous-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-gibbous-4"></i> wi wi-moon-alt-waxing-gibbous-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-gibbous-5"></i> wi wi-moon-alt-waxing-gibbous-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waxing-gibbous-6"></i> wi wi-moon-alt-waxing-gibbous-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-full"></i> wi wi-moon-alt-full
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-gibbous-1"></i> wi wi-moon-alt-waning-gibbous-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-gibbous-2"></i> wi wi-moon-alt-waning-gibbous-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-gibbous-3"></i> wi wi-moon-alt-waning-gibbous-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-gibbous-4"></i> wi wi-moon-alt-waning-gibbous-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-gibbous-5"></i> wi wi-moon-alt-waning-gibbous-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-gibbous-6"></i> wi wi-moon-alt-waning-gibbous-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-third-quarter"></i> wi wi-moon-alt-third-quarter
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-crescent-1"></i> wi wi-moon-alt-waning-crescent-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-crescent-2"></i> wi wi-moon-alt-waning-crescent-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-crescent-3"></i> wi wi-moon-alt-waning-crescent-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-crescent-4"></i> wi wi-moon-alt-waning-crescent-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-crescent-5"></i> wi wi-moon-alt-waning-crescent-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-moon-alt-waning-crescent-6"></i> wi wi-moon-alt-waning-crescent-6
          </div>
        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Time Icons</h4>

        <div class="row icons-list-demo">

          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-1"></i> wi wi-time-1
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-2"></i> wi wi-time-2
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-3"></i> wi wi-time-3
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-4"></i> wi wi-time-4
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-5"></i> wi wi-time-5
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-6"></i> wi wi-time-6
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-7"></i> wi wi-time-7
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-8"></i> wi wi-time-8
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-9"></i> wi wi-time-9
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-10"></i> wi wi-time-10
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-11"></i> wi wi-time-11
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-time-12"></i> wi wi-time-12
          </div>

        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Directional Arrows</h4>

        <div class="row icons-list-demo">
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-up"></i> wi wi-direction-up
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-up-right"></i> wi wi-direction-up-right
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-right"></i> wi wi-direction-right
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-down-right"></i> wi wi-direction-down-right
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-down"></i> wi wi-direction-down
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-down-left"></i> wi wi-direction-down-left
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-left"></i> wi wi-direction-left
          </div>
          <div class="col-sm-6 col-md-4 col-xl-3">
            <i class="wi wi-direction-up-left"></i> wi wi-direction-up-left
          </div>
        </div>
        <!-- End row -->

        <h4 class="header-title my-3">Utility Classes</h4>
        <div class="alert alert-success">
          Use these special, built-in utility classes to flip, rotate, or assign a fixed width to any icon.
        </div>
        <div class="row">

          <div class="col-sm-4">
            <h5>Flip</h5>
            <p>
              <code>
                                wi-flip-horizontal</code>
            </p>
            <p>
              <code>
                                wi-flip-vertical</code>
            </p>
          </div>

          <div class="col-sm-4">
            <h5>Rotate</h5>
            <p>
              <code>
                                wi-rotate-90</code>
            </p>
            <p>
              <code>
                                wi-rotate-180</code>
            </p>
            <p>
              <code>
                                wi-rotate-270</code>
            </p>
          </div>

          <div class="col-sm-4">
            <h5>Fixed Width</h5>
            <p>
              <code>
                                wi-fw</code>
            </p>
          </div>

        </div>
        <!-- End row -->
      </div> <!-- end card-box-->
    </div> <!-- end col-->
  </div>
  <!-- end row-->
</div>