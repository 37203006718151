import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CropperPosition, ImageCroppedEvent, LoadedImage} from 'ngx-image-cropper';
import {CameraSettingService} from '../camera-setting/camera-setting.service';
import {CommandURL} from '../../shared/constant/ManageURLCommand';
import {CookieService} from '../../core/services/cookie.service';
import Swal from 'sweetalert2';
import {EnvService} from '../../../env.service';
import {AuthenticationService} from '../../core/services/auth.service';

@Component({
    selector: 'app-image-cropper',
    templateUrl: './image-cropper.component.html',
    styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
    @Input() camId: string;
    @Input() cropperInit: any;
    @Output() updateCopperImage = new EventEmitter();
    imageChangedEvent: any = '';
    croppedImage: any = '';
    cropperPosition: CropperPosition;
    cropper: CropperPosition = {
        x1: 0,
        y1: 0,
        x2: 100,
        y2: 100,
    };

    defaultCropper = {
        x1: 0,
        y1: 0,
        x2: 100,
        y2: 100,
    };

    sizeInit = {
        width: 0,
        height: 0,
    };

    setValueFirst = true;

    constructor(private cameraSettingService: CameraSettingService,
                private envService: EnvService,
                private authService: AuthenticationService,
                private cookieService: CookieService,
    ) {
    }

    ngOnInit() {
        this.fetchImage();
    }

    fetchImage() {
        const apiUrl = this.envService.getEnvironment() || this.cookieService.getCookie('environment');
        this.imageChangedEvent = 'https://' + apiUrl + '/' + CommandURL.GET_LATEST_IMAGE_URL + '?cameraId=' + this.camId +
            '&token=' + this.authService.currentUser().token;
        // this.cameraSettingService.getLatestImage(this.camId).subscribe(img => {
        //     console.log(img)
        // });
    }

    fileChangeEvent(event: any): void {
        // console.log('fileChangeEvent', event);
        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent) {
        // console.log('imageCropped', event);
        this.croppedImage = event.base64;
        let {x1, y1, x2, y2} =  event.cropperPosition;

        if (this.setValueFirst) {
            this.sizeInit = {
                width: x2,
                height: y2,
            };

            this.setValueFirst = false;
        }

        const {width, height} = this.sizeInit;
        x1 === 0 ? x1 = 0 : x1 = (x1 / width * 100);
        y1 === 0 ? y1 = 0 : y1 = (y1 / height * 100);
        x2 === 0 ? x2 = 0 : x2 = (x2 / width * 100);
        y2 === 0 ? y2 = 0 : y2 = (y2 / height * 100);
        this.cropperPosition = {x1, y1, x2, y2};

        // console.log(this.sizeInit);
        // console.log(this.cropperPosition);

    }

    imageLoaded(image: LoadedImage) {
        // console.log('imageLoaded', image);
    }

    cropperReady() {
        // console.log('cropperReady', this.cropperInit);
        let {x1, y1, x2, y2} = this.cropperInit || this.defaultCropper;
        const {width, height} = this.sizeInit;

        x1 === 0 ? x1 = 0 : x1 = (x1 / 100 * width);
        y1 === 0 ? y1 = 0 : y1 = (y1 / 100 * height);
        x2 === 0 ? x2 = 0 : x2 = (x2 / 100 * width);
        y2 === 0 ? y2 = 0 : y2 = (y2 / 100 * height);

        this.cropper = {x1, y1, x2, y2};
    }

    loadImageFailed() {
        console.log('loadImageFailed');
    }

    copperImage() {
        const {x1, y1, x2, y2} = this.cropperPosition;
        this.cameraSettingService.setMotionZone(this.camId, (x1 + ';' + y1 + '-' + x2 + ';' + y2).toString()).subscribe(data => {
            if (data) {
                this.success();
                this.updateCopperImage.emit(true);
            }
        });
    }

    success = () => {
        Swal.fire({
            title: 'Thành công!',
            text: 'Đã cập nhật khung hình chuyển động.',
            type: 'success',
            showConfirmButton: false,
            // confirmButtonClass: 'btn btn-confirm mt-2'
        });
    }
}
