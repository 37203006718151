// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const schema = {
    HTTP_SCHEMA: 'http',
    HTTPS_SCHEMA: 'https',
    PREFIX: '://',
    API_GATEWAY: 'aivisvn.ddns.net:5001/',
    API_GATEWAYS: 'manager-vn1.aivis.tech/',
};

export const environment = {
    production: true,
    API_URL: schema.HTTPS_SCHEMA + schema.PREFIX + schema.API_GATEWAYS,
    // API_URL: schema.HTTP_SCHEMA + schema.PREFIX + schema.API_GATEWAY,
    firebase: {
        apiKey: 'AIzaSyBQIxMWIyyh6ytQakhVWx3ueBqkmrbzK94',
        authDomain: 'aivis-camera.firebaseapp.com',
        projectId: 'aivis-camera',
        storageBucket: 'aivis-camera.appspot.com',
        messagingSenderId: '201191751459',
        appId: '1:201191751459:web:5577718e3b6b6cffa69696',
        measurementId: 'G-RJN1TN1PV1'
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
