<div class="account-pages mt-5 mb-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <!-- Logo -->
            <div class="text-center w-75 m-auto">
              <a routerLink="/account/login">
                <img src="../../../../assets/images/logo-icon/logo-aivis-dark.png" alt="" height="28" />
              </a>
            </div>

            <div class="mt-3 text-center">
              <i class="fas fa-envelope-open-text fa-7x text-primary"></i>

              <h4 class="text-dark-50 text-center mt-4 font-weight-bold">Please check your email</h4>
              <p class="text-muted mb-4">
                A email has been send to <b>{{email}}</b>.
                Please check for an email from company and click on the included link to
                reset your password.
              </p>
            </div>

            <div class="form-group mb-0 text-center">
              <a routerLink="/account/login" class="btn btn-primary"><i class="mdi mdi-home mr-1"></i>
                Back to Home</a>
            </div>

          </div> <!-- end card-body-->
        </div>
        <!-- end card-->

      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
