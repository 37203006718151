<div class="container-fluid">
    <app-page-title title="Form Elements" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card-box">
                <h4 class="header-title">Input Types</h4>
                <p class="sub-header">
                    Most common form control, text-based input fields. Includes support for all HTML5 types: <code>text</code>, <code>password</code>, <code>datetime</code>, <code>datetime-local</code>, <code>date</code>, <code>month</code>, <code>time</code>, <code>week</code>, <code>number</code>, <code>email</code>, <code>url</code>, <code>search</code>, <code>tel</code>, and <code>color</code>.
                </p>

                <div class="row">
                    <div class="col-12">
                        <div class="p-2">
                            <form class="form-horizontal" role="form">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="simpleinput">Text</label>
                                    <div class="col-sm-10">
                                        <input type="text" id="simpleinput" class="form-control" value="Some text value...">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-email">Email</label>
                                    <div class="col-sm-10">
                                        <input type="email" id="example-email" name="example-email" class="form-control" placeholder="Email">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-password">Password</label>
                                    <div class="col-sm-10">
                                        <input type="password" class="form-control" id="example-password" value="password">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-placeholder">Placeholder</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" id="example-placeholder" placeholder="placeholder">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-textarea">Text area</label>
                                    <div class="col-sm-10">
                                        <textarea class="form-control" id="example-textarea" rows="5"></textarea>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Readonly</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" readonly="" value="Readonly value">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Disabled</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" disabled="" value="Disabled value">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2  col-form-label" for="example-static">Static control</label>
                                    <div class="col-sm-10">
                                        <input type="text" readonly="" class="form-control-plaintext" id="example-static" value="email@example.com">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-helping">Helping text</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" id="example-helping" placeholder="Helping text">
                                        <span class="help-block"><small>A block of help text that breaks onto a new line and may extend beyond one line.</small></span>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Input Select</label>
                                    <div class="col-sm-10">
                                        <select class="form-control">
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                        <h6>Multiple select</h6>
                                        <select multiple class="form-control">
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-fileinput">Default file input</label>
                                    <div class="col-sm-10">
                                        <input type="file" class="form-control" id="example-fileinput">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-date">Date</label>
                                    <div class="col-sm-10">
                                        <input class="form-control" type="date" name="date" id="example-date">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-month">Month</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="month" id="example-month" name="month">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-time">Time</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="time" id="example-time" name="time">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-week">Week</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="week" id="example-week" name="week">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-number">Number</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="number" id="example-number" name="number">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">URL</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="url" name="url">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Search</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="search" name="search">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label">Tel</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="tel" name="tel">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="example-color">Color</label>
                                    <div class="col-md-10">
                                        <input class="form-control" type="color" id="example-color" name="color" value="#3bafda" >
                                    </div>
                                </div>

                                <div class="form-group row mb-0">
                                    <label class="col-sm-2 col-form-label" for="example-range">Range</label>
                                    <div class="col-md-10">
                                        <input class="custom-range" type="range" id="example-range" name="range" min="0" max="10">
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
                <!-- end row -->

            </div> <!-- end card-box -->
        </div><!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
        <div class="col-12">
            <div class="card-box">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="header-title">Select menu</h4>
                        <p class="sub-header">
                            Custom <code>&lt;select&gt;</code> menus need only a custom class, <code>.custom-select</code> to trigger the custom styles.
                        </p>

                        <select class="custom-select mt-3">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        
                        <h4 class="header-title mt-4">Switches</h4>
                        <p class="sub-header">
                            A switch has the markup of a custom checkbox but uses the <code>.custom-switch</code> class to render a toggle switch. Switches also support the <code>disabled</code> attribute.
                        </p>

                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitch1">
                            <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
                        </div>
                        <div class="custom-control custom-switch mt-1">
                            <input type="checkbox" class="custom-control-input" disabled id="customSwitch2">
                            <label class="custom-control-label" for="customSwitch2">Disabled switch element</label>
                        </div>

                    </div>

                    <div class="col-md-6">
                        <h4 class="header-title">Checkboxes and radios</h4>
                        <div class="mt-3">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck1">
                                <label class="custom-control-label" for="customCheck1">Check this custom checkbox</label>
                            </div>
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="customCheck2">
                                <label class="custom-control-label" for="customCheck2">Check this custom checkbox</label>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">
                                <label class="custom-control-label" for="customRadio1">Toggle this custom radio</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">
                                <label class="custom-control-label" for="customRadio2">Or toggle this other custom radio</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div> <!-- end card-box -->
        </div> <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
        <div class="col-12">
            <div class="card-box">
                <h4 class="header-title">Input Sizes & Group</h4>
                <p class="sub-header">
                    Set heights using classes like <code>.input-lg</code>, and set widths using grid column classes like <code>.col-lg-*</code>.
                </p>

                <div class="p-2">
                    <form role="form" class="form-horizontal">
                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label" for="example-input-small">Small</label>
                            <div class="col-sm-10">
                                <input type="text" id="example-input-small" name="example-input-small" class="form-control form-control-sm" placeholder=".input-sm">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label" for="example-input-normal">Normal</label>
                            <div class="col-sm-10">
                                <input type="text" id="example-input-normal" name="example-input-normal" class="form-control" placeholder="Normal">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label" for="example-input-large">Large</label>
                            <div class="col-sm-10">
                                <input type="text" id="example-input-large" name="example-input-large" class="form-control form-control-lg" placeholder=".input-lg">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Grid Sizes</label>
                            <div class="col-sm-4">
                                <input type="text" class="form-control" placeholder=".col-sm-4">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Static</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" id="basic-addon1">@</span>
                                    </div>
                                    <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Dropdowns</label>
                            <div class="col-sm-10">
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend" ngbDropdown>
                                        <button class="btn btn-primary waves-effect waves-light dropdown-toggle" type="button" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true" aria-expanded="false">Dropdown <i class="mdi mdi-chevron-down"></i></button>
                                        <div class="dropdown-menu" ngbDropdownMenu>
                                            <a class="dropdown-item" href="javascript: void(0);">Action</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                                            <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                                            <div role="separator" class="dropdown-divider"></div>
                                            <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
                                        </div>
                                    </div>
                                    <input type="text" class="form-control" placeholder="" aria-label="" aria-describedby="basic-addon1">
                                </div>

                            </div>
                        </div>

                        <div class="form-group row mb-0">
                            <label class="col-sm-2 col-form-label">Buttons</label>
                            <div class="col-sm-10">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <button class="btn btn-dark waves-effect waves-light" type="button">Button</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div> <!-- end card-box -->
        </div> <!-- end col -->
    </div>
    <!-- end row -->
</div>
