<div class="container-fluid">
  <app-page-title title="Widgets" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <!-- First row widget -->
    <ng-template [ngTemplateOutlet]="CardData" [ngTemplateOutletContext]="{card: card}" *ngFor="let card of cardData">
    </ng-template>
  </div>
  <!-- end row -->

  <div class="row">
    <!-- Second row widget Data -->
    <ng-template [ngTemplateOutlet]="WidgetData" [ngTemplateOutletContext]="{widgetdata: widgetdata}"
      *ngFor="let widgetdata of widgetIcon">
    </ng-template>
  </div>
  
  <div class="row">
    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left">
          <apx-chart class="apex-chart-widget" [series]="customerChart.series" [chart]="customerChart.chart"
            [colors]="customerChart.color" [plotOptions]="customerChart.plotOptions" [tooltip]="customerChart.tooltip"
            [stroke]="customerChart.stroke" [grid]="customerChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> 268 </h3>
          <p class="text-muted mb-1">New Customers</p>
        </div>
      </div>
    </div><!-- end col -->

    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left" dir="ltr">
          <apx-chart [series]="orderChart.series" [chart]="orderChart.chart" [colors]="orderChart.color"
            [tooltip]="orderChart.tooltip" [stroke]="orderChart.stroke" [grid]="orderChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> 8715 </h3>
          <p class="text-muted mb-1">Online Orders</p>
        </div>
      </div>
    </div><!-- end col -->

    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left" dir="ltr">
          <apx-chart [series]="revenueChart.series" [chart]="revenueChart.chart" [colors]="revenueChart.color"
            [tooltip]="revenueChart.tooltip" [stroke]="revenueChart.stroke" [grid]="revenueChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> $925.78 </h3>
          <p class="text-muted mb-1">Revenue</p>
        </div>
      </div>
    </div><!-- end col -->

    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left" dir="ltr">
          <apx-chart [series]="averageChart.series" [chart]="averageChart.chart" [colors]="averageChart.color"
            [tooltip]="averageChart.tooltip" [stroke]="averageChart.stroke" [grid]="averageChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> $78.58 </h3>
          <p class="text-muted mb-1">Daily Average</p>
        </div>
      </div>
    </div><!-- end col -->

  </div>
  <!-- end row -->
  <div class="row">
    <div class="col-xl-3 col-md-6" *ngFor="let widgetuser of widgetuser">
      <div class="widget-rounded-circle card-box">
        <div class="row align-items-center">
          <div class="col-auto">
            <div class="avatar-lg">
              <img src="{{widgetuser.image}}" class="img-fluid rounded-circle" alt="user">
            </div>
          </div>

          <div class="col">
            <h5 class="mt-0">{{widgetuser.name}}</h5>
            <p class="text-muted mb-1 font-13">coderthemes@gmail.com</p>
            <small class="text-{{widgetuser.color}}"><b>{{widgetuser.type}}</b></small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End row-->

  <div class="row">
    <div class="col-xl-3 col-md-6" *ngFor="let widget of widget">
      <div class="card-box widget-icon">
        <div class="avatar-lg float-left">
          <i class="{{widget.icon}} text-{{widget.iconcolor}} avatar-title display-4 m-0"></i>
        </div>
        <div class="wid-icon-info text-right">
          <p class="text-muted mb-1 font-13 text-uppercase">{{widget.title}}</p>
          <h4 class="mb-1 counter"><span [CountTo]="widget.number" [from]="0" [duration]="1"></span></h4>
          <small class="text-{{widget.textcolor}}"><b>{{widget.text}}</b></small>
        </div>
      </div>
    </div>
  </div>
  <!-- End Row -->

  <!-- WEATHER -->
  <div class="row">

    <div class="col-xl-6">

      <!-- BEGIN WEATHER WIDGET 1 -->
      <div class="card-box bg-info border-info">

        <div class="row">
          <div class="col-md-7">
            <div class="row align-items-center">
              <div class="col-6 text-center">
                <h1 class="display-4"><i class="wi wi-day-sleet text-white"></i></h1>
              </div>
              <div class="col-6">
                <div class="text-white">
                  <h2 class="text-white"><b>32°</b></h2>
                  <p>Partly cloudy</p>
                  <p class=" mb-0">15km/h - 37%</p>
                </div>
              </div>
            </div><!-- End row -->
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-4 text-center">
                <h4 class="text-white mt-0">SAT</h4>
                <h3 class="my-3"><i class="wi wi-night-alt-cloudy text-white"></i></h3>
                <h4 class="text-white mb-0">30<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-white mt-0">SUN</h4>
                <h3 class="my-3"><i class="wi wi-day-sprinkle text-white"></i></h3>
                <h4 class="text-white mb-0">28<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-white mt-0">MON</h4>
                <h3 class="my-3"><i class="wi wi-hot text-white"></i></h3>
                <h4 class="text-white mb-0">33<i class="wi wi-degrees"></i></h4>
              </div>
            </div><!-- end row -->
          </div>
        </div><!-- end row -->
      </div><!-- cardbox -->
      <!-- END Weather WIDGET 1 -->

    </div><!-- End col-xl-6 -->

    <div class="col-xl-6">

      <!-- WEATHER WIDGET 2 -->
      <div class="card-box bg-success border-success">

        <div class="row">
          <div class="col-md-7">
            <div class="row align-items-center">
              <div class="col-6 text-center">
                <h1 class="display-4"><i class="wi wi-night-sprinkle text-white"></i></h1>
              </div>
              <div class="col-6">
                <div class="text-white">
                  <h2 class="text-white"><b>18°</b></h2>
                  <p>Partly cloudy</p>
                  <p class=" mb-0">15km/h - 37%</p>
                </div>
              </div>
            </div><!-- End row -->
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-4 text-center">
                <h4 class="text-white mt-0">SAT</h4>
                <h3 class="my-3"><i class="wi wi-day-sprinkle text-white"></i></h3>
                <h4 class="text-white mb-0">30<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-white mt-0">SUN</h4>
                <h3 class="my-3"><i class="wi wi-storm-showers text-white"></i></h3>
                <h4 class="text-white mb-0">28<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-white mt-0">MON</h4>
                <h3 class="my-3"><i class="wi wi-night-alt-cloudy text-white"></i></h3>
                <h4 class="text-white mb-0">33<i class="wi wi-degrees"></i></h4>
              </div>
            </div><!-- end row -->
          </div>
        </div><!-- end row -->
      </div><!-- card-box -->
      <!-- END WEATHER WIDGET 2 -->

    </div><!-- /.col-xl-6 -->
  </div> <!-- End row -->

  <div class="row">
    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Total Revenue</h4>

        <div class="widget-chart text-center">
          <!-- total Revenue area chart-->
          <apx-chart [series]="revenueAreaChart.series" [chart]="revenueAreaChart" [tooltip]="revenueAreaChart.tooltip"
            [stroke]="revenueAreaChart.stroke" [dataLabels]="revenueAreaChart.dataLabels"
            [legend]="revenueAreaChart.legend" [grid]="revenueAreaChart.grid" [xaxis]="revenueAreaChart.xaxis"
            [colors]="revenueAreaChart.colors">
          </apx-chart>
          <!-- end revenue chart-->
          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4>$943</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4>$624</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4>$904</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Total Revenue</h4>

        <div class="widget-chart text-center">
          <!-- Revenue bar chart-->
          <apx-chart class="chartcolor" [series]="revenueBarChart.series" [chart]="revenueBarChart"
            [tooltip]="revenueBarChart.tooltip" [colors]="revenueBarChart.color"
            [dataLabels]="revenueBarChart.dataLabels" [legend]="revenueBarChart.legend"
            [stroke]="revenueBarChart.stroke" [grid]="revenueBarChart.grid" [xaxis]="revenueBarChart.xaxis"
            [plotOptions]="revenueBarChart.plotOptions"></apx-chart>
          <!-- Revenue bar chart-->

          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4>$1022</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4>$538</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4>$988</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Total Revenue</h4>

        <div class="widget-chart text-center">
          <!-- Weekly sales report pie chart -->
          <apx-chart [series]="revenuePieChart.series" [chart]="revenuePieChart"
            [colors]="revenuePieChart.piechartcolor" [labels]="revenuePieChart.labels"
            [dataLabels]="revenuePieChart.dataLabels" [tooltip]="revenuePieChart.tooltip"
            [legend]="revenuePieChart.legend" [plotOptions]="revenuePieChart.option" [grid]="revenuePieChart.grid">
          </apx-chart>
          <!-- end Weekly sales pie chart -->
          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4>$1022</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4>$538</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4>$988</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Total Revenue</h4>

        <div class="widget-chart text-center">
          <!-- Revenue Line chart -->
          <apx-chart [series]="revenueLineChart.series" [chart]="revenueLineChart" [tooltip]="revenueLineChart.tooltip"
            [stroke]="revenueLineChart.stroke" [dataLabels]="revenueLineChart.dataLabels"
            [legend]="revenueLineChart.legend" [grid]="revenueLineChart.grid" [xaxis]="revenueLineChart.xaxis"
            [colors]="revenueLineChart.colors">
          </apx-chart>
          <!-- End revenue Line Chart -->
          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4>$943</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4>$624</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4>$904</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Total Revenue</h4>
        <div class="widget-chart text-center">
          <!-- Revenue Realtime chart -->
          <apx-chart [series]="revenueRealTimeChart.series" [chart]="revenueRealTimeChart"
            [tooltip]="revenueRealTimeChart.tooltip" [stroke]="revenueRealTimeChart.stroke"
            [dataLabels]="revenueRealTimeChart.dataLabels" [legend]="revenueRealTimeChart.legend"
            [grid]="revenueRealTimeChart.grid" [xaxis]="revenueRealTimeChart.xaxis"
            [colors]="revenueRealTimeChart.colors">
          </apx-chart>
          <!-- End Realtime Chart -->
          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4>$1022</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4>$538</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4>$988</h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Total Revenue</h4>
        <div class="widget-chart text-center">
          <!-- Revenue line column chart -->
          <apx-chart [series]="revenueLineColumnChart.series" [chart]="revenueLineColumnChart"
            [tooltip]="revenueLineColumnChart.tooltip" [stroke]="revenueLineColumnChart.stroke"
            [dataLabels]="revenueLineColumnChart.dataLabels" [legend]="revenueLineColumnChart.legend"
            [grid]="revenueLineColumnChart.grid" [xaxis]="revenueLineColumnChart.xaxis"
            [colors]="revenueLineColumnChart.colors">
            <!--End Revenue line column chart -->
          </apx-chart>
          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4>$1022</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4>$538</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4>$988</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- INBOX -->
    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Inbox</h4>
        <div class="inbox-widget" appSlimScroll style="max-height: 394px;">
          <div class="inbox-item" *ngFor="let inbox of inboxData">
            <a href="javascript: void(0);">
              <div class="inbox-item-img"><img src="{{inbox.image}}" class="rounded-circle" alt="">
              </div>
              <p class="inbox-item-author">{{inbox.name}}</p>
              <p class="inbox-item-text">{{inbox.message}}</p>
              <p class="inbox-item-date">{{inbox.time}}</p>
            </a>
          </div>
        </div>
      </div>
    </div> <!-- end col -->

    <!-- CHAT -->
    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="header-title mb-3">Chat</h4>
        <div class="chat-conversation">
          <ul class="conversation-list" appSlimScroll style="max-height: 340px;">
            <li class="clearfix" *ngFor="let chat of chatdata" [ngClass]="
            {
              'odd': chat.name === 'Smith'
            }">
              <div class="chat-avatar">
                <img src="{{chat.image}}" alt="male">
                <i>{{chat.time}}</i>
              </div>
              <div class="conversation-text">
                <div class="ctext-wrap">
                  <i>{{chat.name}}</i>
                  <p>{{chat.message}}</p>
                </div>
              </div>
            </li>
          </ul>
          <form (ngSubmit)="messageSave()" [formGroup]="formData">
            <div class="row">
              <div class="col-lg-9">
                <input type="text" class="form-control" placeholder="Enter your text" formControlName="message"
                  [ngClass]="{'is-invalid': chatSubmit && form.message.errors}">
                <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                  <span *ngIf="form.message.errors.required">This value is required.</span>
                </div>
              </div>

              <div class="col-lg-3">
                <button type="submit" class="btn btn-primary width-sm waves-effect waves-light">Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END CHAT-->

    <!-- TODO -->
    <div class="col-xl-4">
      <div class="card-box">
        <h4 class="mb-3 header-title">Todo</h4>
        <div class="todoapp">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <h5 id="todo-message"><span id="todo-remaining">{{completedTodo.length}}</span> of <span
                  id="todo-total">{{todoData.length}}</span> remaining</h5>
            </div>
            <div class="col-sm-6">
              <a href="javascript: void(0);" class="float-right btn btn-primary btn-sm waves-effect waves-light"
                id="btn-archive" (click)="archiveTodo()">Archive</a>
            </div>
          </div>
          <div appSlimScroll>
            <ul class="list-group list-group-flush todo-list" style="max-height: 315px" id="todo-list">
              <li class="list-group-item border-0 pl-1 checkbox checkbox-primary"
                *ngFor="let todo of todoData;let i = index;">
                <input type="checkbox" [checked]="todo?.done" (change)="changeStatus(i)" id="{{i}}"><label
                  for="{{i}}">{{todo.text}}</label>
              </li>
            </ul>
          </div>
          <form (ngSubmit)="saveTodo()" [formGroup]="formTodoData">
            <div class="row">
              <div class="col-lg-9">
                <input type="text" class="form-control" placeholder="Enter your text" formControlName="text"
                  [ngClass]="{'is-invalid': todoSubmit && form1.text.errors}">
                <div *ngIf="todoSubmit && form1.text.errors" class="invalid-feedback">
                  <span *ngIf="form1.text.errors.required">This value is required.</span>
                </div>
              </div>

              <div class="col-lg-3">
                <button type="submit" class="btn btn-primary width-sm waves-effect waves-light">Add</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- END TODO -->
  </div>
</div>

<!-- First row widget data -->
<ng-template #CardData let-card='card'>
  <div class="col-xl-3 col-md-6">
    <div class="widget-simple text-center card-box">
      <h3 class="text-{{card.color}} mt-0"><span [CountTo]="card.value" [from]="0" [duration]="1">{{card.value}}</span>
      </h3>
      <p class="text-muted mb-0">{{card.title}}</p>
    </div>
  </div>
</ng-template>

<!-- Second and third row widget data -->
<ng-template #WidgetData let-widgetdata='widgetdata'>
  <div class="col-xl-3 col-md-6">
    <div class="widget-bg-color-icon card-box">
      <div class="avatar-lg rounded-circle bg-icon-{{widgetdata.color}} float-left">
        <i class="{{widgetdata.icon}} font-24 avatar-title text-white"></i>
      </div>
      <div class="text-right">
        <h3 class="text-dark mt-1"><span [CountTo]="widgetdata.number" [from]="0" [duration]="1"></span></h3>
        <p class="text-muted mb-0">{{widgetdata.title}}</p>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</ng-template>