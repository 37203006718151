import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CommandURL} from '../../shared/constant/ManageURLCommand';

@Injectable({
    providedIn: 'root'
})
export class NetworkManagementService {

    constructor(private http: HttpClient) {
    }

    getCurrentConnect(cameraId: string) {
        return this.http.post<any>(CommandURL.GET_CURRENT_NETWORK_URL, {cameraId});
    }

    getWifiList(cameraId: string) {
        return this.http.post<any>(CommandURL.GET_WIFI_LIST_URL, {cameraId});
    }

    connectWifi(cameraId: string, ssid: string, password: string) {
        return this.http.post<any>(CommandURL.CONNECT_WIFI_URL, {cameraId, ssid, password});
    }
}
