import {Component, OnInit} from '@angular/core';
import {UserManagementService} from './user-management.service';
import {Personal} from '../../shared/model/main';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-user-management',
    templateUrl: './user-management.component.html',
    styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
    breadCrumbItems: Array<{}>;
    personal: Personal = {};

    infoForm: FormGroup;
    submitted = false;
    loading = false;
    email = '';
    phone = '';
    type = 'email';

    updateForm: FormGroup;
    verifyForm: FormGroup;

    sentAuth = false;
    goInt: number;
    secondWait = 30;

    keyLang: any;
    arrKeyLang = [
        'notify.update_data_success', 'success'
    ];

    constructor(private userManagementService: UserManagementService,
                private formBuilder: FormBuilder,
                private router: Router,
                private modalService: NgbModal,
                private translate: TranslateService
    ) {
        this.infoForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            //    Validators.pattern('[a-zA-Z0-9]+')
        });

        this.updateForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]],
        });

        this.verifyForm = this.formBuilder.group({
            token: ['', [Validators.required]],
        });
    }

    get f() {
        return this.infoForm.controls;
    }

    get uf() {
        return this.updateForm.controls;
    }

    get vf() {
        return this.verifyForm.controls;
    }

    success = () => {
        Swal.fire({
            title: this.keyLang.success + '!',
            text: this.keyLang['notify.update_data_success'],
            type: 'success',
            showConfirmButton: false,
            // confirmButtonClass: 'btn btn-confirm mt-2'
        });
    }

    ngOnInit() {
        this.translate.stream(this.arrKeyLang).subscribe(data => {
            this.keyLang = data;
            this.breadCrumbItems = [{label: 'Aivis', path: '/list-camera'}, {
                label: data.profile,
                active: true
            }];
        });
        this.fetchData();
    }

    fetchData() {
        this.userManagementService.getPersonalInfo().subscribe(data => {
            if (data) {
                this.personal = data;
                this.f.username.setValue(data.username);
                this.email = data.email;
                this.phone = data.phone;
            }
        });
    }

    updateInfo() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.infoForm.invalid) {
            return;
        }
        this.loading = true;

        this.userManagementService.updatePersonalInfo({username: this.f.username.value}).subscribe(data => {
            if (data) {
                this.loading = false;
                this.submitted = false;
                this.fetchData();
                this.success();
            }
        }, error => this.loading = false);

    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.updateForm.invalid) {
            return;
        }

        this.loading = true;

        if (this.type === 'email') {
            this.userManagementService.updateEmail(this.uf.email.value).subscribe(data => {
                if (data) {
                    this.loading = false;
                    this.sentAuth = true;
                    this.submitted = false;
                    this.secondWaitReduce();
                }
            }, error => {
                this.loading = false;
            });
        } else {
            this.userManagementService.updatePhone(this.uf.phone.value).subscribe(data => {
                if (data) {
                    this.loading = false;
                    this.sentAuth = true;
                    this.submitted = false;
                    this.secondWaitReduce();
                }
            }, error => {
                this.loading = false;
            });
        }
    }

    onSubmitToken() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.verifyForm.invalid) {
            return;
        }

        this.loading = true;
        if (this.type === 'email') {
            this.userManagementService.getTokenVerifyByEmail(this.vf.token.value).subscribe(data => {
                if (data) {
                    this.finnishUpdate();
                }
            });
        } else {
            this.userManagementService.getTokenVerifyByPhone(this.vf.token.value).subscribe(data => {
                if (data) {
                    this.finnishUpdate();
                }
            });
        }
    }

    finnishUpdate() {
        this.loading = false;
        this.sentAuth = false;
        this.modalService.dismissAll();
        this.fetchData();
        this.success();
    }

    updateAuth(type: string, content: any) {
        this.type = type;
        this.changeValidators();
        this.modalService.open(content, {centered: true});
    }

    secondWaitReduce() {
        clearInterval(this.goInt);
        this.goInt = setInterval(() => {
            if (this.secondWait < 1) {
                clearInterval(this.goInt);
            }
            this.secondWait -= 1;
        }, 1000);
    }

    changeValidators() {
        if (this.type === 'email') {
            this.updateForm.controls.email.setValidators([Validators.required, Validators.email]);
            this.updateForm.controls.phone.clearValidators();
        } else {
            this.updateForm.controls.phone.setValidators([Validators.required]);
            this.updateForm.controls.email.clearValidators();
        }

        this.updateForm.get('email').updateValueAndValidity();
        this.updateForm.get('phone').updateValueAndValidity();
    }

    goToChangePassword() {
        this.router.navigate(['/account/change-password']);
    }
}
