<div class="row">
    <div class="col-12">
        <div class="card-box">

            <div class="form-group">
                <div class="custom-control custom-switch">
                    <input [(ngModel)]="alarmStatus" (change)="saveAlarmStatus($event)" class="custom-control-input" id="customSwitch0" type="checkbox">
                    <label class="custom-control-label" for="customSwitch0">Cảnh báo xâm nhập</label>
                </div>
                <div class="custom-control custom-switch">
                    <input [disabled]="!alarmStatus" [(ngModel)]="alarmSoundStatus" (change)="saveAlarmSound($event)" class="custom-control-input" id="customSwitch1" type="checkbox">
                    <label class="custom-control-label" for="customSwitch1">Báo động tại camera</label>
                </div>
                <div class="custom-control custom-switch">
                    <input [disabled]="!alarmStatus" [(ngModel)]="popupNotifyStatus" (change)="saveAlarmPopupNotify($event)" class="custom-control-input" id="customSwitch2" type="checkbox">
                    <label class="custom-control-label" for="customSwitch2">Gửi thông báo tới thiết bị dạng tin nhắn</label>
                </div>
            </div>

            <hr/>

            <h4 class="header-title mb-3">Chọn khung giờ báo động</h4>
<!--            <p class="sub-header">-->
<!--                Add <code>.table-sm</code> to make tables more compact by cutting cell padding in half.-->
<!--            </p>-->

            <div class="table-responsive">
                <table class="table table-sm mb-0">
                    <thead>
                    <tr>
                        <th></th>
                        <th class="text-center" *ngFor="let item of arrDay; let i = index;">
                            {{item | translate}}
                            <div class="custom-control custom-checkbox mt-2">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + 'col'"
                                       (change)="checkAllHour($event, i)" [checked]="checkingAllHour(i)">
                                <label class="custom-control-label" [for]="i + 'col'"></label>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let hour of arrTime; let i = index">
                        <th scope="row">
                            <div class="custom-control custom-checkbox">
                            <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + 'row'"
                                   (change)="checkAllDay($event, i)" [checked]="checkingAllDay(i)">
                            <label class="custom-control-label" [for]="i + 'row'">{{hour}}</label>
                        </div>
                        </th>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + '0'"
                                (change)="checkTimeAlarm($event, i, 0)" [checked]="arrTimeChecked[i][0]">
                                <label class="custom-control-label" [for]="i + '0'"></label>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + '1'"
                                       (change)="checkTimeAlarm($event, i, 1)" [checked]="arrTimeChecked[i][1]">
                                <label class="custom-control-label" [for]="i + '1'"></label>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + '2'"
                                       (change)="checkTimeAlarm($event, i, 2)" [checked]="arrTimeChecked[i][2]">
                                <label class="custom-control-label" [for]="i + '2'"></label>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + '3'"
                                       (change)="checkTimeAlarm($event, i, 3)" [checked]="arrTimeChecked[i][3]">
                                <label class="custom-control-label" [for]="i + '3'"></label>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + '4'"
                                       (change)="checkTimeAlarm($event, i, 4)" [checked]="arrTimeChecked[i][4]">
                                <label class="custom-control-label" [for]="i + '4'"></label>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + '5'"
                                       (change)="checkTimeAlarm($event, i, 5)" [checked]="arrTimeChecked[i][5]">
                                <label class="custom-control-label" [for]="i + '5'"></label>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="custom-control custom-checkbox">
                                <input [disabled]="!alarmStatus" type="checkbox" class="custom-control-input" [id]="i + '6'"
                                       (change)="checkTimeAlarm($event, i, 6)" [checked]="arrTimeChecked[i][6]">
                                <label class="custom-control-label" [for]="i + '6'"></label>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->

            <div class="row justify-content-center mt-3">
                <button [disabled]="!alarmStatus" (click)="saveAlarmHour()" class="btn btn-sm btn-primary my-3">
                    {{'save' | translate}} <i class="ml-1 fas fa-save"></i>
                </button>
            </div>
        </div>
    </div>
</div>
