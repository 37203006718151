<div class="container-fluid">
  <app-page-title title="Inbox" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">

    <!-- Right Sidebar -->
    <div class="col-12">
      <div class="card-box">
        <!-- Left sidebar -->
        <div class="inbox-leftbar">
          <a routerLink="/email-compose"
            class="btn btn-danger btn-block waves-effect waves-light mb-4">Compose</a>

          <app-emaillist></app-emaillist>
          <!--Leftbar email label and list-->
        </div>
        <!--End Left sidebar -->

        <!--Right Sidebar-->
        <div class="inbox-rightbar">
          <app-widget></app-widget>
          <!--Button Group-->
          <div class="mt-3">
            <ul class="message-list">
              <li class="unread" *ngFor="let email of emailData | slice:0:15; let i = index;"
                [ngClass]="{ 'unread': email.unread === true }">
                <div class="col-mail col-mail-1">
                  <div class="checkbox-wrapper-mail">
                    <input type="checkbox" id="chk-{{i}}" />
                    <label for="chk-{{i}}"></label>
                  </div>
                  <span class="star-toggle far fa-star text-{{email.text}}"></span>
                  <a href="" class="title">{{email.title}}</a>
                </div>
                <div class="col-mail col-mail-2">
                  <a href="" class="subject">{{email.subject}}
                  </a>
                  <div class="date">{{email.date | date:'MMM d'}}</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="row justify-content-md-between align-items-md-center">
            <div class="col-7">
              Showing {{startIndex}} - {{endIndex}} of {{totalRecords}}
            </div> <!-- end col-->
            <div class="col-5">
              <div class="text-md-right float-md-right mt-2">
                <ngb-pagination [collectionSize]="totalRecords" (pageChange)="onPageChange($event)" [(page)]="page" [pageSize]="pageSize">
                </ngb-pagination>
                <!--Pagination-->
              </div>
            </div> <!-- end col-->
          </div>
        </div>
        <!--End Right Sidebar-->
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>