<div class="container-fluid">
    <app-page-title [breadcrumbItems]="breadCrumbItems" title="{{'profile' | translate}}"></app-page-title>

    <div class="row">
        <div class="col-lg-4 col-xl-4">
            <div class="card-box text-center">

                <i class="fas fa-user-circle fa-7x"></i>

                <h4 class="mb-0">{{personal.username}}</h4>
                <div class="text-left mt-3">

                    <p class="text-muted mb-2 font-13"><strong>{{'full_name' | translate}} :</strong>
                        <span class="ml-2">{{personal.username}}</span></p>

                    <p class="text-muted mb-2 font-13"><strong>{{'phone.number' | translate}} :</strong>
                        <span class="ml-2">{{personal.phone}}</span></p>

                    <p class="text-muted mb-2 font-13"><strong>Email :</strong>
                        <span class="ml-2 ">{{personal.email}}</span></p>
                </div>

            </div> <!-- end card-box -->

        </div> <!-- end col-->

        <div class="col-lg-8 col-xl-8">
            <div class="card-box">
                <ngb-tabset type="pills">
                    <ngb-tab title="{{'persion_info' | translate}}">
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <div class="tab-pane show active" id="about-me">

                                    <form (ngSubmit)="updateInfo()" [formGroup]="infoForm" class="needs-validation"
                                          name="signupForm">
                                        <h5 class="mb-3 text-uppercase bg-light p-2"><i
                                                class="mdi mdi-account-circle mr-1"></i>
                                            {{'persion_info' | translate}}</h5>

                                        <app-ui-preloader [display]="loading"></app-ui-preloader>
                                        <div class="form-group mb-3">
                                            <label>{{'username' | translate}}<span class="text-danger">*</span></label>

                                            <input [ngClass]="{ 'is-invalid': (submitted && f.username.errors)}"
                                                   class="form-control"
                                                   formControlName="username"
                                                   id="username" placeholder="{{'username' | translate}}"/>

                                            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                                <span *ngIf="f.username.errors.required">{{'notify.validate.username_plz' | translate}}</span>
                                                <!--                                                <span *ngIf="f.username.errors.pattern">Please enter user.</span>-->
                                            </div>
                                        </div>

                                        <!--                                        <div class="row">-->
                                        <!--                                            <div class="col-md-6">-->
                                        <!--                                                <div class="form-group">-->
                                        <!--                                                    <label for="firstname">First Name</label>-->
                                        <!--                                                    <input class="form-control" id="firstname" placeholder="Enter first name"-->
                                        <!--                                                           type="text">-->
                                        <!--                                                </div>-->
                                        <!--                                            </div>-->
                                        <!--                                            <div class="col-md-6">-->
                                        <!--                                                <div class="form-group">-->
                                        <!--                                                    <label for="lastname">Last Name</label>-->
                                        <!--                                                    <input class="form-control" id="lastname" placeholder="Enter last name"-->
                                        <!--                                                           type="text">-->
                                        <!--                                                </div>-->
                                        <!--                                            </div> &lt;!&ndash; end col &ndash;&gt;-->
                                        <!--                                        </div> &lt;!&ndash; end row &ndash;&gt;-->

                                        <div class="text-right">
                                            <button class="btn btn-success waves-effect waves-light mt-2" type="submit">
                                                <i
                                                        class="mdi mdi-content-save"></i> {{'update' | translate}}
                                            </button>
                                        </div>
                                    </form>

                                    <h5 class="mb-3 text-uppercase bg-light p-2"><i
                                            class="mdi mdi-account-circle mr-1"></i>
                                        {{'security' | translate}}</h5>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div *ngIf="email" class="form-group">
                                                <label for="useremail">{{'email.address' | translate}}</label>
                                                <input [ngModel]="email" class="form-control" id="useremail"
                                                       placeholder="{{'email.enter' | translate}}" readonly
                                                       type="email">
                                                <span class="form-text text-muted"><small>{{('email.notify' | translate) + ' ' }}
                                                    <a (click)="updateAuth('email', update)"
                                                       href="javascript: void(0);">
                                                            {{('click' | translate) + ' ' }}
                                                        </a>{{('here' | translate)}}.</small></span>
                                            </div>

                                            <div *ngIf="!email" class="form-group">
                                                <label>{{'email.address' | translate}}</label>
                                                <button (click)="updateAuth('email', update)"
                                                        class="btn btn-primary form-control">{{'email.update' | translate}}
                                                </button>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div *ngIf="phone" class="form-group">
                                                <label for="userpassword">{{'phone.number' | translate}}</label>
                                                <input [ngModel]="phone" class="form-control" id="userpassword"
                                                       placeholder="{{'phone.enter' | translate}}" readonly type="text">
                                                <span class="form-text text-muted"><small>{{'phone.notify' | translate}}
                                                    <a (click)="updateAuth('phone', update)"
                                                       href="javascript: void(0);">
                                                        {{('click' | translate) + ' ' }}
                                                        </a>{{('here' | translate)}}.</small></span>
                                            </div>

                                            <div *ngIf="!phone" class="form-group">
                                                <label>{{'phone.number' | translate}}</label>
                                                <button (click)="updateAuth('phone', update)"
                                                        class="btn btn-primary form-control">{{'phone.update' | translate}}
                                                </button>
                                            </div>
                                        </div> <!-- end col -->

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>{{'password' | translate}}</label>
                                                <button (click)="goToChangePassword()"
                                                        class="btn btn-warning form-control">
                                                    {{'password_change' | translate}}
                                                </button>
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>

                    <!--                    <ngb-tab title="Settings">-->
                    <!--                        <ng-template ngbTabContent>-->
                    <!--                            <div class="tab-pane" id="settings">-->
                    <!--                                <h5 class="mb-3 text-uppercase bg-light p-2"><i-->
                    <!--                                        class="mdi mdi-account-circle mr-1"></i>-->
                    <!--                                    Bảo mật</h5>-->

                    <!--                                <div class="row">-->
                    <!--                                    <div class="col-md-6">-->
                    <!--                                        <div *ngIf="email" class="form-group">-->
                    <!--                                            <label for="useremail">Email Address</label>-->
                    <!--                                            <input [ngModel]="email" class="form-control" id="useremail"-->
                    <!--                                                   placeholder="Enter email" readonly type="email">-->
                    <!--                                            <span class="form-text text-muted"><small>If you want to change email-->
                    <!--                                                        please-->
                    <!--                                                        <a href="javascript: void(0);" (click)="updateAuth('email', update)">click</a> here.</small></span>-->
                    <!--                                        </div>-->

                    <!--                                        <div *ngIf="!email" class="form-group">-->
                    <!--                                            <label>Email Address</label>-->
                    <!--                                            <button (click)="updateAuth('email', update)"-->
                    <!--                                                    class="btn btn-primary form-control">Cập nhật email-->
                    <!--                                            </button>-->
                    <!--                                        </div>-->
                    <!--                                    </div>-->

                    <!--                                    <div class="col-md-6">-->
                    <!--                                        <div *ngIf="phone" class="form-group">-->
                    <!--                                            <label for="userpassword">Phone number</label>-->
                    <!--                                            <input [ngModel]="phone" class="form-control" id="userpassword"-->
                    <!--                                                   placeholder="Enter password" readonly type="text">-->
                    <!--                                            <span class="form-text text-muted"><small>If you want to change number phone-->
                    <!--                                                        please <a href="javascript: void(0);" (click)="updateAuth('phone', update)">click</a>-->
                    <!--                                                        here.</small></span>-->
                    <!--                                        </div>-->

                    <!--                                        <div *ngIf="!phone" class="form-group">-->
                    <!--                                            <label>Phone number</label>-->
                    <!--                                            <button (click)="updateAuth('phone', update)"-->
                    <!--                                                    class="btn btn-primary form-control">Cập nhật số điện thoại-->
                    <!--                                            </button>-->
                    <!--                                        </div>-->
                    <!--                                    </div> &lt;!&ndash; end col &ndash;&gt;-->

                    <!--                                    <div class="col-md-6">-->
                    <!--                                        <div class="form-group">-->
                    <!--                                            <label>Mật khẩu</label>-->
                    <!--                                            <button (click)="goToChangePassword()" class="btn btn-warning form-control">-->
                    <!--                                                Thay đổi mật khẩu-->
                    <!--                                            </button>-->
                    <!--                                        </div>-->
                    <!--                                    </div> &lt;!&ndash; end col &ndash;&gt;-->
                    <!--                                </div> &lt;!&ndash; end row &ndash;&gt;-->
                    <!--                            </div>-->
                    <!--                            &lt;!&ndash; end settings content&ndash;&gt;-->
                    <!--                        </ng-template>-->
                    <!--                    </ngb-tab>-->

                </ngb-tabset>
            </div>
        </div>
    </div>
</div>

<ng-template #update let-modal="close">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="modal-header">
        <h4 class="modal-title">{{('update' | translate) + ' '}} {{type === 'email' ? 'email' : ('phone.number' | translate).toLowerCase()}}</h4>
        <button (click)="modal('Cross click')" aria-hidden="true" class="close" type="button">×</button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <form (ngSubmit)="onSubmit()" [formGroup]="updateForm"
                      [hidden]="sentAuth" class="needs-validation" name="updateForm"
                      novalidate>
                    <div class="form-group mb-3">
                        <label class="">{{type === 'email' ? 'Email' : ('phone.number' | translate)}}<span
                                class="text-danger">*</span></label>
                        <div class="input-group mb-3">
                            <input [hidden]="type === 'phone'"
                                   [ngClass]="{ 'is-invalid': submitted && uf.email.errors }"
                                   aria-describedby="basic-addon1" aria-label="" class="form-control"
                                   formControlName="email" placeholder="{{'email.enter' | translate}}"
                                   type="text">
                            <div *ngIf="submitted && uf.email.errors && type === 'email'"
                                 class="invalid-feedback">
                                <div *ngIf="uf.email.errors.required">{{'notify.validate.email'}}</div>
                                <div *ngIf="uf.email.errors.email">{{'notify.validate.email_syntax'}}</div>
                            </div>

                            <input [hidden]="type === 'email'"
                                   [ngClass]="{ 'is-invalid': submitted && uf.phone.errors }"
                                   aria-describedby="basic-addon1" aria-label="" class="form-control"
                                   formControlName="phone" placeholder="{{'phone.enter' | translate}}"
                                   type="text">
                            <div *ngIf="submitted && uf.phone.errors && type === 'phone'"
                                 class="invalid-feedback">
                                <div *ngIf="uf.phone.errors.required">{{'notify.validate.phone'}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-0 text-center">
                        <button class="btn btn-primary btn-block" type="submit">{{'send' | translate}}</button>
                    </div>
                </form>

                <form (ngSubmit)="onSubmitToken()" [formGroup]="verifyForm" [hidden]="!sentAuth"
                      class="needs-validation" name="verifyForm"
                      novalidate>
                    <div class="form-group mb-3">
                        <label>{{'verify.codes' | translate}}<span class="text-danger">*</span></label>

                        <input [ngClass]="{ 'is-invalid': submitted && vf.token.errors}" class="form-control"
                               formControlName="token"
                               placeholder="{{'verify.enter' | translate}}"/>

                        <div *ngIf="submitted && vf.token.errors" class="invalid-feedback">
                            <span *ngIf="vf.token.errors.required">{{'notify.validate.verify_codes'}}</span>
                        </div>
                    </div>

                    <div class="form-group mb-1 text-center">
                        <button (click)="onSubmit()" [disabled]="secondWait > 1"
                                class="btn btn-warning btn-block"
                                type="button">{{('verify.send_again' | translate) + ' '}}
                            {{secondWait > 1 ? ('after' | translate) + ' ' : ''}}
                            <span *ngIf="secondWait > 1" class="text-danger">{{secondWait | number}}s</span>
                        </button>
                    </div>

                    <div class="form-group mb-0 text-center">
                        <button class="btn btn-primary btn-block"
                                type="submit">{{'confirm' | translate}}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
