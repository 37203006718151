<div class="container-fluid">
  <app-page-title title="Summernote" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Default Editor</h4>
          <p class="sub-header">Super simple wysiwyg editor on Bootstrap</p>
          <!-- Editor -->
<!--          <app-ngx-editor [placeholder]="'Enter text '" [spellcheck]="true" [height]="'250px'"></app-ngx-editor>-->
        </div>
      </div>
    </div>
  </div>
</div>
