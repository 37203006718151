<div class="row">
    <app-ui-preloader [display]="loading"></app-ui-preloader>
    <div class="col-12">
        <div class="card-box">
            <div class="row mb-3">
                <div class="col-6">
                    <h5>Wifi: <span class="text-success">{{wifi}}</span></h5>
                </div>

                <div class="col-6">
                    <h5>Ethernet: <span class="text-primary">{{ethernet}}</span></h5>
                </div>
            </div>

            <h4 class="header-title">{{'wifi.list_connect' | translate}}</h4>
            <div class="row">
                <div class="col-12 mb-3">
                    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="static-1">
                        <ngb-panel *ngFor="let item of listConnection; let i = index;" id="network-{{i}}">
                            <ng-template ngbPanelTitle>
                                <i class="mr-3 mdi mdi-wifi-strength-{{rankStrength(item.Strength)}}"></i>
                                <span class="h5 text-primary m-0">{{item.Ssid}}</span>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <ng-template [ngTemplateOutletContext]="{ssid: item.Ssid}"
                                             [ngTemplateOutlet]="TabContent">
                                </ng-template>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div><!-- End Accordion -->

            </div> <!-- end card-box -->
        </div> <!-- end col -->
    </div>
</div>

<ng-template #TabContent let-ssid='ssid'>
    <div class="form-group row">
        <label class="col-sm-2 col-form-label" for="simpleinput">{{'password' | translate}}</label>
        <div class="col-sm-8 mb-2">
            <input [(ngModel)]="password" class="form-control" id="simpleinput" type="text" value="{{'enter_password' | translate}}">
        </div>
        <div class="col-sm-2">
            <button (click)="connectWifi(ssid)" class="btn btn-primary w-100">{{'connect' | translate}}</button>
        </div>
    </div>
</ng-template>
