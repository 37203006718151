import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.scss']
})

/**
 * Error-500 component - handling the error-500
 */
export class Error500Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
