<div class="container-fluid">
  <app-page-title title="Team Members" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <div class="row">
          <div class="col-lg-8">
            <form class="form-inline">
              <div class="form-group">
                <label class="sr-only">Search</label>
                <input type="search" class="form-control" placeholder="Search..." name="search" [(ngModel)]="term">
              </div>
              <div class="form-group mx-sm-3">
                <label for="status-select" class="mr-2">Sort By</label>
                <select class="custom-select" id="status-select">
                  <option selected="">All</option>
                  <option value="1">Name</option>
                  <option value="2">Post</option>
                  <option value="3">Followers</option>
                  <option value="4">Followings</option>
                </select>
              </div>
            </form>
          </div>
          <div class="col-lg-4">
            <div class="text-lg-right mt-3 mt-lg-0">
              <button type="button" class="btn btn-success waves-effect waves-light mr-1"><i
                  class="mdi mdi-settings"></i></button>
              <a class="btn text-white btn-danger waves-effect waves-light" (click)="openModal(content)"><i
                  class="mdi mdi-plus-circle mr-1"></i> Add New
              </a>
            </div>
          </div><!-- end col-->
        </div> <!-- end row -->
      </div> <!-- end card-box -->
    </div><!-- end col-->
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-lg-4" *ngFor="let team of paginatedTeamData |filter:term">
      <div class="text-center card-box ribbon-box">
        <div class="ribbon-two ribbon-two-success"><span *ngIf="team.admin">Admin</span></div>
        <div class="clearfix"></div>
        <div class="pt-2 pb-2">
          <img src="{{ team.user }}" class="rounded-circle img-thumbnail avatar-xl" alt="profile-image">

          <h4 class="mt-3 font-17"><a routerLink="/extras/profile" class="text-dark">{{team.name}}</a></h4>
          <p class="text-muted">{{team.designation}} <span> | </span> <span> <a href="javascript: void(0);"
                class="text-pink">{{team.website}}</a> </span></p>

          <p class="text-muted font-13 mb-3">
            {{team.description}}
          </p>

          <button type="button" class="btn btn-primary btn-sm waves-effect waves-light"><i
              class="mdi mdi-email mr-1"></i> Message</button>
          <button type="button" class="btn btn-light btn-sm waves-effect ml-1"><i class="mdi mdi-phone mr-1"></i>
            Call</button>

          <ul class="social-list list-inline mt-3 mb-0">
            <li class="list-inline-item">
              <a href="javascript: void(0);" class="social-list-item border-purple text-purple" [ngClass]="
                    {
                      'border-purple text-purple': team.icon[0] === 'mdi mdi-facebook',
                      'border-danger text-danger': team.icon[0] === 'mdi mdi-google'
                    }"><i class="{{team.icon[0]}}"></i></a>
            </li>
            <li class="list-inline-item">
              <a href="javascript: void(0);" class="social-list-item border-danger text-danger" [ngClass]="
                    {
                      'border-danger text-danger': team.icon[1] === 'mdi mdi-google',
                      'border-info text-info': team.icon[1] === 'mdi mdi-twitter'
                    }"><i class="{{team.icon[1]}}"></i></a>
            </li>
            <li class="list-inline-item">
              <a href="javascript: void(0);" *ngIf="team.icon[2]"
                class="social-list-item border-secondary text-secondary" [ngClass]="
                    {
                      'border-info text-info': team.icon[2] === 'mdi mdi-twitter',
                      'border-secondary text-secondary': team.icon[2] === 'mdi mdi-github-circle'
                    }"><i class="{{team.icon[2]}}"></i></a>
            </li>
            <li class="list-inline-item">
              <a href="javascript: void(0);" *ngIf="team.icon[3]"
                class="social-list-item border-secondary text-secondary"><i class="{{team.icon[3]}}"></i></a>
            </li>
          </ul>

        </div> <!-- end .padding -->
      </div> <!-- end card-box-->
    </div> <!-- end col -->
  </div>
  <!--end row-->
  <div class="row">
    <div class="col-12">
      <div class="text-right">
        <ul class="pagination pagination-rounded justify-content-end">
          <ngb-pagination (pageChange)="onPageChange($event)" [pageSize]="pageSize" [(page)]="page"
            [collectionSize]="totalSize"></ngb-pagination>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- Modal  -->
<ng-template #content role="document" let-modal="close">
  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Add Members</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="validationform">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" id="name" placeholder="Enter name" formControlName="name"
            [ngClass]="{'is-invalid': submitted && form.name.errors}">
          <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
            <span *ngIf="form.name.errors.required">This value is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="position">Position</label>
          <input type="text" class="form-control" id="position" placeholder="Enter position" formControlName="position"
            [ngClass]="{'is-invalid': submitted && form.position.errors}">
          <div *ngIf="submitted && form.position.errors" class="invalid-feedback">
            <span *ngIf="form.position.errors.required">This value is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="company">Company </label>
          <input class="form-control" id="company" placeholder="Enter company" formControlName="company"
            [ngClass]="{'is-invalid': submitted && form.company.errors}">
          <div *ngIf="submitted && form.company.errors" class="invalid-feedback">
            <span *ngIf="form.company.errors.required">This value is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email Address </label>
          <input class="form-control" id="email" placeholder="Enter email" formControlName="email"
            [ngClass]="{'is-invalid': submitted && form.email.errors}">
          <div *ngIf="submitted && form.email.errors" class="invalid-feedback">
            <span *ngIf="form.email.errors.required">This value is required.</span>
            <span *ngIf="form.email.errors.pattern">Please enter valid email.</span>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success waves-effect waves-light">Save</button>
          <button type="button" class="btn btn-danger waves-effect waves-light ml-1"
            (click)="modal('close click')">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>