import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../core/services/auth.service';
import Swal from "sweetalert2";

@Component({
    selector: 'app-passwordreset',
    templateUrl: './passwordreset.component.html',
    styleUrls: ['./passwordreset.component.scss']
})
export class PasswordresetComponent implements OnInit, AfterViewInit {

    resetForm: FormGroup;
    verifyForm: FormGroup;
    submitted = false;
    error = '';
    loading = false;

    sentAuth = false;
    goInt: number;
    secondWait = 30;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private authService: AuthenticationService
    ) {}

    // convenience getter for easy access to form fields
    get f() {
        return this.resetForm.controls;
    }

    get vf() {
        return this.verifyForm.controls;
    }

    ngOnInit() {
        this.resetForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
            type: ['email'],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]],
            newPass: ['', [Validators.required, Validators.minLength(6)]],
            confirmpwd: ['', [Validators.required]],
        }, {validators: this.checkPasswords});

        this.verifyForm = this.formBuilder.group({
            token: ['', [Validators.required]],
        });

        this.changeValidators();
    }

    checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        const pass = group.get('newPass').value;
        const confirmPass = group.get('confirmpwd').value;
        return pass === confirmPass ? null : {notSame: true};
    }


    ngAfterViewInit() {
        document.body.classList.add('authentication-bg');
    }

    success = () => {
        Swal.fire({
            title: 'Thành công!',
            text: 'Dữ liệu đã được cập nhật.',
            type: 'success',
            showConfirmButton: false,
            // confirmButtonClass: 'btn btn-confirm mt-2'
        });
    }

    /**
     * On submit form
     */
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.resetForm.invalid) {
            return;
        }

        this.loading = true;

        if (this.f.type.value === 'email') {
            this.authService.getTokenVerifyByEmail(this.f.username.value, this.f.email.value).subscribe(data => {
                if (data) {
                    this.loading = false;
                    this.sentAuth = true;
                    this.submitted = false;
                    this.secondWaitReduce();
                }
            }, error => {
                this.loading = false;
            });
        } else {
            this.authService.getTokenVerifyByPhone(this.f.username.value, this.f.phone.value).subscribe(data => {
                if (data) {
                    this.loading = false;
                    this.sentAuth = true;
                    this.submitted = false;
                    this.secondWaitReduce();
                }
            }, error => {
                this.loading = false;
            });
        }
    }

    onSubmitToken() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.verifyForm.invalid) {
            return;
        }

        this.loading = true;

        this.authService.resetPassword(this.f.username.value, this.vf.token.value, this.f.newPass.value).subscribe(data => {
            if (data) {
                this.loading = false;
                this.success();
                this.router.navigate(['/account/login']);
            }
        });
    }

    changeValidators() {
        if (this.resetForm.get('type').value === 'email') {
            this.resetForm.controls.email.setValidators([Validators.required, Validators.email]);
            this.resetForm.controls.phone.clearValidators();
        } else {
            this.resetForm.controls.phone.setValidators([Validators.required]);
            this.resetForm.controls.email.clearValidators();
        }

        this.resetForm.get('email').updateValueAndValidity();
        this.resetForm.get('phone').updateValueAndValidity();
    }

    secondWaitReduce() {
        clearInterval(this.goInt);
        this.goInt = setInterval(() => {
            if (this.secondWait < 1) {
                clearInterval(this.goInt);
            }
            this.secondWait -= 1;
        }, 1000);
    }
}
