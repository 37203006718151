import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'timePipe'
})
export class TimePipePipe implements PipeTransform {

    transform(timeValue: string): any {
        if (!timeValue) {
            return '';
        }
        const lastIndex = timeValue.lastIndexOf('T');
        const hour = timeValue.substr(lastIndex + 1, 2);
        const minute = timeValue.substr(lastIndex + 4, 2);
        const second = timeValue.substr(lastIndex + 7, 2);
        let minuteNext = minute;
        const secondNext = (parseFloat(second) + 30) > 59 ?
            ((parseFloat(second) + 30 - 60) === 0 ? '00' : (parseFloat(second) + 30 - 60).toString())
            :
            (parseFloat(second) + 30).toString();

        if ((parseFloat(second) + 30) > 59) {
            minuteNext = (parseFloat(minute) + 1).toString();
        }
        return hour + ':' + minute + ':' + second;
        // return (minute + ':' + second + ' - ' + (parseFloat(minuteNext) < 60 ? minuteNext : '00') + ':' + secondNext) || '';
    }

}
