<div class="container-fluid">
    <app-page-title title="Timeline" [breadcrumbItems]="breadCrumbItems"></app-page-title>
    
    <div class="row">
        <div class="col-12">
            <div class="timeline" dir="ltr">

                <article class="timeline-item" *ngFor="let data of timelineData"
                    [ngClass]="{ 'timeline-item-left': data.align === 'left'}">
                    <div *ngIf="data.button">
                        <h2 class="m-0 d-none">&nbsp;</h2>
                        <div class="time-show" *ngIf="data.button">
                            <a href="javascript: void(0);" class="btn btn-primary width-lg">{{data.button}}</a>
                        </div>
                    </div>

                    <div class="timeline-desk">
                        <div class="timeline-box" *ngIf="!data.button">
                            <span class="arrow" [ngClass]="{'arrow-alt': data.align === 'left',
                                                            'arrow': data.align !== 'left'}"></span>
                            <span class="timeline-icon"><i class="mdi mdi-adjust"></i></span>
                            <h4 class="mt-0 font-16">{{data.time}}</h4>
                            <p class="text-muted"><small>{{data.minute}}</small></p>
                            <p class="mb-0">{{data.description}} </p>
                            <div class="timeline-album" *ngIf="data.image">
                                <a href="javascript: void(0);">
                                    <img alt="" src="{{data.image[0]}}">
                                </a>
                                <a href="javascript: void(0);">
                                    <img alt="" src="{{data.image[1]}}">
                                </a>
                                <a href="javascript: void(0);">
                                    <img alt="" src="{{data.image[2]}}">
                                </a>
                            </div>
                        </div>
                    </div>
                </article>

            </div>
            <!-- end timeline -->
        </div> <!-- end col -->
    </div>
</div>