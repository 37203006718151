import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgApexchartsModule } from 'ng-apexcharts';

import { ChartRoutingModule } from './chart.routing.module';
import { UIModule } from '../../shared/ui/ui.module';

import { ApexComponent } from './apex/apex.component';

@NgModule({
  declarations: [ApexComponent],
  imports: [
    CommonModule,
    ChartRoutingModule,
    UIModule,
    NgApexchartsModule,
  ]
})
export class ChartModule { }
