import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
    NgbAccordionModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTabsetModule
} from '@ng-bootstrap/ng-bootstrap';
import {NgApexchartsModule} from 'ng-apexcharts';

import {UIModule} from '../shared/ui/ui.module';

import {DashboardsModule} from './dashboards/dashboards.module';
import {AppsModule} from './apps/apps.module';
import {UiModule} from './ui/ui.module';
import {ExtrasModule} from './extras/extras.module';
import {ErrorModule} from './error/error.module';
import {AdminUIModule} from './adminUI/adminUI.module';
import {FormModule} from './form/form.module';
import {TablesModule} from './tables/tables.module';
import {IconsModule} from './icons/icons.module';
import {ChartModule} from './chart/chart.module';
import {MapsModule} from './maps/maps.module';
import {WidgetsComponent} from './widgets/widgets.component';

import {PagesRoutingModule} from './pages-routing.module';
import {ListCameraViewComponent} from './list-camera-view/list-camera-view.component';
import {VisTimelineComponent} from './vis-timeline/vis-timeline.component';
import {SharedModule} from '../shared/shared.module';
import {LayoutsModule} from '../layouts/layouts.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {UserManagementComponent} from './user-management/user-management.component';
import {AuthModule} from '../account/auth/auth.module';
import {ImageCropperComponent} from './image-cropper/image-cropper.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CameraSettingComponent} from './camera-setting/camera-setting.component';
import {CameraShareManagementComponent} from './camera-share-management/camera-share-management.component';
import {UpdateFirmwareComponent} from './update-firmware/update-firmware.component';
import {NetworkManagementComponent} from './network-management/network-management.component';
import {CameraMonitorComponent} from './camera-monitor/camera-monitor.component';
import {TranslateModule} from '@ngx-translate/core';
import {AlarmSettingComponent} from './alarm-setting/alarm-setting.component';

@NgModule({
    declarations: [
        WidgetsComponent,
        ListCameraViewComponent,
        VisTimelineComponent,
        UserManagementComponent,
        ImageCropperComponent,
        CameraSettingComponent,
        CameraShareManagementComponent,
        UpdateFirmwareComponent,
        NetworkManagementComponent,
        CameraMonitorComponent,
        AlarmSettingComponent
    ],
    imports: [
        CommonModule,
        NgbDropdownModule,
        UIModule,
        DashboardsModule,
        AppsModule,
        UiModule,
        NgApexchartsModule,
        ExtrasModule,
        ErrorModule,
        AdminUIModule,
        FormModule,
        TablesModule,
        IconsModule,
        ChartModule,
        MapsModule,
        PagesRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgbDatepickerModule,
        LayoutsModule,
        NgSelectModule,
        NgbTabsetModule,
        AuthModule,
        ImageCropperModule,
        NgbNavModule,
        NgbAccordionModule,
        TranslateModule
    ]
})
export class PagesModule {
}
