<div class="row">
    <div class="col-12">
        <div class="card-box">
            <h4 class="header-title">{{'user_share_list' | translate}}</h4>
<!--            <p class="sub-header">-->
<!--                Use one of two modifier classes to make <code>&lt;thead&gt;</code>s appear light or dark gray.-->
<!--            </p>-->

            <div class="table-responsive">
                <table class="table mb-0">
                    <thead class="thead-light">
                    <tr>
                        <th>#</th>
                        <th>{{'username' | translate}}</th>
                        <th>{{'stop_share' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of shareList; let i = index;">
                        <th scope="row">{{i + 1}}</th>
                        <td>{{item.username}}</td>
                        <td>
                            <button class="btn btn-sm btn-danger" (click)="removeShare(item)">
                                {{'cancel' | translate}}
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div> <!-- end table-responsive-->

        </div> <!-- end card-box -->
    </div> <!-- end col -->
</div>
