<div class="container-fluid">
  <app-page-title title="Material Icons" [breadcrumbItems]="breadCrumbItems"></app-page-title>


  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Examples</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-tennis"></i> mdi mdi-table-tennis
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-badge-horizontal"></i> mdi mdi-account-badge-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-badge-horizontal-outline"></i> mdi mdi-account-badge-horizontal-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-biathlon"></i> mdi mdi-biathlon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fireplace "></i> mdi mdi-fireplace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fireplace-off"></i> mdi mdi-fireplace-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-frame"></i> mdi mdi-image-frame
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-underline-circle"></i> mdi mdi-check-underline-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-underline-circle-outline"></i> mdi mdi-check-underline-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-underline"></i> mdi mdi-check-underline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-bold"></i> mdi mdi-check-bold
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-comfy"></i> mdi mdi-view-comfy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-compact"></i> mdi mdi-view-compact
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-compact-outline"></i> mdi mdi-view-compact-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pdf-box"></i> mdi mdi-pdf-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-retake"></i> mdi mdi-camera-retake
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-retake-outline"></i> mdi mdi-camera-retake-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nfc-search-variant"></i> mdi mdi-nfc-search-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-saw-blade"></i> mdi mdi-saw-blade
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-saw"></i> mdi mdi-hand-saw
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spade "></i> mdi mdi-spade
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circular-saw"></i> mdi mdi-circular-saw
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-video-outline"></i> mdi mdi-file-video-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-month"></i> mdi mdi-calendar-month
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-month-outline"></i> mdi mdi-calendar-month-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-layers-plus"></i> mdi mdi-layers-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-layers-minus"></i> mdi mdi-layers-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-layers-remove"></i> mdi mdi-layers-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-notebook-multiple"></i> mdi mdi-notebook-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-right"></i> mdi mdi-hand-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-left"></i> mdi mdi-hand-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-down"></i> mdi mdi-gamepad-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-left"></i> mdi mdi-gamepad-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-right"></i> mdi mdi-gamepad-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-up"></i> mdi mdi-gamepad-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-circle"></i> mdi mdi-gamepad-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-circle-outline"></i> mdi mdi-gamepad-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-circle-down"></i> mdi mdi-gamepad-circle-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-circle-left"></i> mdi mdi-gamepad-circle-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-circle-right"></i> mdi mdi-gamepad-circle-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-circle-up"></i> mdi mdi-gamepad-circle-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-round"></i> mdi mdi-gamepad-round
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-round-outline"></i> mdi mdi-gamepad-round-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-round-down"></i> mdi mdi-gamepad-round-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-round-left"></i> mdi mdi-gamepad-round-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-round-right"></i> mdi mdi-gamepad-round-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-round-up"></i> mdi mdi-gamepad-round-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-menu"></i> mdi mdi-xbox-controller-menu
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-view"></i> mdi mdi-xbox-controller-view
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gatsby "></i> mdi mdi-gatsby
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rectangle "></i> mdi mdi-rectangle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rectangle-outline"></i> mdi mdi-rectangle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-picture-in-picture-bottom-right"></i> mdi mdi-picture-in-picture-bottom-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-picture-in-picture-bottom-right-outline"></i> mdi
              mdi-picture-in-picture-bottom-right-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-picture-in-picture-top-right"></i> mdi mdi-picture-in-picture-top-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-picture-in-picture-top-right-outline"></i> mdi mdi-picture-in-picture-top-right-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-check-outline"></i> mdi mdi-file-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rivet"></i> mdi mdi-rivet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-pdf-outline"></i> mdi mdi-file-pdf-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-music-outline"></i> mdi mdi-file-music-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-aws"></i> mdi mdi-aws
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-firework"></i> mdi mdi-firework
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gift"></i> mdi mdi-gift
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cast-education"></i> mdi mdi-cast-education
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-multiple"></i> mdi mdi-bookmark-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-multiple-outline"></i> mdi mdi-bookmark-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sprout"></i> mdi mdi-sprout
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seed"></i> mdi mdi-seed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sprout-outline"></i> mdi mdi-sprout-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seed-outline"></i> mdi mdi-seed-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-grill"></i> mdi mdi-grill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tree-outline"></i> mdi mdi-tree-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-distance-variant"></i> mdi mdi-signal-distance-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-cloudy-arrow-right"></i> mdi mdi-weather-cloudy-arrow-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-off"></i> mdi mdi-car-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comma"></i> mdi mdi-comma
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comma-box"></i> mdi mdi-comma-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comma-box-outline"></i> mdi mdi-comma-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comma-circle"></i> mdi mdi-comma-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comma-circle-outline"></i> mdi mdi-comma-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer-off"></i> mdi mdi-printer-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bag-personal"></i> mdi mdi-bag-personal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bag-personal-off"></i> mdi mdi-bag-personal-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bag-personal-off-outline"></i> mdi mdi-bag-personal-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bag-personal-outline"></i> mdi mdi-bag-personal-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-save-move"></i> mdi mdi-content-save-move
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-save-move-outline"></i> mdi mdi-content-save-move-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-off-outline"></i> mdi mdi-keyboard-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-back"></i> mdi mdi-car-back
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor-screenshot"></i> mdi mdi-monitor-screenshot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-color-highlight"></i> mdi mdi-format-color-highlight
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-invert-colors-off"></i> mdi mdi-invert-colors-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-text-variant"></i> mdi mdi-format-text-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nfc-off"></i> mdi mdi-nfc-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nfc-variant-off"></i> mdi mdi-nfc-variant-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer-3d-nozzle"></i> mdi mdi-printer-3d-nozzle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer-3d-nozzle-outline"></i> mdi mdi-printer-3d-nozzle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vuetify"></i> mdi mdi-vuetify
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lightbulb-off"></i> mdi mdi-lightbulb-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lightbulb-off-outline"></i> mdi mdi-lightbulb-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-palette-outline"></i> mdi mdi-palette-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-group"></i> mdi mdi-home-group
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-movie-outline"></i> mdi mdi-movie-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bandage "></i> mdi mdi-bandage
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-wireless-outline"></i> mdi mdi-camera-wireless-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-wireless"></i> mdi mdi-camera-wireless
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gantry-crane"></i> mdi mdi-gantry-crane
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ocarina"></i> mdi mdi-ocarina
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bugle"></i> mdi mdi-bugle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-badge-outline"></i> mdi mdi-account-badge-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-badge-alert-outline"></i> mdi mdi-account-badge-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-badge-alert"></i> mdi mdi-account-badge-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-badge"></i> mdi mdi-account-badge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bolt"></i> mdi mdi-bolt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nail "></i> mdi mdi-nail
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screw-round-top"></i> mdi mdi-screw-round-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screw-machine-round-top"></i> mdi mdi-screw-machine-round-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screw-machine-flat-top"></i> mdi mdi-screw-machine-flat-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screw-lag"></i> mdi mdi-screw-lag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screw-flat-top"></i> mdi mdi-screw-flat-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-alert-outline"></i> mdi mdi-folder-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-alert"></i> mdi mdi-folder-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermometer-plus"></i> mdi mdi-thermometer-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermometer-minus"></i> mdi mdi-thermometer-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermometer-chevron-up"></i> mdi mdi-thermometer-chevron-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermometer-chevron-down"></i> mdi mdi-thermometer-chevron-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermometer-alert"></i> mdi mdi-thermometer-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wallpaper "></i> mdi mdi-wallpaper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mine"></i> mdi mdi-mine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-edit-outline"></i> mdi mdi-folder-edit-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-open-outline"></i> mdi mdi-folder-open-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-upload-outline"></i> mdi mdi-upload-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-outline"></i> mdi mdi-phone-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document-edit-outline"></i> mdi mdi-file-document-edit-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-key-outline"></i> mdi mdi-key-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-group"></i> mdi mdi-home-group
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-star"></i> mdi mdi-wifi-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-triple-left"></i> mdi mdi-chevron-triple-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-triple-up"></i> mdi mdi-chevron-triple-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-triple-right"></i> mdi mdi-chevron-triple-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-triple-down"></i> mdi mdi-chevron-triple-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-floor-negative-1"></i> mdi mdi-home-floor-negative-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-floor-0"></i> mdi mdi-home-floor-0
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-card-details-outline"></i> mdi mdi-account-card-details-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card-marker"></i> mdi mdi-credit-card-marker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cash-marker"></i> mdi mdi-cash-marker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-lock-outline"></i> mdi mdi-pencil-lock-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-off-outline"></i> mdi mdi-pencil-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pen-lock"></i> mdi mdi-pen-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diving-snorkel"></i> mdi mdi-diving-snorkel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diving-scuba-tank-multiple"></i> mdi mdi-diving-scuba-tank-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diving-scuba-tank"></i> mdi mdi-diving-scuba-tank
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diving-scuba-flag"></i> mdi mdi-diving-scuba-flag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diving-scuba"></i> mdi mdi-diving-scuba
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diving-helmet"></i> mdi mdi-diving-helmet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diving-flippers"></i> mdi mdi-diving-flippers
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signature"></i> mdi mdi-signature
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signature-text"></i> mdi mdi-signature-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signature-image"></i> mdi mdi-signature-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signature-freehand"></i> mdi mdi-signature-freehand
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-closed-caption-outline"></i> mdi mdi-closed-caption-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-marker-cancel"></i> mdi mdi-marker-cancel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-cancel-outline"></i> mdi mdi-file-cancel-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-cancel"></i> mdi mdi-file-cancel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-remove-outline"></i> mdi mdi-pencil-remove-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-remove"></i> mdi mdi-pencil-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-minus-outline"></i> mdi mdi-pencil-minus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-minus"></i> mdi mdi-pencil-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-plus-outline"></i> mdi mdi-pencil-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-plus"></i> mdi mdi-pencil-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pen-remove"></i> mdi mdi-pen-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pen-off"></i> mdi mdi-pen-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pen-minus"></i> mdi mdi-pen-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pen-plus"></i> mdi mdi-pen-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-slope-uphill"></i> mdi mdi-slope-uphill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-slope-downhill"></i> mdi mdi-slope-downhill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-passport-biometric"></i> mdi mdi-passport-biometric
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-plus"></i> mdi mdi-music-note-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-translate-off"></i> mdi mdi-translate-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor-star"></i> mdi mdi-monitor-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor-lock"></i> mdi mdi-monitor-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-star"></i> mdi mdi-playlist-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-leak-off"></i> mdi mdi-leak-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-leak "></i> mdi mdi-leak
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-list-bulleted-square"></i> mdi mdi-format-list-bulleted-square
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-application-import"></i> mdi mdi-application-import
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-application-export"></i> mdi mdi-application-export
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-air-horn"></i> mdi mdi-air-horn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-eye-outline"></i> mdi mdi-file-eye-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-eye"></i> mdi mdi-file-eye
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bank-remove"></i> mdi mdi-bank-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bank-plus"></i> mdi mdi-bank-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bank-minus"></i> mdi mdi-bank-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-send-circle-outline"></i> mdi mdi-send-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-send-circle"></i> mdi mdi-send-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-water-outline"></i> mdi mdi-water-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-off"></i> mdi mdi-phone-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-variant-off"></i> mdi mdi-volume-variant-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pi-hole"></i> mdi mdi-pi-hole
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document-edit"></i> mdi mdi-file-document-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shoe-print"></i> mdi mdi-shoe-print
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cactus"></i> mdi mdi-cactus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-jabber"></i> mdi mdi-jabber
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-turtle"></i> mdi mdi-turtle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-face-recognition"></i> mdi mdi-face-recognition
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-postage-stamp"></i> mdi mdi-postage-stamp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-adwords"></i> mdi mdi-google-adwords
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-street-view"></i> mdi mdi-google-street-view
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort-variant-lock-open"></i> mdi mdi-sort-variant-lock-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort-variant-lock"></i> mdi mdi-sort-variant-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-devil"></i> mdi mdi-emoticon-devil
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-dead"></i> mdi mdi-emoticon-dead
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon"></i> mdi mdi-emoticon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-excited"></i> mdi mdi-emoticon-excited
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-cool"></i> mdi mdi-emoticon-cool
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-neutral"></i> mdi mdi-emoticon-neutral
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-poop-outline"></i> mdi mdi-emoticon-poop-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-sad"></i> mdi mdi-emoticon-sad
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-happy"></i> mdi mdi-emoticon-happy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-tongue"></i> mdi mdi-emoticon-tongue
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-tongue-outline"></i> mdi mdi-emoticon-tongue-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-cry-outline"></i> mdi mdi-emoticon-cry-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-cry"></i> mdi mdi-emoticon-cry
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-kiss-outline"></i> mdi mdi-emoticon-kiss-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-kiss"></i> mdi mdi-emoticon-kiss
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-wink-outline"></i> mdi mdi-emoticon-wink-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-wink"></i> mdi mdi-emoticon-wink
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-angry-outline"></i> mdi mdi-emoticon-angry-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-angry"></i> mdi mdi-emoticon-angry
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sheep"></i> mdi mdi-sheep
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-repository-multiple"></i> mdi mdi-source-repository-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-repository"></i> mdi mdi-source-repository
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cart-arrow-right"></i> mdi mdi-cart-arrow-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radio-am"></i> mdi mdi-radio-am
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radio-fm"></i> mdi mdi-radio-fm
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dump-truck"></i> mdi mdi-dump-truck
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airbag"></i> mdi mdi-airbag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seatbelt"></i> mdi mdi-seatbelt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-account-outline"></i> mdi mdi-clipboard-account-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-arrow-down-outline"></i> mdi mdi-clipboard-arrow-down-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-arrow-up-outline"></i> mdi mdi-clipboard-arrow-up-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-arrow-up"></i> mdi mdi-clipboard-arrow-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hazard-lights"></i> mdi mdi-hazard-lights
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-esp"></i> mdi mdi-car-esp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-brake-parking"></i> mdi mdi-car-brake-parking
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-brake-abs"></i> mdi mdi-car-brake-abs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-tire-alert"></i> mdi mdi-car-tire-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-search-outline"></i> mdi mdi-file-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-search"></i> mdi mdi-file-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-light-high"></i> mdi mdi-car-light-high
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-light-fog"></i> mdi mdi-car-light-fog
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-light-dimmed"></i> mdi mdi-car-light-dimmed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-box-multiple-outline"></i> mdi mdi-close-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-box-multiple"></i> mdi mdi-close-box-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-cross-outline"></i> mdi mdi-shield-cross-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-cross"></i> mdi mdi-shield-cross
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-link-off"></i> mdi mdi-link-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-link"></i> mdi mdi-link
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-search-outline"></i> mdi mdi-briefcase-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-download-outline"></i> mdi mdi-briefcase-download-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-upload-outline"></i> mdi mdi-briefcase-upload-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-edit-outline"></i> mdi mdi-briefcase-edit-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-remove-outline"></i> mdi mdi-briefcase-remove-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-plus-outline"></i> mdi mdi-briefcase-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-minus-outline"></i> mdi mdi-briefcase-minus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-link-plus"></i> mdi mdi-link-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-food-apple-outline"></i> mdi mdi-food-apple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spa-outline"></i> mdi mdi-spa-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spa"></i> mdi mdi-spa
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-size-select-small"></i> mdi mdi-image-size-select-small
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-size-select-large"></i> mdi mdi-image-size-select-large
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-size-select-actual"></i> mdi mdi-image-size-select-actual
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-plus-circle-outline"></i> mdi mdi-numeric-9-plus-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-plus-circle"></i> mdi mdi-numeric-9-plus-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-circle-outline"></i> mdi mdi-numeric-9-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-circle"></i> mdi mdi-numeric-9-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-8-circle-outline"></i> mdi mdi-numeric-8-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-8-circle"></i> mdi mdi-numeric-8-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-7-circle-outline"></i> mdi mdi-numeric-7-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-7-circle"></i> mdi mdi-numeric-7-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-6-circle-outline"></i> mdi mdi-numeric-6-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-6-circle"></i> mdi mdi-numeric-6-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-5-circle-outline"></i> mdi mdi-numeric-5-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-5-circle"></i> mdi mdi-numeric-5-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-4-circle-outline"></i> mdi mdi-numeric-4-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-4-circle"></i> mdi mdi-numeric-4-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-3-circle-outline"></i> mdi mdi-numeric-3-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-3-circle"></i> mdi mdi-numeric-3-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-2-circle-outline"></i> mdi mdi-numeric-2-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-2-circle"></i> mdi mdi-numeric-2-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-1-circle-outline"></i> mdi mdi-numeric-1-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-1-circle"></i> mdi mdi-numeric-1-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-0-circle-outline"></i> mdi mdi-numeric-0-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-0-circle"></i> mdi mdi-numeric-0-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-z-circle-outline"></i> mdi mdi-alpha-z-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-z-circle"></i> mdi mdi-alpha-z-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-z-box-outline"></i> mdi mdi-alpha-z-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-y-circle-outline"></i> mdi mdi-alpha-y-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-y-circle"></i> mdi mdi-alpha-y-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-y-box-outline"></i> mdi mdi-alpha-y-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-x-circle-outline"></i> mdi mdi-alpha-x-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-x-circle"></i> mdi mdi-alpha-x-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-x-box-outline"></i> mdi mdi-alpha-x-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-w-circle-outline"></i> mdi mdi-alpha-w-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-w-circle"></i> mdi mdi-alpha-w-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-w-box-outline"></i> mdi mdi-alpha-w-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-v-circle-outline"></i> mdi mdi-alpha-v-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-v-circle"></i> mdi mdi-alpha-v-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-v-box-outline"></i> mdi mdi-alpha-v-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-u-circle-outline"></i> mdi mdi-alpha-u-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-u-circle"></i> mdi mdi-alpha-u-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-u-box-outline"></i> mdi mdi-alpha-u-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-t-circle-outline"></i> mdi mdi-alpha-t-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-t-circle"></i> mdi mdi-alpha-t-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-t-box-outline"></i> mdi mdi-alpha-t-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-s-circle-outline"></i> mdi mdi-alpha-s-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-s-circle"></i> mdi mdi-alpha-s-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-s-box-outline"></i> mdi mdi-alpha-s-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-r-circle-outline"></i> mdi mdi-alpha-r-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-r-circle"></i> mdi mdi-alpha-r-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-r-box-outline"></i> mdi mdi-alpha-r-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-q-circle-outline"></i> mdi mdi-alpha-q-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-q-circle"></i> mdi mdi-alpha-q-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-q-box-outline"></i> mdi mdi-alpha-q-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-p-circle-outline"></i> mdi mdi-alpha-p-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-p-circle"></i> mdi mdi-alpha-p-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-p-box-outline"></i> mdi mdi-alpha-p-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-o-circle-outline"></i> mdi mdi-alpha-o-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-o-circle"></i> mdi mdi-alpha-o-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-o-box-outline"></i> mdi mdi-alpha-o-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-n-circle-outline"></i> mdi mdi-alpha-n-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-n-circle"></i> mdi mdi-alpha-n-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-n-box-outline"></i> mdi mdi-alpha-n-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-m-circle-outline"></i> mdi mdi-alpha-m-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-m-circle"></i> mdi mdi-alpha-m-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-m-box-outline"></i> mdi mdi-alpha-m-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-l-circle-outline"></i> mdi mdi-alpha-l-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-l-circle"></i> mdi mdi-alpha-l-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-l-box-outline"></i> mdi mdi-alpha-l-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-k-circle-outline"></i> mdi mdi-alpha-k-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-k-circle"></i> mdi mdi-alpha-k-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-k-box-outline"></i> mdi mdi-alpha-k-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-j-circle-outline"></i> mdi mdi-alpha-j-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-j-circle"></i> mdi mdi-alpha-j-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-j-box-outline"></i> mdi mdi-alpha-j-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-i-circle-outline"></i> mdi mdi-alpha-i-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-i-circle"></i> mdi mdi-alpha-i-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-i-box-outline"></i> mdi mdi-alpha-i-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-h-circle-outline"></i> mdi mdi-alpha-h-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-h-circle"></i> mdi mdi-alpha-h-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-h-box-outline"></i> mdi mdi-alpha-h-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-g-circle-outline"></i> mdi mdi-alpha-g-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-g-circle"></i> mdi mdi-alpha-g-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-g-box-outline"></i> mdi mdi-alpha-g-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-f-circle-outline"></i> mdi mdi-alpha-f-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-f-circle"></i> mdi mdi-alpha-f-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-f-box-outline"></i> mdi mdi-alpha-f-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-e-circle-outline"></i> mdi mdi-alpha-e-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-e-circle"></i> mdi mdi-alpha-e-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-e-box-outline"></i> mdi mdi-alpha-e-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-d-circle-outline"></i> mdi mdi-alpha-d-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-d-circle"></i> mdi mdi-alpha-d-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-d-box-outline"></i> mdi mdi-alpha-d-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-c-circle-outline"></i> mdi mdi-alpha-c-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-c-circle"></i> mdi mdi-alpha-c-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-c-box-outline"></i> mdi mdi-alpha-c-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-b-circle-outline"></i> mdi mdi-alpha-b-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-b-circle"></i> mdi mdi-alpha-b-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-b-box-outline"></i> mdi mdi-alpha-b-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-a-circle-outline"></i> mdi mdi-alpha-a-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-a-circle"></i> mdi mdi-alpha-a-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-a-box-outline"></i> mdi mdi-alpha-a-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-logout"></i> mdi mdi-logout
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-login"></i> mdi mdi-login
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-progress-wrench"></i> mdi mdi-progress-wrench
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-truck-check"></i> mdi mdi-truck-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-multiple-plus-outline"></i> mdi mdi-account-multiple-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-multiple-outline"></i> mdi mdi-account-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-box-outline"></i> mdi mdi-check-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-box-multiple-outline"></i> mdi mdi-check-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fuse-blade"></i> mdi mdi-fuse-blade
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fuse"></i> mdi mdi-fuse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-light-outline"></i> mdi mdi-alarm-light-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-outline"></i> mdi mdi-pencil-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-network-outline"></i> mdi mdi-check-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-off-outline"></i> mdi mdi-network-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-off"></i> mdi mdi-network-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-network"></i> mdi mdi-check-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-basketball-hoop-outline"></i> mdi mdi-basketball-hoop-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-basketball-hoop"></i> mdi mdi-basketball-hoop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-network-outline"></i> mdi mdi-close-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-heart-outline"></i> mdi mdi-account-heart-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-key-outline"></i> mdi mdi-account-key-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-star-outline"></i> mdi mdi-account-star-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooltip-account"></i> mdi mdi-tooltip-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server-network-off"></i> mdi mdi-server-network-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server-network"></i> mdi mdi-server-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-security-network"></i> mdi mdi-security-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ip-network"></i> mdi mdi-ip-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-network"></i> mdi mdi-folder-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-key-network"></i> mdi mdi-folder-key-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-console-network"></i> mdi mdi-console-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-network"></i> mdi mdi-account-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-access-point-network"></i> mdi mdi-access-point-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-iframe-outline"></i> mdi mdi-iframe-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-iframe"></i> mdi mdi-iframe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ruler-square"></i> mdi mdi-ruler-square
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-text-outline"></i> mdi mdi-folder-text-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-text"></i> mdi mdi-folder-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ballot-recount"></i> mdi mdi-ballot-recount
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ballot-recount-outline"></i> mdi mdi-ballot-recount-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-home-outline"></i> mdi mdi-shield-home-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-lock-outline"></i> mdi mdi-shield-lock-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-airplane-outline"></i> mdi mdi-shield-airplane-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-check-outline"></i> mdi mdi-shield-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-off-outline"></i> mdi mdi-shield-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-parachute-outline"></i> mdi mdi-parachute-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-parachute"></i> mdi mdi-parachute
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-turnstile-outline"></i> mdi mdi-turnstile-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-turnstile"></i> mdi mdi-turnstile
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-sweep-outline"></i> mdi mdi-delete-sweep-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-bell-curve"></i> mdi mdi-chart-bell-curve
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-math-tan"></i> mdi mdi-math-tan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-math-sin"></i> mdi mdi-math-sin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-math-cos"></i> mdi mdi-math-cos
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toaster-oven"></i> mdi mdi-toaster-oven
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microwave"></i> mdi mdi-microwave
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ipod"></i> mdi mdi-ipod
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-table-outline"></i> mdi mdi-file-table-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-table"></i> mdi mdi-file-table
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-network-outline"></i> mdi mdi-account-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-upload-network-outline"></i> mdi mdi-upload-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-access-point-network-off"></i> mdi mdi-access-point-network-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-console-network-outline"></i> mdi mdi-console-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-download-network-outline"></i> mdi mdi-download-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-key-network-outline"></i> mdi mdi-folder-key-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-network-outline"></i> mdi mdi-folder-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help-network-outline"></i> mdi mdi-help-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ip-network-outline"></i> mdi mdi-ip-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minus-network-outline"></i> mdi mdi-minus-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-outline"></i> mdi mdi-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-network-outline"></i> mdi mdi-plus-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-network-outline"></i> mdi mdi-play-network-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-progress-alert"></i> mdi mdi-progress-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eslint"></i> mdi mdi-eslint
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-leaf-maple"></i> mdi mdi-leaf-maple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-play-outline"></i> mdi mdi-clipboard-play-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-play"></i> mdi mdi-clipboard-play
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-text-play-outline"></i> mdi mdi-clipboard-text-play-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-text-play"></i> mdi mdi-clipboard-text-play
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-check-outline"></i> mdi mdi-account-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-check"></i> mdi mdi-account-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-check"></i> mdi mdi-map-marker-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radius-outline"></i> mdi mdi-radius-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radius"></i> mdi mdi-radius
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diameter-variant"></i> mdi mdi-diameter-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diameter-outline"></i> mdi mdi-diameter-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diameter"></i> mdi mdi-diameter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-outline"></i> mdi mdi-account-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-off-outline"></i> mdi mdi-account-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-multiple-minus-outline"></i> mdi mdi-account-multiple-minus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-music-outline"></i> mdi mdi-playlist-music-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-music"></i> mdi mdi-playlist-music
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-outline"></i> mdi mdi-seat-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat"></i> mdi mdi-seat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-check-outline"></i> mdi mdi-calendar-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-remove-outline"></i> mdi mdi-calendar-remove-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-text-outline"></i> mdi mdi-calendar-text-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-charity"></i> mdi mdi-charity
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-ils"></i> mdi mdi-currency-ils
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch-vibrate-off"></i> mdi mdi-watch-vibrate-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vibrate-off"></i> mdi mdi-vibrate-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-haskell"></i> mdi mdi-language-haskell
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-inbox-multiple-outline"></i> mdi mdi-inbox-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-circle-outline"></i> mdi mdi-account-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wallet-outline"></i> mdi mdi-wallet-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-download-outline"></i> mdi mdi-cloud-download-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-arrow-right-outline"></i> mdi mdi-account-arrow-right-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-arrow-right"></i> mdi mdi-account-arrow-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-motorbike"></i> mdi mdi-motorbike
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-outline"></i> mdi mdi-book-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-edit"></i> mdi mdi-database-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-circle-outline"></i> mdi mdi-eye-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-circle"></i> mdi mdi-eye-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-clock-outline"></i> mdi mdi-account-clock-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-clock"></i> mdi mdi-account-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help-rhombus-outline"></i> mdi mdi-help-rhombus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help-rhombus"></i> mdi mdi-help-rhombus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-train-car"></i> mdi mdi-train-car
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-multiple"></i> mdi mdi-car-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mapbox"></i> mdi mdi-mapbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hat-fedora"></i> mdi mdi-hat-fedora
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-target-account"></i> mdi mdi-target-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-backspace-outline"></i> mdi mdi-backspace-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-top-right"></i> mdi mdi-pan-top-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-top-left"></i> mdi mdi-pan-top-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-bottom-left"></i> mdi mdi-pan-bottom-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-bottom-right"></i> mdi mdi-pan-bottom-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-left"></i> mdi mdi-pan-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-down"></i> mdi mdi-pan-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-right"></i> mdi mdi-pan-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-up"></i> mdi mdi-pan-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-circle-outline"></i> mdi mdi-delete-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-remove"></i> mdi mdi-file-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-mark-as-unread"></i> mdi mdi-email-mark-as-unread
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-artstation"></i> mdi mdi-artstation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-control"></i> mdi mdi-camera-control
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-horizontal"></i> mdi mdi-pan-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan-vertical"></i> mdi mdi-pan-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pan"></i> mdi mdi-pan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-barley-off"></i> mdi mdi-barley-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-barn"></i> mdi mdi-barn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drag-variant"></i> mdi mdi-drag-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-library-shelves"></i> mdi mdi-library-shelves
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ubisoft"></i> mdi mdi-ubisoft
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gog"></i> mdi mdi-gog
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-open"></i> mdi mdi-menu-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-key-outline"></i> mdi mdi-shield-key-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-key"></i> mdi mdi-shield-key
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wrap-disabled"></i> mdi mdi-wrap-disabled
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-door"></i> mdi mdi-car-door
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chat-alert"></i> mdi mdi-chat-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chat-processing"></i> mdi mdi-chat-processing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chat"></i> mdi mdi-chat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rollupjs"></i> mdi mdi-rollupjs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-brl"></i> mdi mdi-currency-brl
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battlenet"></i> mdi mdi-battlenet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-access-point"></i> mdi mdi-access-point
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account"></i> mdi mdi-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-alert"></i> mdi mdi-account-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-box"></i> mdi mdi-account-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-box-multiple"></i> mdi mdi-account-box-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-box-outline"></i> mdi mdi-account-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-card-details"></i> mdi mdi-account-card-details
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-child"></i> mdi mdi-account-child
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-child-circle"></i> mdi mdi-account-child-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-circle"></i> mdi mdi-account-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-convert"></i> mdi mdi-account-convert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-details"></i> mdi mdi-account-details
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-edit"></i> mdi mdi-account-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-group"></i> mdi mdi-account-group
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-heart"></i> mdi mdi-account-heart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-key"></i> mdi mdi-account-key
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-minus"></i> mdi mdi-account-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-minus-outline"></i> mdi mdi-account-minus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-multiple"></i> mdi mdi-account-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-multiple-check"></i> mdi mdi-account-multiple-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-multiple-minus"></i> mdi mdi-account-multiple-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-multiple-plus"></i> mdi mdi-account-multiple-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-off"></i> mdi mdi-account-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-plus"></i> mdi mdi-account-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-plus-outline"></i> mdi mdi-account-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-remove"></i> mdi mdi-account-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-remove-outline"></i> mdi mdi-account-remove-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-search"></i> mdi mdi-account-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-search-outline"></i> mdi mdi-account-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-settings"></i> mdi mdi-account-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-star"></i> mdi mdi-account-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-supervisor"></i> mdi mdi-account-supervisor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-supervisor-circle"></i> mdi mdi-account-supervisor-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-account-switch"></i> mdi mdi-account-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-accusoft"></i> mdi mdi-accusoft
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-adjust"></i> mdi mdi-adjust
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-adobe"></i> mdi mdi-adobe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-air-conditioner"></i> mdi mdi-air-conditioner
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airballoon"></i> mdi mdi-airballoon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airplane"></i> mdi mdi-airplane
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airplane-landing"></i> mdi mdi-airplane-landing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airplane-off"></i> mdi mdi-airplane-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airplane-takeoff"></i> mdi mdi-airplane-takeoff
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airplay"></i> mdi mdi-airplay
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-airport"></i> mdi mdi-airport
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm"></i> mdi mdi-alarm
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-bell"></i> mdi mdi-alarm-bell
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-check"></i> mdi mdi-alarm-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-light"></i> mdi mdi-alarm-light
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-multiple"></i> mdi mdi-alarm-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-off"></i> mdi mdi-alarm-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-plus"></i> mdi mdi-alarm-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alarm-snooze"></i> mdi mdi-alarm-snooze
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-album"></i> mdi mdi-album
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert"></i> mdi mdi-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert-box"></i> mdi mdi-alert-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert-circle"></i> mdi mdi-alert-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert-circle-outline"></i> mdi mdi-alert-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert-decagram"></i> mdi mdi-alert-decagram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert-octagon"></i> mdi mdi-alert-octagon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert-octagram"></i> mdi mdi-alert-octagram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alert-outline"></i> mdi mdi-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alien"></i> mdi mdi-alien
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-all-inclusive"></i> mdi mdi-all-inclusive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha"></i> mdi mdi-alpha
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-a"></i> mdi mdi-alpha-a
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-a-box"></i> mdi mdi-alpha-a-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-b"></i> mdi mdi-alpha-b
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-b-box"></i> mdi mdi-alpha-b-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-c"></i> mdi mdi-alpha-c
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-c-box"></i> mdi mdi-alpha-c-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-d"></i> mdi mdi-alpha-d
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-d-box"></i> mdi mdi-alpha-d-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-e"></i> mdi mdi-alpha-e
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-e-box"></i> mdi mdi-alpha-e-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-f"></i> mdi mdi-alpha-f
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-f-box"></i> mdi mdi-alpha-f-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-g"></i> mdi mdi-alpha-g
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-g-box"></i> mdi mdi-alpha-g-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-h"></i> mdi mdi-alpha-h
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-h-box"></i> mdi mdi-alpha-h-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-i"></i> mdi mdi-alpha-i
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-i-box"></i> mdi mdi-alpha-i-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-j"></i> mdi mdi-alpha-j
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-j-box"></i> mdi mdi-alpha-j-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-k"></i> mdi mdi-alpha-k
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-k-box"></i> mdi mdi-alpha-k-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-l"></i> mdi mdi-alpha-l
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-l-box"></i> mdi mdi-alpha-l-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-m"></i> mdi mdi-alpha-m
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-m-box"></i> mdi mdi-alpha-m-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-n"></i> mdi mdi-alpha-n
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-n-box"></i> mdi mdi-alpha-n-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-o"></i> mdi mdi-alpha-o
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-o-box"></i> mdi mdi-alpha-o-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-p"></i> mdi mdi-alpha-p
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-p-box"></i> mdi mdi-alpha-p-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-q"></i> mdi mdi-alpha-q
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-q-box"></i> mdi mdi-alpha-q-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-r"></i> mdi mdi-alpha-r
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-r-box"></i> mdi mdi-alpha-r-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-s"></i> mdi mdi-alpha-s
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-s-box"></i> mdi mdi-alpha-s-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-t"></i> mdi mdi-alpha-t
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-t-box"></i> mdi mdi-alpha-t-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-u"></i> mdi mdi-alpha-u
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-u-box"></i> mdi mdi-alpha-u-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-v"></i> mdi mdi-alpha-v
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-v-box"></i> mdi mdi-alpha-v-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-w"></i> mdi mdi-alpha-w
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-w-box"></i> mdi mdi-alpha-w-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-x"></i> mdi mdi-alpha-x
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-x-box"></i> mdi mdi-alpha-x-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-y"></i> mdi mdi-alpha-y
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-y-box"></i> mdi mdi-alpha-y-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-z"></i> mdi mdi-alpha-z
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alpha-z-box"></i> mdi mdi-alpha-z-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-alphabetical"></i> mdi mdi-alphabetical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-altimeter"></i> mdi mdi-altimeter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-amazon"></i> mdi mdi-amazon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-amazon-alexa"></i> mdi mdi-amazon-alexa
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-amazon-drive"></i> mdi mdi-amazon-drive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ambulance"></i> mdi mdi-ambulance
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ampersand"></i> mdi mdi-ampersand
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-amplifier"></i> mdi mdi-amplifier
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-anchor"></i> mdi mdi-anchor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-android"></i> mdi mdi-android
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-android-auto"></i> mdi mdi-android-auto
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-android-debug-bridge"></i> mdi mdi-android-debug-bridge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-android-head"></i> mdi mdi-android-head
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-android-head"></i> mdi mdi-android-head
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-android-studio"></i> mdi mdi-android-studio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-angle-acute"></i> mdi mdi-angle-acute
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-angle-obtuse"></i> mdi mdi-angle-obtuse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-angle-right"></i> mdi mdi-angle-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-angular"></i> mdi mdi-angular
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-angularjs"></i> mdi mdi-angularjs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-animation"></i> mdi mdi-animation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-animation-outline"></i> mdi mdi-animation-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-animation-play"></i> mdi mdi-animation-play
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-animation-play-outline"></i> mdi mdi-animation-play-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-anvil"></i> mdi mdi-anvil
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple"></i> mdi mdi-apple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-finder"></i> mdi mdi-apple-finder
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-icloud"></i> mdi mdi-apple-icloud
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-ios"></i> mdi mdi-apple-ios
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-keyboard-caps"></i> mdi mdi-apple-keyboard-caps
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-keyboard-command"></i> mdi mdi-apple-keyboard-command
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-keyboard-control"></i> mdi mdi-apple-keyboard-control
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-keyboard-option"></i> mdi mdi-apple-keyboard-option
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-keyboard-shift"></i> mdi mdi-apple-keyboard-shift
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apple-safari"></i> mdi mdi-apple-safari
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-application"></i> mdi mdi-application
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-apps"></i> mdi mdi-apps
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arch"></i> mdi mdi-arch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-archive"></i> mdi mdi-archive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrange-bring-forward"></i> mdi mdi-arrange-bring-forward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrange-bring-to-front"></i> mdi mdi-arrange-bring-to-front
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrange-send-backward"></i> mdi mdi-arrange-send-backward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrange-send-to-back"></i> mdi mdi-arrange-send-to-back
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-all"></i> mdi mdi-arrow-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-bottom-left"></i> mdi mdi-arrow-bottom-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-bottom-left-bold-outline"></i> mdi mdi-arrow-bottom-left-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-bottom-left-thick"></i> mdi mdi-arrow-bottom-left-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-bottom-right"></i> mdi mdi-arrow-bottom-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-bottom-right-bold-outline"></i> mdi mdi-arrow-bottom-right-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-bottom-right-thick"></i> mdi mdi-arrow-bottom-right-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse"></i> mdi mdi-arrow-collapse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse-all"></i> mdi mdi-arrow-collapse-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse-down"></i> mdi mdi-arrow-collapse-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse-horizontal"></i> mdi mdi-arrow-collapse-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse-left"></i> mdi mdi-arrow-collapse-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse-right"></i> mdi mdi-arrow-collapse-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse-up"></i> mdi mdi-arrow-collapse-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-collapse-vertical"></i> mdi mdi-arrow-collapse-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-decision"></i> mdi mdi-arrow-decision
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-decision-auto"></i> mdi mdi-arrow-decision-auto
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-decision-auto-outline"></i> mdi mdi-arrow-decision-auto-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-decision-outline"></i> mdi mdi-arrow-decision-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down"></i> mdi mdi-arrow-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-bold"></i> mdi mdi-arrow-down-bold
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-bold-box"></i> mdi mdi-arrow-down-bold-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-bold-box-outline"></i> mdi mdi-arrow-down-bold-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-bold-circle"></i> mdi mdi-arrow-down-bold-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-bold-circle-outline"></i> mdi mdi-arrow-down-bold-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-bold-hexagon-outline"></i> mdi mdi-arrow-down-bold-hexagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-bold-outline"></i> mdi mdi-arrow-down-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-box"></i> mdi mdi-arrow-down-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-drop-circle"></i> mdi mdi-arrow-down-drop-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-drop-circle-outline"></i> mdi mdi-arrow-down-drop-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-down-thick"></i> mdi mdi-arrow-down-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand"></i> mdi mdi-arrow-expand
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-all"></i> mdi mdi-arrow-expand-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-down"></i> mdi mdi-arrow-expand-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-horizontal"></i> mdi mdi-arrow-expand-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-left"></i> mdi mdi-arrow-expand-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-right"></i> mdi mdi-arrow-expand-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-up"></i> mdi mdi-arrow-expand-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-vertical"></i> mdi mdi-arrow-expand-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left"></i> mdi mdi-arrow-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-bold"></i> mdi mdi-arrow-left-bold
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-bold-box"></i> mdi mdi-arrow-left-bold-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-bold-box-outline"></i> mdi mdi-arrow-left-bold-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-bold-circle"></i> mdi mdi-arrow-left-bold-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-bold-circle-outline"></i> mdi mdi-arrow-left-bold-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-bold-hexagon-outline"></i> mdi mdi-arrow-left-bold-hexagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-bold-outline"></i> mdi mdi-arrow-left-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-box"></i> mdi mdi-arrow-left-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-drop-circle"></i> mdi mdi-arrow-left-drop-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-drop-circle-outline"></i> mdi mdi-arrow-left-drop-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-right-bold-outline"></i> mdi mdi-arrow-left-right-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-left-thick"></i> mdi mdi-arrow-left-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right"></i> mdi mdi-arrow-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-bold"></i> mdi mdi-arrow-right-bold
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-bold-box"></i> mdi mdi-arrow-right-bold-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-bold-box-outline"></i> mdi mdi-arrow-right-bold-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-bold-circle"></i> mdi mdi-arrow-right-bold-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-bold-circle-outline"></i> mdi mdi-arrow-right-bold-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-bold-hexagon-outline"></i> mdi mdi-arrow-right-bold-hexagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-bold-outline"></i> mdi mdi-arrow-right-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-box"></i> mdi mdi-arrow-right-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-drop-circle"></i> mdi mdi-arrow-right-drop-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-drop-circle-outline"></i> mdi mdi-arrow-right-drop-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-right-thick"></i> mdi mdi-arrow-right-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-split-horizontal"></i> mdi mdi-arrow-split-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-split-vertical"></i> mdi mdi-arrow-split-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-top-left"></i> mdi mdi-arrow-top-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-top-left-bold-outline"></i> mdi mdi-arrow-top-left-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-top-left-thick"></i> mdi mdi-arrow-top-left-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-top-right"></i> mdi mdi-arrow-top-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-top-right-bold-outline"></i> mdi mdi-arrow-top-right-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-top-right-thick"></i> mdi mdi-arrow-top-right-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-expand-up"></i> mdi mdi-arrow-expand-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-bold"></i> mdi mdi-arrow-up-bold
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-bold-box"></i> mdi mdi-arrow-up-bold-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-bold-box-outline"></i> mdi mdi-arrow-up-bold-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-bold-circle"></i> mdi mdi-arrow-up-bold-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-bold-circle-outline"></i> mdi mdi-arrow-up-bold-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-bold-hexagon-outline"></i> mdi mdi-arrow-up-bold-hexagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-bold-outline"></i> mdi mdi-arrow-up-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-box"></i> mdi mdi-arrow-up-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-down-bold-outline"></i> mdi mdi-arrow-up-down-bold-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-drop-circle"></i> mdi mdi-arrow-up-drop-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-drop-circle-outline"></i> mdi mdi-arrow-up-drop-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-arrow-up-thick"></i> mdi mdi-arrow-up-thick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-artist"></i> mdi mdi-artist
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-aspect-ratio"></i> mdi mdi-aspect-ratio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-assistant"></i> mdi mdi-assistant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-asterisk"></i> mdi mdi-asterisk
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-at"></i> mdi mdi-at
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-atlassian"></i> mdi mdi-atlassian
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-atom"></i> mdi mdi-atom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-attachment"></i> mdi mdi-attachment
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-audio-video"></i> mdi mdi-audio-video
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-audiobook"></i> mdi mdi-audiobook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-augmented-reality"></i> mdi mdi-augmented-reality
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-auto-fix"></i> mdi mdi-auto-fix
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-auto-upload"></i> mdi mdi-auto-upload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-autorenew"></i> mdi mdi-autorenew
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-av-timer"></i> mdi mdi-av-timer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-axe"></i> mdi mdi-axe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-azure"></i> mdi mdi-azure
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-babel"></i> mdi mdi-babel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-baby"></i> mdi mdi-baby
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-baby-buggy"></i> mdi mdi-baby-buggy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-backburger"></i> mdi mdi-backburger
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-backspace"></i> mdi mdi-backspace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-backup-restore"></i> mdi mdi-backup-restore
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-badminton"></i> mdi mdi-badminton
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-balloon"></i> mdi mdi-balloon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ballot"></i> mdi mdi-ballot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ballot-outline"></i> mdi mdi-ballot-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bandcamp"></i> mdi mdi-bandcamp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bank"></i> mdi mdi-bank
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bank-transfer"></i> mdi mdi-bank-transfer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bank-transfer-in"></i> mdi mdi-bank-transfer-in
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bank-transfer-out"></i> mdi mdi-bank-transfer-out
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-barcode"></i> mdi mdi-barcode
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-barcode-scan"></i> mdi mdi-barcode-scan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-barley"></i> mdi mdi-barley
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-barrel"></i> mdi mdi-barrel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-baseball"></i> mdi mdi-baseball
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-baseball-bat"></i> mdi mdi-baseball-bat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-basecamp"></i> mdi mdi-basecamp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-basket"></i> mdi mdi-basket
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-basket-fill"></i> mdi mdi-basket-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-basket-unfill"></i> mdi mdi-basket-unfill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-basketball"></i> mdi mdi-basketball
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery"></i> mdi mdi-battery
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-10"></i> mdi mdi-battery-10
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-10-bluetooth"></i> mdi mdi-battery-10-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-20"></i> mdi mdi-battery-20
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-20-bluetooth"></i> mdi mdi-battery-20-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-30"></i> mdi mdi-battery-30
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-30-bluetooth"></i> mdi mdi-battery-30-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-40"></i> mdi mdi-battery-40
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-40-bluetooth"></i> mdi mdi-battery-40-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-50"></i> mdi mdi-battery-50
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-50-bluetooth"></i> mdi mdi-battery-50-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-60"></i> mdi mdi-battery-60
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-60-bluetooth"></i> mdi mdi-battery-60-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-70"></i> mdi mdi-battery-70
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-70-bluetooth"></i> mdi mdi-battery-70-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-80"></i> mdi mdi-battery-80
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-80-bluetooth"></i> mdi mdi-battery-80-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-90"></i> mdi mdi-battery-90
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-90-bluetooth"></i> mdi mdi-battery-90-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-alert"></i> mdi mdi-battery-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-alert-bluetooth"></i> mdi mdi-battery-alert-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-bluetooth"></i> mdi mdi-battery-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-bluetooth-variant"></i> mdi mdi-battery-bluetooth-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging"></i> mdi mdi-battery-charging
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-10"></i> mdi mdi-battery-charging-10
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-100"></i> mdi mdi-battery-charging-100
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-20"></i> mdi mdi-battery-charging-20
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-30"></i> mdi mdi-battery-charging-30
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-40"></i> mdi mdi-battery-charging-40
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-50"></i> mdi mdi-battery-charging-50
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-60"></i> mdi mdi-battery-charging-60
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-70"></i> mdi mdi-battery-charging-70
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-80"></i> mdi mdi-battery-charging-80
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-90"></i> mdi mdi-battery-charging-90
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-wireless-alert"></i> mdi mdi-battery-charging-wireless-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-charging-wireless-outline"></i> mdi mdi-battery-charging-wireless-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-minus"></i> mdi mdi-battery-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-negative"></i> mdi mdi-battery-negative
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-outline"></i> mdi mdi-battery-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-plus"></i> mdi mdi-battery-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-positive"></i> mdi mdi-battery-positive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-unknown"></i> mdi mdi-battery-unknown
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-battery-unknown-bluetooth"></i> mdi mdi-battery-unknown-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-beach"></i> mdi mdi-beach
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-beaker"></i> mdi mdi-beaker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-beats"></i> mdi mdi-beats
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bed-empty"></i> mdi mdi-bed-empty
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-beer"></i> mdi mdi-beer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-behance"></i> mdi mdi-behance
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell"></i> mdi mdi-bell
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-off"></i> mdi mdi-bell-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-off-outline"></i> mdi mdi-bell-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-outline"></i> mdi mdi-bell-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-plus"></i> mdi mdi-bell-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-plus-outline"></i> mdi mdi-bell-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-ring"></i> mdi mdi-bell-ring
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-ring-outline"></i> mdi mdi-bell-ring-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-sleep"></i> mdi mdi-bell-sleep
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bell-sleep-outline"></i> mdi mdi-bell-sleep-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-beta"></i> mdi mdi-beta
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-betamax"></i> mdi mdi-betamax
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bible"></i> mdi mdi-bible
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bike"></i> mdi mdi-bike
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bing"></i> mdi mdi-bing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-binoculars"></i> mdi mdi-binoculars
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bio"></i> mdi mdi-bio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-biohazard"></i> mdi mdi-biohazard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bitbucket"></i> mdi mdi-bitbucket
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bitcoin"></i> mdi mdi-bitcoin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-black-mesa"></i> mdi mdi-black-mesa
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blackberry"></i> mdi mdi-blackberry
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blender"></i> mdi mdi-blender
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blinds"></i> mdi mdi-blinds
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-block-helper"></i> mdi mdi-block-helper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blogger"></i> mdi mdi-blogger
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bluetooth"></i> mdi mdi-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bluetooth-audio"></i> mdi mdi-bluetooth-audio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bluetooth-connect"></i> mdi mdi-bluetooth-connect
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bluetooth-off"></i> mdi mdi-bluetooth-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bluetooth-settings"></i> mdi mdi-bluetooth-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bluetooth-transfer"></i> mdi mdi-bluetooth-transfer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blur"></i> mdi mdi-blur
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blur-linear"></i> mdi mdi-blur-linear
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blur-off"></i> mdi mdi-blur-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blur-radial"></i> mdi mdi-blur-radial
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bomb"></i> mdi mdi-bomb
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bomb-off"></i> mdi mdi-bomb-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bone"></i> mdi mdi-bone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book"></i> mdi mdi-book
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-lock"></i> mdi mdi-book-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-lock-open"></i> mdi mdi-book-lock-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-minus"></i> mdi mdi-book-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-multiple"></i> mdi mdi-book-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-multiple-minus"></i> mdi mdi-book-multiple-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-multiple-plus"></i> mdi mdi-book-multiple-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-multiple-remove"></i> mdi mdi-book-multiple-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-multiple-variant"></i> mdi mdi-book-multiple-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-open"></i> mdi mdi-book-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-open-page-variant"></i> mdi mdi-book-open-page-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-open-variant"></i> mdi mdi-book-open-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-plus"></i> mdi mdi-book-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-remove"></i> mdi mdi-book-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-book-variant"></i> mdi mdi-book-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark"></i> mdi mdi-bookmark
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-check"></i> mdi mdi-bookmark-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-minus"></i> mdi mdi-bookmark-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-minus-outline"></i> mdi mdi-bookmark-minus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-music"></i> mdi mdi-bookmark-music
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-off"></i> mdi mdi-bookmark-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-off-outline"></i> mdi mdi-bookmark-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-outline"></i> mdi mdi-bookmark-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-plus"></i> mdi mdi-bookmark-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-plus-outline"></i> mdi mdi-bookmark-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bookmark-remove"></i> mdi mdi-bookmark-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-boombox"></i> mdi mdi-boombox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bootstrap"></i> mdi mdi-bootstrap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-all"></i> mdi mdi-border-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-all-variant"></i> mdi mdi-border-all-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-bottom"></i> mdi mdi-border-bottom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-bottom-variant"></i> mdi mdi-border-bottom-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-color"></i> mdi mdi-border-color
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-horizontal"></i> mdi mdi-border-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-inside"></i> mdi mdi-border-inside
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-left"></i> mdi mdi-border-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-left-variant"></i> mdi mdi-border-left-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-none"></i> mdi mdi-border-none
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-none-variant"></i> mdi mdi-border-none-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-outside"></i> mdi mdi-border-outside
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-right"></i> mdi mdi-border-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-right-variant"></i> mdi mdi-border-right-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-style"></i> mdi mdi-border-style
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-top"></i> mdi mdi-border-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-top-variant"></i> mdi mdi-border-top-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-border-vertical"></i> mdi mdi-border-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bottle-wine"></i> mdi mdi-bottle-wine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bow-tie"></i> mdi mdi-bow-tie
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bowl"></i> mdi mdi-bowl
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bowling"></i> mdi mdi-bowling
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-box"></i> mdi mdi-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-box-cutter"></i> mdi mdi-box-cutter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-box-shadow"></i> mdi mdi-box-shadow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-braille"></i> mdi mdi-braille
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brain"></i> mdi mdi-brain
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bridge"></i> mdi mdi-bridge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase"></i> mdi mdi-briefcase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-check"></i> mdi mdi-briefcase-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-download"></i> mdi mdi-briefcase-download
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-edit"></i> mdi mdi-briefcase-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-minus"></i> mdi mdi-briefcase-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-outline"></i> mdi mdi-briefcase-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-plus"></i> mdi mdi-briefcase-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-remove"></i> mdi mdi-briefcase-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-search"></i> mdi mdi-briefcase-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-briefcase-upload"></i> mdi mdi-briefcase-upload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-1"></i> mdi mdi-brightness-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-2"></i> mdi mdi-brightness-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-3"></i> mdi mdi-brightness-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-4"></i> mdi mdi-brightness-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-5"></i> mdi mdi-brightness-5
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-6"></i> mdi mdi-brightness-6
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-7"></i> mdi mdi-brightness-7
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brightness-auto"></i> mdi mdi-brightness-auto
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-broom"></i> mdi mdi-broom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-brush"></i> mdi mdi-brush
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-buddhism"></i> mdi mdi-buddhism
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-buffer"></i> mdi mdi-buffer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bug"></i> mdi mdi-bug
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bug-check"></i> mdi mdi-bug-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bug-check-outline"></i> mdi mdi-bug-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bug-outline"></i> mdi mdi-bug-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bulldozer"></i> mdi mdi-bulldozer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bulletin-board"></i> mdi mdi-bulletin-board
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bullhorn"></i> mdi mdi-bullhorn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bullhorn-outline"></i> mdi mdi-bullhorn-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bullseye-arrow"></i> mdi mdi-bullseye-arrow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus"></i> mdi mdi-bus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus-alert"></i> mdi mdi-bus-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus-articulated-end"></i> mdi mdi-bus-articulated-end
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus-articulated-front"></i> mdi mdi-bus-articulated-front
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus-clock"></i> mdi mdi-bus-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus-double-decker"></i> mdi mdi-bus-double-decker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus-school"></i> mdi mdi-bus-school
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-bus-side"></i> mdi mdi-bus-side
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cached"></i> mdi mdi-cached
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cake"></i> mdi mdi-cake
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cake-layered"></i> mdi mdi-cake-layered
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cake-variant"></i> mdi mdi-cake-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calculator"></i> mdi mdi-calculator
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calculator-variant"></i> mdi mdi-calculator-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar"></i> mdi mdi-calendar
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-alert"></i> mdi mdi-calendar-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-blank"></i> mdi mdi-calendar-blank
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-check"></i> mdi mdi-calendar-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-clock"></i> mdi mdi-calendar-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-edit"></i> mdi mdi-calendar-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-export"></i> mdi mdi-calendar-export
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-heart"></i> mdi mdi-calendar-heart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-import"></i> mdi mdi-calendar-import
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-multiple"></i> mdi mdi-calendar-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-multiple-check"></i> mdi mdi-calendar-multiple-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-multiselect"></i> mdi mdi-calendar-multiselect
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-plus"></i> mdi mdi-calendar-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-question"></i> mdi mdi-calendar-question
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-range"></i> mdi mdi-calendar-range
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-remove"></i> mdi mdi-calendar-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-search"></i> mdi mdi-calendar-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-star"></i> mdi mdi-calendar-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-text"></i> mdi mdi-calendar-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-today"></i> mdi mdi-calendar-today
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-week"></i> mdi mdi-calendar-week
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-calendar-week-begin"></i> mdi mdi-calendar-week-begin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-call-made"></i> mdi mdi-call-made
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-call-merge"></i> mdi mdi-call-merge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-call-missed"></i> mdi mdi-call-missed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-call-received"></i> mdi mdi-call-received
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-call-split"></i> mdi mdi-call-split
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camcorder"></i> mdi mdi-camcorder
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camcorder-box"></i> mdi mdi-camcorder-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camcorder-box-off"></i> mdi mdi-camcorder-box-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camcorder-off"></i> mdi mdi-camcorder-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera"></i> mdi mdi-camera
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-account"></i> mdi mdi-camera-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-burst"></i> mdi mdi-camera-burst
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-enhance"></i> mdi mdi-camera-enhance
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-front"></i> mdi mdi-camera-front
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-front-variant"></i> mdi mdi-camera-front-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-gopro"></i> mdi mdi-camera-gopro
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-image"></i> mdi mdi-camera-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-iris"></i> mdi mdi-camera-iris
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-metering-center"></i> mdi mdi-camera-metering-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-metering-matrix"></i> mdi mdi-camera-metering-matrix
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-metering-partial"></i> mdi mdi-camera-metering-partial
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-metering-spot"></i> mdi mdi-camera-metering-spot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-off"></i> mdi mdi-camera-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-party-mode"></i> mdi mdi-camera-party-mode
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-rear"></i> mdi mdi-camera-rear
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-rear-variant"></i> mdi mdi-camera-rear-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-switch"></i> mdi mdi-camera-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-camera-timer"></i> mdi mdi-camera-timer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cancel"></i> mdi mdi-cancel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-candle"></i> mdi mdi-candle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-candycane"></i> mdi mdi-candycane
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cannabis"></i> mdi mdi-cannabis
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-caps-lock"></i> mdi mdi-caps-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car"></i> mdi mdi-car
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-battery"></i> mdi mdi-car-battery
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-connected"></i> mdi mdi-car-connected
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-convertible"></i> mdi mdi-car-convertible
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-estate"></i> mdi mdi-car-estate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-hatchback"></i> mdi mdi-car-hatchback
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-limousine"></i> mdi mdi-car-limousine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-pickup"></i> mdi mdi-car-pickup
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-side"></i> mdi mdi-car-side
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-sports"></i> mdi mdi-car-sports
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-car-wash"></i> mdi mdi-car-wash
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-caravan"></i> mdi mdi-caravan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards"></i> mdi mdi-cards
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards-club"></i> mdi mdi-cards-club
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards-diamond"></i> mdi mdi-cards-diamond
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards-heart"></i> mdi mdi-cards-heart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards-outline"></i> mdi mdi-cards-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards-playing-outline"></i> mdi mdi-cards-playing-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards-spade"></i> mdi mdi-cards-spade
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cards-variant"></i> mdi mdi-cards-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-carrot"></i> mdi mdi-carrot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cart"></i> mdi mdi-cart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cart-off"></i> mdi mdi-cart-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cart-outline"></i> mdi mdi-cart-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cart-plus"></i> mdi mdi-cart-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-case-sensitive-alt"></i> mdi mdi-case-sensitive-alt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cash"></i> mdi mdi-cash
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cash-100"></i> mdi mdi-cash-100
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cash-multiple"></i> mdi mdi-cash-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cash-refund"></i> mdi mdi-cash-refund
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cash-usd"></i> mdi mdi-cash-usd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cassette"></i> mdi mdi-cassette
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cast"></i> mdi mdi-cast
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cast-connected"></i> mdi mdi-cast-connected
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cast-off"></i> mdi mdi-cast-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-castle"></i> mdi mdi-castle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cat"></i> mdi mdi-cat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cctv"></i> mdi mdi-cctv
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ceiling-light"></i> mdi mdi-ceiling-light
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone"></i> mdi mdi-cellphone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-android"></i> mdi mdi-cellphone-android
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-arrow-down"></i> mdi mdi-cellphone-arrow-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-basic"></i> mdi mdi-cellphone-basic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-dock"></i> mdi mdi-cellphone-dock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-erase"></i> mdi mdi-cellphone-erase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-iphone"></i> mdi mdi-cellphone-iphone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-key"></i> mdi mdi-cellphone-key
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-link"></i> mdi mdi-cellphone-link
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-link-off"></i> mdi mdi-cellphone-link-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-lock"></i> mdi mdi-cellphone-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-message"></i> mdi mdi-cellphone-message
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-off"></i> mdi mdi-cellphone-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-screenshot"></i> mdi mdi-cellphone-screenshot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-settings"></i> mdi mdi-cellphone-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-settings-variant"></i> mdi mdi-cellphone-settings-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-sound"></i> mdi mdi-cellphone-sound
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-text"></i> mdi mdi-cellphone-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cellphone-wireless"></i> mdi mdi-cellphone-wireless
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-certificate"></i> mdi mdi-certificate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chair-school"></i> mdi mdi-chair-school
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-arc"></i> mdi mdi-chart-arc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-areaspline"></i> mdi mdi-chart-areaspline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-bar"></i> mdi mdi-chart-bar
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-bar-stacked"></i> mdi mdi-chart-bar-stacked
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-bubble"></i> mdi mdi-chart-bubble
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-donut"></i> mdi mdi-chart-donut
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-donut-variant"></i> mdi mdi-chart-donut-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-gantt"></i> mdi mdi-chart-gantt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-histogram"></i> mdi mdi-chart-histogram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-line"></i> mdi mdi-chart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-line-stacked"></i> mdi mdi-chart-line-stacked
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-line-variant"></i> mdi mdi-chart-line-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-multiline"></i> mdi mdi-chart-multiline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-pie"></i> mdi mdi-chart-pie
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-scatterplot-hexbin"></i> mdi mdi-chart-scatterplot-hexbin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chart-timeline"></i> mdi mdi-chart-timeline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check"></i> mdi mdi-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-all"></i> mdi mdi-check-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-circle"></i> mdi mdi-check-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-circle-outline"></i> mdi mdi-check-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-decagram"></i> mdi mdi-check-decagram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-check-outline"></i> mdi mdi-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbook"></i> mdi mdi-checkbook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-blank"></i> mdi mdi-checkbox-blank
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-blank-circle"></i> mdi mdi-checkbox-blank-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-blank-circle-outline"></i> mdi mdi-checkbox-blank-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-blank-outline"></i> mdi mdi-checkbox-blank-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-intermediate"></i> mdi mdi-checkbox-intermediate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-marked"></i> mdi mdi-checkbox-marked
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-marked-circle"></i> mdi mdi-checkbox-marked-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-marked-circle-outline"></i> mdi mdi-checkbox-marked-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-marked-outline"></i> mdi mdi-checkbox-marked-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-blank"></i> mdi mdi-checkbox-multiple-blank
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-blank-circle"></i> mdi mdi-checkbox-multiple-blank-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-blank-circle-outline"></i> mdi
              mdi-checkbox-multiple-blank-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-blank-outline"></i> mdi mdi-checkbox-multiple-blank-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-marked"></i> mdi mdi-checkbox-multiple-marked
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-marked-circle"></i> mdi mdi-checkbox-multiple-marked-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-marked-circle-outline"></i> mdi
              mdi-checkbox-multiple-marked-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkbox-multiple-marked-outline"></i> mdi mdi-checkbox-multiple-marked-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-checkerboard"></i> mdi mdi-checkerboard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chemical-weapon"></i> mdi mdi-chemical-weapon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chess-bishop"></i> mdi mdi-chess-bishop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chess-king"></i> mdi mdi-chess-king
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chess-knight"></i> mdi mdi-chess-knight
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chess-pawn"></i> mdi mdi-chess-pawn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chess-queen   "></i> mdi mdi-chess-queen
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chess-rook"></i> mdi mdi-chess-rook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-double-down"></i> mdi mdi-chevron-double-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-double-left"></i> mdi mdi-chevron-double-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-double-right"></i> mdi mdi-chevron-double-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-double-up"></i> mdi mdi-chevron-double-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-down"></i> mdi mdi-chevron-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-down-box"></i> mdi mdi-chevron-down-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-down-box-outline"></i> mdi mdi-chevron-down-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-down-circle"></i> mdi mdi-chevron-down-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-down-circle-outline"></i> mdi mdi-chevron-down-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-left"></i> mdi mdi-chevron-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-left-box"></i> mdi mdi-chevron-left-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-left-box-outline"></i> mdi mdi-chevron-left-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-left-circle"></i> mdi mdi-chevron-left-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-left-circle-outline"></i> mdi mdi-chevron-left-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-right"></i> mdi mdi-chevron-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-right-box"></i> mdi mdi-chevron-right-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-right-box-outline"></i> mdi mdi-chevron-right-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-right-circle"></i> mdi mdi-chevron-right-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-right-circle-outline"></i> mdi mdi-chevron-right-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-up"></i> mdi mdi-chevron-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-up-box"></i> mdi mdi-chevron-up-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-up-box-outline"></i> mdi mdi-chevron-up-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-up-circle"></i> mdi mdi-chevron-up-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chevron-up-circle-outline"></i> mdi mdi-chevron-up-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chili-hot"></i> mdi mdi-chili-hot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chili-medium"></i> mdi mdi-chili-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chili-mild"></i> mdi mdi-chili-mild
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-chip"></i> mdi mdi-chip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-christianity"></i> mdi mdi-christianity
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-church"></i> mdi mdi-church
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle"></i> mdi mdi-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-edit-outline"></i> mdi mdi-circle-edit-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-medium"></i> mdi mdi-circle-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-outline"></i> mdi mdi-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-1"></i> mdi mdi-circle-slice-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-2"></i> mdi mdi-circle-slice-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-3"></i> mdi mdi-circle-slice-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-4"></i> mdi mdi-circle-slice-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-5"></i> mdi mdi-circle-slice-5
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-6"></i> mdi mdi-circle-slice-6
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-7"></i> mdi mdi-circle-slice-7
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-slice-8"></i> mdi mdi-circle-slice-8
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-circle-small"></i> mdi mdi-circle-small
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cisco-webex"></i> mdi mdi-cisco-webex
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-city"></i> mdi mdi-city
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-city-variant"></i> mdi mdi-city-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-city-variant-outline"></i> mdi mdi-city-variant-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard"></i> mdi mdi-clipboard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-account"></i> mdi mdi-clipboard-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-alert"></i> mdi mdi-clipboard-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-arrow-down"></i> mdi mdi-clipboard-arrow-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-arrow-left"></i> mdi mdi-clipboard-arrow-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-check"></i> mdi mdi-clipboard-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-check-outline"></i> mdi mdi-clipboard-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-flow"></i> mdi mdi-clipboard-flow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-outline"></i> mdi mdi-clipboard-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-plus"></i> mdi mdi-clipboard-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-pulse"></i> mdi mdi-clipboard-pulse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-pulse-outline"></i> mdi mdi-clipboard-pulse-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-text"></i> mdi mdi-clipboard-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clipboard-text-outline"></i> mdi mdi-clipboard-text-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clippy"></i> mdi mdi-clippy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock"></i> mdi mdi-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-alert"></i> mdi mdi-clock-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-alert-outline"></i> mdi mdi-clock-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-end"></i> mdi mdi-clock-end
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-fast"></i> mdi mdi-clock-fast
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-in"></i> mdi mdi-clock-in
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-out"></i> mdi mdi-clock-out
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-outline"></i> mdi mdi-clock-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clock-start"></i> mdi mdi-clock-start
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close"></i> mdi mdi-close
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-box"></i> mdi mdi-close-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-box-outline"></i> mdi mdi-close-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-circle"></i> mdi mdi-close-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-circle-outline"></i> mdi mdi-close-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-network"></i> mdi mdi-close-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-octagon"></i> mdi mdi-close-octagon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-octagon-outline"></i> mdi mdi-close-octagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-close-outline"></i> mdi mdi-close-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-closed-caption"></i> mdi mdi-closed-caption
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud"></i> mdi mdi-cloud
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-alert"></i> mdi mdi-cloud-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-braces"></i> mdi mdi-cloud-braces
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-check"></i> mdi mdi-cloud-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-circle"></i> mdi mdi-cloud-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-download"></i> mdi mdi-cloud-download
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-off-outline"></i> mdi mdi-cloud-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-outline"></i> mdi mdi-cloud-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-print"></i> mdi mdi-cloud-print
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-print-outline"></i> mdi mdi-cloud-print-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-question"></i> mdi mdi-cloud-question
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-search"></i> mdi mdi-cloud-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-search-outline"></i> mdi mdi-cloud-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-sync"></i> mdi mdi-cloud-sync
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-tags"></i> mdi mdi-cloud-tags
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cloud-upload"></i> mdi mdi-cloud-upload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-clover"></i> mdi mdi-clover
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-array"></i> mdi mdi-code-array
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-braces"></i> mdi mdi-code-braces
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-brackets"></i> mdi mdi-code-brackets
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-equal"></i> mdi mdi-code-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-greater-than"></i> mdi mdi-code-greater-than
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-greater-than-or-equal"></i> mdi mdi-code-greater-than-or-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-less-than"></i> mdi mdi-code-less-than
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-less-than-or-equal"></i> mdi mdi-code-less-than-or-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-not-equal"></i> mdi mdi-code-not-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-not-equal-variant"></i> mdi mdi-code-not-equal-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-parentheses"></i> mdi mdi-code-parentheses
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-string"></i> mdi mdi-code-string
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-tags"></i> mdi mdi-code-tags
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-code-tags-check"></i> mdi mdi-code-tags-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-codepen"></i> mdi mdi-codepen
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-coffee"></i> mdi mdi-coffee
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-coffee-outline"></i> mdi mdi-coffee-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-coffee-to-go"></i> mdi mdi-coffee-to-go
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cogs"></i> mdi mdi-cogs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-coin"></i> mdi mdi-coin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-coins"></i> mdi mdi-coins
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-collage"></i> mdi mdi-collage
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-collapse-all"></i> mdi mdi-collapse-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-collapse-all-outline"></i> mdi mdi-collapse-all-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-color-helper"></i> mdi mdi-color-helper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment"></i> mdi mdi-comment
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-account"></i> mdi mdi-comment-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-account-outline"></i> mdi mdi-comment-account-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-alert"></i> mdi mdi-comment-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-alert-outline"></i> mdi mdi-comment-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-arrow-left"></i> mdi mdi-comment-arrow-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-arrow-left-outline"></i> mdi mdi-comment-arrow-left-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-arrow-right"></i> mdi mdi-comment-arrow-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-arrow-right-outline"></i> mdi mdi-comment-arrow-right-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-check"></i> mdi mdi-comment-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-check-outline"></i> mdi mdi-comment-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-eye"></i> mdi mdi-comment-eye
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-eye-outline"></i> mdi mdi-comment-eye-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-multiple"></i> mdi mdi-comment-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-multiple-outline"></i> mdi mdi-comment-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-outline"></i> mdi mdi-comment-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-plus"></i> mdi mdi-comment-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-plus-outline"></i> mdi mdi-comment-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-processing"></i> mdi mdi-comment-processing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-processing-outline"></i> mdi mdi-comment-processing-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-question"></i> mdi mdi-comment-question
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-question-outline"></i> mdi mdi-comment-question-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-remove"></i> mdi mdi-comment-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-remove-outline"></i> mdi mdi-comment-remove-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-search"></i> mdi mdi-comment-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-search-outline"></i> mdi mdi-comment-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-text"></i> mdi mdi-comment-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-text-multiple"></i> mdi mdi-comment-text-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-text-multiple-outline"></i> mdi mdi-comment-text-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-comment-text-outline"></i> mdi mdi-comment-text-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-compare"></i> mdi mdi-compare
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-compass"></i> mdi mdi-compass
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-compass-outline"></i> mdi mdi-compass-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-console"></i> mdi mdi-console
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-console-line"></i> mdi mdi-console-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contact-mail"></i> mdi mdi-contact-mail
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contacts"></i> mdi mdi-contacts
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contain"></i> mdi mdi-contain
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contain-end"></i> mdi mdi-contain-end
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contain-start"></i> mdi mdi-contain-start
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-copy"></i> mdi mdi-content-copy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-cut"></i> mdi mdi-content-cut
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-duplicate"></i> mdi mdi-content-duplicate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-paste"></i> mdi mdi-content-paste
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-save"></i> mdi mdi-content-save
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-save-all"></i> mdi mdi-content-save-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-save-outline"></i> mdi mdi-content-save-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-save-settings"></i> mdi mdi-content-save-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-content-save-settings-outline"></i> mdi mdi-content-save-settings-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contrast"></i> mdi mdi-contrast
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contrast-box"></i> mdi mdi-contrast-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-contrast-circle"></i> mdi mdi-contrast-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cookie"></i> mdi mdi-cookie
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-copyright"></i> mdi mdi-copyright
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cordova"></i> mdi mdi-cordova
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-corn"></i> mdi mdi-corn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-counter"></i> mdi mdi-counter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cow"></i> mdi mdi-cow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crane"></i> mdi mdi-crane
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-creation"></i> mdi mdi-creation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card"></i> mdi mdi-credit-card
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card-multiple"></i> mdi mdi-credit-card-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card-off"></i> mdi mdi-credit-card-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card-plus"></i> mdi mdi-credit-card-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card-refund"></i> mdi mdi-credit-card-refund
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card-scan"></i> mdi mdi-credit-card-scan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-credit-card-settings"></i> mdi mdi-credit-card-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crop"></i> mdi mdi-crop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crop-free"></i> mdi mdi-crop-free
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crop-landscape"></i> mdi mdi-crop-landscape
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crop-portrait"></i> mdi mdi-crop-portrait
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crop-rotate"></i> mdi mdi-crop-rotate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crop-square"></i> mdi mdi-crop-square
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crosshairs"></i> mdi mdi-crosshairs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crosshairs-gps"></i> mdi mdi-crosshairs-gps
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crown"></i> mdi mdi-crown
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cryengine"></i> mdi mdi-cryengine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-crystal-ball"></i> mdi mdi-crystal-ball
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cube"></i> mdi mdi-cube
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cube-outline"></i> mdi mdi-cube-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cube-send"></i> mdi mdi-cube-send
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cube-unfolded"></i> mdi mdi-cube-unfolded
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cup"></i> mdi mdi-cup
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cup-off"></i> mdi mdi-cup-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cup-water"></i> mdi mdi-cup-water
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cupcake"></i> mdi mdi-cupcake
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-curling"></i> mdi mdi-curling
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-bdt"></i> mdi mdi-currency-bdt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-btc"></i> mdi mdi-currency-btc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-chf"></i> mdi mdi-currency-chf
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-cny"></i> mdi mdi-currency-cny
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-eth"></i> mdi mdi-currency-eth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-eur"></i> mdi mdi-currency-eur
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-gbp"></i> mdi mdi-currency-gbp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-inr"></i> mdi mdi-currency-inr
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-jpy"></i> mdi mdi-currency-jpy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-krw"></i> mdi mdi-currency-krw
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-kzt"></i> mdi mdi-currency-kzt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-ngn"></i> mdi mdi-currency-ngn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-php"></i> mdi mdi-currency-php
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-rub"></i> mdi mdi-currency-rub
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-sign"></i> mdi mdi-currency-sign
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-try"></i> mdi mdi-currency-try
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-twd"></i> mdi mdi-currency-twd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-usd"></i> mdi mdi-currency-usd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-currency-usd-off"></i> mdi mdi-currency-usd-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-current-ac"></i> mdi mdi-current-ac
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-current-dc"></i> mdi mdi-current-dc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cursor-default"></i> mdi mdi-cursor-default
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cursor-default-outline"></i> mdi mdi-cursor-default-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cursor-move"></i> mdi mdi-cursor-move
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cursor-pointer"></i> mdi mdi-cursor-pointer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-cursor-text"></i> mdi mdi-cursor-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database"></i> mdi mdi-database
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-check"></i> mdi mdi-database-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-export"></i> mdi mdi-database-export
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-import"></i> mdi mdi-database-import
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-lock"></i> mdi mdi-database-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-minus"></i> mdi mdi-database-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-plus"></i> mdi mdi-database-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-database-search"></i> mdi mdi-database-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-death-star"></i> mdi mdi-death-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-death-star-variant"></i> mdi mdi-death-star-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-debian"></i> mdi mdi-debian
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-debug-step-into"></i> mdi mdi-debug-step-into
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-debug-step-into"></i> mdi mdi-debug-step-into
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-debug-step-out"></i> mdi mdi-debug-step-out
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-debug-step-over"></i> mdi mdi-debug-step-over
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-decagram"></i> mdi mdi-decagram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-decagram-outline"></i> mdi mdi-decagram-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-decimal-decrease"></i> mdi mdi-decimal-decrease
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-decimal-increase"></i> mdi mdi-decimal-increase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete"></i> mdi mdi-delete
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-circle"></i> mdi mdi-delete-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-empty"></i> mdi mdi-delete-empty
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-forever"></i> mdi mdi-delete-forever
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-outline"></i> mdi mdi-delete-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-restore"></i> mdi mdi-delete-restore
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-sweep"></i> mdi mdi-delete-sweep
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delete-variant"></i> mdi mdi-delete-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-delta"></i> mdi mdi-delta
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-desk-lamp"></i> mdi mdi-desk-lamp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-deskphone"></i> mdi mdi-deskphone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-desktop-classic"></i> mdi mdi-desktop-classic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-desktop-mac"></i> mdi mdi-desktop-mac
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-desktop-mac-dashboard"></i> mdi mdi-desktop-mac-dashboard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-desktop-tower"></i> mdi mdi-desktop-tower
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-desktop-tower-monitor"></i> mdi mdi-desktop-tower-monitor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-details"></i> mdi mdi-details
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-developer-board"></i> mdi mdi-developer-board
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-deviantart"></i> mdi mdi-deviantart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dialpad"></i> mdi mdi-dialpad
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-diamond"></i> mdi mdi-diamond
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-1"></i> mdi mdi-dice-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-2"></i> mdi mdi-dice-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-3"></i> mdi mdi-dice-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-4"></i> mdi mdi-dice-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-5"></i> mdi mdi-dice-5
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-6"></i> mdi mdi-dice-6
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-d10"></i> mdi mdi-dice-d10
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-d12"></i> mdi mdi-dice-d12
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-d20"></i> mdi mdi-dice-d20
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-d4"></i> mdi mdi-dice-d4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-d6"></i> mdi mdi-dice-d6
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-d8"></i> mdi mdi-dice-d8
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dice-multiple"></i> mdi mdi-dice-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dictionary"></i> mdi mdi-dictionary
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dip-switch"></i> mdi mdi-dip-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-directions"></i> mdi mdi-directions
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-directions-fork"></i> mdi mdi-directions-fork
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-disc"></i> mdi mdi-disc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-disc-alert"></i> mdi mdi-disc-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-disc-player"></i> mdi mdi-disc-player
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-discord"></i> mdi mdi-discord
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dishwasher"></i> mdi mdi-dishwasher
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-disqus"></i> mdi mdi-disqus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-disqus-outline"></i> mdi mdi-disqus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-division"></i> mdi mdi-division
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-division-box"></i> mdi mdi-division-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dlna"></i> mdi mdi-dlna
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dna"></i> mdi mdi-dna
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dns"></i> mdi mdi-dns
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-do-not-disturb"></i> mdi mdi-do-not-disturb
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-do-not-disturb-off"></i> mdi mdi-do-not-disturb-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-docker"></i> mdi mdi-docker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-doctor"></i> mdi mdi-doctor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dog"></i> mdi mdi-dog
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dog-service"></i> mdi mdi-dog-service
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dog-side"></i> mdi mdi-dog-side
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dolby"></i> mdi mdi-dolby
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-domain"></i> mdi mdi-domain
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-donkey"></i> mdi mdi-donkey
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-door"></i> mdi mdi-door
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-door-closed"></i> mdi mdi-door-closed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-door-open"></i> mdi mdi-door-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-doorbell-video"></i> mdi mdi-doorbell-video
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dot-net"></i> mdi mdi-dot-net
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dots-horizontal"></i> mdi mdi-dots-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dots-horizontal-circle"></i> mdi mdi-dots-horizontal-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dots-vertical"></i> mdi mdi-dots-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dots-vertical-circle"></i> mdi mdi-dots-vertical-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-douban"></i> mdi mdi-douban
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-download"></i> mdi mdi-download
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-download-multiple"></i> mdi mdi-download-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-download-network"></i> mdi mdi-download-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drag"></i> mdi mdi-drag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drag-horizontal"></i> mdi mdi-drag-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drag-vertical"></i> mdi mdi-drag-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drawing"></i> mdi mdi-drawing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drawing-box"></i> mdi mdi-drawing-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dribbble"></i> mdi mdi-dribbble
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dribbble-box"></i> mdi mdi-dribbble-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drone"></i> mdi mdi-drone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dropbox"></i> mdi mdi-dropbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-drupal"></i> mdi mdi-drupal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-duck"></i> mdi mdi-duck
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-dumbbell"></i> mdi mdi-dumbbell
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ear-hearing"></i> mdi mdi-ear-hearing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ear-hearing-off"></i> mdi mdi-ear-hearing-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-earth"></i> mdi mdi-earth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-earth-box"></i> mdi mdi-earth-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-earth-box-off"></i> mdi mdi-earth-box-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-earth-off"></i> mdi mdi-earth-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-edge"></i> mdi mdi-edge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-egg"></i> mdi mdi-egg
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-egg-easter"></i> mdi mdi-egg-easter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eight-track"></i> mdi mdi-eight-track
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eject"></i> mdi mdi-eject
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-elephant"></i> mdi mdi-elephant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-elevation-decline"></i> mdi mdi-elevation-decline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-elevation-rise"></i> mdi mdi-elevation-rise
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-elevator"></i> mdi mdi-elevator
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email"></i> mdi mdi-email
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-alert"></i> mdi mdi-email-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-check"></i> mdi mdi-email-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-check-outline"></i> mdi mdi-email-check-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-lock"></i> mdi mdi-email-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-open"></i> mdi mdi-email-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-open-outline"></i> mdi mdi-email-open-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-outline"></i> mdi mdi-email-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-plus"></i> mdi mdi-email-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-plus-outline"></i> mdi mdi-email-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-search"></i> mdi mdi-email-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-search-outline"></i> mdi mdi-email-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-email-variant"></i> mdi mdi-email-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ember"></i> mdi mdi-ember
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emby"></i> mdi mdi-emby
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-outline"></i> mdi mdi-emoticon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-cool-outline"></i> mdi mdi-emoticon-cool-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-dead-outline"></i> mdi mdi-emoticon-dead-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-devil-outline"></i> mdi mdi-emoticon-devil-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-excited-outline"></i> mdi mdi-emoticon-excited-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-happy-outline"></i> mdi mdi-emoticon-happy-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-neutral-outline"></i> mdi mdi-emoticon-neutral-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-poop"></i> mdi mdi-emoticon-poop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-sad-outline"></i> mdi mdi-emoticon-sad-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-emoticon-tongue"></i> mdi mdi-emoticon-tongue
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-engine"></i> mdi mdi-engine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-engine-off"></i> mdi mdi-engine-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-engine-off-outline"></i> mdi mdi-engine-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-engine-outline"></i> mdi mdi-engine-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-equal"></i> mdi mdi-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-equal-box"></i> mdi mdi-equal-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eraser"></i> mdi mdi-eraser
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eraser-variant"></i> mdi mdi-eraser-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-escalator"></i> mdi mdi-escalator
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-et"></i> mdi mdi-et
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ethereum"></i> mdi mdi-ethereum
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ethernet"></i> mdi mdi-ethernet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ethernet-cable"></i> mdi mdi-ethernet-cable
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ethernet-cable-off"></i> mdi mdi-ethernet-cable-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-etsy"></i> mdi mdi-etsy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ev-station"></i> mdi mdi-ev-station
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eventbrite"></i> mdi mdi-eventbrite
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-evernote"></i> mdi mdi-evernote
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-exclamation"></i> mdi mdi-exclamation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-exit-run"></i> mdi mdi-exit-run
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-exit-to-app"></i> mdi mdi-exit-to-app
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-expand-all"></i> mdi mdi-expand-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-expand-all-outline"></i> mdi mdi-expand-all-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-exponent"></i> mdi mdi-exponent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-exponent-box"></i> mdi mdi-exponent-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-export"></i> mdi mdi-export
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye"></i> mdi mdi-eye
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-off"></i> mdi mdi-eye-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-off-outline"></i> mdi mdi-eye-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-outline"></i> mdi mdi-eye-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-plus"></i> mdi mdi-eye-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-plus-outline"></i> mdi mdi-eye-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-settings"></i> mdi mdi-eye-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eye-settings-outline"></i> mdi mdi-eye-settings-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eyedropper"></i> mdi mdi-eyedropper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-eyedropper-variant"></i> mdi mdi-eyedropper-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-face"></i> mdi mdi-face
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-face-profile"></i> mdi mdi-face-profile
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-facebook"></i> mdi mdi-facebook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-facebook-box"></i> mdi mdi-facebook-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-facebook-messenger"></i> mdi mdi-facebook-messenger
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-facebook-workplace"></i> mdi mdi-facebook-workplace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-factory"></i> mdi mdi-factory
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fan"></i> mdi mdi-fan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fan-off"></i> mdi mdi-fan-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fast-forward"></i> mdi mdi-fast-forward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fast-forward-outline"></i> mdi mdi-fast-forward-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fax"></i> mdi mdi-fax
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-feather"></i> mdi mdi-feather
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-feature-search"></i> mdi mdi-feature-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-feature-search-outline"></i> mdi mdi-feature-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fedora"></i> mdi mdi-fedora
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ferry"></i> mdi mdi-ferry
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file"></i> mdi mdi-file
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-account"></i> mdi mdi-file-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-alert"></i> mdi mdi-file-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-alert-outline"></i> mdi mdi-file-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-cabinet"></i> mdi mdi-file-cabinet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-chart"></i> mdi mdi-file-chart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-check"></i> mdi mdi-file-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-cloud"></i> mdi mdi-file-cloud
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-compare"></i> mdi mdi-file-compare
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-delimited"></i> mdi mdi-file-delimited
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document"></i> mdi mdi-file-document
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document-box"></i> mdi mdi-file-document-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document-box-multiple"></i> mdi mdi-file-document-box-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document-box-multiple-outline"></i> mdi mdi-file-document-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document-box-outline"></i> mdi mdi-file-document-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-document-outline"></i> mdi mdi-file-document-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-download"></i> mdi mdi-file-download
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-download-outline"></i> mdi mdi-file-download-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-excel"></i> mdi mdi-file-excel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-excel-box"></i> mdi mdi-file-excel-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-export"></i> mdi mdi-file-export
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-find"></i> mdi mdi-file-find
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-hidden"></i> mdi mdi-file-hidden
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-image"></i> mdi mdi-file-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-import"></i> mdi mdi-file-import
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-lock"></i> mdi mdi-file-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-move"></i> mdi mdi-file-move
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-multiple"></i> mdi mdi-file-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-music"></i> mdi mdi-file-music
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-outline"></i> mdi mdi-file-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-pdf"></i> mdi mdi-file-pdf
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-pdf-box"></i> mdi mdi-file-pdf-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-percent"></i> mdi mdi-file-percent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-plus"></i> mdi mdi-file-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-powerpoint"></i> mdi mdi-file-powerpoint
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-powerpoint-box"></i> mdi mdi-file-powerpoint-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-presentation-box"></i> mdi mdi-file-presentation-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-question"></i> mdi mdi-file-question
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-replace"></i> mdi mdi-file-replace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-replace-outline"></i> mdi mdi-file-replace-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-restore"></i> mdi mdi-file-restore
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-send"></i> mdi mdi-file-send
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-tree"></i> mdi mdi-file-tree
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-undo"></i> mdi mdi-file-undo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-upload"></i> mdi mdi-file-upload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-upload-outline"></i> mdi mdi-file-upload-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-video"></i> mdi mdi-file-video
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-word"></i> mdi mdi-file-word
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-word-box"></i> mdi mdi-file-word-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-file-xml"></i> mdi mdi-file-xml
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-film"></i> mdi mdi-film
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-filmstrip"></i> mdi mdi-filmstrip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-filmstrip-off"></i> mdi mdi-filmstrip-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-filter"></i> mdi mdi-filter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-filter-outline"></i> mdi mdi-filter-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-filter-remove"></i> mdi mdi-filter-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-filter-remove-outline"></i> mdi mdi-filter-remove-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-filter-variant"></i> mdi mdi-filter-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-finance"></i> mdi mdi-finance
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-find-replace"></i> mdi mdi-find-replace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fingerprint"></i> mdi mdi-fingerprint
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fire"></i> mdi mdi-fire
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fire-truck"></i> mdi mdi-fire-truck
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fire-truck"></i> mdi mdi-fire-truck
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-firebase"></i> mdi mdi-firebase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-firefox"></i> mdi mdi-firefox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fish"></i> mdi mdi-fish
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flag"></i> mdi mdi-flag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flag-checkered"></i> mdi mdi-flag-checkered
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flag-outline"></i> mdi mdi-flag-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flag-triangle"></i> mdi mdi-flag-triangle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flag-variant"></i> mdi mdi-flag-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flag-variant-outline"></i> mdi mdi-flag-variant-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flash"></i> mdi mdi-flash
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flash-auto"></i> mdi mdi-flash-auto
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flash-circle"></i> mdi mdi-flash-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flash-off"></i> mdi mdi-flash-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flash-outline"></i> mdi mdi-flash-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flash-red-eye"></i> mdi mdi-flash-red-eye
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flashlight"></i> mdi mdi-flashlight
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flashlight-off"></i> mdi mdi-flashlight-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flask"></i> mdi mdi-flask
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flask-empty"></i> mdi mdi-flask-empty
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flask-empty-outline"></i> mdi mdi-flask-empty-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flask-outline"></i> mdi mdi-flask-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flattr"></i> mdi mdi-flattr
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flip-to-back"></i> mdi mdi-flip-to-back
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flip-to-front"></i> mdi mdi-flip-to-front
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-floor-lamp"></i> mdi mdi-floor-lamp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-floor-plan"></i> mdi mdi-floor-plan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-floppy"></i> mdi mdi-floppy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-floppy-variant"></i> mdi mdi-floppy-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flower"></i> mdi mdi-flower
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flower-outline"></i> mdi mdi-flower-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flower-tulip"></i> mdi mdi-flower-tulip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-flower-tulip-outline"></i> mdi mdi-flower-tulip-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder"></i> mdi mdi-folder
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-account"></i> mdi mdi-folder-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-clock"></i> mdi mdi-folder-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-clock-outline"></i> mdi mdi-folder-clock-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-download"></i> mdi mdi-folder-download
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-edit"></i> mdi mdi-folder-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-google-drive"></i> mdi mdi-folder-google-drive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-image"></i> mdi mdi-folder-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-key"></i> mdi mdi-folder-key
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-lock"></i> mdi mdi-folder-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-lock-open"></i> mdi mdi-folder-lock-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-move"></i> mdi mdi-folder-move
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-multiple"></i> mdi mdi-folder-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-multiple-image"></i> mdi mdi-folder-multiple-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-multiple-outline"></i> mdi mdi-folder-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-open"></i> mdi mdi-folder-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-outline"></i> mdi mdi-folder-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-plus"></i> mdi mdi-folder-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-remove"></i> mdi mdi-folder-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-search"></i> mdi mdi-folder-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-search-outline"></i> mdi mdi-folder-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-star"></i> mdi mdi-folder-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-folder-upload"></i> mdi mdi-folder-upload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-font-awesome"></i> mdi mdi-font-awesome
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-food"></i> mdi mdi-food
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-food-apple"></i> mdi mdi-food-apple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-food-croissant"></i> mdi mdi-food-croissant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-food-fork-drink"></i> mdi mdi-food-fork-drink
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-food-off"></i> mdi mdi-food-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-food-variant"></i> mdi mdi-food-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-football"></i> mdi mdi-football
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-football-australian"></i> mdi mdi-football-australian
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-football-helmet"></i> mdi mdi-football-helmet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-forklift"></i> mdi mdi-forklift
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-align-bottom"></i> mdi mdi-format-align-bottom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-align-center"></i> mdi mdi-format-align-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-align-justify"></i> mdi mdi-format-align-justify
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-align-left"></i> mdi mdi-format-align-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-align-middle"></i> mdi mdi-format-align-middle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-align-right"></i> mdi mdi-format-align-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-align-top"></i> mdi mdi-format-align-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-annotation-minus"></i> mdi mdi-format-annotation-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-annotation-plus"></i> mdi mdi-format-annotation-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-bold"></i> mdi mdi-format-bold
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-clear"></i> mdi mdi-format-clear
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-color-fill"></i> mdi mdi-format-color-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-color-text"></i> mdi mdi-format-color-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-columns"></i> mdi mdi-format-columns
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-float-center"></i> mdi mdi-format-float-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-float-left"></i> mdi mdi-format-float-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-float-none"></i> mdi mdi-format-float-none
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-float-right"></i> mdi mdi-format-float-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-font"></i> mdi mdi-format-font
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-font-size-decrease"></i> mdi mdi-format-font-size-decrease
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-font-size-increase"></i> mdi mdi-format-font-size-increase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-1"></i> mdi mdi-format-header-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-2"></i> mdi mdi-format-header-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-3"></i> mdi mdi-format-header-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-4"></i> mdi mdi-format-header-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-5"></i> mdi mdi-format-header-5
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-6"></i> mdi mdi-format-header-6
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-decrease"></i> mdi mdi-format-header-decrease
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-equal"></i> mdi mdi-format-header-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-increase"></i> mdi mdi-format-header-increase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-header-pound"></i> mdi mdi-format-header-pound
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-horizontal-align-center"></i> mdi mdi-format-horizontal-align-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-horizontal-align-left"></i> mdi mdi-format-horizontal-align-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-horizontal-align-right"></i> mdi mdi-format-horizontal-align-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-indent-decrease"></i> mdi mdi-format-indent-decrease
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-indent-increase"></i> mdi mdi-format-indent-increase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-italic"></i> mdi mdi-format-italic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-letter-case"></i> mdi mdi-format-letter-case
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-letter-case-lower"></i> mdi mdi-format-letter-case-lower
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-letter-case-upper"></i> mdi mdi-format-letter-case-upper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-line-spacing"></i> mdi mdi-format-line-spacing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-line-style"></i> mdi mdi-format-line-style
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-line-weight"></i> mdi mdi-format-line-weight
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-list-bulleted"></i> mdi mdi-format-list-bulleted
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-list-bulleted-type"></i> mdi mdi-format-list-bulleted-type
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-list-checkbox"></i> mdi mdi-format-list-checkbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-list-checks"></i> mdi mdi-format-list-checks
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-list-numbered"></i> mdi mdi-format-list-numbered
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-page-break"></i> mdi mdi-format-page-break
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-paint"></i> mdi mdi-format-paint
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-paragraph"></i> mdi mdi-format-paragraph
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-pilcrow"></i> mdi mdi-format-pilcrow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-quote-close"></i> mdi mdi-format-quote-close
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-quote-open"></i> mdi mdi-format-quote-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-rotate-90"></i> mdi mdi-format-rotate-90
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-section"></i> mdi mdi-format-section
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-size"></i> mdi mdi-format-size
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-strikethrough"></i> mdi mdi-format-strikethrough
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-strikethrough-variant"></i> mdi mdi-format-strikethrough-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-subscript"></i> mdi mdi-format-subscript
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-superscript"></i> mdi mdi-format-superscript
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-text"></i> mdi mdi-format-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-textdirection-l-to-r"></i> mdi mdi-format-textdirection-l-to-r
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-textdirection-r-to-l"></i> mdi mdi-format-textdirection-r-to-l
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-title"></i> mdi mdi-format-title
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-underline"></i> mdi mdi-format-underline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-vertical-align-bottom"></i> mdi mdi-format-vertical-align-bottom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-vertical-align-center"></i> mdi mdi-format-vertical-align-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-vertical-align-top"></i> mdi mdi-format-vertical-align-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-wrap-inline"></i> mdi mdi-format-wrap-inline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-wrap-square"></i> mdi mdi-format-wrap-square
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-wrap-tight"></i> mdi mdi-format-wrap-tight
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-format-wrap-top-bottom"></i> mdi mdi-format-wrap-top-bottom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-forum"></i> mdi mdi-forum
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-forum-outline"></i> mdi mdi-forum-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-forward"></i> mdi mdi-forward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fountain"></i> mdi mdi-fountain
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-foursquare"></i> mdi mdi-foursquare
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-freebsd"></i> mdi mdi-freebsd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fridge-outline"></i> mdi mdi-fridge-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fridge"></i> mdi mdi-fridge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fridge-bottom"></i> mdi mdi-fridge-bottom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fridge-top"></i> mdi mdi-fridge-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fuel"></i> mdi mdi-fuel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fullscreen"></i> mdi mdi-fullscreen
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-fullscreen-exit"></i> mdi mdi-fullscreen-exit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-function"></i> mdi mdi-function
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-function-variant"></i> mdi mdi-function-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad"></i> mdi mdi-gamepad
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gamepad-variant"></i> mdi mdi-gamepad-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-garage"></i> mdi mdi-garage
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-garage-alert"></i> mdi mdi-garage-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-garage-open"></i> mdi mdi-garage-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gas-cylinder"></i> mdi mdi-gas-cylinder
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gas-station"></i> mdi mdi-gas-station
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate"></i> mdi mdi-gate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate-and"></i> mdi mdi-gate-and
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate-nand"></i> mdi mdi-gate-nand
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate-nor"></i> mdi mdi-gate-nor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate-not"></i> mdi mdi-gate-not
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate-or"></i> mdi mdi-gate-or
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate-xnor"></i> mdi mdi-gate-xnor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gate-xor"></i> mdi mdi-gate-xor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gauge"></i> mdi mdi-gauge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gauge-empty"></i> mdi mdi-gauge-empty
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gauge-full"></i> mdi mdi-gauge-full
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gauge-low"></i> mdi mdi-gauge-low
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gavel"></i> mdi mdi-gavel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gender-female"></i> mdi mdi-gender-female
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gender-male"></i> mdi mdi-gender-male
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gender-male-female"></i> mdi mdi-gender-male-female
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gender-transgender"></i> mdi mdi-gender-transgender
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gentoo"></i> mdi mdi-gentoo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture"></i> mdi mdi-gesture
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-double-tap"></i> mdi mdi-gesture-double-tap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-pinch"></i> mdi mdi-gesture-pinch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-spread"></i> mdi mdi-gesture-spread
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-swipe-down"></i> mdi mdi-gesture-swipe-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-swipe-horizontal"></i> mdi mdi-gesture-swipe-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-swipe-left"></i> mdi mdi-gesture-swipe-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-swipe-right"></i> mdi mdi-gesture-swipe-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-swipe-up"></i> mdi mdi-gesture-swipe-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-swipe-vertical"></i> mdi mdi-gesture-swipe-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-tap"></i> mdi mdi-gesture-tap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-two-double-tap"></i> mdi mdi-gesture-two-double-tap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gesture-two-tap"></i> mdi mdi-gesture-two-tap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ghost"></i> mdi mdi-ghost
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ghost-off"></i> mdi mdi-ghost-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gift"></i> mdi mdi-gift
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-git"></i> mdi mdi-git
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-github-box"></i> mdi mdi-github-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-github-circle"></i> mdi mdi-github-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-github-face"></i> mdi mdi-github-face
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glass-cocktail"></i> mdi mdi-glass-cocktail
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glass-flute"></i> mdi mdi-glass-flute
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glass-mug"></i> mdi mdi-glass-mug
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glass-stange"></i> mdi mdi-glass-stange
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glass-tulip"></i> mdi mdi-glass-tulip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glass-wine"></i> mdi mdi-glass-wine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glassdoor"></i> mdi mdi-glassdoor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-glasses"></i> mdi mdi-glasses
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-globe-model"></i> mdi mdi-globe-model
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gmail"></i> mdi mdi-gmail
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gnome"></i> mdi mdi-gnome
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-golf"></i> mdi mdi-golf
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gondola"></i> mdi mdi-gondola
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google"></i> mdi mdi-google
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-allo"></i> mdi mdi-google-allo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-analytics"></i> mdi mdi-google-analytics
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-assistant"></i> mdi mdi-google-assistant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-cardboard"></i> mdi mdi-google-cardboard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-chrome"></i> mdi mdi-google-chrome
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-circles"></i> mdi mdi-google-circles
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-circles-communities"></i> mdi mdi-google-circles-communities
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-circles-extended"></i> mdi mdi-google-circles-extended
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-circles-group"></i> mdi mdi-google-circles-group
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-controller"></i> mdi mdi-google-controller
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-controller-off"></i> mdi mdi-google-controller-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-drive"></i> mdi mdi-google-drive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-earth"></i> mdi mdi-google-earth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-fit"></i> mdi mdi-google-fit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-glass"></i> mdi mdi-google-glass
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-hangouts"></i> mdi mdi-google-hangouts
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-home"></i> mdi mdi-google-home
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-keep"></i> mdi mdi-google-keep
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-lens"></i> mdi mdi-google-lens
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-maps"></i> mdi mdi-google-maps
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-nearby"></i> mdi mdi-google-nearby
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-pages"></i> mdi mdi-google-pages
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-photos"></i> mdi mdi-google-photos
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-physical-web"></i> mdi mdi-google-physical-web
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-play"></i> mdi mdi-google-play
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-plus"></i> mdi mdi-google-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-plus-box"></i> mdi mdi-google-plus-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-spreadsheet"></i> mdi mdi-google-spreadsheet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-translate"></i> mdi mdi-google-translate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-google-wallet"></i> mdi mdi-google-wallet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gpu"></i> mdi mdi-gpu
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-gradient"></i> mdi mdi-gradient
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-graphql"></i> mdi mdi-graphql
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-grease-pencil"></i> mdi mdi-grease-pencil
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-greater-than"></i> mdi mdi-greater-than
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-greater-than-or-equal"></i> mdi mdi-greater-than-or-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-grid"></i> mdi mdi-grid
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-grid-large"></i> mdi mdi-grid-large
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-grid-off"></i> mdi mdi-grid-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-group"></i> mdi mdi-group
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-guitar-acoustic"></i> mdi mdi-guitar-acoustic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-guitar-electric"></i> mdi mdi-guitar-electric
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-guitar-pick"></i> mdi mdi-guitar-pick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-guitar-pick-outline"></i> mdi mdi-guitar-pick-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-guy-fawkes-mask"></i> mdi mdi-guy-fawkes-mask
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hackernews"></i> mdi mdi-hackernews
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hail"></i> mdi mdi-hail
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hamburger"></i> mdi mdi-hamburger
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hammer"></i> mdi mdi-hammer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand"></i> mdi mdi-hand
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-okay"></i> mdi mdi-hand-okay
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-peace"></i> mdi mdi-hand-peace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-peace-variant"></i> mdi mdi-hand-peace-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-pointing-down"></i> mdi mdi-hand-pointing-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-pointing-left"></i> mdi mdi-hand-pointing-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-pointing-right"></i> mdi mdi-hand-pointing-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hand-pointing-up"></i> mdi mdi-hand-pointing-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hanger"></i> mdi mdi-hanger
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hard-hat"></i> mdi mdi-hard-hat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-harddisk"></i> mdi mdi-harddisk
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headphones"></i> mdi mdi-headphones
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headphones-bluetooth"></i> mdi mdi-headphones-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headphones-box"></i> mdi mdi-headphones-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headphones-off"></i> mdi mdi-headphones-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headphones-settings"></i> mdi mdi-headphones-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headset"></i> mdi mdi-headset
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headset-dock"></i> mdi mdi-headset-dock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-headset-off"></i> mdi mdi-headset-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart"></i> mdi mdi-heart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-box"></i> mdi mdi-heart-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-box-outline"></i> mdi mdi-heart-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-broken"></i> mdi mdi-heart-broken
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-circle"></i> mdi mdi-heart-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-circle-outline"></i> mdi mdi-heart-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-half"></i> mdi mdi-heart-half
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-half-full"></i> mdi mdi-heart-half-full
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-half-outline"></i> mdi mdi-heart-half-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-multiple"></i> mdi mdi-heart-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-multiple-outline"></i> mdi mdi-heart-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-off"></i> mdi mdi-heart-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-outline"></i> mdi mdi-heart-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-heart-pulse"></i> mdi mdi-heart-pulse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-helicopter"></i> mdi mdi-helicopter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help"></i> mdi mdi-help
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help-box"></i> mdi mdi-help-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help-circle"></i> mdi mdi-help-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help-circle-outline"></i> mdi mdi-help-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-help-network"></i> mdi mdi-help-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon"></i> mdi mdi-hexagon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-multiple"></i> mdi mdi-hexagon-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-outline"></i> mdi mdi-hexagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-slice-1"></i> mdi mdi-hexagon-slice-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-slice-2"></i> mdi mdi-hexagon-slice-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-slice-3"></i> mdi mdi-hexagon-slice-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-slice-4"></i> mdi mdi-hexagon-slice-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-slice-5"></i> mdi mdi-hexagon-slice-5
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagon-slice-6"></i> mdi mdi-hexagon-slice-6
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagram"></i> mdi mdi-hexagram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hexagram-outline"></i> mdi mdi-hexagram-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-high-definition"></i> mdi mdi-high-definition
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-high-definition-box"></i> mdi mdi-high-definition-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-highway"></i> mdi mdi-highway
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hinduism"></i> mdi mdi-hinduism
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-history"></i> mdi mdi-history
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hockey-puck"></i> mdi mdi-hockey-puck
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hockey-sticks"></i> mdi mdi-hockey-sticks
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hololens"></i> mdi mdi-hololens
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home"></i> mdi mdi-home
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-account"></i> mdi mdi-home-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-alert"></i> mdi mdi-home-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-assistant"></i> mdi mdi-home-assistant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-automation"></i> mdi mdi-home-automation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-circle"></i> mdi mdi-home-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-currency-usd"></i> mdi mdi-home-currency-usd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-heart"></i> mdi mdi-home-heart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-lock"></i> mdi mdi-home-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-lock-open"></i> mdi mdi-home-lock-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-map-marker"></i> mdi mdi-home-map-marker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-minus"></i> mdi mdi-home-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-modern"></i> mdi mdi-home-modern
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-outline"></i> mdi mdi-home-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-plus"></i> mdi mdi-home-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-home-variant"></i> mdi mdi-home-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hook"></i> mdi mdi-hook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hook-off"></i> mdi mdi-hook-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hops"></i> mdi mdi-hops
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-horseshoe"></i> mdi mdi-horseshoe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hospital"></i> mdi mdi-hospital
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hospital-building"></i> mdi mdi-hospital-building
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hospital-marker"></i> mdi mdi-hospital-marker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hot-tub"></i> mdi mdi-hot-tub
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hotel"></i> mdi mdi-hotel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-houzz"></i> mdi mdi-houzz
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-houzz-box"></i> mdi mdi-houzz-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-hulu"></i> mdi mdi-hulu
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human"></i> mdi mdi-human
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-child"></i> mdi mdi-human-child
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-female"></i> mdi mdi-human-female
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-female-boy"></i> mdi mdi-human-female-boy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-female-female"></i> mdi mdi-human-female-female
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-female-girl"></i> mdi mdi-human-female-girl
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-greeting"></i> mdi mdi-human-greeting
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-handsdown"></i> mdi mdi-human-handsdown
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-handsup"></i> mdi mdi-human-handsup
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-male"></i> mdi mdi-human-male
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-male-boy"></i> mdi mdi-human-male-boy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-male-female"></i> mdi mdi-human-male-female
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-male-girl"></i> mdi mdi-human-male-girl
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-male-male"></i> mdi mdi-human-male-male
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-human-pregnant"></i> mdi mdi-human-pregnant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-humble-bundle"></i> mdi mdi-humble-bundle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ice-cream"></i> mdi mdi-ice-cream
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image"></i> mdi mdi-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-album"></i> mdi mdi-image-album
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-area"></i> mdi mdi-image-area
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-area-close"></i> mdi mdi-image-area-close
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-broken"></i> mdi mdi-image-broken
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-broken-variant"></i> mdi mdi-image-broken-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter"></i> mdi mdi-image-filter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-black-white"></i> mdi mdi-image-filter-black-white
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-center-focus"></i> mdi mdi-image-filter-center-focus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-center-focus-weak"></i> mdi mdi-image-filter-center-focus-weak
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-drama"></i> mdi mdi-image-filter-drama
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-frames"></i> mdi mdi-image-filter-frames
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-hdr"></i> mdi mdi-image-filter-hdr
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-none"></i> mdi mdi-image-filter-none
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-tilt-shift"></i> mdi mdi-image-filter-tilt-shift
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-filter-vintage"></i> mdi mdi-image-filter-vintage
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-move"></i> mdi mdi-image-move
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-multiple"></i> mdi mdi-image-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-off"></i> mdi mdi-image-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-outline"></i> mdi mdi-image-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-plus"></i> mdi mdi-image-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-search"></i> mdi mdi-image-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-image-search-outline"></i> mdi mdi-image-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-import"></i> mdi mdi-import
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-inbox"></i> mdi mdi-inbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-inbox-arrow-down"></i> mdi mdi-inbox-arrow-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-inbox-arrow-up"></i> mdi mdi-inbox-arrow-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-inbox-multiple"></i> mdi mdi-inbox-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-incognito"></i> mdi mdi-incognito
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-infinity"></i> mdi mdi-infinity
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-information"></i> mdi mdi-information
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-information-outline"></i> mdi mdi-information-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-information-variant"></i> mdi mdi-information-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-instagram"></i> mdi mdi-instagram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-instapaper"></i> mdi mdi-instapaper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-internet-explorer"></i> mdi mdi-internet-explorer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-invert-colors"></i> mdi mdi-invert-colors
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ip"></i> mdi mdi-ip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-islam"></i> mdi mdi-islam
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-itunes"></i> mdi mdi-itunes
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-jeepney"></i> mdi mdi-jeepney
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-jira"></i> mdi mdi-jira
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-jquery"></i> mdi mdi-jquery
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-jsfiddle"></i> mdi mdi-jsfiddle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-json"></i> mdi mdi-json
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-judaism"></i> mdi mdi-judaism
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-karate"></i> mdi mdi-karate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keg"></i> mdi mdi-keg
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-kettle"></i> mdi mdi-kettle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-key"></i> mdi mdi-key
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-key-change"></i> mdi mdi-key-change
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-key-minus"></i> mdi mdi-key-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-key-plus"></i> mdi mdi-key-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-key-remove"></i> mdi mdi-key-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-key-variant"></i> mdi mdi-key-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard"></i> mdi mdi-keyboard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-backspace"></i> mdi mdi-keyboard-backspace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-caps"></i> mdi mdi-keyboard-caps
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-close"></i> mdi mdi-keyboard-close
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-off"></i> mdi mdi-keyboard-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-outline"></i> mdi mdi-keyboard-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-return"></i> mdi mdi-keyboard-return
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-settings"></i> mdi mdi-keyboard-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-settings-outline"></i> mdi mdi-keyboard-settings-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-tab"></i> mdi mdi-keyboard-tab
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-keyboard-variant"></i> mdi mdi-keyboard-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-kickstarter"></i> mdi mdi-kickstarter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-knife"></i> mdi mdi-knife
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-knife-military"></i> mdi mdi-knife-military
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-kodi"></i> mdi mdi-kodi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-label"></i> mdi mdi-label
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-label-off"></i> mdi mdi-label-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-label-off-outline"></i> mdi mdi-label-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-label-outline"></i> mdi mdi-label-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-label-variant"></i> mdi mdi-label-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-label-variant-outline"></i> mdi mdi-label-variant-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ladybug"></i> mdi mdi-ladybug
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lambda"></i> mdi mdi-lambda
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lamp"></i> mdi mdi-lamp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lan"></i> mdi mdi-lan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lan-connect"></i> mdi mdi-lan-connect
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lan-disconnect"></i> mdi mdi-lan-disconnect
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lan-pending"></i> mdi mdi-lan-pending
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-c"></i> mdi mdi-language-c
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-cpp"></i> mdi mdi-language-cpp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-csharp"></i> mdi mdi-language-csharp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-css3"></i> mdi mdi-language-css3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-go"></i> mdi mdi-language-go
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-html5"></i> mdi mdi-language-html5
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-java"></i> mdi mdi-language-java
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-javascript"></i> mdi mdi-language-javascript
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-lua"></i> mdi mdi-language-lua
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-php"></i> mdi mdi-language-php
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-python"></i> mdi mdi-language-python
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-python-text"></i> mdi mdi-language-python-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-r"></i> mdi mdi-language-r
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-ruby-on-rails"></i> mdi mdi-language-ruby-on-rails
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-swift"></i> mdi mdi-language-swift
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-language-typescript"></i> mdi mdi-language-typescript
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-laptop"></i> mdi mdi-laptop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-laptop-chromebook"></i> mdi mdi-laptop-chromebook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-laptop-mac"></i> mdi mdi-laptop-mac
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-laptop-off"></i> mdi mdi-laptop-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-laptop-windows"></i> mdi mdi-laptop-windows
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-laravel"></i> mdi mdi-laravel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lastfm"></i> mdi mdi-lastfm
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lastpass"></i> mdi mdi-lastpass
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-launch"></i> mdi mdi-launch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lava-lamp"></i> mdi mdi-lava-lamp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-layers"></i> mdi mdi-layers
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-layers-off"></i> mdi mdi-layers-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-layers-off-outline"></i> mdi mdi-layers-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-layers-outline"></i> mdi mdi-layers-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lead-pencil"></i> mdi mdi-lead-pencil
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-leaf"></i> mdi mdi-leaf
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-led-off"></i> mdi mdi-led-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-led-on"></i> mdi mdi-led-on
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-led-outline"></i> mdi mdi-led-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-led-strip"></i> mdi mdi-led-strip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-led-variant-off"></i> mdi mdi-led-variant-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-led-variant-on"></i> mdi mdi-led-variant-on
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-led-variant-outline"></i> mdi mdi-led-variant-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-less-than"></i> mdi mdi-less-than
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-less-than-or-equal"></i> mdi mdi-less-than-or-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-library"></i> mdi mdi-library
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-library-books"></i> mdi mdi-library-books
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-library-music"></i> mdi mdi-library-music
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-library-plus"></i> mdi mdi-library-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lifebuoy"></i> mdi mdi-lifebuoy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-light-switch"></i> mdi mdi-light-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lightbulb"></i> mdi mdi-lightbulb
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lightbulb-on"></i> mdi mdi-lightbulb-on
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lightbulb-on-outline"></i> mdi mdi-lightbulb-on-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lightbulb-outline"></i> mdi mdi-lightbulb-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lighthouse"></i> mdi mdi-lighthouse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lighthouse-on"></i> mdi mdi-lighthouse-on
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-link-variant"></i> mdi mdi-link-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-link-variant-off"></i> mdi mdi-link-variant-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-linkedin"></i> mdi mdi-linkedin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-linkedin-box"></i> mdi mdi-linkedin-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-linux"></i> mdi mdi-linux
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-linux-mint"></i> mdi mdi-linux-mint
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-litecoin"></i> mdi mdi-litecoin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-loading"></i> mdi mdi-loading
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock"></i> mdi mdi-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-alert"></i> mdi mdi-lock-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-clock"></i> mdi mdi-lock-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-open"></i> mdi mdi-lock-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-open-outline"></i> mdi mdi-lock-open-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-outline"></i> mdi mdi-lock-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-pattern"></i> mdi mdi-lock-pattern
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-plus"></i> mdi mdi-lock-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-question"></i> mdi mdi-lock-question
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-reset"></i> mdi mdi-lock-reset
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lock-smart"></i> mdi mdi-lock-smart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-locker"></i> mdi mdi-locker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-locker-multiple"></i> mdi mdi-locker-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-login-variant"></i> mdi mdi-login-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-logout-variant"></i> mdi mdi-logout-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-looks"></i> mdi mdi-looks
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-loop"></i> mdi mdi-loop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-loupe"></i> mdi mdi-loupe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lumx"></i> mdi mdi-lumx
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-lyft"></i> mdi mdi-lyft
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnet"></i> mdi mdi-magnet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnet-on"></i> mdi mdi-magnet-on
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify"></i> mdi mdi-magnify
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify-close"></i> mdi mdi-magnify-close
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify-minus"></i> mdi mdi-magnify-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify-minus-cursor"></i> mdi mdi-magnify-minus-cursor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify-minus-outline"></i> mdi mdi-magnify-minus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify-plus"></i> mdi mdi-magnify-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify-plus-cursor"></i> mdi mdi-magnify-plus-cursor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-magnify-plus-outline"></i> mdi mdi-magnify-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mail-ru"></i> mdi mdi-mail-ru
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mailbox"></i> mdi mdi-mailbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map"></i> mdi mdi-map
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-legend"></i> mdi mdi-map-legend
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker"></i> mdi mdi-map-marker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-circle"></i> mdi mdi-map-marker-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-distance"></i> mdi mdi-map-marker-distance
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-minus"></i> mdi mdi-map-marker-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-multiple"></i> mdi mdi-map-marker-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-off"></i> mdi mdi-map-marker-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-outline"></i> mdi mdi-map-marker-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-plus"></i> mdi mdi-map-marker-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-marker-radius"></i> mdi mdi-map-marker-radius
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-minus"></i> mdi mdi-map-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-outline"></i> mdi mdi-map-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-plus"></i> mdi mdi-map-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-search"></i> mdi mdi-map-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-map-search-outline"></i> mdi mdi-map-search-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-margin"></i> mdi mdi-margin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-markdown"></i> mdi mdi-markdown
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-marker"></i> mdi mdi-marker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-marker-check"></i> mdi mdi-marker-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mastodon"></i> mdi mdi-mastodon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mastodon-variant"></i> mdi mdi-mastodon-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-material-design"></i> mdi mdi-material-design
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-material-ui"></i> mdi mdi-material-ui
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-math-compass"></i> mdi mdi-math-compass
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-matrix"></i> mdi mdi-matrix
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-maxcdn"></i> mdi mdi-maxcdn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-medal"></i> mdi mdi-medal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-medical-bag"></i> mdi mdi-medical-bag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-medium"></i> mdi mdi-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-meetup"></i> mdi mdi-meetup
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-memory"></i> mdi mdi-memory
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu"></i> mdi mdi-menu
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-down"></i> mdi mdi-menu-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-down-outline"></i> mdi mdi-menu-down-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-left"></i> mdi mdi-menu-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-left-outline"></i> mdi mdi-menu-left-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-right"></i> mdi mdi-menu-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-right-outline"></i> mdi mdi-menu-right-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-swap"></i> mdi mdi-menu-swap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-swap-outline"></i> mdi mdi-menu-swap-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-up"></i> mdi mdi-menu-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-menu-up-outline"></i> mdi mdi-menu-up-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message"></i> mdi mdi-message
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-alert"></i> mdi mdi-message-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-alert-outline"></i> mdi mdi-message-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-bulleted"></i> mdi mdi-message-bulleted
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-bulleted-off"></i> mdi mdi-message-bulleted-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-draw"></i> mdi mdi-message-draw
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-image"></i> mdi mdi-message-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-outline"></i> mdi mdi-message-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-plus"></i> mdi mdi-message-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-processing"></i> mdi mdi-message-processing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-reply"></i> mdi mdi-message-reply
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-reply-text"></i> mdi mdi-message-reply-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-settings"></i> mdi mdi-message-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-settings-variant"></i> mdi mdi-message-settings-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-text"></i> mdi mdi-message-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-text-outline"></i> mdi mdi-message-text-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-message-video"></i> mdi mdi-message-video
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-meteor"></i> mdi mdi-meteor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-metronome"></i> mdi mdi-metronome
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-metronome-tick"></i> mdi mdi-metronome-tick
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-micro-sd"></i> mdi mdi-micro-sd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone"></i> mdi mdi-microphone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone-minus"></i> mdi mdi-microphone-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone-off"></i> mdi mdi-microphone-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone-outline"></i> mdi mdi-microphone-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone-plus"></i> mdi mdi-microphone-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone-settings"></i> mdi mdi-microphone-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone-variant"></i> mdi mdi-microphone-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microphone-variant-off"></i> mdi mdi-microphone-variant-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microscope"></i> mdi mdi-microscope
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microsoft"></i> mdi mdi-microsoft
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-microsoft-dynamics"></i> mdi mdi-microsoft-dynamics
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-midi"></i> mdi mdi-midi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-midi-port"></i> mdi mdi-midi-port
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minecraft"></i> mdi mdi-minecraft
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mini-sd"></i> mdi mdi-mini-sd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minidisc"></i> mdi mdi-minidisc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minus"></i> mdi mdi-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minus-box"></i> mdi mdi-minus-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minus-box-outline"></i> mdi mdi-minus-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minus-circle"></i> mdi mdi-minus-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minus-circle-outline"></i> mdi mdi-minus-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-minus-network"></i> mdi mdi-minus-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mixcloud"></i> mdi mdi-mixcloud
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mixed-reality"></i> mdi mdi-mixed-reality
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mixer"></i> mdi mdi-mixer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor"></i> mdi mdi-monitor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor-cellphone"></i> mdi mdi-monitor-cellphone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor-cellphone-star"></i> mdi mdi-monitor-cellphone-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor-dashboard"></i> mdi mdi-monitor-dashboard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-monitor-multiple"></i> mdi mdi-monitor-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-more"></i> mdi mdi-more
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mouse"></i> mdi mdi-mouse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mouse-bluetooth"></i> mdi mdi-mouse-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mouse-off"></i> mdi mdi-mouse-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mouse-variant"></i> mdi mdi-mouse-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mouse-variant-off"></i> mdi mdi-mouse-variant-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-move-resize"></i> mdi mdi-move-resize
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-move-resize-variant"></i> mdi mdi-move-resize-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-movie"></i> mdi mdi-movie
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-movie-roll"></i> mdi mdi-movie-roll
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-muffin"></i> mdi mdi-muffin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-multiplication"></i> mdi mdi-multiplication
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-multiplication-box"></i> mdi mdi-multiplication-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mushroom"></i> mdi mdi-mushroom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-mushroom-outline"></i> mdi mdi-mushroom-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music"></i> mdi mdi-music
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-box"></i> mdi mdi-music-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-box-outline"></i> mdi mdi-music-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-circle"></i> mdi mdi-music-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-circle-outline"></i> mdi mdi-music-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note"></i> mdi mdi-music-note
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-bluetooth"></i> mdi mdi-music-note-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-bluetooth-off"></i> mdi mdi-music-note-bluetooth-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-eighth"></i> mdi mdi-music-note-eighth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-half"></i> mdi mdi-music-note-half
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-off"></i> mdi mdi-music-note-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-quarter"></i> mdi mdi-music-note-quarter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-sixteenth"></i> mdi mdi-music-note-sixteenth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-note-whole"></i> mdi mdi-music-note-whole
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-music-off"></i> mdi mdi-music-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nas"></i> mdi mdi-nas
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nativescript"></i> mdi mdi-nativescript
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nature"></i> mdi mdi-nature
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nature-people"></i> mdi mdi-nature-people
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-navigation"></i> mdi mdi-navigation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-near-me"></i> mdi mdi-near-me
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-needle"></i> mdi mdi-needle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-netflix"></i> mdi mdi-netflix
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network"></i> mdi mdi-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-1"></i> mdi mdi-network-strength-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-1-alert"></i> mdi mdi-network-strength-1-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-2"></i> mdi mdi-network-strength-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-2-alert"></i> mdi mdi-network-strength-2-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-3"></i> mdi mdi-network-strength-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-3-alert"></i> mdi mdi-network-strength-3-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-4"></i> mdi mdi-network-strength-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-4-alert"></i> mdi mdi-network-strength-4-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-off"></i> mdi mdi-network-strength-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-off-outline"></i> mdi mdi-network-strength-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-network-strength-outline"></i> mdi mdi-network-strength-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-new-box"></i> mdi mdi-new-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-newspaper"></i> mdi mdi-newspaper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nfc"></i> mdi mdi-nfc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nfc-tap"></i> mdi mdi-nfc-tap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nfc-variant"></i> mdi mdi-nfc-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ninja"></i> mdi mdi-ninja
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nintendo-switch"></i> mdi mdi-nintendo-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nodejs"></i> mdi mdi-nodejs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-not-equal"></i> mdi mdi-not-equal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-not-equal-variant"></i> mdi mdi-not-equal-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-note"></i> mdi mdi-note
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-note-multiple"></i> mdi mdi-note-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-note-multiple-outline"></i> mdi mdi-note-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-note-outline"></i> mdi mdi-note-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-note-plus"></i> mdi mdi-note-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-note-plus-outline"></i> mdi mdi-note-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-note-text"></i> mdi mdi-note-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-notebook"></i> mdi mdi-notebook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-notification-clear-all"></i> mdi mdi-notification-clear-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-npm"></i> mdi mdi-npm
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-npm-variant"></i> mdi mdi-npm-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-npm-variant-outline"></i> mdi mdi-npm-variant-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nuke"></i> mdi mdi-nuke
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-null"></i> mdi mdi-null
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric"></i> mdi mdi-numeric
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-0"></i> mdi mdi-numeric-0
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-0-box"></i> mdi mdi-numeric-0-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-0-box-multiple-outline"></i> mdi mdi-numeric-0-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-0-box-outline"></i> mdi mdi-numeric-0-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-1"></i> mdi mdi-numeric-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-1-box"></i> mdi mdi-numeric-1-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-1-box-multiple-outline"></i> mdi mdi-numeric-1-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-1-box-outline"></i> mdi mdi-numeric-1-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-2"></i> mdi mdi-numeric-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-2-box"></i> mdi mdi-numeric-2-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-2-box-multiple-outline"></i> mdi mdi-numeric-2-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-2-box-outline"></i> mdi mdi-numeric-2-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-3"></i> mdi mdi-numeric-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-3-box"></i> mdi mdi-numeric-3-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-3-box-multiple-outline"></i> mdi mdi-numeric-3-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-3-box-outline"></i> mdi mdi-numeric-3-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-4"></i> mdi mdi-numeric-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-4-box"></i> mdi mdi-numeric-4-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-4-box-multiple-outline"></i> mdi mdi-numeric-4-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-4-box-outline"></i> mdi mdi-numeric-4-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-5"></i> mdi mdi-numeric-5
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-5-box"></i> mdi mdi-numeric-5-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-5-box-multiple-outline"></i> mdi mdi-numeric-5-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-5-box-outline"></i> mdi mdi-numeric-5-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-6"></i> mdi mdi-numeric-6
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-6-box"></i> mdi mdi-numeric-6-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-6-box-multiple-outline"></i> mdi mdi-numeric-6-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-6-box-outline"></i> mdi mdi-numeric-6-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-7"></i> mdi mdi-numeric-7
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-7-box"></i> mdi mdi-numeric-7-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-7-box-multiple-outline"></i> mdi mdi-numeric-7-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-7-box-outline"></i> mdi mdi-numeric-7-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-8"></i> mdi mdi-numeric-8
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-8-box"></i> mdi mdi-numeric-8-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-8-box-multiple-outline"></i> mdi mdi-numeric-8-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-8-box-outline"></i> mdi mdi-numeric-8-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9"></i> mdi mdi-numeric-9
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-box"></i> mdi mdi-numeric-9-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-box-multiple-outline"></i> mdi mdi-numeric-9-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-box-outline"></i> mdi mdi-numeric-9-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-plus-box"></i> mdi mdi-numeric-9-plus-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-plus-box-multiple-outline"></i> mdi mdi-numeric-9-plus-box-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-numeric-9-plus-box-outline"></i> mdi mdi-numeric-9-plus-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nut"></i> mdi mdi-nut
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-nutrition"></i> mdi mdi-nutrition
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-oar"></i> mdi mdi-oar
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-octagon"></i> mdi mdi-octagon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-octagon-outline"></i> mdi mdi-octagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-octagram"></i> mdi mdi-octagram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-octagram-outline"></i> mdi mdi-octagram-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-odnoklassniki"></i> mdi mdi-odnoklassniki
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-office"></i> mdi mdi-office
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-office-building"></i> mdi mdi-office-building
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-oil"></i> mdi mdi-oil
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-oil-temperature"></i> mdi mdi-oil-temperature
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-omega"></i> mdi mdi-omega
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-onedrive"></i> mdi mdi-onedrive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-onenote"></i> mdi mdi-onenote
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-onepassword"></i> mdi mdi-onepassword
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-opacity"></i> mdi mdi-opacity
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-open-in-app"></i> mdi mdi-open-in-app
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-open-in-new"></i> mdi mdi-open-in-new
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-openid"></i> mdi mdi-openid
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-opera"></i> mdi mdi-opera
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-orbit"></i> mdi mdi-orbit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-origin"></i> mdi mdi-origin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ornament"></i> mdi mdi-ornament
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ornament-variant"></i> mdi mdi-ornament-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-owl"></i> mdi mdi-owl
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-package"></i> mdi mdi-package
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-package-down"></i> mdi mdi-package-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-package-up"></i> mdi mdi-package-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-package-variant"></i> mdi mdi-package-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-package-variant-closed"></i> mdi mdi-package-variant-closed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-page-first"></i> mdi mdi-page-first
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-page-last"></i> mdi mdi-page-last
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-page-layout-body"></i> mdi mdi-page-layout-body
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-page-layout-footer"></i> mdi mdi-page-layout-footer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-page-layout-header"></i> mdi mdi-page-layout-header
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-page-layout-sidebar-left"></i> mdi mdi-page-layout-sidebar-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-page-layout-sidebar-right"></i> mdi mdi-page-layout-sidebar-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-palette"></i> mdi mdi-palette
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-palette-advanced"></i> mdi mdi-palette-advanced
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-palette-swatch"></i> mdi mdi-palette-swatch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-panda"></i> mdi mdi-panda
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pandora"></i> mdi mdi-pandora
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-panorama"></i> mdi mdi-panorama
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-panorama-fisheye"></i> mdi mdi-panorama-fisheye
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-panorama-horizontal"></i> mdi mdi-panorama-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-panorama-vertical"></i> mdi mdi-panorama-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-panorama-wide-angle"></i> mdi mdi-panorama-wide-angle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-paper-cut-vertical"></i> mdi mdi-paper-cut-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-paperclip"></i> mdi mdi-paperclip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-parking"></i> mdi mdi-parking
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-passport"></i> mdi mdi-passport
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-patreon"></i> mdi mdi-patreon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pause"></i> mdi mdi-pause
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pause-circle"></i> mdi mdi-pause-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pause-circle-outline"></i> mdi mdi-pause-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pause-octagon"></i> mdi mdi-pause-octagon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pause-octagon-outline"></i> mdi mdi-pause-octagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-paw"></i> mdi mdi-paw
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-paw-off"></i> mdi mdi-paw-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-paypal"></i> mdi mdi-paypal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-peace"></i> mdi mdi-peace
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pen"></i> mdi mdi-pen
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil"></i> mdi mdi-pencil
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-box"></i> mdi mdi-pencil-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-box-outline"></i> mdi mdi-pencil-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-circle"></i> mdi mdi-pencil-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-circle-outline"></i> mdi mdi-pencil-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-lock"></i> mdi mdi-pencil-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pencil-off"></i> mdi mdi-pencil-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pentagon"></i> mdi mdi-pentagon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pentagon-outline"></i> mdi mdi-pentagon-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-percent"></i> mdi mdi-percent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-periodic-table"></i> mdi mdi-periodic-table
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-periodic-table-co2"></i> mdi mdi-periodic-table-co2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-periscope"></i> mdi mdi-periscope
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pharmacy"></i> mdi mdi-pharmacy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone"></i> mdi mdi-phone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-bluetooth"></i> mdi mdi-phone-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-classic"></i> mdi mdi-phone-classic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-forward"></i> mdi mdi-phone-forward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-hangup"></i> mdi mdi-phone-hangup
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-in-talk"></i> mdi mdi-phone-in-talk
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-incoming"></i> mdi mdi-phone-incoming
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-lock"></i> mdi mdi-phone-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-log"></i> mdi mdi-phone-log
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-minus"></i> mdi mdi-phone-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-missed"></i> mdi mdi-phone-missed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-outgoing"></i> mdi mdi-phone-outgoing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-paused"></i> mdi mdi-phone-paused
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-plus"></i> mdi mdi-phone-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-return"></i> mdi mdi-phone-return
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-rotate-landscape"></i> mdi mdi-phone-rotate-landscape
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-rotate-portrait"></i> mdi mdi-phone-rotate-portrait
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-settings"></i> mdi mdi-phone-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-phone-voip"></i> mdi mdi-phone-voip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pi"></i> mdi mdi-pi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pi-box"></i> mdi mdi-pi-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-piano"></i> mdi mdi-piano
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pickaxe"></i> mdi mdi-pickaxe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pier"></i> mdi mdi-pier
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pier-crane"></i> mdi mdi-pier-crane
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pig"></i> mdi mdi-pig
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pill"></i> mdi mdi-pill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pillar"></i> mdi mdi-pillar
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pin"></i> mdi mdi-pin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pin-off"></i> mdi mdi-pin-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pin-off-outline"></i> mdi mdi-pin-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pin-outline"></i> mdi mdi-pin-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pine-tree"></i> mdi mdi-pine-tree
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pine-tree-box"></i> mdi mdi-pine-tree-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pinterest"></i> mdi mdi-pinterest
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pinterest-box"></i> mdi mdi-pinterest-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pinwheel"></i> mdi mdi-pinwheel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pinwheel-outline"></i> mdi mdi-pinwheel-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pipe"></i> mdi mdi-pipe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pipe-disconnected"></i> mdi mdi-pipe-disconnected
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pipe-leak"></i> mdi mdi-pipe-leak
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pirate"></i> mdi mdi-pirate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pistol"></i> mdi mdi-pistol
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-piston"></i> mdi mdi-piston
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pizza"></i> mdi mdi-pizza
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-airplane"></i> mdi mdi-shield-airplane
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play"></i> mdi mdi-play
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-box-outline"></i> mdi mdi-play-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-circle"></i> mdi mdi-play-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-circle-outline"></i> mdi mdi-play-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-network"></i> mdi mdi-play-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-pause"></i> mdi mdi-play-pause
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-protected-content"></i> mdi mdi-play-protected-content
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-play-speed"></i> mdi mdi-play-speed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-check"></i> mdi mdi-playlist-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-edit"></i> mdi mdi-playlist-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-minus"></i> mdi mdi-playlist-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-play"></i> mdi mdi-playlist-play
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-plus"></i> mdi mdi-playlist-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playlist-remove"></i> mdi mdi-playlist-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-playstation"></i> mdi mdi-playstation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plex"></i> mdi mdi-plex
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus"></i> mdi mdi-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-box"></i> mdi mdi-plus-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-box-outline"></i> mdi mdi-plus-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-circle"></i> mdi mdi-plus-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-circle-multiple-outline"></i> mdi mdi-plus-circle-multiple-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-circle-outline"></i> mdi mdi-plus-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-minus"></i> mdi mdi-plus-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-minus-box"></i> mdi mdi-plus-minus-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-network"></i> mdi mdi-plus-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-one"></i> mdi mdi-plus-one
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-plus-outline"></i> mdi mdi-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pocket"></i> mdi mdi-pocket
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-podcast"></i> mdi mdi-podcast
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pokeball"></i> mdi mdi-pokeball
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pokemon-go"></i> mdi mdi-pokemon-go
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-poker-chip"></i> mdi mdi-poker-chip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-polaroid"></i> mdi mdi-polaroid
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-poll"></i> mdi mdi-poll
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-poll-box"></i> mdi mdi-poll-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-polymer"></i> mdi mdi-polymer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pool"></i> mdi mdi-pool
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-popcorn"></i> mdi mdi-popcorn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pot"></i> mdi mdi-pot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pot-mix"></i> mdi mdi-pot-mix
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pound"></i> mdi mdi-pound
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pound-box"></i> mdi mdi-pound-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power"></i> mdi mdi-power
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-cycle"></i> mdi mdi-power-cycle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-off"></i> mdi mdi-power-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-on"></i> mdi mdi-power-on
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-plug"></i> mdi mdi-power-plug
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-plug-off"></i> mdi mdi-power-plug-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-settings"></i> mdi mdi-power-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-sleep"></i> mdi mdi-power-sleep
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-socket"></i> mdi mdi-power-socket
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-socket-au"></i> mdi mdi-power-socket-au
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-socket-eu"></i> mdi mdi-power-socket-eu
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-socket-uk"></i> mdi mdi-power-socket-uk
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-socket-us"></i> mdi mdi-power-socket-us
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-power-standby"></i> mdi mdi-power-standby
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-powershell"></i> mdi mdi-powershell
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-prescription"></i> mdi mdi-prescription
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-presentation"></i> mdi mdi-presentation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-presentation-play"></i> mdi mdi-presentation-play
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer"></i> mdi mdi-printer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer-3d"></i> mdi mdi-printer-3d
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer-alert"></i> mdi mdi-printer-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer-settings"></i> mdi mdi-printer-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-printer-wireless"></i> mdi mdi-printer-wireless
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-priority-high"></i> mdi mdi-priority-high
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-priority-low"></i> mdi mdi-priority-low
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-professional-hexagon"></i> mdi mdi-professional-hexagon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-progress-check"></i> mdi mdi-progress-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-progress-clock"></i> mdi mdi-progress-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-progress-download"></i> mdi mdi-progress-download
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-progress-upload"></i> mdi mdi-progress-upload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-projector"></i> mdi mdi-projector
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-projector-screen"></i> mdi mdi-projector-screen
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-publish"></i> mdi mdi-publish
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-pulse"></i> mdi mdi-pulse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-puzzle"></i> mdi mdi-puzzle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-puzzle-outline"></i> mdi mdi-puzzle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-qi"></i> mdi mdi-qi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-qqchat"></i> mdi mdi-qqchat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-qrcode"></i> mdi mdi-qrcode
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-qrcode-edit"></i> mdi mdi-qrcode-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-qrcode-scan"></i> mdi mdi-qrcode-scan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-quadcopter"></i> mdi mdi-quadcopter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-quality-high"></i> mdi mdi-quality-high
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-quality-low"></i> mdi mdi-quality-low
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-quality-medium"></i> mdi mdi-quality-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-quicktime"></i> mdi mdi-quicktime
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rabbit"></i> mdi mdi-rabbit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radar"></i> mdi mdi-radar
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radiator"></i> mdi mdi-radiator
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radiator-disabled"></i> mdi mdi-radiator-disabled
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radiator-off"></i> mdi mdi-radiator-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radio"></i> mdi mdi-radio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radio-handheld"></i> mdi mdi-radio-handheld
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radio-tower"></i> mdi mdi-radio-tower
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radioactive"></i> mdi mdi-radioactive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radiobox-blank"></i> mdi mdi-radiobox-blank
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-radiobox-marked"></i> mdi mdi-radiobox-marked
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-raspberrypi"></i> mdi mdi-raspberrypi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ray-end"></i> mdi mdi-ray-end
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ray-end-arrow"></i> mdi mdi-ray-end-arrow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ray-start"></i> mdi mdi-ray-start
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ray-start-arrow"></i> mdi mdi-ray-start-arrow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ray-start-end"></i> mdi mdi-ray-start-end
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ray-vertex"></i> mdi mdi-ray-vertex
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-react"></i> mdi mdi-react
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-read"></i> mdi mdi-read
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-receipt"></i> mdi mdi-receipt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-record"></i> mdi mdi-record
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-record-player"></i> mdi mdi-record-player
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-record-rec"></i> mdi mdi-record-rec
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-recycle"></i> mdi mdi-recycle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reddit"></i> mdi mdi-reddit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-redo"></i> mdi mdi-redo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-redo-variant"></i> mdi mdi-redo-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reflect-horizontal"></i> mdi mdi-reflect-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reflect-vertical"></i> mdi mdi-reflect-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-refresh"></i> mdi mdi-refresh
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-regex"></i> mdi mdi-regex
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-registered-trademark"></i> mdi mdi-registered-trademark
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-relative-scale"></i> mdi mdi-relative-scale
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reload"></i> mdi mdi-reload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reminder"></i> mdi mdi-reminder
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-remote"></i> mdi mdi-remote
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-remote-desktop"></i> mdi mdi-remote-desktop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rename-box"></i> mdi mdi-rename-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reorder-horizontal"></i> mdi mdi-reorder-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reorder-vertical"></i> mdi mdi-reorder-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-repeat"></i> mdi mdi-repeat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-repeat-off"></i> mdi mdi-repeat-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-repeat-once"></i> mdi mdi-repeat-once
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-replay"></i> mdi mdi-replay
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reply"></i> mdi mdi-reply
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reply-all"></i> mdi mdi-reply-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-reproduction"></i> mdi mdi-reproduction
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-resistor"></i> mdi mdi-resistor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-resistor-nodes"></i> mdi mdi-resistor-nodes
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-resize"></i> mdi mdi-resize
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-resize-bottom-right"></i> mdi mdi-resize-bottom-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-responsive"></i> mdi mdi-responsive
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-restart"></i> mdi mdi-restart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-restore"></i> mdi mdi-restore
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-restore-clock"></i> mdi mdi-restore-clock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rewind"></i> mdi mdi-rewind
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rewind-outline"></i> mdi mdi-rewind-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rhombus"></i> mdi mdi-rhombus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rhombus-medium"></i> mdi mdi-rhombus-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rhombus-outline"></i> mdi mdi-rhombus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rhombus-split"></i> mdi mdi-rhombus-split
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ribbon"></i> mdi mdi-ribbon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rice"></i> mdi mdi-rice
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ring"></i> mdi mdi-ring
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-road"></i> mdi mdi-road
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-road-variant"></i> mdi mdi-road-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-robot"></i> mdi mdi-robot
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-robot-industrial"></i> mdi mdi-robot-industrial
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-robot-vacuum"></i> mdi mdi-robot-vacuum
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-robot-vacuum-variant"></i> mdi mdi-robot-vacuum-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rocket"></i> mdi mdi-rocket
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-room-service"></i> mdi mdi-room-service
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-3d"></i> mdi mdi-rotate-3d
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-left"></i> mdi mdi-rotate-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-left-variant"></i> mdi mdi-rotate-left-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-right"></i> mdi mdi-rotate-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-right-variant"></i> mdi mdi-rotate-right-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rounded-corner"></i> mdi mdi-rounded-corner
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-router-wireless"></i> mdi mdi-router-wireless
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-router-wireless-settings"></i> mdi mdi-router-wireless-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-routes"></i> mdi mdi-routes
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rowing"></i> mdi mdi-rowing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rss"></i> mdi mdi-rss
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rss-box"></i> mdi mdi-rss-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ruler"></i> mdi mdi-ruler
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-run"></i> mdi mdi-run
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-run-fast"></i> mdi mdi-run-fast
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-safe"></i> mdi mdi-safe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sale"></i> mdi mdi-sale
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-salesforce"></i> mdi mdi-salesforce
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sass"></i> mdi mdi-sass
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-satellite"></i> mdi mdi-satellite
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-satellite-uplink"></i> mdi mdi-satellite-uplink
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-satellite-variant"></i> mdi mdi-satellite-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sausage"></i> mdi mdi-sausage
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-saxophone"></i> mdi mdi-saxophone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-scale"></i> mdi mdi-scale
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-scale-balance"></i> mdi mdi-scale-balance
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-scale-bathroom"></i> mdi mdi-scale-bathroom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-scanner"></i> mdi mdi-scanner
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-scanner-off"></i> mdi mdi-scanner-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-school"></i> mdi mdi-school
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-scissors-cutting"></i> mdi mdi-scissors-cutting
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screen-rotation"></i> mdi mdi-screen-rotation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screen-rotation-lock"></i> mdi mdi-screen-rotation-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-screwdriver"></i> mdi mdi-screwdriver
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-script"></i> mdi mdi-script
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sd"></i> mdi mdi-sd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seal"></i> mdi mdi-seal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-search-web"></i> mdi mdi-search-web
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-flat"></i> mdi mdi-seat-flat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-flat-angled"></i> mdi mdi-seat-flat-angled
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-individual-suite"></i> mdi mdi-seat-individual-suite
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-legroom-extra"></i> mdi mdi-seat-legroom-extra
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-legroom-normal"></i> mdi mdi-seat-legroom-normal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-legroom-reduced"></i> mdi mdi-seat-legroom-reduced
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-recline-extra"></i> mdi mdi-seat-recline-extra
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-seat-recline-normal"></i> mdi mdi-seat-recline-normal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-security"></i> mdi mdi-security
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-account"></i> mdi mdi-shield-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-account-outline"></i> mdi mdi-shield-account-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-home"></i> mdi mdi-shield-home
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-lock"></i> mdi mdi-shield-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-off"></i> mdi mdi-shield-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-select"></i> mdi mdi-select
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-select-all"></i> mdi mdi-select-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-select-compare"></i> mdi mdi-select-compare
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-select-drag"></i> mdi mdi-select-drag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-select-inverse"></i> mdi mdi-select-inverse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-select-off"></i> mdi mdi-select-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-selection"></i> mdi mdi-selection
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-selection-drag"></i> mdi mdi-selection-drag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-selection-off"></i> mdi mdi-selection-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-send"></i> mdi mdi-send
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-send-lock"></i> mdi mdi-send-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-serial-port"></i> mdi mdi-serial-port
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server"></i> mdi mdi-server
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server-minus"></i> mdi mdi-server-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server-off"></i> mdi mdi-server-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server-plus"></i> mdi mdi-server-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server-remove"></i> mdi mdi-server-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-server-security"></i> mdi mdi-server-security
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-all"></i> mdi mdi-set-all
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-center"></i> mdi mdi-set-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-center-right"></i> mdi mdi-set-center-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-left"></i> mdi mdi-set-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-left-center"></i> mdi mdi-set-left-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-left-right"></i> mdi mdi-set-left-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-none"></i> mdi mdi-set-none
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-right"></i> mdi mdi-set-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-set-top-box"></i> mdi mdi-set-top-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-settings"></i> mdi mdi-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-settings-box"></i> mdi mdi-settings-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-settings-helper"></i> mdi mdi-settings-helper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-settings-outline"></i> mdi mdi-settings-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shape"></i> mdi mdi-shape
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shape-circle-plus"></i> mdi mdi-shape-circle-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shape-outline"></i> mdi mdi-shape-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shape-plus"></i> mdi mdi-shape-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shape-polygon-plus"></i> mdi mdi-shape-polygon-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shape-rectangle-plus"></i> mdi mdi-shape-rectangle-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shape-square-plus"></i> mdi mdi-shape-square-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-share"></i> mdi mdi-share
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-share-outline"></i> mdi mdi-share-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-share-variant"></i> mdi mdi-share-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield"></i> mdi mdi-shield
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-half-full"></i> mdi mdi-shield-half-full
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-outline"></i> mdi mdi-shield-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-plus"></i> mdi mdi-shield-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-plus-outline"></i> mdi mdi-shield-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-remove-outline"></i> mdi mdi-shield-remove-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ship-wheel"></i> mdi mdi-ship-wheel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shoe-formal"></i> mdi mdi-shoe-formal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shoe-heel"></i> mdi mdi-shoe-heel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shopify"></i> mdi mdi-shopify
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shopping"></i> mdi mdi-shopping
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shopping-music"></i> mdi mdi-shopping-music
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shovel"></i> mdi mdi-shovel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shovel-off"></i> mdi mdi-shovel-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shower"></i> mdi mdi-shower
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shower-head"></i> mdi mdi-shower-head
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shredder"></i> mdi mdi-shredder
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shuffle"></i> mdi mdi-shuffle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shuffle-disabled"></i> mdi mdi-shuffle-disabled
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shuffle-variant"></i> mdi mdi-shuffle-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sigma"></i> mdi mdi-sigma
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sigma-lower"></i> mdi mdi-sigma-lower
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sign-caution"></i> mdi mdi-sign-caution
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sign-direction"></i> mdi mdi-sign-direction
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sign-text"></i> mdi mdi-sign-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal"></i> mdi mdi-signal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-2g"></i> mdi mdi-signal-2g
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-3g"></i> mdi mdi-signal-3g
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-4g"></i> mdi mdi-signal-4g
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-5g"></i> mdi mdi-signal-5g
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-cellular-1"></i> mdi mdi-signal-cellular-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-cellular-2"></i> mdi mdi-signal-cellular-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-cellular-3"></i> mdi mdi-signal-cellular-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-cellular-outline"></i> mdi mdi-signal-cellular-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-hspa"></i> mdi mdi-signal-hspa
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-hspa-plus"></i> mdi mdi-signal-hspa-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-off"></i> mdi mdi-signal-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-signal-variant"></i> mdi mdi-signal-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-silo"></i> mdi mdi-silo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-silverware"></i> mdi mdi-silverware
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-silverware-fork"></i> mdi mdi-silverware-fork
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-silverware-fork-knife"></i> mdi mdi-silverware-fork-knife
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-silverware-spoon"></i> mdi mdi-silverware-spoon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-silverware-variant"></i> mdi mdi-silverware-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sim"></i> mdi mdi-sim
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sim-alert"></i> mdi mdi-sim-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sim-off"></i> mdi mdi-sim-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sina-weibo"></i> mdi mdi-sina-weibo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sitemap"></i> mdi mdi-sitemap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-backward"></i> mdi mdi-skip-backward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-forward"></i> mdi mdi-skip-forward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-next"></i> mdi mdi-skip-next
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-next-circle"></i> mdi mdi-skip-next-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-next-circle-outline"></i> mdi mdi-skip-next-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-previous"></i> mdi mdi-skip-previous
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-previous-circle"></i> mdi mdi-skip-previous-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skip-previous-circle-outline"></i> mdi mdi-skip-previous-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skull"></i> mdi mdi-skull
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skype"></i> mdi mdi-skype
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-skype-business"></i> mdi mdi-skype-business
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-slack"></i> mdi mdi-slack
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-slackware"></i> mdi mdi-slackware
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sleep"></i> mdi mdi-sleep
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sleep-off"></i> mdi mdi-sleep-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-smog"></i> mdi mdi-smog
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-snapchat"></i> mdi mdi-snapchat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-snowflake"></i> mdi mdi-snowflake
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-snowman"></i> mdi mdi-snowman
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-soccer"></i> mdi mdi-soccer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-soccer-field"></i> mdi mdi-soccer-field
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sofa"></i> mdi mdi-sofa
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-solar-power"></i> mdi mdi-solar-power
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-solid"></i> mdi mdi-solid
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort"></i> mdi mdi-sort
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort-alphabetical"></i> mdi mdi-sort-alphabetical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort-ascending"></i> mdi mdi-sort-ascending
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort-descending"></i> mdi mdi-sort-descending
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort-numeric"></i> mdi mdi-sort-numeric
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sort-variant"></i> mdi mdi-sort-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-soundcloud"></i> mdi mdi-soundcloud
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-branch"></i> mdi mdi-source-branch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-commit"></i> mdi mdi-source-commit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-commit-end"></i> mdi mdi-source-commit-end
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-commit-end-local"></i> mdi mdi-source-commit-end-local
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-commit-local"></i> mdi mdi-source-commit-local
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-commit-next-local"></i> mdi mdi-source-commit-next-local
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-commit-start"></i> mdi mdi-source-commit-start
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-commit-start-next-local"></i> mdi mdi-source-commit-start-next-local
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-fork"></i> mdi mdi-source-fork
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-merge"></i> mdi mdi-source-merge
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-source-pull"></i> mdi mdi-source-pull
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-soy-sauce"></i> mdi mdi-soy-sauce
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-speaker"></i> mdi mdi-speaker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-speaker-bluetooth"></i> mdi mdi-speaker-bluetooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-speaker-off"></i> mdi mdi-speaker-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-speaker-wireless"></i> mdi mdi-speaker-wireless
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-speedometer"></i> mdi mdi-speedometer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spellcheck"></i> mdi mdi-spellcheck
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spotify"></i> mdi mdi-spotify
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spotlight"></i> mdi mdi-spotlight
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spotlight-beam"></i> mdi mdi-spotlight-beam
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spray"></i> mdi mdi-spray
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spray-bottle"></i> mdi mdi-spray-bottle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square"></i> mdi mdi-square
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-edit-outline"></i> mdi mdi-square-edit-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-inc"></i> mdi mdi-square-inc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-inc-cash"></i> mdi mdi-square-inc-cash
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-medium"></i> mdi mdi-square-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-medium-outline"></i> mdi mdi-square-medium-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-outline"></i> mdi mdi-square-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-root"></i> mdi mdi-square-root
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-root-box"></i> mdi mdi-square-root-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-square-small"></i> mdi mdi-square-small
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-squeegee"></i> mdi mdi-squeegee
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ssh"></i> mdi mdi-ssh
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stack-exchange"></i> mdi mdi-stack-exchange
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stack-overflow"></i> mdi mdi-stack-overflow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stadium"></i> mdi mdi-stadium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stairs"></i> mdi mdi-stairs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-standard-definition"></i> mdi mdi-standard-definition
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star"></i> mdi mdi-star
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-box"></i> mdi mdi-star-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-box-outline"></i> mdi mdi-star-box-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-circle"></i> mdi mdi-star-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-circle-outline"></i> mdi mdi-star-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-face"></i> mdi mdi-star-face
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-four-points"></i> mdi mdi-star-four-points
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-four-points-outline"></i> mdi mdi-star-four-points-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-half"></i> mdi mdi-star-half
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-off"></i> mdi mdi-star-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-outline"></i> mdi mdi-star-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-three-points"></i> mdi mdi-star-three-points
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-star-three-points-outline"></i> mdi mdi-star-three-points-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-steam"></i> mdi mdi-steam
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-steam-box"></i> mdi mdi-steam-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-steering"></i> mdi mdi-steering
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-steering-off"></i> mdi mdi-steering-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-step-backward"></i> mdi mdi-step-backward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-step-backward-2"></i> mdi mdi-step-backward-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-step-forward"></i> mdi mdi-step-forward
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-step-forward-2"></i> mdi mdi-step-forward-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stethoscope"></i> mdi mdi-stethoscope
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sticker"></i> mdi mdi-sticker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sticker-emoji"></i> mdi mdi-sticker-emoji
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stocking"></i> mdi mdi-stocking
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stop"></i> mdi mdi-stop
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stop-circle"></i> mdi mdi-stop-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stop-circle-outline"></i> mdi mdi-stop-circle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-store"></i> mdi mdi-store
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-store-24-hour"></i> mdi mdi-store-24-hour
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-stove"></i> mdi mdi-stove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-strava"></i> mdi mdi-strava
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-subdirectory-arrow-left"></i> mdi mdi-subdirectory-arrow-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-subdirectory-arrow-right"></i> mdi mdi-subdirectory-arrow-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-subtitles"></i> mdi mdi-subtitles
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-subtitles-outline"></i> mdi mdi-subtitles-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-subway"></i> mdi mdi-subway
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-subway-variant"></i> mdi mdi-subway-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-summit"></i> mdi mdi-summit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sunglasses"></i> mdi mdi-sunglasses
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-surround-sound"></i> mdi mdi-surround-sound
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-surround-sound-2-0"></i> mdi mdi-surround-sound-2-0
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-surround-sound-3-1"></i> mdi mdi-surround-sound-3-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-surround-sound-5-1"></i> mdi mdi-surround-sound-5-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-surround-sound-7-1"></i> mdi mdi-surround-sound-7-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-svg"></i> mdi mdi-svg
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-swap-horizontal"></i> mdi mdi-swap-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-swap-horizontal-variant"></i> mdi mdi-swap-horizontal-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-swap-vertical"></i> mdi mdi-swap-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-swap-vertical-variant"></i> mdi mdi-swap-vertical-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-swim"></i> mdi mdi-swim
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-switch"></i> mdi mdi-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sword"></i> mdi mdi-sword
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sword-cross"></i> mdi mdi-sword-cross
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-symfony"></i> mdi mdi-symfony
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sync"></i> mdi mdi-sync
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sync-alert"></i> mdi mdi-sync-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-sync-off"></i> mdi mdi-sync-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tab"></i> mdi mdi-tab
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tab-minus"></i> mdi mdi-tab-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tab-plus"></i> mdi mdi-tab-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tab-remove"></i> mdi mdi-tab-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tab-unselected"></i> mdi mdi-tab-unselected
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table"></i> mdi mdi-table
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-border"></i> mdi mdi-table-border
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-column"></i> mdi mdi-table-column
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-column-plus-after"></i> mdi mdi-table-column-plus-after
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-column-plus-before"></i> mdi mdi-table-column-plus-before
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-column-remove"></i> mdi mdi-table-column-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-column-width"></i> mdi mdi-table-column-width
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-edit"></i> mdi mdi-table-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-large"></i> mdi mdi-table-large
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-merge-cells"></i> mdi mdi-table-merge-cells
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-of-contents"></i> mdi mdi-table-of-contents
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-plus"></i> mdi mdi-table-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-remove"></i> mdi mdi-table-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-row"></i> mdi mdi-table-row
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-row-height"></i> mdi mdi-table-row-height
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-row-plus-after"></i> mdi mdi-table-row-plus-after
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-row-plus-before"></i> mdi mdi-table-row-plus-before
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-row-remove"></i> mdi mdi-table-row-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-search"></i> mdi mdi-table-search
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-table-settings"></i> mdi mdi-table-settings
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tablet"></i> mdi mdi-tablet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tablet-android"></i> mdi mdi-tablet-android
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tablet-cellphone"></i> mdi mdi-tablet-cellphone
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tablet-ipad"></i> mdi mdi-tablet-ipad
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-taco"></i> mdi mdi-taco
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag"></i> mdi mdi-tag
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-faces"></i> mdi mdi-tag-faces
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-heart"></i> mdi mdi-tag-heart
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-minus"></i> mdi mdi-tag-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-multiple"></i> mdi mdi-tag-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-outline"></i> mdi mdi-tag-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-plus"></i> mdi mdi-tag-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-remove"></i> mdi mdi-tag-remove
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tag-text-outline"></i> mdi mdi-tag-text-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tape-measure"></i> mdi mdi-tape-measure
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-target"></i> mdi mdi-target
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-target-variant"></i> mdi mdi-target-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-taxi"></i> mdi mdi-taxi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-teach"></i> mdi mdi-teach
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-teamviewer"></i> mdi mdi-teamviewer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-telegram"></i> mdi mdi-telegram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-telescope"></i> mdi mdi-telescope
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-television"></i> mdi mdi-television
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-television-box"></i> mdi mdi-television-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-television-classic"></i> mdi mdi-television-classic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-television-classic-off"></i> mdi mdi-television-classic-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-television-guide"></i> mdi mdi-television-guide
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-television-off"></i> mdi mdi-television-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-temperature-celsius"></i> mdi mdi-temperature-celsius
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-temperature-fahrenheit"></i> mdi mdi-temperature-fahrenheit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-temperature-kelvin"></i> mdi mdi-temperature-kelvin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tennis"></i> mdi mdi-tennis
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tent"></i> mdi mdi-tent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-terrain"></i> mdi mdi-terrain
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-test-tube"></i> mdi mdi-test-tube
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-test-tube-empty"></i> mdi mdi-test-tube-empty
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-test-tube-off"></i> mdi mdi-test-tube-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-text"></i> mdi mdi-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-text-shadow"></i> mdi mdi-text-shadow
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-text-short"></i> mdi mdi-text-short
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-text-subject"></i> mdi mdi-text-subject
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-text-to-speech"></i> mdi mdi-text-to-speech
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-text-to-speech-off"></i> mdi mdi-text-to-speech-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-textbox"></i> mdi mdi-textbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-textbox-password"></i> mdi mdi-textbox-password
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-texture"></i> mdi mdi-texture
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-theater"></i> mdi mdi-theater
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-theme-light-dark"></i> mdi mdi-theme-light-dark
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermometer"></i> mdi mdi-thermometer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermometer-lines"></i> mdi mdi-thermometer-lines
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermostat"></i> mdi mdi-thermostat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thermostat-box"></i> mdi mdi-thermostat-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thought-bubble"></i> mdi mdi-thought-bubble
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thought-bubble-outline"></i> mdi mdi-thought-bubble-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thumb-down"></i> mdi mdi-thumb-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thumb-down-outline"></i> mdi mdi-thumb-down-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thumb-up"></i> mdi mdi-thumb-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thumb-up-outline"></i> mdi mdi-thumb-up-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-thumbs-up-down"></i> mdi mdi-thumbs-up-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ticket"></i> mdi mdi-ticket
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ticket-account"></i> mdi mdi-ticket-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ticket-confirmation"></i> mdi mdi-ticket-confirmation
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ticket-outline"></i> mdi mdi-ticket-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ticket-percent"></i> mdi mdi-ticket-percent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tie"></i> mdi mdi-tie
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tilde"></i> mdi mdi-tilde
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timelapse"></i> mdi mdi-timelapse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timer"></i> mdi mdi-timer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timer-10"></i> mdi mdi-timer-10
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timer-3"></i> mdi mdi-timer-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timer-off"></i> mdi mdi-timer-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timer-sand"></i> mdi mdi-timer-sand
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timer-sand-empty"></i> mdi mdi-timer-sand-empty
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timer-sand-full"></i> mdi mdi-timer-sand-full
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-timetable"></i> mdi mdi-timetable
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toggle-switch"></i> mdi mdi-toggle-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toggle-switch-off"></i> mdi mdi-toggle-switch-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toggle-switch-off-outline"></i> mdi mdi-toggle-switch-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toggle-switch-outline"></i> mdi mdi-toggle-switch-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toilet"></i> mdi mdi-toilet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toolbox"></i> mdi mdi-toolbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-toolbox-outline"></i> mdi mdi-toolbox-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooltip"></i> mdi mdi-tooltip
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooltip-edit"></i> mdi mdi-tooltip-edit
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooltip-image"></i> mdi mdi-tooltip-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooltip-outline"></i> mdi mdi-tooltip-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooltip-plus-outline"></i> mdi mdi-tooltip-plus-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooltip-text"></i> mdi mdi-tooltip-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooth"></i> mdi mdi-tooth
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tooth-outline"></i> mdi mdi-tooth-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tor"></i> mdi mdi-tor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tournament"></i> mdi mdi-tournament
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tower-beach"></i> mdi mdi-tower-beach
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tower-fire"></i> mdi mdi-tower-fire
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-towing"></i> mdi mdi-towing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-track-light"></i> mdi mdi-track-light
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trackpad"></i> mdi mdi-trackpad
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trackpad-lock"></i> mdi mdi-trackpad-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tractor"></i> mdi mdi-tractor
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trademark"></i> mdi mdi-trademark
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-traffic-light"></i> mdi mdi-traffic-light
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-train"></i> mdi mdi-train
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-train-variant"></i> mdi mdi-train-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tram"></i> mdi mdi-tram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-transcribe"></i> mdi mdi-transcribe
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-transcribe-close"></i> mdi mdi-transcribe-close
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-transfer"></i> mdi mdi-transfer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-transit-transfer"></i> mdi mdi-transit-transfer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-transition"></i> mdi mdi-transition
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-transition-masked"></i> mdi mdi-transition-masked
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-translate"></i> mdi mdi-translate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trash-can"></i> mdi mdi-trash-can
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trash-can-outline"></i> mdi mdi-trash-can-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-treasure-chest"></i> mdi mdi-treasure-chest
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tree"></i> mdi mdi-tree
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trello"></i> mdi mdi-trello
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trending-down"></i> mdi mdi-trending-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trending-neutral"></i> mdi mdi-trending-neutral
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trending-up"></i> mdi mdi-trending-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-triangle"></i> mdi mdi-triangle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-triangle-outline"></i> mdi mdi-triangle-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trophy"></i> mdi mdi-trophy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trophy-award"></i> mdi mdi-trophy-award
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trophy-outline"></i> mdi mdi-trophy-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trophy-variant"></i> mdi mdi-trophy-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-trophy-variant-outline"></i> mdi mdi-trophy-variant-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-truck"></i> mdi mdi-truck
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-truck-delivery"></i> mdi mdi-truck-delivery
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-truck-fast"></i> mdi mdi-truck-fast
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-truck-trailer"></i> mdi mdi-truck-trailer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tshirt-crew"></i> mdi mdi-tshirt-crew
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tshirt-crew-outline"></i> mdi mdi-tshirt-crew-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tshirt-v"></i> mdi mdi-tshirt-v
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tshirt-v-outline"></i> mdi mdi-tshirt-v-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tumble-dryer"></i> mdi mdi-tumble-dryer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tumblr"></i> mdi mdi-tumblr
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tumblr-box"></i> mdi mdi-tumblr-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tumblr-reblog"></i> mdi mdi-tumblr-reblog
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tune"></i> mdi mdi-tune
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-tune-vertical"></i> mdi mdi-tune-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-twitch"></i> mdi mdi-twitch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-twitter"></i> mdi mdi-twitter
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-twitter-box"></i> mdi mdi-twitter-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-twitter-circle"></i> mdi mdi-twitter-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-twitter-retweet"></i> mdi mdi-twitter-retweet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-two-factor-authentication"></i> mdi mdi-two-factor-authentication
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-uber"></i> mdi mdi-uber
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ubuntu"></i> mdi mdi-ubuntu
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ultra-high-definition"></i> mdi mdi-ultra-high-definition
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-umbraco"></i> mdi mdi-umbraco
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-umbrella"></i> mdi mdi-umbrella
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-umbrella-closed"></i> mdi mdi-umbrella-closed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-umbrella-outline"></i> mdi mdi-umbrella-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-undo"></i> mdi mdi-undo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-undo-variant"></i> mdi mdi-undo-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-unfold-less-horizontal"></i> mdi mdi-unfold-less-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-unfold-less-vertical"></i> mdi mdi-unfold-less-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-unfold-more-horizontal"></i> mdi mdi-unfold-more-horizontal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-unfold-more-vertical"></i> mdi mdi-unfold-more-vertical
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-ungroup"></i> mdi mdi-ungroup
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-unity"></i> mdi mdi-unity
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-unreal"></i> mdi mdi-unreal
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-untappd"></i> mdi mdi-untappd
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-update"></i> mdi mdi-update
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-upload"></i> mdi mdi-upload
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-upload-multiple"></i> mdi mdi-upload-multiple
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-upload-network"></i> mdi mdi-upload-network
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-usb"></i> mdi mdi-usb
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-van-passenger"></i> mdi mdi-van-passenger
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-van-utility"></i> mdi mdi-van-utility
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vanish"></i> mdi mdi-vanish
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-variable"></i> mdi mdi-variable
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-arrange-above"></i> mdi mdi-vector-arrange-above
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-arrange-below"></i> mdi mdi-vector-arrange-below
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-bezier"></i> mdi mdi-vector-bezier
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-circle"></i> mdi mdi-vector-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-circle-variant"></i> mdi mdi-vector-circle-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-combine"></i> mdi mdi-vector-combine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-curve"></i> mdi mdi-vector-curve
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-difference"></i> mdi mdi-vector-difference
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-difference-ab"></i> mdi mdi-vector-difference-ab
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-difference-ba"></i> mdi mdi-vector-difference-ba
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-ellipse"></i> mdi mdi-vector-ellipse
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-intersection"></i> mdi mdi-vector-intersection
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-line"></i> mdi mdi-vector-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-point"></i> mdi mdi-vector-point
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-polygon"></i> mdi mdi-vector-polygon
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-polyline"></i> mdi mdi-vector-polyline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-radius"></i> mdi mdi-vector-radius
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-rectangle"></i> mdi mdi-vector-rectangle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-selection"></i> mdi mdi-vector-selection
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-square"></i> mdi mdi-vector-square
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-triangle"></i> mdi mdi-vector-triangle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vector-union"></i> mdi mdi-vector-union
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-venmo"></i> mdi mdi-venmo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-shield-check"></i> mdi mdi-shield-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vhs"></i> mdi mdi-vhs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vibrate"></i> mdi mdi-vibrate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video"></i> mdi mdi-video
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-3d"></i> mdi mdi-video-3d
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-4k-box"></i> mdi mdi-video-4k-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-account"></i> mdi mdi-video-account
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-image"></i> mdi mdi-video-image
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-input-antenna"></i> mdi mdi-video-input-antenna
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-input-component"></i> mdi mdi-video-input-component
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-input-hdmi"></i> mdi mdi-video-input-hdmi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-input-svideo"></i> mdi mdi-video-input-svideo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-minus"></i> mdi mdi-video-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-off"></i> mdi mdi-video-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-plus"></i> mdi mdi-video-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-stabilization"></i> mdi mdi-video-stabilization
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-switch"></i> mdi mdi-video-switch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-video-vintage"></i> mdi mdi-video-vintage
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-agenda"></i> mdi mdi-view-agenda
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-array"></i> mdi mdi-view-array
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-carousel"></i> mdi mdi-view-carousel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-column"></i> mdi mdi-view-column
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-dashboard"></i> mdi mdi-view-dashboard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-dashboard-outline"></i> mdi mdi-view-dashboard-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-dashboard-variant"></i> mdi mdi-view-dashboard-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-day"></i> mdi mdi-view-day
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-grid"></i> mdi mdi-view-grid
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-headline"></i> mdi mdi-view-headline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-list"></i> mdi mdi-view-list
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-module"></i> mdi mdi-view-module
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-parallel"></i> mdi mdi-view-parallel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-quilt"></i> mdi mdi-view-quilt
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-sequential"></i> mdi mdi-view-sequential
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-stream"></i> mdi mdi-view-stream
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-view-week"></i> mdi mdi-view-week
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vimeo"></i> mdi mdi-vimeo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-violin"></i> mdi mdi-violin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-virtual-reality"></i> mdi mdi-virtual-reality
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-visual-studio"></i> mdi mdi-visual-studio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-visual-studio-code"></i> mdi mdi-visual-studio-code
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vk"></i> mdi mdi-vk
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vk-box"></i> mdi mdi-vk-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vk-circle"></i> mdi mdi-vk-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vlc"></i> mdi mdi-vlc
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-voice"></i> mdi mdi-voice
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-voicemail"></i> mdi mdi-voicemail
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volleyball"></i> mdi mdi-volleyball
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-high"></i> mdi mdi-volume-high
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-low"></i> mdi mdi-volume-low
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-medium"></i> mdi mdi-volume-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-minus"></i> mdi mdi-volume-minus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-mute"></i> mdi mdi-volume-mute
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-off"></i> mdi mdi-volume-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-volume-plus"></i> mdi mdi-volume-plus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vote"></i> mdi mdi-vote
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vote-outline"></i> mdi mdi-vote-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vpn"></i> mdi mdi-vpn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-vuejs"></i> mdi mdi-vuejs
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-walk"></i> mdi mdi-walk
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wall"></i> mdi mdi-wall
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wall-sconce"></i> mdi mdi-wall-sconce
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wall-sconce-flat"></i> mdi mdi-wall-sconce-flat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wall-sconce-variant"></i> mdi mdi-wall-sconce-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wallet"></i> mdi mdi-wallet
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wallet-giftcard"></i> mdi mdi-wallet-giftcard
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wallet-membership"></i> mdi mdi-wallet-membership
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wallet-travel"></i> mdi mdi-wallet-travel
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wan"></i> mdi mdi-wan
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-washing-machine"></i> mdi mdi-washing-machine
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch"></i> mdi mdi-watch
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch-export"></i> mdi mdi-watch-export
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch-export-variant"></i> mdi mdi-watch-export-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch-import"></i> mdi mdi-watch-import
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch-import-variant"></i> mdi mdi-watch-import-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch-variant"></i> mdi mdi-watch-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watch-vibrate"></i> mdi mdi-watch-vibrate
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-water"></i> mdi mdi-water
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-water-off"></i> mdi mdi-water-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-water-percent"></i> mdi mdi-water-percent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-water-pump"></i> mdi mdi-water-pump
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-watermark"></i> mdi mdi-watermark
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-waves"></i> mdi mdi-waves
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-cloudy"></i> mdi mdi-weather-cloudy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-fog"></i> mdi mdi-weather-fog
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-hail"></i> mdi mdi-weather-hail
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-hurricane"></i> mdi mdi-weather-hurricane
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-lightning"></i> mdi mdi-weather-lightning
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-lightning-rainy"></i> mdi mdi-weather-lightning-rainy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-night"></i> mdi mdi-weather-night
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-partlycloudy"></i> mdi mdi-weather-partlycloudy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-pouring"></i> mdi mdi-weather-pouring
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-rainy"></i> mdi mdi-weather-rainy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-snowy"></i> mdi mdi-weather-snowy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-snowy-rainy"></i> mdi mdi-weather-snowy-rainy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-sunny"></i> mdi mdi-weather-sunny
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-sunset"></i> mdi mdi-weather-sunset
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-sunset-down"></i> mdi mdi-weather-sunset-down
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-sunset-up"></i> mdi mdi-weather-sunset-up
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-windy"></i> mdi mdi-weather-windy
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weather-windy-variant"></i> mdi mdi-weather-windy-variant
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-web"></i> mdi mdi-web
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-webcam"></i> mdi mdi-webcam
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-webhook"></i> mdi mdi-webhook
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-webpack"></i> mdi mdi-webpack
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wechat"></i> mdi mdi-wechat
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weight"></i> mdi mdi-weight
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weight-kilogram"></i> mdi mdi-weight-kilogram
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-weight-pound"></i> mdi mdi-weight-pound
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-whatsapp"></i> mdi mdi-whatsapp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wheelchair-accessibility"></i> mdi mdi-wheelchair-accessibility
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-whistle"></i> mdi mdi-whistle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-white-balance-auto"></i> mdi mdi-white-balance-auto
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-white-balance-incandescent"></i> mdi mdi-white-balance-incandescent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-white-balance-iridescent"></i> mdi mdi-white-balance-iridescent
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-white-balance-sunny"></i> mdi mdi-white-balance-sunny
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-widgets"></i> mdi mdi-widgets
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi"></i> mdi mdi-wifi
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-off"></i> mdi mdi-wifi-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-1"></i> mdi mdi-wifi-strength-1
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-1-alert"></i> mdi mdi-wifi-strength-1-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-1-lock"></i> mdi mdi-wifi-strength-1-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-2"></i> mdi mdi-wifi-strength-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-2-alert"></i> mdi mdi-wifi-strength-2-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-2-lock"></i> mdi mdi-wifi-strength-2-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-3"></i> mdi mdi-wifi-strength-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-3-alert"></i> mdi mdi-wifi-strength-3-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-3-lock"></i> mdi mdi-wifi-strength-3-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-4"></i> mdi mdi-wifi-strength-4
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-4-alert"></i> mdi mdi-wifi-strength-4-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-4-lock"></i> mdi mdi-wifi-strength-4-lock
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-alert-outline"></i> mdi mdi-wifi-strength-alert-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-lock-outline"></i> mdi mdi-wifi-strength-lock-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-off"></i> mdi mdi-wifi-strength-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-off-outline"></i> mdi mdi-wifi-strength-off-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wifi-strength-outline"></i> mdi mdi-wifi-strength-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wii"></i> mdi mdi-wii
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wiiu"></i> mdi mdi-wiiu
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wikipedia"></i> mdi mdi-wikipedia
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-window-close"></i> mdi mdi-window-close
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-window-closed"></i> mdi mdi-window-closed
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-window-maximize"></i> mdi mdi-window-maximize
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-window-minimize"></i> mdi mdi-window-minimize
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-window-open"></i> mdi mdi-window-open
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-window-restore"></i> mdi mdi-window-restore
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-windows"></i> mdi mdi-windows
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-windows-classic"></i> mdi mdi-windows-classic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wiper"></i> mdi mdi-wiper
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wordpress"></i> mdi mdi-wordpress
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-worker"></i> mdi mdi-worker
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wrap"></i> mdi mdi-wrap
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wrench"></i> mdi mdi-wrench
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-wunderlist"></i> mdi mdi-wunderlist
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xamarin"></i> mdi mdi-xamarin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xamarin-outline"></i> mdi mdi-xamarin-outline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xaml"></i> mdi mdi-xaml
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox"></i> mdi mdi-xbox
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller"></i> mdi mdi-xbox-controller
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-battery-alert"></i> mdi mdi-xbox-controller-battery-alert
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-battery-charging"></i> mdi mdi-xbox-controller-battery-charging
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-battery-empty"></i> mdi mdi-xbox-controller-battery-empty
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-battery-full"></i> mdi mdi-xbox-controller-battery-full
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-battery-low"></i> mdi mdi-xbox-controller-battery-low
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-battery-medium"></i> mdi mdi-xbox-controller-battery-medium
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-battery-unknown"></i> mdi mdi-xbox-controller-battery-unknown
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xbox-controller-off"></i> mdi mdi-xbox-controller-off
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xda"></i> mdi mdi-xda
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xing"></i> mdi mdi-xing
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xing-box"></i> mdi mdi-xing-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xing-circle"></i> mdi mdi-xing-circle
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xml"></i> mdi mdi-xml
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-xmpp"></i> mdi mdi-xmpp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-yahoo"></i> mdi mdi-yahoo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-yammer"></i> mdi mdi-yammer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-yeast"></i> mdi mdi-yeast
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-yelp"></i> mdi mdi-yelp
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-yin-yang"></i> mdi mdi-yin-yang
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-youtube"></i> mdi mdi-youtube
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-youtube-creator-studio"></i> mdi mdi-youtube-creator-studio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-youtube-gaming"></i> mdi mdi-youtube-gaming
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-youtube-tv"></i> mdi mdi-youtube-tv
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-z-wave"></i> mdi mdi-z-wave
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zend"></i> mdi mdi-zend
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zip-box"></i> mdi mdi-zip-box
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zip-disk"></i> mdi mdi-zip-disk
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-aquarius"></i> mdi mdi-zodiac-aquarius
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-aries"></i> mdi mdi-zodiac-aries
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-cancer"></i> mdi mdi-zodiac-cancer
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-capricorn"></i> mdi mdi-zodiac-capricorn
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-gemini"></i> mdi mdi-zodiac-gemini
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-leo"></i> mdi mdi-zodiac-leo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-libra"></i> mdi mdi-zodiac-libra
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-pisces"></i> mdi mdi-zodiac-pisces
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-sagittarius"></i> mdi mdi-zodiac-sagittarius
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-scorpio"></i> mdi mdi-zodiac-scorpio
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-taurus"></i> mdi mdi-zodiac-taurus
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-zodiac-virgo"></i> mdi mdi-zodiac-virgo
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-blank"></i> mdi mdi-blank
            </div>

          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Size</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-18px mdi-account"></i> mdi-18px
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-24px mdi-account"></i> mdi-24px
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-36px mdi-account"></i> mdi-36px
            </div>

            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-48px mdi-account"></i> mdi-48px
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Rotate</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-45 mdi-account"></i> mdi-rotate-45
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-90 mdi-account"></i> mdi-rotate-90
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-135 mdi-account"></i> mdi-rotate-135
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-180 mdi-account"></i> mdi-rotate-180
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-225 mdi-account"></i> mdi-rotate-225
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-270 mdi-account"></i> mdi-rotate-270
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-rotate-315 mdi-account"></i> mdi-rotate-315
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Spin</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spin mdi-loading"></i> mdi-spin
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="mdi mdi-spin mdi-star"></i> mdi-spin
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>