import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PagesService} from '../../shared/service/pages.service';
import Swal from 'sweetalert2';
import {CameraSettingService} from './camera-setting.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-camera-setting',
    templateUrl: './camera-setting.component.html',
    styleUrls: ['./camera-setting.component.scss']
})
export class CameraSettingComponent implements OnInit {
    breadCrumbItems: Array<{}>;
    // active = 'zoneMotion';
    active = 'alarm';
    camId = '...';
    cropperPosition: any;
    roleByCamera = '';
    loading = false;

    keyLang: any;
    arrKeyLang = [
        'config', 'camera.list', 'agree', 'refuse', 'notify.complete',
        'notify.delete_camera_ask', 'notify.restart_camera_ask', 'notify.reboot_camera_ask',
        'notify.delete_camera_complete', 'notify.restart_camera_complete', 'notify.delete_camera_ask_info',
        'notify.reboot_camera_ask_info',
    ];

    // alarm setting
    daysOfWeekHours =
        {
            Mon: [],
            Tue: [],
            Wed: [],
            Thu: [],
            Fri: [],
            Sar: [],
            Sun: []
        };
    alarmStatus = false;
    alarmSoundStatus = false;
    popupNotifyStatus = false;
    incomingcallNotifyStatus = false;
    arrDay = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sar', 'Sun'];
    arrTimeChecked = [];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private pageService: PagesService,
                private cameraSettingService: CameraSettingService,
                private translate: TranslateService
    ) {
        this.camId = this.route.snapshot.params.id;
    }

    ngOnInit() {
        this.translate.stream(this.arrKeyLang).subscribe(data => {
            this.keyLang = data;

            this.breadCrumbItems = [{label: 'Aivis', path: '/list-camera'},
                {label: data['camera.list'], path: '/list-camera'},
                {label: data.config + ' camera', active: true}];
        });
        this.getSetting();
    }

    getSetting() {
        this.pageService.fetchSettingCamera(this.camId).subscribe(data => {
            if (data) {
                this.roleByCamera = data.role;
                this.alarmStatus = this.pipeBoolean(data.intrusion_alarm_status);
                this.alarmSoundStatus = this.pipeBoolean(data.intrusion_alarm_sound);
                this.popupNotifyStatus = this.pipeBoolean(data.intrusion_alarm_popup_notify);
                this.incomingcallNotifyStatus = this.pipeBoolean(data.intrusion_alarm_incomingcall_notify);
                this.daysOfWeekHours = data.intrusion_alarm_hour;

                // tslint:disable-next-line:forin
                for (const property in this.daysOfWeekHours) {
                    console.log(`${property}: ${this.daysOfWeekHours[property]}`);
                    const dayIndex = this.arrDay.indexOf(property);
                    for (let i = 0; i < 24; i++) {
                        this.arrTimeChecked[i][dayIndex] = this.daysOfWeekHours[property].includes(i);
                    }
                }

                const position = data.motion_region.split(/[,-]+/);
                this.cropperPosition = {
                    x1: Number(position[0]),
                    y1: Number(position[1]),
                    x2: Number(position[2]),
                    y2: Number(position[3]),
                };
            }
        });
    }

    pipeBoolean(str: string) {
        return str === 'on';
    }

    deleteCamera() {
        if (this.roleByCamera) {
            Swal.fire({
                title: this.keyLang['notify.delete_camera_ask'],
                text: this.keyLang['notify.delete_camera_ask_info'],
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.keyLang.agree,
                cancelButtonText: this.keyLang.refuse
            }).then((result) => {
                if (result.value) {

                    this.loading = true;
                    if (this.roleByCamera === 'admin') {
                        this.cameraSettingService.deleteCameraFactory(this.camId).subscribe(data => {
                            if (data) {
                                this.backToCameraList();
                            }
                        }, error => this.loading = false);
                    } else {
                        this.cameraSettingService.deleteCameraRel(this.camId).subscribe(data => {
                            if (data) {
                                this.backToCameraList();
                            }
                        }, error => this.loading = false);
                    }

                }
            });
        }
    }

    backToCameraList() {
        this.loading = false;
        Swal.fire(this.keyLang['notify.complete'] + '!', this.keyLang['notify.delete_camera_complete'], 'success');
        this.router.navigate(['/list-camera'], {queryParams: {returnUrl: true}});
    }

    restart() {
        Swal.fire({
            title: this.keyLang['notify.restart_camera_ask'],
            // text: '',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.keyLang.agree
        }).then((result) => {
            if (result.value) {
                this.cameraSettingService.restartCamera(this.camId).subscribe(data => {
                    if (data) {
                        Swal.fire(this.keyLang['notify.complete'] + '!', this.keyLang['notify.restart_camera_complete'], 'success');
                    }
                });
            }
        });
    }

    reboot() {
        Swal.fire({
            title: this.keyLang['notify.reboot_camera_ask'],
            text: this.keyLang['notify.reboot_camera_ask_info'],
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.keyLang.agree
        }).then((result) => {
            if (result.value) {
                this.cameraSettingService.rebootCamera(this.camId).subscribe(data => {
                    if (data) {
                        Swal.fire(this.keyLang['notify.complete'] + '!', this.keyLang['notify.restart_camera_complete'], 'success');
                    }
                });
            }
        });
    }
}

