import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-typography',
  templateUrl: './typography.component.html',
  styleUrls: ['./typography.component.scss']
})

/**
 * Typography component - handling the typography with navbar and content
 */
export class TypographyComponent implements OnInit {
  // bread crumb items
  breadCrumbItems: Array<{}>;

  constructor() { }

  ngOnInit() {
    // tslint:disable-next-line: max-line-length
    this.breadCrumbItems = [{ label: 'Aivis', path: '/' }, { label: 'UI Elements', path: '/' }, { label: 'Typography', path: '/', active: true }];

  }

}
