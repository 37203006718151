import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

import { UIModule } from '../../shared/ui/ui.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { AuthRoutingModule } from './auth-routing';
import { ConfirmComponent } from './confirm/confirm.component';
import { PasswordresetComponent } from './passwordreset/passwordreset.component';
import {SharedModule} from '../../shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
    declarations: [LoginComponent, SignupComponent, ConfirmComponent, PasswordresetComponent, ChangePasswordComponent],
    exports: [
        ChangePasswordComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbAlertModule,
        UIModule,
        AuthRoutingModule,
        SharedModule,
        NgSelectModule,
        FormsModule
    ]
})
export class AuthModule { }
