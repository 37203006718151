import {Injectable, Input} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CommandURL} from '../../shared/constant/ManageURLCommand';
import {CookieService} from '../../core/services/cookie.service';

@Injectable({
    providedIn: 'root'
})
export class CameraSettingService {

    constructor(private http: HttpClient,
                private cookieService: CookieService) {
    }

    getLatestImage(cameraId: string) {
        const params = new HttpParams().set('cameraId', cameraId)
            .set('token', JSON.parse(this.cookieService.getCookie('currentUser')).token);
        return this.http.get<any>(CommandURL.GET_LATEST_IMAGE_URL, {params});
    }

    setMotionZone(cameraId: string, coordinatesRatio: string) {
        return this.http.post<any>(CommandURL.SET_MOTION_ZONE_RATIO_URL, {cameraId, coordinatesRatio});
    }

    getListCameraShare(cameraId: string) {
        return this.http.post<any>(CommandURL.GET_LIST_CAMERA_SHARE, {cameraId});
    }

    removeCameraShare(cameraId: string, userId: string) {
        return this.http.post<any>(CommandURL.REMOVE_CAMERA_SHARE, {cameraId, userId});
    }

    deleteCameraRel(cameraId: string) {
        return this.http.post<any>(CommandURL.REMOVE_CAMERA_REL, {cameraId});
    }

    deleteCameraFactory(cameraId: string) {
        return this.http.post<any>(CommandURL.REMOVE_CAMERA_FACTORY, {cameraId});
    }

    restartCamera(cameraId: string) {
        return this.http.post<any>(CommandURL.RESTART_CAMERA_URL, {cameraId});
    }

    rebootCamera(cameraId: string) {
        return this.http.post<any>(CommandURL.RESBOOT_CAMERA_URL, {cameraId});
    }

    setIntrusionAlarmStatus(cameraId: string, status: string) {
        return this.http.post<any>(CommandURL.SET_INTRUCSION_ALARM_STATUS_URL, {cameraId, status});
    }

    setIntrusionAlarmHour(cameraId: string, daysOfWeekHours: {}) {
        return this.http.post<any>(CommandURL.SET_INTRUCSION_ALARM_HOUR_URL, {cameraId, daysOfWeekHours});
    }

    setIntrusionAlarmSound(cameraId: string, status: string) {
        return this.http.post<any>(CommandURL.SET_INTRUCSION_ALARM_SOUND_URL, {cameraId, status});
    }

    setIntrusionAlarmPopupNotify(cameraId: string, status: string) {
        return this.http.post<any>(CommandURL.SET_INTRUCSION_ALARM_POPUP_NOTIFY_URL, {cameraId, status});
    }

}
