<!--    <input (change)="fileChangeEvent($event)" type="file"/>-->

<!--    [aspectRatio]="4 / 3"-->
<!--    [imageChangedEvent]="imageChangedEvent"-->
<image-cropper
        (cropperReady)="cropperReady()"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded($event)"
        (loadImageFailed)="loadImageFailed()"
        [imageURL]="imageChangedEvent"
        [maintainAspectRatio]="false"
        format="png"
        [cropper]="cropper"
></image-cropper>


<div class="row justify-content-center mt-3">
    <div class="col-12 text-center">
        <img [src]="croppedImage" class="img-fluid"/>
    </div>
    <button (click)="copperImage()" class="btn btn-sm btn-primary my-3">
        {{'save' | translate}} <i class="ml-1 fas fa-save"></i>
    </button>
</div>
