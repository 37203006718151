import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CommandURL} from '../../shared/constant/ManageURLCommand';

@Injectable({
  providedIn: 'root'
})
export class UpdateFirmwareService {

  constructor(private http: HttpClient) { }

  getCurrentFirmwareVerion(cameraId: string) {
    return this.http.post<any>(CommandURL.GET_CURRENT_FIRMWARE_VERSION_URL, {cameraId});
  }

  getListFirmwareVersion(model: string) {
    return this.http.post<any>(CommandURL.GET_LIST_FIRMWARE_URL, {model});
  }

  updateFirmwareVerion(cameraId: string, version: string) {
    return this.http.post<any>(CommandURL.UPDATE_FIRMWARE_URL, {cameraId, version});
  }
}
