const projectData = [
    {
        id: 1,
        name: 'App design and development',
        startdate: '01/01/2015',
        duedate: '10/15/2018',
        status: 'Work in Progress',
        client: 'Halette Boivin'
    },
    {
        id: 2,
        name: '	Coffee detail page - Main Page',
        startdate: '21/07/2016',
        duedate: '12/05/2018',
        status: 'Pending',
        client: 'Durandana Jolicoeur'
    },
    {
        id: 3,
        name: 'Poster illustation design',
        startdate: '18/03/2018',
        duedate: '28/09/2018',
        status: 'Done',
        client: 'Lucas Sabourin'
    },
    {
        id: 4,
        name: 'Drinking bottle graphics',
        startdate: '02/10/2017',
        duedate: '07/05/2018',
        status: 'Work in Progress',
        client: 'Donatien Brunelle'
    },
    {
        id: 5,
        name: 'Landing page design - Home',
        startdate: '17/01/2017',
        duedate: '25/05/2021',
        status: 'Coming soon',
        client: 'Karel Auberjo'
    },
];

export { projectData };
