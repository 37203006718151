<div class="container-fluid">
    <app-page-title title="Form Validation" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="header-title">Bootstrap Validation - Normal</h4>
                    <p class="sub-header">Provide valuable, actionable feedback to your users with HTML5 form
                        validation–available in all our supported browsers.</p>

                    <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">
                        <div class="form-group position-relative mb-3">
                            <label>First name</label>
                            <input type="text" class="form-control" formControlName="firstName" placeholder="First name"
                                value="Mark" [ngClass]="{'is-valid': submit && form.firstName.errors}">
                            <div *ngIf="submit && form.firstName.errors" class="valid-feedback">
                                <span *ngIf="form.firstName.errors.required">Looks good!</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>Last name</label>
                            <input type="text" class="form-control" formControlName="lastName" placeholder="Last name"
                                value="Otto" [ngClass]="{'is-valid': submit && form.lastName.errors}">
                            <div *ngIf="submit && form.lastName.errors" class="valid-feedback">
                                <span *ngIf="form.lastName.errors.required">Looks good!</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label for="validationTooltipUsername">Username</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">@</span>
                                </div>
                                <input type="text" class="form-control" formControlName="userName"
                                    placeholder="Username" aria-describedby="validationTooltipUsernamePrepend"
                                    [ngClass]="{'is-invalid': submit && form.userName.errors}">
                                <div *ngIf="submit && form.userName.errors" class="invalid-feedback">
                                    <span *ngIf="form.userName.errors.required">Please choose a username.</span>
                                </div>

                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>City</label>
                            <input type="text" class="form-control" placeholder="City" formControlName="city"
                                [ngClass]="{'is-invalid': submit && form.city.errors}">
                            <div *ngIf="submit && form.city.errors" class="invalid-feedback">
                                <span *ngIf="form.city.errors.required">Please provide a valid city.</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>State</label>
                            <input type="text" class="form-control" placeholder="State" formControlName="state"
                                [ngClass]="{'is-invalid': submit && form.state.errors}">
                            <div *ngIf="submit && form.state.errors" class="invalid-feedback">
                                <span *ngIf="form.state.errors.required">Please provide a valid state.</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>Zip</label>
                            <input type="text" class="form-control" placeholder="Zip" formControlName="zip"
                                [ngClass]="{'is-invalid': submit && form.zip.errors}">
                            <div *ngIf="submit && form.zip.errors" class="invalid-feedback">
                                <span *ngIf="form.zip.errors.required">Please provide a valid zip.</span>
                            </div>
                        </div>

                        <button class="btn btn-primary" type="submit">Submit form</button>
                    </form>

                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div> <!-- end col-->


        <div class="col-lg-6">
            <div class="card">
                <div class="card-body">
                    <h4 class="header-title">Bootstrap Validation (Tooltips)</h4>
                    <p class="sub-header">If your form layout allows it, you can swap the
                        <code>.valid|invalid-feedback</code>
                        classes for <code>.valid|invalid-tooltip</code> classes to display validation feedback
                        in a styled tooltip.</p>

                    <form class="needs-validation" (ngSubmit)="formSubmit()" [formGroup]="tooltipvalidationform">
                        <div class="form-group position-relative mb-3">
                            <label>First name</label>
                            <input type="text" class="form-control" formControlName="firstName" placeholder="First name"
                                value="Mark" [ngClass]="{'is-valid': formsubmit && formData.firstName.errors}">
                            <div *ngIf="formsubmit && formData.firstName.errors" class="valid-tooltip">
                                <span *ngIf="formData.firstName.errors.required">Looks good!</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>Last name</label>
                            <input type="text" class="form-control" formControlName="lastName" placeholder="Last name"
                                value="Otto" [ngClass]="{'is-valid': formsubmit && formData.lastName.errors}">
                            <div *ngIf="formsubmit && formData.lastName.errors" class="valid-tooltip">
                                <span *ngIf="formData.lastName.errors.required">Looks good!</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>Username</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">@</span>
                                </div>
                                <input type="text" class="form-control" formControlName="userName"
                                    placeholder="Username"
                                    [ngClass]="{'is-invalid': formsubmit && formData.userName.errors}">
                                <div *ngIf="formsubmit && formData.userName.errors" class="invalid-tooltip">
                                    <span *ngIf="formData.userName.errors.required">Please choose a username.</span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>City</label>
                            <input type="text" class="form-control" placeholder="City" formControlName="city"
                                [ngClass]="{'is-invalid': formsubmit && formData.city.errors}">
                            <div *ngIf="formsubmit && formData.city.errors" class="invalid-tooltip">
                                <span *ngIf="formData.city.errors.required">Please provide a valid city.</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>State</label>
                            <input type="text" class="form-control" placeholder="State" formControlName="state"
                                [ngClass]="{'is-invalid': formsubmit && formData.state.errors}">
                            <div *ngIf="formsubmit && formData.state.errors" class="invalid-tooltip">
                                <span *ngIf="formData.state.errors.required">Please provide a valid state.</span>
                            </div>
                        </div>
                        <div class="form-group position-relative mb-3">
                            <label>Zip</label>
                            <input type="text" class="form-control" placeholder="Zip" formControlName="zip"
                                [ngClass]="{'is-invalid': formsubmit && formData.zip.errors}">
                            <div *ngIf="formsubmit && formData.zip.errors" class="invalid-tooltip">
                                <span *ngIf="formData.zip.errors.required">Please provide a valid zip.</span>
                            </div>
                        </div>
                        <button class="btn btn-primary" type="submit">Submit form</button>
                    </form>

                </div> <!-- end card-body-->
            </div> <!-- end card-->
        </div> <!-- end col-->
    </div>
    <!-- end row -->

    <div class="row">
        <div class="col-lg-6">
            <div class="card-box">
                <h4 class="header-title">Basic Form</h4>
                <p class="sub-header">
                    Parsley is a javascript form validation library. It helps you provide your users with feedback on
                    their form submission before sending it to your server.
                </p>
                <form (ngSubmit)="basicSubmit()" [formGroup]="basicFormvalidation">
                    <div class="form-group">
                        <label>User Name<span class="text-danger">*</span></label>
                        <input type="text" name="nick" formControlName="user" placeholder="Enter user name"
                            class="form-control" [ngClass]="{'is-invalid': basicsubmit && basic.user.errors}">
                        <div *ngIf="basicsubmit && basic.user.errors" class="invalid-feedback">
                            <span *ngIf="basic.user.errors.required">Please enter user name.</span>
                            <span *ngIf="basic.user.errors.pattern">Please enter user.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Email address<span class="text-danger">*</span></label>
                        <input type="email" name="email" formControlName="email" placeholder="Enter email"
                            class="form-control" [ngClass]="{'is-invalid': basicsubmit && basic.email.errors}">
                        <div *ngIf="basicsubmit && basic.email.errors" class="invalid-feedback">
                            <span *ngIf="basic.email.errors.required">Please enter email.</span>
                            <span *ngIf="basic.email.errors.pattern">Please enter valid email.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="pass1">Password<span class="text-danger">*</span></label>
                        <input id="pass1" type="password" placeholder="Password" formControlName="password"
                            class="form-control" [ngClass]="{'is-invalid': basicsubmit && basic.password.errors}">
                        <div *ngIf="basicsubmit && basic.password.errors" class="invalid-feedback">
                            <span *ngIf="basic.password.errors.required">Please enter password.</span>
                            <span *ngIf="basic.password.errors.minlength">Password must be at least 6 characters.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Confirm Password <span class="text-danger">*</span></label>
                        <input type="password" formControlName="confirmpwd" placeholder="Password" class="form-control"
                            id="passWord2" [ngClass]="{'is-invalid': basicsubmit && basic.confirmpwd.errors}">
                        <div *ngIf="basicsubmit && basic.confirmpwd.errors" class="invalid-feedback">
                            <span *ngIf="basic.confirmpwd.errors.required">Please enter confirm password.</span>
                            <span *ngIf="basic.confirmpwd.errors.mustMatch">This value should be the same.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox checkbox-purple">
                            <input type="checkbox">
                            <label>
                                Remember me
                            </label>
                        </div>
                    </div>
                    <div class="form-group text-right mb-0">
                        <button class="btn btn-primary waves-effect waves-light mr-1" type="submit">
                            Submit
                        </button>
                        <button type="reset" class="btn btn-secondary waves-effect waves-light">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card-box">
                <h4 class="header-title">Horizontal Form</h4>
                <p class="sub-header">
                    Parsley is a javascript form validation library. It helps you provide your users with feedback on
                    their form submission before sending it to your server.
                </p>
                <form (ngSubmit)="horizontalSubmit()" [formGroup]="horizontalFormValidation">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Email<span class="text-danger">*</span></label>
                        <div class="col-sm-7">
                            <input type="email" class="form-control" formControlName="email" placeholder="Email"
                                [ngClass]="{'is-invalid': horizontalsubmit && horizontal.email.errors}">
                            <div *ngIf="horizontalsubmit && horizontal.email.errors" class="invalid-feedback">
                                <span *ngIf="horizontal.email.errors.required">This value is required.</span>
                                <span *ngIf="horizontal.email.errors.pattern">This value should be a valid email.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Password<span class="text-danger">*</span></label>
                        <div class="col-sm-7">
                            <input type="password" class="form-control" formControlName="password"
                                placeholder="Password"
                                [ngClass]="{'is-invalid': horizontalsubmit && horizontal.password.errors}">
                            <div *ngIf="horizontalsubmit && horizontal.password.errors" class="invalid-feedback">
                                <span *ngIf="horizontal.password.errors.required">This value is required.</span>
                                <span *ngIf="horizontal.password.errors.minlength">Password must be at least 6
                                    characters.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Confirm Password<span
                                class="text-danger">*</span></label>
                        <div class="col-sm-7">
                            <input type="password" class="form-control" formControlName="confirmpwd"
                                placeholder="Password"
                                [ngClass]="{'is-invalid': horizontalsubmit && horizontal.confirmpwd.errors}">
                            <div *ngIf="horizontalsubmit && horizontal.confirmpwd.errors" class="invalid-feedback">
                                <span *ngIf="horizontal.confirmpwd.errors.required">This value is required.</span>
                                <span *ngIf="horizontal.confirmpwd.errors.mustMatch">This value should be the
                                    same.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Web Site<span class="text-danger">*</span></label>
                        <div class="col-sm-7">
                            <input type="email" class="form-control" formControlName="url" placeholder="URL"
                                [ngClass]="{'is-invalid': horizontalsubmit && horizontal.url.errors}">
                            <div *ngIf="horizontalsubmit && horizontal.url.errors" class="invalid-feedback">
                                <span *ngIf="horizontal.url.errors.required">This value is required.</span>
                                <span *ngIf="horizontal.url.errors.pattern">This value should be a valid url.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-8 offset-sm-4">
                            <button type="submit" class="btn btn-primary waves-effect waves-light mr-1">
                                Register
                            </button>
                            <button type="reset" class="btn btn-secondary waves-effect waves-light">
                                Cancel
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox checkbox-purple">
                            <input type="checkbox">
                            <label>
                                Remember me
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="card-box">
                <h4 class="header-title">Validation type</h4>
                <p class="sub-header">
                    Parsley is a javascript form validation library. It helps you provide your users with feedback on
                    their form submission before sending it to your server.
                </p>
                <form (ngSubmit)="typeSubmit()" [formGroup]="typeValidationForm">
                    <div class="form-group">
                        <label>Required</label>
                        <input type="text" class="form-control" formControlName="text"
                            [ngClass]="{'is-invalid': typesubmit && type.text.errors}" placeholder="Type something" />
                        <div *ngIf="typesubmit && type.text.errors" class="invalid-feedback">
                            <span *ngIf="type.text.errors.required">This value is required.</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Equal To</label>
                        <div>
                            <input type="password" class="form-control" placeholder="password"
                                formControlName="password"
                                [ngClass]="{'is-invalid': typesubmit && type.password.errors}" />
                            <div *ngIf="typesubmit && type.password.errors" class="invalid-feedback">
                                <span *ngIf="type.password.errors.required">This value is required.</span>
                                <span *ngIf="type.password.errors.minlength">Password must be at least 6
                                    characters.</span>
                            </div>
                        </div>
                        <div class="m-t-10">
                            <input type="password" class="form-control" placeholder="Re-Type Password"
                                formControlName="confirmpwd"
                                [ngClass]="{'is-invalid': typesubmit && type.confirmpwd.errors}" />
                            <div *ngIf="typesubmit && type.confirmpwd.errors" class="invalid-feedback">
                                <span *ngIf="type.confirmpwd.errors.required">This value is required.</span>
                                <span *ngIf="type.confirmpwd.errors.mustMatch">This value should be the same.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>E-Mail</label>
                        <div>
                            <input type="email" class="form-control" formControlName="email"
                                [ngClass]="{'is-invalid': typesubmit && type.email.errors}"
                                placeholder="Enter a valid e-mail" />
                            <div *ngIf="typesubmit && type.email.errors" class="invalid-feedback">
                                <span *ngIf="type.email.errors.required">This value is required.</span>
                                <span *ngIf="type.email.errors.pattern">This value should be a valid email.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>URL</label>
                        <div>
                            <input type="url" class="form-control" placeholder="URL" formControlName="url"
                                [ngClass]="{'is-invalid': typesubmit && type.url.errors}" />
                            <div *ngIf="typesubmit && type.url.errors" class="invalid-feedback">
                                <span *ngIf="type.url.errors.required">This value is required.</span>
                                <span *ngIf="type.url.errors.pattern">This value should be a valid url.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Digits</label>
                        <div>
                            <input formControlName="digits" type="text" class="form-control"
                                [ngClass]="{'is-invalid': typesubmit && type.digits.errors}"
                                placeholder="Enter only digits" />
                            <div *ngIf="typesubmit && type.digits.errors" class="invalid-feedback">
                                <span *ngIf="type.digits.errors.required">This value is required.</span>
                                <span *ngIf="type.digits.errors.pattern">This value should be digits.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Number</label>
                        <div>
                            <input formControlName="number" type="text" class="form-control"
                                [ngClass]="{'is-invalid': typesubmit && type.number.errors}"
                                placeholder="Enter only numbers">
                            <div *ngIf="typesubmit && type.number.errors" class="invalid-feedback">
                                <span *ngIf="type.number.errors.required">This value is required.</span>
                                <span *ngIf="type.number.errors.pattern">This value should be a valid number.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Alphanumeric</label>
                        <div>
                            <input formControlName="alphanum" type="text" class="form-control"
                                [ngClass]="{'is-invalid': typesubmit && type.alphanum.errors}"
                                placeholder="Enter alphanumeric value" />
                            <div *ngIf="typesubmit && type.alphanum.errors" class="invalid-feedback">
                                <span *ngIf="type.alphanum.errors.required">This value is required.</span>
                                <span *ngIf="type.alphanum.errors.pattern">This value should be alphanumeric.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Textarea</label>
                        <div>
                            <textarea formControlName="textarea" class="form-control" rows="5"
                                [ngClass]="{'is-invalid': typesubmit && type.textarea.errors}"></textarea>
                            <div *ngIf="typesubmit && type.textarea.errors" class="invalid-feedback">
                                <span *ngIf="type.textarea.errors.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <div>
                            <button type="submit" class="btn btn-primary waves-effect waves-light mr-1">
                                Submit
                            </button>
                            <button type="reset" class="btn btn-secondary waves-effect">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-lg-6">
            <div class="card-box">
                <h4 class="header-title">Range validation</h4>
                <p class="sub-header">
                    Parsley is a javascript form validation library. It helps you provide your users with feedback on
                    their form submission before sending it to your server.
                </p>
                <form (ngSubmit)="rangeSubmit()" [formGroup]="rangeValidationForm">
                    <div class="form-group">
                        <label>Min Length</label>
                        <div>
                            <input type="text" class="form-control" formControlName="minlength"
                                [ngClass]="{'is-invalid': rangesubmit && range.minlength.errors}"
                                placeholder="Min 6 chars." />
                            <div *ngIf="rangesubmit && range.minlength.errors" class="invalid-feedback">
                                <span *ngIf="range.minlength.errors.required">This value is required.</span>
                                <span *ngIf="range.minlength.errors.minlength">This value is too short. It should have 6
                                    characters or more.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Max Length</label>
                        <div>
                            <input type="text" class="form-control" formControlName="maxlength"
                                [ngClass]="{'is-invalid': rangesubmit && range.maxlength.errors}"
                                placeholder="Max 6 chars." />
                            <div *ngIf="rangesubmit && range.maxlength.errors" class="invalid-feedback">
                                <span *ngIf="range.maxlength.errors.required">This value is required.</span>
                                <span *ngIf="range.maxlength.errors.maxlength">This value is too long. It should have 6
                                    characters or fewer.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Range Length</label>
                        <div>
                            <input type="text" class="form-control" formControlName="rangelength"
                                [ngClass]="{'is-invalid': rangesubmit && range.rangelength.errors}"
                                placeholder="Text between 5 - 10 chars length" />
                            <div *ngIf="rangesubmit && range.rangelength.errors" class="invalid-feedback">
                                <span *ngIf="range.rangelength.errors.required">This value is required.</span>
                                <span *ngIf="range.rangelength.errors.minlength">This value length is invalid. It should
                                    be between 5 and 10 characters long.</span>
                                <span *ngIf="range.rangelength.errors.maxlength">This value length is invalid. It should
                                    be between 5 and 10 characters long.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Min Value</label>
                        <div>
                            <input type="text" class="form-control" formControlName="minvalue"
                                [ngClass]="{'is-invalid': rangesubmit && range.minvalue.errors}"
                                placeholder="Min value is 6" />
                            <div *ngIf="rangesubmit && range.minvalue.errors" class="invalid-feedback">
                                <span *ngIf="range.minvalue.errors.required">This value is required.</span>
                                <span *ngIf="range.minvalue.errors.min">This value should be greater than or equal to
                                    6.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Max Value</label>
                        <div>
                            <input type="text" class="form-control" formControlName="maxvalue"
                                [ngClass]="{'is-invalid': rangesubmit && range.maxvalue.errors}"
                                placeholder="Max value is 6" />
                            <div *ngIf="rangesubmit && range.maxvalue.errors" class="invalid-feedback">
                                <span *ngIf="range.maxvalue.errors.required">This value is required.</span>
                                <span *ngIf="range.maxvalue.errors.max">This value should be lower than or equal to
                                    6.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Range Value</label>
                        <div>
                            <input class="form-control" type="text range" formControlName="rangevalue"
                                [ngClass]="{'is-invalid': rangesubmit && range.rangevalue.errors}"
                                placeholder="Number between 6 - 100" />
                            <div *ngIf="rangesubmit && range.rangevalue.errors" class="invalid-feedback">
                                <span *ngIf="range.rangevalue.errors.required">This value is required.</span>
                                <span *ngIf="range.rangevalue.errors.min">This value should be between 6 and 100.</span>
                                <span *ngIf="range.rangevalue.errors.max">This value should be between 6 and 100.</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Regular Exp</label>
                        <div>
                            <input type="text" class="form-control" formControlName="regularexp"
                                [ngClass]="{'is-invalid': rangesubmit && range.regularexp.errors}"
                                placeholder="Hex. Color" />
                            <div *ngIf="rangesubmit && range.regularexp.errors" class="invalid-feedback">
                                <span *ngIf="range.regularexp.errors.required">This value is required.</span>
                                <span *ngIf="range.regularexp.errors.pattern">This value seems to be invalid.</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-0">
                        <div>
                            <button type="submit" class="btn btn-primary waves-effect waves-light mr-1">
                                Submit
                            </button>
                            <button type="reset" class="btn btn-secondary waves-effect">
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>