<div class="account-pages mt-5 mb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card">
                    <div class="card-body p-4">
                        <!-- Logo-->
                        <div class="text-center w-75 m-auto">
                            <a routerLink="/account/login">
                                <img alt="" height="28" src="../../../../assets/images/logo-icon/logo-aivis-dark.png"/>
                            </a>
                            <p class="text-muted mb-4 mt-3">Don't have an account? Create your own account, it takes
                                less than a minute</p>
                        </div>

                        <form (ngSubmit)="onSubmit()" [formGroup]="changePasswordForm" class="needs-validation"
                              name="signupForm">

                            <app-ui-preloader [display]="loading"></app-ui-preloader>

                            <ngb-alert *ngIf="error" [dismissible]="false" type="danger">{{ error }}</ngb-alert>

                            <div class="form-group mb-3">
                                <label for="pass">Old password<span class="text-danger">*</span></label>
                                <input [ngClass]="{'is-invalid': submitted && f.oldPass.errors}" class="form-control"
                                       id="pass" formControlName="oldPass"
                                       placeholder="Password" type="password">
                                <div *ngIf="submitted && f.oldPass.errors" class="invalid-feedback">
                                    <span *ngIf="f.oldPass.errors.required">Please enter old password.</span>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label for="pass1">New password<span class="text-danger">*</span></label>
                                <input [ngClass]="{'is-invalid': submitted && f.newPass.errors}" class="form-control"
                                       formControlName="newPass" id="pass1"
                                       placeholder="Password" type="password">
                                <div *ngIf="submitted && f.newPass.errors" class="invalid-feedback">
                                    <span *ngIf="f.newPass.errors.required">Please enter new newPass.</span>
                                    <span *ngIf="f.newPass.errors.minlength">Password must be at least 6 characters.</span>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label>Confirm new Password <span class="text-danger">*</span></label>
                                <input [ngClass]="{'is-invalid': submitted && (f.confirmpwd.errors || f.confirmpwd.value !== f.newPass.value)}"
                                       class="form-control" formControlName="confirmpwd"
                                       id="passWord2"
                                       placeholder="Password" type="password">
                                <div *ngIf="submitted" class="invalid-feedback">
                                    <span *ngIf="!f.confirmpwd.value">Please enter confirm password.</span>
                                    <span *ngIf="(f.confirmpwd.value !== f.newPass.value) && f.confirmpwd.value">This value should be the same.</span>
                                </div>
                            </div>

                            <div class="form-group mb-0 text-center">
                                <button class="btn btn-primary btn-block" type="submit">Change password</button>
                            </div>
                        </form>
                    </div> <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="row mt-3">
                    <div class="col-12 text-center">
                        <p class="text-muted">
                            <a class="text-muted ml-1" routerLink="/account/reset-password">
                                <b>Forgot password ?</b>
                            </a>
                        </p>
                    </div> <!-- end col-->
                </div>
                <!-- end row -->

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
    2021 © Aivis - <span class="text-primary">Stay in the corner, control your world</span>
</footer>
