import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';

import {Inbox} from './rightsidebar.model';

import {inboxData} from './data';
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'app-rightsidebar',
    templateUrl: './rightsidebar.component.html',
    styleUrls: ['./rightsidebar.component.scss']
})
export class RightsidebarComponent implements OnInit, OnChanges {

    @Output() clickGetRecordVideo = new EventEmitter();
    @Input() listTimeData = [];
    @Input() ngDateChoose: NgbDate;

    inboxData: Inbox[];

    timelineData = [
        // {
        //     button: 'Today',
        // },
        // {
        //     time: '10 hours ago',
        //     description: '3 new photo Uploaded on facebook fan page',
        //     align: 'left',
        //     image: ['assets/images/small/img-1.jpg', 'assets/images/small/img-2.jpg', 'assets/images/small/img-3.jpg'],
        //     minute: '08:25 am'
        // }
    ];

    constructor() {
    }

    ngOnInit() {
        /**
         * fetches data
         */
        this._fetchData();

    }

    ngOnChanges() {
        // console.log('listTimeData', this.listTimeData)
        if (this.listTimeData.length > 0) {
            const arr = this.listTimeData.map((x, i) => {
                if (i % 2 === 0) {
                    return {
                        align: 'left',
                        time: x.content,
                    };
                }
                return {
                    align: 'right',
                    time: x.content,
                };
            });
            // @ts-ignore
            arr.unshift({button: this.ngDateChoose.day + '/' + this.ngDateChoose.month + '/' + this.ngDateChoose.year});
            this.timelineData = arr;
            // console.log('timelineData', arr);
        } else {
            this.timelineData = [];
        }
    }

    /**
     * Hide the sidebar
     */
    public hide() {
        document.body.classList.remove('right-bar-enabled');
    }

    getRecordVideo(data: any) {
        this.clickGetRecordVideo.emit(data);
        this.hide();
    }

    /**
     * fetches the inbox value
     */
    private _fetchData() {
        this.inboxData = inboxData;
    }
}
