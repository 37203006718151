import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from '../services/auth.service';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            // if (err.status === 401 && this.authenticationService.currentUser().token) {
            //     // auto logout if 401 response returned from api
            //     this.authenticationService.resetToken(this.authenticationService.currentUser().refreshToken).subscribe();
            //     // this.router.navigate(['/account/login']);
            //     return;
            //     // location.reload();
            // }
            const error = err.error.message || err.statusText;

            switch (err.status) {
                case 401:
                    if (this.router.url.search('camera-monitor') === 1) {
                        this.authenticationService.resetToken(this.authenticationService.currentUser().refreshToken).subscribe();
                    } else {
                        this.authenticationService.logout();
                        this.router.navigate(['/account/login']);
                        Swal.fire({
                            type: 'error',
                            title: 'Lỗi',
                            text: 'Hết phiên đăng nhập, vui lòng đăng nhập lại.',
                            confirmButtonClass: 'btn btn-confirm mt-2',
                        });
                    }
                    break;
                default:
                    if (err.error.message !== 'timeout') {
                        Swal.fire({
                            type: 'error',
                            title: 'Lỗi',
                            text: 'Có lỗi sảy ra, vui lòng thử lại.',
                            confirmButtonClass: 'btn btn-confirm mt-2',
                            // footer: '<a>Why do I have this issue?</a>'
                        });
                    }
                    break;
            }

            console.log('err: ', err);
            return throwError(error);

        }));
    }
}
