<div class="container-fluid">
  <app-page-title title="Basic Tables" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Basic example</h4>
        <p class="sub-header">
          For basic styling—light padding and only horizontal dividers—add the base class <code>.table</code> to any
          <code>&lt;table&gt;</code>.
        </p>

        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Inverse table</h4>
        <p class="sub-header">
          You can also invert the colors—with light text on dark backgrounds—with <code
            class="highlighter-rouge">.table-dark</code>.
        </p>

        <div class="table-responsive">
          <table class="table table-dark mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> <!-- end card-box -->
    </div> <!-- end col -->
  </div>
  <!--- end row -->


  <div class="row">
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Table head options</h4>
        <p class="sub-header">
          Use one of two modifier classes to make <code>&lt;thead&gt;</code>s appear light or dark gray.
        </p>

        <div class="table-responsive">
          <table class="table mb-0">
            <thead class="thead-light">
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Striped rows</h4>
        <p class="sub-header">
          Use <code>.table-striped</code> to add zebra-striping to any table row
          within the <code>&lt;tbody&gt;</code>.
        </p>

        <div class="table-responsive">
          <table class="table table-striped mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->
  </div>
  <!-- end row -->


  <div class="row">
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Bordered table</h4>
        <p class="sub-header">
          Add <code>.table-bordered</code> for borders on all sides of the table and cells.
        </p>

        <div class="table-responsive">
          <table class="table table-bordered mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@TwBootstrap</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end .table-responsive-->
      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Hoverable rows</h4>
        <p class="sub-header">
          Create responsive tables by wrapping any <code>.table</code> in <code>.table-responsive</code>
          to make them scroll horizontally on small devices (under 768px).
        </p>

        <div class="table-responsive">
          <table class="table table-hover mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Small table</h4>
        <p class="sub-header">
          Add <code>.table-sm</code> to make tables more compact by cutting cell padding in half.
        </p>

        <div class="table-responsive">
          <table class="table table-sm mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">7</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">8</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">9</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Contextual classes</h4>
        <p class="sub-header">
          Use contextual classes to color table rows or individual cells.
        </p>

        <div class="table-responsive">
          <table class="table mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>Column heading</th>
                <th>Column heading</th>
                <th>Column heading</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-active">
                <th scope="row">1</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr class="table-success">
                <th scope="row">3</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr class="table-info">
                <th scope="row">5</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr class="table-warning">
                <th scope="row">7</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr>
                <th scope="row">8</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
              <tr class="table-danger">
                <th scope="row">9</th>
                <td>Column content</td>
                <td>Column content</td>
                <td>Column content</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->
  </div>
  <!-- end row -->


  <div class="row">
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Table head options</h4>
        <p class="sub-header">
          Similar to tables and dark tables, use the modifier classes <code
            class="highlighter-rouge">.thead-light</code> or <code class="highlighter-rouge">.thead-dark</code> to make
          <code class="highlighter-rouge">&lt;thead&gt;</code>s appear light or dark gray.
        </p>

        <div class="table-responsive">
          <table class="table mb-0">
            <thead class="thead-dark">
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Contextual classes with Background color</h4>
        <p class="sub-header">
          You can also invert the colors—with light text on dark backgrounds—with <code
            class="highlighter-rouge">.table-dark</code>.
        </p>

        <div class="table-responsive">
          <table class="table table-dark text-white mb-0">
            <thead>
              <tr class="">
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr class="bg-primary">
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr class="bg-custom">
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr class="bg-pink">
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->
  </div>
  <!--- end row -->

  <div class="row">
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Borderless table</h4>
        <p class="sub-header">
          For basic styling—light padding and only horizontal dividers—add the base class <code>.table</code> to any
          <code>&lt;table&gt;</code>.
        </p>

        <div class="table-responsive">
          <table class="table table-borderless mb-0">
            <thead class="thead-light">
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Borderless Inverse table</h4>
        <p class="sub-header">
          Your awesome text goes here.Your awesome text goes here.
        </p>

        <div class="table-responsive">
          <table class="table table-dark table-borderless mb-0">
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Username</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Larry</td>
                <td>the Bird</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end table-responsive-->

      </div> <!-- end card-box -->
    </div> <!-- end col -->
  </div>
  <!--- end row -->
</div>