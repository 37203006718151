<div class="container-fluid">
    <app-ui-preloader [display]="loading"></app-ui-preloader>

    <div class="row mt-2">
        <div class="col-12">

<!--            <button class="btn btn-primary" (click)="refreshCamera({cameraId: '1awq'}, 0, 0)">-->
<!--                Refresh 1-->
<!--            </button>-->

<!--            <button class="btn btn-primary" (click)="refreshCamera({cameraId: '1awqe'}, 0, 1)">-->
<!--                Refresh 2-->
<!--            </button>-->

<!--            <button class="btn btn-primary" (click)="refreshCamera({cameraId: 'tin-dev'}, 1, 0)">-->
<!--                Refresh 3-->
<!--            </button>-->

            <button (click)="onClickShowFullScreen(eleScreen, $event)" class="float-right btn btn-primary">Toàn màn
                hình
            </button>
        </div>
    </div>

    <div #eleScreen class="row mt-3">
        <div class="col-12 p-0 m-0" id="cameraMonitor">
            <div *ngFor="let row of rows;" class="card-group">

                <div *ngFor="let item of listCamera[row]; let i = index;" class="card p-0 m-0">
                    <div [id]="item.cameraId" class="card-img-top">
                        <div *ngIf="!isShakeHands[row][i]" class="video-container">
                            <!--                            <video controls preload="metadata" width="100%" height="100%"></video>-->
                            <!--                            <img [src]="videoNotFoundImg" class="img-fluid">-->
                        </div>
                    </div>

                    <!--                    <p [id]="'log' + item.cameraId" class="card-text"></p>-->
                </div>

                <ng-container *ngIf="row === rows.length - 1">
                    <div *ngFor="let item of listFake;" class="card p-0 m-0">
                        <div class="card-img-top">
                            <div class="video-container">
                                <!--                                <video controls preload="metadata" width="100%"></video>-->
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
