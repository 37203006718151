import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {CookieService} from './cookie.service';
import {User} from '../models/auth.models';
import {CommandURL} from '../../shared/constant/ManageURLCommand';
import {EnvService} from '../../../env.service';
import {base64EncodeUnicode} from '../../shared/utils/convert.util';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    user: User;
    env: string;

    constructor(private http: HttpClient,
                private cookieService: CookieService,
                private envService: EnvService,
    ) {
        setTimeout(() => {
            const apiUrl = this.envService.getEnvironment() || this.cookieService.getCookie('environment');
            this.env = 'https://' + apiUrl + '/';
        }, 800);
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        // if (!this.user) {
        this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
        // }
        return this.user;
    }

    checkUsername(username: string) {
        const params = new HttpParams().set('username', username);
        return this.http.get<any>(this.env + CommandURL.CHECK_USERNAME_URL, {params});
    }

    changePassword(oldPass: string, newPass: string) {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.currentUser()}`);
        return this.http.post(this.env + CommandURL.CHANGE_PASSWORD_URL, {oldPass, newPass}, {headers});
    }

    getTokenVerifyByEmail(username: string, email: string) {
        return this.http.post(this.env + CommandURL.CHANGE_PASSWORD_VERIFY_EMAIL_TOKEN_URL, {username, email});
    }

    getTokenVerifyByPhone(username: string, phone: string) {
        return this.http.post(this.env + CommandURL.CHANGE_PASSWORD_VERIFY_PHONE_TOKEN_URL, {username, phone});
    }

    signup(username: string, password: string) {
        return this.http.post(this.env + CommandURL.SIGNUP_URL, {username, password});
    }

    resetPassword(username: string, token: string, newPass: string) {
        return this.http.post(this.env + CommandURL.RESET_PASSWORD_URL, {username, token, newPass});
    }

    // tslint:disable-next-line:variable-name
    resetToken(refresh_token: string) {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${this.currentUser()}`);
        return this.http.post<any>(this.env + CommandURL.RESET_TOKEN_URL, {refresh_token}, {headers})
            .pipe(map(res => {
                const jsonRes = JSON.parse(JSON.stringify(res));
                const user = {
                    ...this.currentUser(),
                    token: jsonRes.access_token
                };
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    this.user = user;
                    // store user details and jwt in cookie

                    // base64EncodeUnicode(JSON.stringify(payload))
                    this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);

                }

                return user;
            }));
    }

    /**
     * Performs the auth
     * @param username email of user
     * @param password password of user
     */
    login(username: string, password: string, rememberDay: number) {
        // return this.http.post<any>(`/api/login`, { email, password })
        //     .pipe(map(user => {
        //         // login successful if there's a jwt token in the response
        //         if (user && user.token) {
        //             this.user = user;
        //             // store user details and jwt in cookie
        //             this.cookieService.setCookie('currentUser', JSON.stringify(user), 1);
        //         }
        //         console.log('user without api: ', user);
        //         return user;
        //     }));

        return this.http.post<any>(this.env + CommandURL.LOGIN_URL, {username, password})
            .pipe(map(res => {
                const jsonRes = JSON.parse(JSON.stringify(res));
                const user = {
                    token: jsonRes.access_token,
                    refreshToken: jsonRes.refresh_token
                };
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    this.user = user;
                    // store user details and jwt in cookie
                    if (rememberDay > 0) {
                        // base64EncodeUnicode(JSON.stringify(payload))
                        this.cookieService.setCookie('currentUser', JSON.stringify(user), rememberDay);
                    }
                }

                return user;
            }));
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        this.user = null;
    }
}

