<div class="container-fluid">
        <app-page-title title="Notifications" [breadcrumbItems]="breadCrumbItems"></app-page-title>
    
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
    
                        <h4 class="header-title">Bootstrap Toasts</h4>
                        <p class="text-muted">Push notifications to your visitors with a toast, a lightweight and easily
                            customizable alert message.</p>
    
                        <div class="row">
                            <div class="col-md-6">
                                <h5 class="mb-2">Basic</h5>
                                <p class="text-muted">Toasts are as flexible as you need and have very little required
                                    markup. At a minimum, we
                                    require a single element to contain your “toasted” content and strongly encourage a
                                    dismiss button.</p>
                                <!-- Toast -->
                                <ngb-toast *ngIf="show" [autohide]="false" (hide)="closeToast()">
                                    <ng-template ngbToastHeader>
                                        <img src="assets/images/users/avatar-2.jpg" alt="brand-logo" height="28"
                                            class="mr-1 rounded" />
                                        <strong class="mr-auto">Alfredo</strong>
                                        <small>11 mins ago</small>
                                    </ng-template>
                                    Hello, world! This is a toast message.
                                </ngb-toast>
                                <!-- End toast -->
                            </div>
                            <div class="col-md-6">
                                <h5 class="mb-2">Translucent</h5>
                                <p class="text-muted">Toasts are slightly translucent, too, so they blend over whatever they
                                    might appear over.
                                    For browsers that support the backdrop-filter CSS property, we’ll also attempt to blur
                                    the elements under a toast.</p>
    
                                <div class="p-3 bg-secondary">
                                    <!-- Toast -->
                                    <ngb-toast *ngIf="toast2" [autohide]="false" (hide)="closeToast1()">
                                        <ng-template ngbToastHeader>
                                            <img src="assets/images/users/avatar-3.jpg" alt="brand-logo" height="28"
                                                class="mr-1 rounded" />
                                            <strong class="mr-auto">Andrew</strong>
                                            <small>11 mins ago</small>
                                        </ng-template>
                                        Hello, world! This is a toast message.
                                    </ngb-toast>
                                    <!--end toast-->
                                </div>
                            </div> <!-- end col-->
                        </div>
                        <div class="row">
                            <div class="col-md-6 mt-4">
                                <h5 class="mb-2">Stacking</h5>
                                <p class="text-muted">When you have multiple toasts, we default to vertiaclly stacking them
                                    in a readable manner.</p>
                                <div class="p-3">
                                    <div aria-live="polite" aria-atomic="true"
                                        style="position: relative; min-height: 200px;">
                                        <!-- Position it -->
                                        <div style="position: absolute; top: 0; right: 0;">
                                            <!-- Toast -->
                                            <ngb-toast *ngIf="toast3" [autohide]="false" (hide)="closeToast2()">
                                                <ng-template ngbToastHeader>
                                                    <img src="assets/images/users/avatar-4.jpg" alt="brand-logo" height="28"
                                                        class="mr-1 rounded" />
                                                    <strong class="mr-auto">Richard</strong>
                                                    <small>just now</small>
                                                </ng-template>
                                                See? Just like this.
                                            </ngb-toast>
                                            <ngb-toast *ngIf="toast4" [autohide]="false" (hide)="closeToast3()">
                                                <ng-template ngbToastHeader>
                                                    <img src="assets/images/users/avatar-5.jpg" alt="brand-logo" height="28"
                                                        class="mr-1 rounded" />
                                                    <strong class="mr-auto">Edward</strong>
                                                    <small>2 seconds ago</small>
                                                </ng-template>
                                                Heads up, toasts will stack automatically
                                            </ngb-toast>
                                            <!-- End toast -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mt-4">
                                <h5 class="mb-2">Placement</h5>
                                <p class="text-muted">Place toasts with custom CSS as you need them. The top right is often
                                    used for
                                    notifications, as is the top middle. If you’re only ever going to show one toast at a
                                    time, put the positioning
                                    styles right on the <code>.toast</code>.</p>
                                <div class="p-3">
                                    <div aria-live="polite" aria-atomic="true"
                                        class="d-flex justify-content-center align-items-center" style="min-height: 200px;">
                                        <!-- Toast -->
                                        <ngb-toast *ngIf="toast5" [autohide]="false" (hide)="closeToast4()">
                                            <ng-template ngbToastHeader>
                                                <img src="assets/images/users/avatar-6.jpg" alt="brand-logo" height="28"
                                                    class="mr-1 rounded" />
                                                <strong class="mr-auto">Ronnie</strong>
                                                <small>11 mins ago</small>
                                            </ng-template>
                                            Hello, world! This is a toast message.
                                        </ngb-toast>
                                        <!-- End toast -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <!-- Default alert -->
                <div class="card-box">
                    <h4 class="header-title">Default Alert</h4>
                    <p class="sub-header">
                        Alerts are available for any length of text, as well as an optional dismiss button. For proper
                        styling, use one of the eight
                        <strong>required</strong> contextual classes (e.g., <code>.alert-success</code>).
                    </p>
                    <div *ngFor="let color of alertData">
                        <ngb-alert [dismissible]="false" type="{{color.color}}"><i class="{{color.icon}} mr-1"></i> This is
                            a <strong>{{color.color}}</strong>
                            alert—check it out!</ngb-alert>
                    </div>
                </div>
                <!-- End default alert -->
            </div>
            <div class="col-lg-6">
                <!-- Dismissing alert -->
                <div class="card-box">
                    <h4 class="header-title">Dismissing Alerts</h4>
                    <p class="sub-header">
                        Add a dismiss button and the <code>.alert-dismissible</code> class, which adds extra padding
                        to the right of the alert and positions the <code>.close</code> button.
                    </p>
                    <div *ngFor="let color of alertData2">
                        <ngb-alert type="{{color.color}}" (close)="close(color, alertData2)">This is a
                            <strong>{{color.color}}</strong>
                            alert—check it out!</ngb-alert>
                    </div>
                </div>
                <!-- End dismissing alert -->
            </div>
        </div>
    
        <div class="row">
            <div class="col-lg-6">
                <!-- Custom alert -->
                <div class="card-box">
                    <h4 class="header-title">Custom outline Alert</h4>
                    <p class="sub-header">
                        Use <code>.bg-white</code>, <code>.text-*</code> classes.
                    </p>
                    <div *ngFor="let color of customAlert">
                        <ngb-alert class="alert alert-{{color.color}} bg-white text-{{color.color}}" type="{{color.color}}"
                            (close)="close(color, customAlert)">This is a
                            <strong>{{color.color}}</strong>
                            alert—check it out!</ngb-alert>
                    </div>
                </div>
                <!-- End custom alert -->
            </div>
            <div class="col-lg-6">
                <!-- Custom background -->
                <div class="card-box">
                    <h4 class="header-title">Custom Background Alert</h4>
                    <p class="sub-header">
                        Use <code>.bg-*</code>,<code>.border-0</code> classes.
                    </p>
                    <div *ngFor="let color of customAlert2">
                        <ngb-alert (close)="close(color, customAlert2)" class="alert text-white bg-{{color.color}}">This is
                            a
                            <strong>{{color.color}}</strong>
                            alert—check it out!</ngb-alert>
                    </div>
                </div>
                <!-- End custom background -->
            </div>
        </div>
    </div>