import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

import {AuthenticationService} from '../services/auth.service';
import {CookieService} from '../services/cookie.service';
import {EnvService} from '../../../env.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private cookieService: CookieService,
                private envService: EnvService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const currentUser = this.authenticationService.currentUser();
        const apiUrl = this.envService.getEnvironment() || this.cookieService.getCookie('environment');
        if (request.url.search('//') === -1) {
            request = request.clone({
                url: 'https://' + apiUrl + '/' + request.url
            });
        }

        if (request.url.search('assets/i18n/') !== -1) {
            request = request.clone({
                url: request.url.substring(request.url.lastIndexOf('//') + 1, request.url.length)
            });
        }

        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`,
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
                }
            });
        }

        return next.handle(request);
    }
}
