import {Component, Input, OnInit} from '@angular/core';
import {UpdateFirmwareService} from './update-firmware.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-update-firmware',
    templateUrl: './update-firmware.component.html',
    styleUrls: ['./update-firmware.component.scss']
})
export class UpdateFirmwareComponent implements OnInit {
    @Input() camId = '';
    model = '';
    version = '';
    firmwareVersionList = [];

    constructor(private updateFirmwareService: UpdateFirmwareService) {
    }

    ngOnInit() {
        this.getCurrent();
    }

    getCurrent() {
        this.updateFirmwareService.getCurrentFirmwareVerion(this.camId).subscribe(data => {
            if (data) {
                this.model = data.model || 'Chưa cập nhật';
                this.version = data.version || 'Chưa cập nhật';
                this.getListFirmware();
            }
        });
    }

    getListFirmware() {
        this.updateFirmwareService.getListFirmwareVersion(this.model).subscribe(data => {
            if (data) {
                this.firmwareVersionList = data.list || [];
            }
        });
    }

    updateFirmware(version: string) {
        Swal.fire({
            title: '<h3>Bạn muốn cập nhật phiên bản ' + '<span class="text-primary">' + version + '</span></h3>',
            text: 'Việc cập nhật sẽ mất vài phút, trong thời gian này camera sẽ không hoạt động.',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Đồng ý',
            cancelButtonText: 'Từ chối'
        }).then((result) => {
            if (result.value) {
                this.updateFirmwareService.updateFirmwareVerion(this.camId, version).subscribe(data => {
                    if (data) {
                        this.success();
                    }
                });
            }
        });
    }

    success = () => {
        Swal.fire({
            title: 'Thành công!',
            text: 'Phiên bản firmware hiện đang được cập nhật.',
            type: 'success',
            showConfirmButton: false,
        });
    }
}
