<div class="container-fluid">
  <app-page-title title="File Manager" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <div class="fileupload btn btn-success waves-effect waves-light mb-3">
          <span><i class="mdi mdi-cloud-upload mr-1"></i> Upload Files</span>
          <form> <input type="file" class="upload" (change)="getFileDetails($event)"></form>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table table-centered mb-0">
            <thead class="font-13 bg-light text-muted">
              <tr>
                <th class="font-weight-medium">File Name</th>
                <th class="font-weight-medium">Date Modified</th>
                <th class="font-weight-medium">Size</th>
                <th class="font-weight-medium">Contributors</th>
                <th class="font-weight-medium text-center" style="width: 125px;">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tableData of fileManagerData">
                <td>
                  <img src="{{ tableData.icon }}" *ngIf="tableData.icon" height="30" alt="icon" class="mr-2">
                  <a href="javascript:void(0);" class="text-dark">{{tableData.name}}</a>
                </td>
                <td class="text-muted font-13">{{tableData.date | date: 'd-MMM-yy h:mm a'}}</td>
                <td>{{tableData.size}}</td>
                <td>
                  <img src="{{ tableData.users[0] }}" alt="task-user" class="avatar-sm img-thumbnail rounded-circle">
                  <img src="{{ tableData.users[1] }}" *ngIf="tableData.users[1]" alt="task-user"
                    class="avatar-sm img-thumbnail rounded-circle">
                  <img src="{{ tableData.users[2] }}" *ngIf="tableData.users[2]" alt="task-user"
                    class="avatar-sm img-thumbnail rounded-circle">
                </td>
                <td>
                  <a href="javascript:void(0);" class="font-18 text-muted btn-sm">
                    <i class="mdi mdi-close"></i>
                  </a>
                  <a href="javascript:void(0);" class="font-18 text-muted btn-sm">
                    <i class="mdi mdi-pencil"></i>
                  </a>
                </td>
              </tr>
          </table>
        </div>
        <!-- End table -->
      </div>
    </div>
  </div>

</div>