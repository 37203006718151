<div class="container-fluid">
  <app-page-title title="Email Compose" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">

    <!-- Right Sidebar -->
    <div class="col-12">
      <div class="card-box">
        <!-- Left sidebar -->
        <div class="inbox-leftbar">
          <a routerLink="/email-inbox" class="btn btn-danger btn-block waves-effect waves-light mb-4">Inbox</a>
          <app-emaillist></app-emaillist><!-- Leftbar email label and list -->
        </div>
        <!-- End Left sidebar -->

        <!-- Right sidebar -->
        <div class="inbox-rightbar">
          <app-widget></app-widget><!-- Button group -->
          <div class="mt-4">
            <form>
              <div class="form-group">
                <input type="email" class="form-control" placeholder="To">
              </div>

              <div class="form-group">
                <input type="text" class="form-control" placeholder="Subject">
              </div>
              <div class="form-group">
                  <!-- Editor -->
<!--                  <app-ngx-editor [placeholder]="'Enter text '" [spellcheck]="true" [height]="'250px'"></app-ngx-editor>-->
              </div>

              <div class="form-group m-b-0">
                  <div class="text-right">
                      <button type="button" class="btn btn-success waves-effect waves-light m-r-5"><i class="mdi mdi-content-save-outline"></i></button>
                      <button type="button" class="btn btn-success waves-effect waves-light m-r-5 ml-1"><i class="mdi mdi-delete"></i></button>
                      <button class="btn btn-primary waves-effect waves-light ml-1"> <span>Send</span> <i class="mdi mdi-send ml-2"></i> </button>
                  </div>
              </div>
            </form>
          </div>
        </div>
        <!-- End Right Sidebar -->
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>
