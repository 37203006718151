

// export class BaseURL {
//     public static API_URL = environment.API_URL;
//     public static API_USER = environment.API_URL + 'user/';
//     public static API_CAMERA = environment.API_URL + 'camera/';
//     public static API_MANAGER = environment.API_URL + 'manager/';
// }


export class BaseURL {
    public static API_URL = '';
    public static API_USER = 'user/';
    public static API_USER_CAMERA = 'user-camera/';
    public static API_CAMERA = 'camera/';
    public static API_MANAGER = 'manager/';
    public static API_MANAGER_CAMERA = 'manager-camera/';
}

export class CommandURL {
    public static LOGIN_URL = BaseURL.API_URL + 'auth/';
    public static RESET_TOKEN_URL = BaseURL.API_URL + 'auth/refresh';
    public static RESET_PASSWORD_URL = BaseURL.API_USER + 'reset-password/';
    // USER
    public static SIGNUP_URL = BaseURL.API_USER + 'registry/';
    public static CHANGE_PASSWORD_URL = BaseURL.API_USER + 'change-password/';
    public static CHECK_USERNAME_URL = BaseURL.API_USER + 'check-username';
    public static GET_LIST_CAMERA_URL = BaseURL.API_USER + 'get-list-camera/';
    public static GET_LIST_CAMERA_WITH_SETTING_URL = BaseURL.API_USER_CAMERA + 'get-list-camera/';
    public static REQUEST_STREAM_SIGNAL_URL = BaseURL.API_USER + 'request-stream-signal/';
    public static REQUEST_STREAM_SIGNAL_WITH_TURN_URL = BaseURL.API_USER + 'request-stream-signal-with-turn/';
    public static GET_PERSONAL_INFO_URL = BaseURL.API_USER + 'personal-info/';
    public static UPDATE_PERSONAL_INFO_URL = BaseURL.API_USER + 'update-personal-info/';
    public static UPDATE_EMAIL_INFO_URL = BaseURL.API_USER + 'update-email/';
    public static UPDATE_PHONE_INFO_URL = BaseURL.API_USER + 'update-phone/';

    public static VERIFY_EMAIL_TOKEN_URL = BaseURL.API_USER + 'verify-email/';
    public static VERIFY_PHONE_TOKEN_URL = BaseURL.API_USER + 'verify-phone/';
    public static CHANGE_PASSWORD_VERIFY_EMAIL_TOKEN_URL = CommandURL.RESET_PASSWORD_URL + 'email/';
    public static CHANGE_PASSWORD_VERIFY_PHONE_TOKEN_URL = CommandURL.RESET_PASSWORD_URL + 'phone/';

    // CAMERA
    public static GET_LIST_VIDEO_URL = BaseURL.API_CAMERA + 'get-video-list';
    public static GET_RECORD_VIDEO_URL = BaseURL.API_CAMERA + 'get-record-video';
    public static GET_EVENT_LIST_URL = BaseURL.API_CAMERA + 'get-event-list';
    public static GET_EVENT_LIST_FILTER_URL = BaseURL.API_CAMERA + 'get-event-list-filter-by';
    public static PREPARE_DETECT_OBJ_FOR_EVENT_URL = BaseURL.API_CAMERA + 'prepare-detect-object-for-event';
    public static SET_MOTION_ZONE_URL = BaseURL.API_CAMERA + 'set-motion-zone';
    public static SET_MOTION_ZONE_RATIO_URL = BaseURL.API_CAMERA + 'set-motion-zone-ratio';
    public static GET_LATEST_IMAGE_URL = BaseURL.API_CAMERA + 'get-latest-image';
    public static SET_ALARM_STATUS_URL = BaseURL.API_CAMERA + 'set-alarm-status';
    public static GET_SETTING_CAMERA_URL = BaseURL.API_CAMERA + 'camera-setting';
    public static GET_SETTING_CAMERA_CALIB_MOTION_ZONE_URL = BaseURL.API_CAMERA + 'camera-setting-calib-motion-zone';
    public static SET_IR_STATUS_URL = BaseURL.API_CAMERA + 'set-ir-status';
    public static GET_CURRENT_FIRMWARE_VERSION_URL = BaseURL.API_CAMERA + 'get-current-firmware-version';
    public static UPDATE_FIRMWARE_URL = BaseURL.API_CAMERA + 'update-firmware';
    public static GET_CURRENT_NETWORK_URL = BaseURL.API_CAMERA + 'get-current-network';
    public static GET_WIFI_LIST_URL = BaseURL.API_CAMERA + 'get-wifi-list';
    public static CONNECT_WIFI_URL = BaseURL.API_CAMERA + 'connect-wifi';
    public static GET_EARLIEST_RECORD_DATE_URL = BaseURL.API_CAMERA + 'earliest-record-date';
    public static GET_VIDEO_LIST_BEFORE_URL = BaseURL.API_CAMERA + 'get-video-list-before';
    public static GET_VIDEO_LIST_AFTER_URL = BaseURL.API_CAMERA + 'get-video-list-after';
    public static RESTART_CAMERA_URL = BaseURL.API_CAMERA + 'restart-program';
    public static RESBOOT_CAMERA_URL = BaseURL.API_CAMERA + 'reboot';
    public static SET_INTRUCSION_ALARM_STATUS_URL = BaseURL.API_CAMERA + 'set-intrusion-alarm-status';
    public static SET_INTRUCSION_ALARM_HOUR_URL = BaseURL.API_CAMERA + 'set-intrusion-alarm-hour';
    public static SET_INTRUCSION_ALARM_SOUND_URL = BaseURL.API_CAMERA + 'set-intrusion-alarm-sound';
    public static SET_INTRUCSION_ALARM_POPUP_NOTIFY_URL = BaseURL.API_CAMERA + 'set-intrusion-alarm-popup-notify';
    public static SET_INTRUCSION_ALARM_INCOMINGCALL_NOTIFY_URL = BaseURL.API_CAMERA + 'set-intrusion-alarm-incomingcall-notify';
    public static SET_CAM_PTZ_URL = BaseURL.API_CAMERA + 'set-ptz';
    // MANAGER
    public static GET_TURN_CREDENTIAL = BaseURL.API_MANAGER + 'get-turn-credential';
    public static GET_IP_PUBLIC = BaseURL.API_MANAGER + 'get-ip-public';
    public static GET_DOMAIN_BACKEND = BaseURL.API_MANAGER + 'get-domain-backend';
    public static SET_NAME_CAMERA = BaseURL.API_MANAGER + 'rename-camera';
    public static SHARE_CAMERA = BaseURL.API_MANAGER + 'share-camera';
    public static GET_LIST_CAMERA_SHARE = BaseURL.API_MANAGER + 'get-shared-list';
    public static REMOVE_CAMERA_SHARE = BaseURL.API_MANAGER + 'delete-shared-user';
    public static REMOVE_CAMERA_REL = BaseURL.API_MANAGER + 'delete-camera-rel';
    public static GET_LIST_FIRMWARE_URL = BaseURL.API_MANAGER + 'get-list-firmware-available';
    public static ADD_DEVICE_TOKEN = BaseURL.API_MANAGER + 'add-device-token';

    public static REMOVE_CAMERA_FACTORY = BaseURL.API_MANAGER_CAMERA + 'delete-and-reset-factory-camera';
}
