<div class="container-fluid">
  <app-page-title title="Remix Icons" [breadcrumbItems]="breadCrumbItems"></app-page-title>
  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Buildings</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-line"></i> remixicon-home-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-2-line"></i> remixicon-home-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-3-line"></i> remixicon-home-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-4-line"></i> remixicon-home-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-5-line"></i> remixicon-home-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-6-line"></i> remixicon-home-6-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-7-line"></i> remixicon-home-7-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-8-line"></i> remixicon-home-8-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-gear-line"></i> remixicon-home-gear-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-line"></i> remixicon-building-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-fill"></i> remixicon-home-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-2-fill"></i> remixicon-home-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-3-fill"></i> remixicon-home-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-4-fill"></i> remixicon-home-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-5-fill"></i> remixicon-home-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-6-fill"></i> remixicon-home-6-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-7-fill"></i> remixicon-home-7-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-8-fill"></i> remixicon-home-8-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-home-gear-fill"></i> remixicon-home-gear-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-fill"></i> remixicon-building-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-2-line"></i> remixicon-building-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-3-line"></i> remixicon-building-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-4-line"></i> remixicon-building-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-government-line"></i> remixicon-government-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bank-line"></i> remixicon-bank-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-store-line"></i> remixicon-store-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-store-2-line"></i> remixicon-store-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-store-3-line"></i> remixicon-store-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-2-fill"></i> remixicon-building-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-3-fill"></i> remixicon-building-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-building-4-fill"></i> remixicon-building-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-government-fill"></i> remixicon-government-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bank-fill"></i> remixicon-bank-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-store-fill"></i> remixicon-store-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-store-2-fill"></i> remixicon-store-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-store-3-fill"></i> remixicon-store-3-fill
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Business</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-line"></i> remixicon-mail-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-open-line"></i> remixicon-mail-open-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-send-line"></i> remixicon-mail-send-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-unread-line"></i> remixicon-mail-unread-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-inbox-line"></i> remixicon-inbox-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-inbox-archive-line"></i> remixicon-inbox-archive-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cloud-line"></i> remixicon-cloud-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-attachment-line"></i> remixicon-attachment-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-profile-line"></i> remixicon-profile-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-archive-line"></i> remixicon-archive-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-fill"></i> remixicon-mail-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-open-fill"></i> remixicon-mail-open-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-send-fill"></i> remixicon-mail-send-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mail-unread-fill"></i> remixicon-mail-unread-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-inbox-fill"></i> remixicon-inbox-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-inbox-archive-fill"></i> remixicon-inbox-archive-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cloud-fill"></i> remixicon-cloud-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-attachment-fill"></i> remixicon-attachment-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-profile-fill"></i> remixicon-profile-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-archive-fill"></i> remixicon-archive-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-archive-drawer-line"></i> remixicon-archive-drawer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-at-line"></i> remixicon-at-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-award-line"></i> remixicon-award-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bar-chart-line"></i> remixicon-bar-chart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bar-chart-2-line"></i> remixicon-bar-chart-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bar-chart-box-line"></i> remixicon-bar-chart-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bookmark-line"></i> remixicon-bookmark-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bookmark-2-line"></i> remixicon-bookmark-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bookmark-3-line"></i> remixicon-bookmark-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-line"></i> remixicon-briefcase-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-archive-drawer-fill"></i> remixicon-archive-drawer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-at-fill"></i> remixicon-at-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-award-fill"></i> remixicon-award-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bar-chart-fill"></i> remixicon-bar-chart-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bar-chart-2-fill"></i> remixicon-bar-chart-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bar-chart-box-fill"></i> remixicon-bar-chart-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bookmark-fill"></i> remixicon-bookmark-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bookmark-2-fill"></i> remixicon-bookmark-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bookmark-3-fill"></i> remixicon-bookmark-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-fill"></i> remixicon-briefcase-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-2-line"></i> remixicon-briefcase-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-3-line"></i> remixicon-briefcase-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-4-line"></i> remixicon-briefcase-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-5-line"></i> remixicon-briefcase-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calculator-line"></i> remixicon-calculator-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-line"></i> remixicon-calendar-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-2-line"></i> remixicon-calendar-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-event-line"></i> remixicon-calendar-event-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-todo-line"></i> remixicon-calendar-todo-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-customer-service-line"></i> remixicon-customer-service-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-2-fill"></i> remixicon-briefcase-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-3-fill"></i> remixicon-briefcase-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-4-fill"></i> remixicon-briefcase-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-briefcase-5-fill"></i> remixicon-briefcase-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calculator-fill"></i> remixicon-calculator-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-fill"></i> remixicon-calendar-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-2-fill"></i> remixicon-calendar-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-event-fill"></i> remixicon-calendar-event-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-calendar-todo-fill"></i> remixicon-calendar-todo-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-customer-service-fill"></i> remixicon-customer-service-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-customer-service-2-line"></i> remixicon-customer-service-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flag-line"></i> remixicon-flag-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flag-2-line"></i> remixicon-flag-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-global-line"></i> remixicon-global-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-honour-line"></i> remixicon-honour-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-links-line"></i> remixicon-links-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pie-chart-line"></i> remixicon-pie-chart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pie-chart-2-line"></i> remixicon-pie-chart-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pie-chart-box-line"></i> remixicon-pie-chart-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-printer-line"></i> remixicon-printer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-customer-service-2-fill"></i> remixicon-customer-service-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flag-fill"></i> remixicon-flag-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flag-2-fill"></i> remixicon-flag-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-global-fill"></i> remixicon-global-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-honour-fill"></i> remixicon-honour-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-links-fill"></i> remixicon-links-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pie-chart-fill"></i> remixicon-pie-chart-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pie-chart-2-fill"></i> remixicon-pie-chart-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pie-chart-box-fill"></i> remixicon-pie-chart-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-printer-fill"></i> remixicon-printer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-record-mail-line"></i> remixicon-record-mail-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-reply-line"></i> remixicon-reply-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-send-plane-line"></i> remixicon-send-plane-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-send-plane-2-line"></i> remixicon-send-plane-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-projector-line"></i> remixicon-projector-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-projector-2-line"></i> remixicon-projector-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-line"></i> remixicon-slideshow-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-2-line"></i> remixicon-slideshow-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-3-line"></i> remixicon-slideshow-3
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-4-line"></i> remixicon-slideshow-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-record-mail-fill"></i> remixicon-record-mail-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-reply-fill"></i> remixicon-reply-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-send-plane-fill"></i> remixicon-send-plane-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-send-plane-2-fill"></i> remixicon-send-plane-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-projector-fill"></i> remixicon-projector-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-projector-2-fill"></i> remixicon-projector-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-fill"></i> remixicon-slideshow-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-2-fill"></i> remixicon-slideshow-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-3-fill"></i> remixicon-slideshow-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slideshow-4-fill"></i> remixicon-slideshow-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-window-line"></i> remixicon-window-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-window-2-line"></i> remixicon-window-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stack-line"></i> remixicon-stack-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-window-fill"></i> remixicon-window-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-window-2-fill"></i> remixicon-window-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stack-fill"></i> remixicon-stack-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Communication</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-1-line"></i> remixicon-chat-1-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-2-line"></i> remixicon-chat-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-3-line"></i> remixicon-chat-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-4-line"></i> remixicon-chat-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-message-line"></i> remixicon-message-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-message-2-line"></i> remixicon-message-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-message-3-line"></i> remixicon-message-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-check-line"></i> remixicon-chat-check-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-delete-line"></i> remixicon-chat-delete-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-forward-line"></i> remixicon-chat-forward-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-1-fill"></i> remixicon-chat-1-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-2-fill"></i> remixicon-chat-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-3-fill"></i> remixicon-chat-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-4-fill"></i> remixicon-chat-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-message-fill"></i> remixicon-message-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-message-2-fill"></i> remixicon-message-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-message-3-fill"></i> remixicon-message-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-check-fill"></i> remixicon-chat-check-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-delete-fill"></i> remixicon-chat-delete-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-forward-fill"></i> remixicon-chat-forward-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-upload-line"></i> remixicon-chat-upload-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-download-line"></i> remixicon-chat-download-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-new-line"></i> remixicon-chat-new-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-settings-line"></i> remixicon-chat-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-smile-line"></i> remixicon-chat-smile-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-smile-2-line"></i> remixicon-chat-smile-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-smile-3-line"></i> remixicon-chat-smile-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-feedback-line"></i> remixicon-feedback-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-discuss-line"></i> remixicon-discuss-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-question-answer-line"></i> remixicon-question-answer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-questionnaire-line"></i> remixicon-questionnaire-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-upload-fill"></i> remixicon-chat-upload-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-download-fill"></i> remixicon-chat-download-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-new-fill"></i> remixicon-chat-new-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-settings-fill"></i> remixicon-chat-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-smile-fill"></i> remixicon-chat-smile-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-smile-2-fill"></i> remixicon-chat-smile-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chat-smile-3-fill"></i> remixicon-chat-smile-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-feedback-fill"></i> remixicon-feedback-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-discuss-fill"></i> remixicon-discuss-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-question-answer-fill"></i> remixicon-question-answer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-questionnaire-fill"></i> remixicon-questionnaire-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Design</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pencil-line"></i> remixicon-pencil-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-line"></i> remixicon-edit-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-2-line"></i> remixicon-edit-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ball-pen-line"></i> remixicon-ball-pen-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-quill-pen-line"></i> remixicon-quill-pen-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mark-pen-line"></i> remixicon-mark-pen-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pen-nib-line"></i> remixicon-pen-nib-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-box-line"></i> remixicon-edit-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-circle-line"></i> remixicon-edit-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sip-line"></i> remixicon-sip-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pencil-fill"></i> remixicon-pencil-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-fill"></i> remixicon-edit-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-2-fill"></i> remixicon-edit-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ball-pen-fill"></i> remixicon-ball-pen-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-quill-pen-fill"></i> remixicon-quill-pen-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mark-pen-fill"></i> remixicon-mark-pen-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pen-nib-fill"></i> remixicon-pen-nib-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-box-fill"></i> remixicon-edit-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edit-circle-fill"></i> remixicon-edit-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sip-fill"></i> remixicon-sip-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-line"></i> remixicon-brush-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-2-line"></i> remixicon-brush-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-3-line"></i> remixicon-brush-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-4-line"></i> remixicon-brush-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-paint-brush-line"></i> remixicon-paint-brush-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-line"></i> remixicon-contrast-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-2-line"></i> remixicon-contrast-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drop-line"></i> remixicon-drop-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-blur-off-line"></i> remixicon-blur-off-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-drop-line"></i> remixicon-contrast-drop-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-fill"></i> remixicon-brush-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-2-fill"></i> remixicon-brush-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-3-fill"></i> remixicon-brush-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-brush-4-fill"></i> remixicon-brush-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-paint-brush-fill"></i> remixicon-paint-brush-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-fill"></i> remixicon-contrast-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-2-fill"></i> remixicon-contrast-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drop-fill"></i> remixicon-drop-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-blur-off-fill"></i> remixicon-blur-off-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-drop-fill"></i> remixicon-contrast-drop-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-drop-2-line"></i> remixicon-contrast-drop-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compasses-line"></i> remixicon-compasses-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compasses-2-line"></i> remixicon-compasses-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scissors-line"></i> remixicon-scissors-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scissors-cut-line"></i> remixicon-scissors-cut-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scissors-2-line"></i> remixicon-scissors-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eraser-line"></i> remixicon-eraser-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ruler-line"></i> remixicon-ruler-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ruler-2-line"></i> remixicon-ruler-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pencil-ruler-line"></i> remixicon-pencil-ruler-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contrast-drop-2-fill"></i> remixicon-contrast-drop-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compasses-fill"></i> remixicon-compasses-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compasses-2-fill"></i> remixicon-compasses-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scissors-fill"></i> remixicon-scissors-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scissors-cut-fill"></i> remixicon-scissors-cut-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scissors-2-fill"></i> remixicon-scissors-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eraser-fill"></i> remixicon-eraser-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ruler-fill"></i> remixicon-ruler-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ruler-2-fill"></i> remixicon-ruler-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pencil-ruler-fill"></i> remixicon-pencil-ruler-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pencil-ruler-2-line"></i> remixicon-pencil-ruler-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-t-box-line"></i> remixicon-t-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-input-method-line"></i> remixicon-input-method-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-artboard-line"></i> remixicon-artboard-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-artboard-2-line"></i> remixicon-artboard-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-crop-line"></i> remixicon-crop-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-crop-2-line"></i> remixicon-crop-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-table-line"></i> remixicon-table-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-layout-line"></i> remixicon-layout-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-grid-line"></i> remixicon-grid-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pencil-ruler-2-fill"></i> remixicon-pencil-ruler-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-t-box-fill"></i> remixicon-t-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-input-method-fill"></i> remixicon-input-method-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-artboard-fill"></i> remixicon-artboard-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-artboard-2-fill"></i> remixicon-artboard-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-crop-fill"></i> remixicon-crop-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-crop-2-fill"></i> remixicon-crop-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-table-fill"></i> remixicon-table-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-layout-fill"></i> remixicon-layout-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-grid-fill"></i> remixicon-grid-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drag-move-line"></i> remixicon-drag-move-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drag-move-2-line"></i> remixicon-drag-move-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-focus-line"></i> remixicon-focus-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-focus-2-line"></i> remixicon-focus-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-paint-line"></i> remixicon-paint-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-palette-line"></i> remixicon-palette-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pantone-line"></i> remixicon-pantone-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shape-line"></i> remixicon-shape-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shape-2-line"></i> remixicon-shape-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drag-move-fill"></i> remixicon-drag-move-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drag-move-2-fill"></i> remixicon-drag-move-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-focus-fill"></i> remixicon-focus-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-focus-2-fill"></i> remixicon-focus-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-paint-fill"></i> remixicon-paint-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-palette-fill"></i> remixicon-palette-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pantone-fill"></i> remixicon-pantone-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shape-fill"></i> remixicon-shape-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shape-2-fill"></i> remixicon-shape-2-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Development</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bug-line"></i> remixicon-bug-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bug-2-line"></i> remixicon-bug-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-code-line"></i> remixicon-code-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-terminal-box-line"></i> remixicon-terminal-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-terminal-line"></i> remixicon-terminal-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bug-fill"></i> remixicon-bug-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bug-2-fill"></i> remixicon-bug-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-code-fill"></i> remixicon-code-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-terminal-box-fill"></i> remixicon-terminal-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-terminal-fill"></i> remixicon-terminal-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Device</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tv-line"></i> remixicon-tv-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tv-2-line"></i> remixicon-tv-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-computer-line"></i> remixicon-computer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mac-line"></i> remixicon-mac-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-macbook-line"></i> remixicon-macbook-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cellphone-line"></i> remixicon-cellphone-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-smartphone-line"></i> remixicon-smartphone-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tablet-line"></i> remixicon-tablet-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-device-line"></i> remixicon-device-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-phone-line"></i> remixicon-phone-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tv-fill"></i> remixicon-tv-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tv-2-fill"></i> remixicon-tv-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-computer-fill"></i> remixicon-computer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mac-fill"></i> remixicon-mac-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-macbook-fill"></i> remixicon-macbook-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cellphone-fill"></i> remixicon-cellphone-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-smartphone-fill"></i> remixicon-smartphone-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tablet-fill"></i> remixicon-tablet-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-device-fill"></i> remixicon-device-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-phone-fill"></i> remixicon-phone-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-database-line"></i> remixicon-database-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-server-line"></i> remixicon-server-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-hard-drive-line"></i> remixicon-hard-drive-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-hard-drive-2-line"></i> remixicon-hard-drive-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-save-line"></i> remixicon-save-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-save-2-line"></i> remixicon-save-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-save-3-line"></i> remixicon-save-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sd-card-line"></i> remixicon-sd-card-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sd-card-mini-line"></i> remixicon-sd-card-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sim-card-line"></i> remixicon-sim-card-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-database-fill"></i> remixicon-database-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-server-fill"></i> remixicon-server-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-hard-drive-fill"></i> remixicon-hard-drive-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-hard-drive-2-fill"></i> remixicon-hard-drive-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-save-fill"></i> remixicon-save-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-save-2-fill"></i> remixicon-save-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-save-3-fill"></i> remixicon-save-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sd-card-fill"></i> remixicon-sd-card-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sd-card-mini-fill"></i> remixicon-sd-card-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sim-card-fill"></i> remixicon-sim-card-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sim-card-2-line"></i> remixicon-sim-card-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-u-disk-line"></i> remixicon-u-disk-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-line"></i> remixicon-battery-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-charge-line"></i> remixicon-battery-charge-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-low-line"></i> remixicon-battery-low-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-2-line"></i> remixicon-battery-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-2-charge-line"></i> remixicon-battery-2-charge-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cast-line"></i> remixicon-cast-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-airplay-line"></i> remixicon-airplay-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cpu-line"></i> remixicon-cpu-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sim-card-2-fill"></i> remixicon-sim-card-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-u-disk-fill"></i> remixicon-u-disk-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-fill"></i> remixicon-battery-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-charge-fill"></i> remixicon-battery-charge-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-low-fill"></i> remixicon-battery-low-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-2-fill"></i> remixicon-battery-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-battery-2-charge-fill"></i> remixicon-battery-2-charge-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cast-fill"></i> remixicon-cast-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-airplay-fill"></i> remixicon-airplay-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cpu-fill"></i> remixicon-cpu-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gradienter-line"></i> remixicon-gradienter-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-keyboard-line"></i> remixicon-keyboard-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-keyboard-box-line"></i> remixicon-keyboard-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mouse-line"></i> remixicon-mouse-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sensor-line"></i> remixicon-sensor-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-router-line"></i> remixicon-router-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gradienter-fill"></i> remixicon-gradienter-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-keyboard-fill"></i> remixicon-keyboard-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-keyboard-box-fill"></i> remixicon-keyboard-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mouse-fill"></i> remixicon-mouse-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sensor-fill"></i> remixicon-sensor-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-router-fill"></i> remixicon-router-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Document</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-line"></i> remixicon-file-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-2-line"></i> remixicon-file-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-3-line"></i> remixicon-file-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-edit-line"></i> remixicon-file-edit-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-4-line"></i> remixicon-file-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-paper-line"></i> remixicon-file-paper-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-text-line"></i> remixicon-file-text-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-list-line"></i> remixicon-file-list-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-list-2-line"></i> remixicon-file-list-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-list-3-line"></i> remixicon-file-list-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-fill"></i> remixicon-file-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-2-fill"></i> remixicon-file-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-3-fill"></i> remixicon-file-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-edit-fill"></i> remixicon-file-edit-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-4-fill"></i> remixicon-file-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-paper-fill"></i> remixicon-file-paper-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-text-fill"></i> remixicon-file-text-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-list-fill"></i> remixicon-file-list-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-list-2-fill"></i> remixicon-file-list-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-list-3-fill"></i> remixicon-file-list-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bill-line"></i> remixicon-bill-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-copy-line"></i> remixicon-file-copy-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-copy-2-line"></i> remixicon-file-copy-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-clipboard-line"></i> remixicon-clipboard-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-article-line"></i> remixicon-article-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-newspaper-line"></i> remixicon-newspaper-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-zip-line"></i> remixicon-file-zip-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-mark-line"></i> remixicon-file-mark-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-task-line"></i> remixicon-task-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-todo-line"></i> remixicon-todo-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bill-fill"></i> remixicon-bill-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-copy-fill"></i> remixicon-file-copy-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-copy-2-fill"></i> remixicon-file-copy-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-clipboard-fill"></i> remixicon-clipboard-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-article-fill"></i> remixicon-article-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-newspaper-fill"></i> remixicon-newspaper-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-zip-fill"></i> remixicon-file-zip-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-mark-fill"></i> remixicon-file-mark-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-task-fill"></i> remixicon-task-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-todo-fill"></i> remixicon-todo-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-line"></i> remixicon-book-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-mark-line"></i> remixicon-book-mark-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-2-line"></i> remixicon-book-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-3-line"></i> remixicon-book-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-open-line"></i> remixicon-book-open-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-read-line"></i> remixicon-book-read-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contacts-book-line"></i> remixicon-contacts-book-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contacts-book-2-line"></i> remixicon-contacts-book-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-code-line"></i> remixicon-file-code-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-pdf-line"></i> remixicon-file-pdf-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-fill"></i> remixicon-book-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-mark-fill"></i> remixicon-book-mark-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-2-fill"></i> remixicon-book-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-3-fill"></i> remixicon-book-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-open-fill"></i> remixicon-book-open-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-book-read-fill"></i> remixicon-book-read-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contacts-book-fill"></i> remixicon-contacts-book-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contacts-book-2-fill"></i> remixicon-contacts-book-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-code-fill"></i> remixicon-file-code-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-pdf-fill"></i> remixicon-file-pdf-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-word-line"></i> remixicon-file-word-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-ppt-line"></i> remixicon-file-ppt-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-excel-line"></i> remixicon-file-excel-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-word-2-line"></i> remixicon-file-word-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-ppt-2-line"></i> remixicon-file-ppt-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-excel-2-line"></i> remixicon-file-excel-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-keynote-line"></i> remixicon-keynote-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-numbers-line"></i> remixicon-numbers-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pages-line"></i> remixicon-pages-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-search-line"></i> remixicon-file-search-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-word-fill"></i> remixicon-file-word-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-ppt-fill"></i> remixicon-file-ppt-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-excel-fill"></i> remixicon-file-excel-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-word-2-fill"></i> remixicon-file-word-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-ppt-2-fill"></i> remixicon-file-ppt-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-excel-2-fill"></i> remixicon-file-excel-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-keynote-fill"></i> remixicon-keynote-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-numbers-fill"></i> remixicon-numbers-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pages-fill"></i> remixicon-pages-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-search-fill"></i> remixicon-file-search-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-add-line"></i> remixicon-file-add-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-reduce-line"></i> remixicon-file-reduce-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-settings-line"></i> remixicon-file-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-upload-line"></i> remixicon-file-upload-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-transfer-line"></i> remixicon-file-transfer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-download-line"></i> remixicon-file-download-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-lock-line"></i> remixicon-file-lock-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-chart-line"></i> remixicon-file-chart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-chart-2-line"></i> remixicon-file-chart-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-music-line"></i> remixicon-file-music-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-add-fill"></i> remixicon-file-add-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-reduce-fill"></i> remixicon-file-reduce-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-settings-fill"></i> remixicon-file-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-upload-fill"></i> remixicon-file-upload-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-transfer-fill"></i> remixicon-file-transfer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-download-fill"></i> remixicon-file-download-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-lock-fill"></i> remixicon-file-lock-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-chart-fill"></i> remixicon-file-chart-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-chart-2-fill"></i> remixicon-file-chart-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-music-fill"></i> remixicon-file-music-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-forbid-line"></i> remixicon-file-forbid-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-info-line"></i> remixicon-file-info-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-warning-line"></i> remixicon-file-warning-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-unknow-line"></i> remixicon-file-unknow-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-user-line"></i> remixicon-file-user-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-damage-line"></i> remixicon-file-damage-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-shred-line"></i> remixicon-file-shred-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-line"></i> remixicon-folder-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-2-line"></i> remixicon-folder-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-3-line"></i> remixicon-folder-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-forbid-fill"></i> remixicon-file-forbid-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-info-fill"></i> remixicon-file-info-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-warning-fill"></i> remixicon-file-warning-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-unknow-fill"></i> remixicon-file-unknow-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-user-fill"></i> remixicon-file-user-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-damage-fill"></i> remixicon-file-damage-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-file-shred-fill"></i> remixicon-file-shred-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-fill"></i> remixicon-folder-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-2-fill"></i> remixicon-folder-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-3-fill"></i> remixicon-folder-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-4-line"></i> remixicon-folder-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-5-line"></i> remixicon-folder-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-add-line"></i> remixicon-folder-add-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-reduce-line"></i> remixicon-folder-reduce-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-settings-line"></i> remixicon-folder-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-upload-line"></i> remixicon-folder-upload-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-transfer-line"></i> remixicon-folder-transfer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-download-line"></i> remixicon-folder-download-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-lock-line"></i> remixicon-folder-lock-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-chart-line"></i> remixicon-folder-chart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-4-fill"></i> remixicon-folder-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-5-fill"></i> remixicon-folder-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-add-fill"></i> remixicon-folder-add-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-reduce-fill"></i> remixicon-folder-reduce-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-settings-fill"></i> remixicon-folder-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-upload-fill"></i> remixicon-folder-upload-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-transfer-fill"></i> remixicon-folder-transfer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-download-fill"></i> remixicon-folder-download-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-lock-fill"></i> remixicon-folder-lock-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-chart-fill"></i> remixicon-folder-chart-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-chart-2-line"></i> remixicon-folder-chart-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-music-line"></i> remixicon-folder-music-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-forbid-line"></i> remixicon-folder-forbid-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-info-line"></i> remixicon-folder-info-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-warning-line"></i> remixicon-folder-warning-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-unknow-line"></i> remixicon-folder-unknow-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-user-line"></i> remixicon-folder-user-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-markdown-line"></i> remixicon-markdown-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-chart-2-fill"></i> remixicon-folder-chart-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-music-fill"></i> remixicon-folder-music-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-forbid-fill"></i> remixicon-folder-forbid-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-info-fill"></i> remixicon-folder-info-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-warning-fill"></i> remixicon-folder-warning-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-unknow-fill"></i> remixicon-folder-unknow-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-folder-user-fill"></i> remixicon-folder-user-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-markdown-fill"></i> remixicon-markdown-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Editor</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bold"></i> remixicon-bold
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-italic"></i> remixicon-italic
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-text"></i> remixicon-text
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-font-color"></i> remixicon-font-color
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-font-size"></i> remixicon-font-size
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-font-size-2"></i> remixicon-font-size-2
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-underline"></i> remixicon-underline
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-align-left"></i> remixicon-align-left
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-align-center"></i> remixicon-align-center
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-align-right"></i> remixicon-align-right
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-align-justify"></i> remixicon-align-justify
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-align-top"></i> remixicon-align-top
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-align-vertically"></i> remixicon-align-vertically
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-align-bottom"></i> remixicon-align-bottom
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-list-check"></i> remixicon-list-check
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-list-ordered"></i> remixicon-list-ordered
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-list-unordered"></i> remixicon-list-unordered
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-indent-decrease"></i> remixicon-indent-decrease
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-indent-increase"></i> remixicon-indent-increase
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-line-height"></i> remixicon-line-height
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-link"></i> remixicon-link
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-link-unlink"></i> remixicon-link-unlink
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-separator"></i> remixicon-separator
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-space"></i> remixicon-space
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-code-view"></i> remixicon-code-view
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Finance</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wallet-line"></i> remixicon-wallet-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wallet-2-line"></i> remixicon-wallet-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wallet-3-line"></i> remixicon-wallet-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bank-card-line"></i> remixicon-bank-card-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bank-card-2-line"></i> remixicon-bank-card-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-safe-line"></i> remixicon-safe-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-safe-2-line"></i> remixicon-safe-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-price-tag-line"></i> remixicon-price-tag-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-price-tag-2-line"></i> remixicon-price-tag-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-price-tag-3-line"></i> remixicon-price-tag-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wallet-fill"></i> remixicon-wallet-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wallet-2-fill"></i> remixicon-wallet-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wallet-3-fill"></i> remixicon-wallet-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bank-card-fill"></i> remixicon-bank-card-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bank-card-2-fill"></i> remixicon-bank-card-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-safe-fill"></i> remixicon-safe-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-safe-2-fill"></i> remixicon-safe-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-price-tag-fill"></i> remixicon-price-tag-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-price-tag-2-fill"></i> remixicon-price-tag-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-price-tag-3-fill"></i> remixicon-price-tag-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-line"></i> remixicon-coupon-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-2-line"></i> remixicon-coupon-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-3-line"></i> remixicon-coupon-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-4-line"></i> remixicon-coupon-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-5-line"></i> remixicon-coupon-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-bag-line"></i> remixicon-shopping-bag-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-bag-2-line"></i> remixicon-shopping-bag-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-bag-3-line"></i> remixicon-shopping-bag-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-cart-line"></i> remixicon-shopping-cart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-cart-2-line"></i> remixicon-shopping-cart-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-fill"></i> remixicon-coupon-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-2-fill"></i> remixicon-coupon-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-3-fill"></i> remixicon-coupon-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-4-fill"></i> remixicon-coupon-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-coupon-5-fill"></i> remixicon-coupon-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-bag-fill"></i> remixicon-shopping-bag-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-bag-2-fill"></i> remixicon-shopping-bag-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-bag-3-fill"></i> remixicon-shopping-bag-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-cart-fill"></i> remixicon-shopping-cart-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shopping-cart-2-fill"></i> remixicon-shopping-cart-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-line"></i> remixicon-vip-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-crown-line"></i> remixicon-vip-crown-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-crown-2-line"></i> remixicon-vip-crown-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-diamond-line"></i> remixicon-vip-diamond-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-trophy-line"></i> remixicon-trophy-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-exchange-line"></i> remixicon-exchange-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-swap-line"></i> remixicon-swap-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-increase-decrease-line"></i> remixicon-increase-decrease-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-percent-line"></i> remixicon-percent-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-copper-coin-line"></i> remixicon-copper-coin-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-fill"></i> remixicon-vip-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-crown-fill"></i> remixicon-vip-crown-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-crown-2-fill"></i> remixicon-vip-crown-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vip-diamond-fill"></i> remixicon-vip-diamond-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-trophy-fill"></i> remixicon-trophy-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-exchange-fill"></i> remixicon-exchange-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-swap-fill"></i> remixicon-swap-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-increase-decrease-fill"></i> remixicon-increase-decrease-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-percent-fill"></i> remixicon-percent-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-copper-coin-fill"></i> remixicon-copper-coin-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-copper-diamond-line"></i> remixicon-copper-diamond-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-cny-box-line"></i> remixicon-money-cny-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-cny-circle-line"></i> remixicon-money-cny-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-dollar-box-line"></i> remixicon-money-dollar-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-dollar-circle-line"></i> remixicon-money-dollar-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-euro-box-line"></i> remixicon-money-euro-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-euro-circle-line"></i> remixicon-money-euro-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-pound-box-line"></i> remixicon-money-pound-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-pound-circle-line"></i> remixicon-money-pound-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bit-coin-line"></i> remixicon-bit-coin-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-copper-diamond-fill"></i> remixicon-copper-diamond-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-cny-box-fill"></i> remixicon-money-cny-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-cny-circle-fill"></i> remixicon-money-cny-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-dollar-box-fill"></i> remixicon-money-dollar-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-dollar-circle-fill"></i> remixicon-money-dollar-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-euro-box-fill"></i> remixicon-money-euro-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-euro-circle-fill"></i> remixicon-money-euro-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-pound-box-fill"></i> remixicon-money-pound-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-money-pound-circle-fill"></i> remixicon-money-pound-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bit-coin-fill"></i> remixicon-bit-coin-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Logos</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-windows-line"></i> remixicon-windows-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-apple-line"></i> remixicon-apple-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-google-line"></i> remixicon-google-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-android-line"></i> remixicon-android-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-facebook-line"></i> remixicon-facebook-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-facebook-box-line"></i> remixicon-facebook-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-twitter-line"></i> remixicon-twitter-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-linkedin-line"></i> remixicon-linkedin-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-linkedin-box-line"></i> remixicon-linkedin-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-medium-line"></i> remixicon-medium-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-windows-fill"></i> remixicon-windows-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-apple-fill"></i> remixicon-apple-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-google-fill"></i> remixicon-google-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-android-fill"></i> remixicon-android-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-facebook-fill"></i> remixicon-facebook-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-facebook-box-fill"></i> remixicon-facebook-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-twitter-fill"></i> remixicon-twitter-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-linkedin-fill"></i> remixicon-linkedin-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-linkedin-box-fill"></i> remixicon-linkedin-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-medium-fill"></i> remixicon-medium-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qq-line"></i> remixicon-qq-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wechat-line"></i> remixicon-wechat-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wechat-2-line"></i> remixicon-wechat-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skype-line"></i> remixicon-skype-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slack-line"></i> remixicon-slack-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-messenger-line"></i> remixicon-messenger-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-whatsapp-line"></i> remixicon-whatsapp-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-telegram-line"></i> remixicon-telegram-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-snapchat-line"></i> remixicon-snapchat-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dingding-line"></i> remixicon-dingding-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qq-fill"></i> remixicon-qq-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wechat-fill"></i> remixicon-wechat-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wechat-2-fill"></i> remixicon-wechat-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skype-fill"></i> remixicon-skype-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-slack-fill"></i> remixicon-slack-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-messenger-fill"></i> remixicon-messenger-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-whatsapp-fill"></i> remixicon-whatsapp-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-telegram-fill"></i> remixicon-telegram-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-snapchat-fill"></i> remixicon-snapchat-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dingding-fill"></i> remixicon-dingding-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-line-line"></i> remixicon-line-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-product-hunt-line"></i> remixicon-product-hunt-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-behance-line"></i> remixicon-behance-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-instagram-line"></i> remixicon-instagram-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dribbble-line"></i> remixicon-dribbble-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pinterest-line"></i> remixicon-pinterest-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-invision-line"></i> remixicon-invision-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zcool-line"></i> remixicon-zcool-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drive-line"></i> remixicon-drive-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dropbox-line"></i> remixicon-dropbox-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-line-fill"></i> remixicon-line-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-product-hunt-fill"></i> remixicon-product-hunt-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-behance-fill"></i> remixicon-behance-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-instagram-fill"></i> remixicon-instagram-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dribbble-fill"></i> remixicon-dribbble-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pinterest-fill"></i> remixicon-pinterest-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-invision-fill"></i> remixicon-invision-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zcool-fill"></i> remixicon-zcool-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-drive-fill"></i> remixicon-drive-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dropbox-fill"></i> remixicon-dropbox-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-codepen-line"></i> remixicon-codepen-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-github-line"></i> remixicon-github-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gitlab-line"></i> remixicon-gitlab-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stack-overflow-line"></i> remixicon-stack-overflow-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-trello-line"></i> remixicon-trello-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-alipay-line"></i> remixicon-alipay-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wechat-pay-line"></i> remixicon-wechat-pay-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-evernote-line"></i> remixicon-evernote-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tumblr-line"></i> remixicon-tumblr-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chrome-line"></i> remixicon-chrome-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-codepen-fill"></i> remixicon-codepen-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-github-fill"></i> remixicon-github-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gitlab-fill"></i> remixicon-gitlab-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stack-overflow-fill"></i> remixicon-stack-overflow-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-trello-fill"></i> remixicon-trello-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-alipay-fill"></i> remixicon-alipay-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wechat-pay-fill"></i> remixicon-wechat-pay-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-evernote-fill"></i> remixicon-evernote-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tumblr-fill"></i> remixicon-tumblr-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-chrome-fill"></i> remixicon-chrome-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-safari-line"></i> remixicon-safari-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edge-line"></i> remixicon-edge-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-opera-line"></i> remixicon-opera-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-firefox-line"></i> remixicon-firefox-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ie-line"></i> remixicon-ie-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-taobao-line"></i> remixicon-taobao-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-amazon-line"></i> remixicon-amazon-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-baidu-line"></i> remixicon-baidu-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-weibo-line"></i> remixicon-weibo-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-youtube-line"></i> remixicon-youtube-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zhihu-line"></i> remixicon-zhihu-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-safari-fill"></i> remixicon-safari-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-edge-fill"></i> remixicon-edge-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-opera-fill"></i> remixicon-opera-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-firefox-fill"></i> remixicon-firefox-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ie-fill"></i> remixicon-ie-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-taobao-fill"></i> remixicon-taobao-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-amazon-fill"></i> remixicon-amazon-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-baidu-fill"></i> remixicon-baidu-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-weibo-fill"></i> remixicon-weibo-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-youtube-fill"></i> remixicon-youtube-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zhihu-fill"></i> remixicon-zhihu-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Map</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-line"></i> remixicon-map-pin-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-2-line"></i> remixicon-map-pin-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-3-line"></i> remixicon-map-pin-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-4-line"></i> remixicon-map-pin-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-5-line"></i> remixicon-map-pin-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-add-line"></i> remixicon-map-pin-add-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-range-line"></i> remixicon-map-pin-range-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-time-line"></i> remixicon-map-pin-time-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-user-line"></i> remixicon-map-pin-user-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pin-distance-line"></i> remixicon-pin-distance-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-fill"></i> remixicon-map-pin-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-2-fill"></i> remixicon-map-pin-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-3-fill"></i> remixicon-map-pin-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-4-fill"></i> remixicon-map-pin-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-5-fill"></i> remixicon-map-pin-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-add-fill"></i> remixicon-map-pin-add-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-range-fill"></i> remixicon-map-pin-range-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-time-fill"></i> remixicon-map-pin-time-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-pin-user-fill"></i> remixicon-map-pin-user-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pin-distance-fill"></i> remixicon-pin-distance-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-line"></i> remixicon-compass-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-2-line"></i> remixicon-compass-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-3-line"></i> remixicon-compass-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-4-line"></i> remixicon-compass-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-discover-line"></i> remixicon-compass-discover-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-anchor-line"></i> remixicon-anchor-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-china-railway-line"></i> remixicon-china-railway-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-space-ship-line"></i> remixicon-space-ship-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rocket-line"></i> remixicon-rocket-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rocket-2-line"></i> remixicon-rocket-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-fill"></i> remixicon-compass-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-2-fill"></i> remixicon-compass-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-3-fill"></i> remixicon-compass-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-4-fill"></i> remixicon-compass-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-compass-discover-fill"></i> remixicon-compass-discover-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-anchor-fill"></i> remixicon-anchor-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-china-railway-fill"></i> remixicon-china-railway-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-space-ship-fill"></i> remixicon-space-ship-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rocket-fill"></i> remixicon-rocket-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rocket-2-fill"></i> remixicon-rocket-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-line"></i> remixicon-map-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-2-line"></i> remixicon-map-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-treasure-map-line"></i> remixicon-treasure-map-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-road-map-line"></i> remixicon-road-map-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-earth-line"></i> remixicon-earth-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-globe-line"></i> remixicon-globe-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-parking-line"></i> remixicon-parking-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-parking-box-line"></i> remixicon-parking-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-route-line"></i> remixicon-route-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-guide-line"></i> remixicon-guide-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-fill"></i> remixicon-map-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-map-2-fill"></i> remixicon-map-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-treasure-map-fill"></i> remixicon-treasure-map-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-road-map-fill"></i> remixicon-road-map-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-earth-fill"></i> remixicon-earth-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-globe-fill"></i> remixicon-globe-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-parking-fill"></i> remixicon-parking-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-parking-box-fill"></i> remixicon-parking-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-route-fill"></i> remixicon-route-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-guide-fill"></i> remixicon-guide-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gas-station-line"></i> remixicon-gas-station-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-charging-pile-line"></i> remixicon-charging-pile-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-car-line"></i> remixicon-car-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-car-washing-line"></i> remixicon-car-washing-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-roadster-line"></i> remixicon-roadster-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-taxi-line"></i> remixicon-taxi-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-police-car-line"></i> remixicon-police-car-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bus-line"></i> remixicon-bus-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bus-2-line"></i> remixicon-bus-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-truck-line"></i> remixicon-truck-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gas-station-fill"></i> remixicon-gas-station-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-charging-pile-fill"></i> remixicon-charging-pile-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-car-fill"></i> remixicon-car-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-car-washing-fill"></i> remixicon-car-washing-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-roadster-fill"></i> remixicon-roadster-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-taxi-fill"></i> remixicon-taxi-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-police-car-fill"></i> remixicon-police-car-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bus-fill"></i> remixicon-bus-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bus-2-fill"></i> remixicon-bus-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-truck-fill"></i> remixicon-truck-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-train-line"></i> remixicon-train-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-subway-line"></i> remixicon-subway-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flight-takeoff-line"></i> remixicon-flight-takeoff-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flight-land-line"></i> remixicon-flight-land-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-plane-line"></i> remixicon-plane-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sailboat-line"></i> remixicon-sailboat-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ship-line"></i> remixicon-ship-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ship-2-line"></i> remixicon-ship-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bike-line"></i> remixicon-bike-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-e-bike-line"></i> remixicon-e-bike-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-train-fill"></i> remixicon-train-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-subway-fill"></i> remixicon-subway-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flight-takeoff-fill"></i> remixicon-flight-takeoff-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-flight-land-fill"></i> remixicon-flight-land-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-plane-fill"></i> remixicon-plane-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sailboat-fill"></i> remixicon-sailboat-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ship-fill"></i> remixicon-ship-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-ship-2-fill"></i> remixicon-ship-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bike-fill"></i> remixicon-bike-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-e-bike-fill"></i> remixicon-e-bike-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-e-bike-2-line"></i> remixicon-e-bike-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-motorbike-line"></i> remixicon-motorbike-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-walk-line"></i> remixicon-walk-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-run-line"></i> remixicon-run-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-riding-line"></i> remixicon-riding-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-barricade-line"></i> remixicon-barricade-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-footprint-line"></i> remixicon-footprint-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-traffic-light-line"></i> remixicon-traffic-light-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-signal-tower-line"></i> remixicon-signal-tower-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-restaurant-line"></i> remixicon-restaurant-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-e-bike-2-fill"></i> remixicon-e-bike-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-motorbike-fill"></i> remixicon-motorbike-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-walk-fill"></i> remixicon-walk-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-run-fill"></i> remixicon-run-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-riding-fill"></i> remixicon-riding-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-barricade-fill"></i> remixicon-barricade-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-footprint-fill"></i> remixicon-footprint-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-traffic-light-fill"></i> remixicon-traffic-light-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-signal-tower-fill"></i> remixicon-signal-tower-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-restaurant-fill"></i> remixicon-restaurant-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-restaurant-2-line"></i> remixicon-restaurant-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cup-line"></i> remixicon-cup-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-goblet-line"></i> remixicon-goblet-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-hotel-bed-line"></i> remixicon-hotel-bed-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-navigation-line"></i> remixicon-navigation-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-oil-line"></i> remixicon-oil-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-direction-line"></i> remixicon-direction-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-restaurant-2-fill"></i> remixicon-restaurant-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-cup-fill"></i> remixicon-cup-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-goblet-fill"></i> remixicon-goblet-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-hotel-bed-fill"></i> remixicon-hotel-bed-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-navigation-fill"></i> remixicon-navigation-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-oil-fill"></i> remixicon-oil-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-direction-fill"></i> remixicon-direction-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">Media</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-image-line"></i> remixicon-image-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-image-2-line"></i> remixicon-image-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-landscape-line"></i> remixicon-landscape-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gallery-line"></i> remixicon-gallery-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-video-line"></i> remixicon-video-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-movie-line"></i> remixicon-movie-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-movie-2-line"></i> remixicon-movie-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-film-line"></i> remixicon-film-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-clapperboard-line"></i> remixicon-clapperboard-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vidicon-line"></i> remixicon-vidicon-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-image-fill"></i> remixicon-image-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-image-2-fill"></i> remixicon-image-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-landscape-fill"></i> remixicon-landscape-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gallery-fill"></i> remixicon-gallery-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-video-fill"></i> remixicon-video-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-movie-fill"></i> remixicon-movie-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-movie-2-fill"></i> remixicon-movie-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-film-fill"></i> remixicon-film-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-clapperboard-fill"></i> remixicon-clapperboard-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vidicon-fill"></i> remixicon-vidicon-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vidicon-2-line"></i> remixicon-vidicon-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-line"></i> remixicon-camera-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-off-line"></i> remixicon-camera-off-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-2-line"></i> remixicon-camera-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-3-line"></i> remixicon-camera-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-lens-line"></i> remixicon-camera-lens-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-switch-line"></i> remixicon-camera-switch-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-polaroid-line"></i> remixicon-polaroid-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-polaroid-2-line"></i> remixicon-polaroid-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-phone-camera-line"></i> remixicon-phone-camera-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-vidicon-2-fill"></i> remixicon-vidicon-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-fill"></i> remixicon-camera-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-off-fill"></i> remixicon-camera-off-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-2-fill"></i> remixicon-camera-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-3-fill"></i> remixicon-camera-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-lens-fill"></i> remixicon-camera-lens-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-camera-switch-fill"></i> remixicon-camera-switch-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-polaroid-fill"></i> remixicon-polaroid-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-polaroid-2-fill"></i> remixicon-polaroid-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-phone-camera-fill"></i> remixicon-phone-camera-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-webcam-line"></i> remixicon-webcam-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mv-line"></i> remixicon-mv-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-music-line"></i> remixicon-music-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-music-2-line"></i> remixicon-music-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-disc-line"></i> remixicon-disc-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-album-line"></i> remixicon-album-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dvd-line"></i> remixicon-dvd-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-headphone-line"></i> remixicon-headphone-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-radio-line"></i> remixicon-radio-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-radio-2-line"></i> remixicon-radio-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-webcam-fill"></i> remixicon-webcam-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mv-fill"></i> remixicon-mv-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-music-fill"></i> remixicon-music-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-music-2-fill"></i> remixicon-music-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-disc-fill"></i> remixicon-disc-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-album-fill"></i> remixicon-album-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dvd-fill"></i> remixicon-dvd-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-headphone-fill"></i> remixicon-headphone-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-radio-fill"></i> remixicon-radio-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-radio-2-fill"></i> remixicon-radio-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tape-line"></i> remixicon-tape-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mic-line"></i> remixicon-mic-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mic-2-line"></i> remixicon-mic-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mic-off-line"></i> remixicon-mic-off-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-volume-down-line"></i> remixicon-volume-down-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-volume-mute-line"></i> remixicon-volume-mute-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-volume-up-line"></i> remixicon-volume-up-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speaker-line"></i> remixicon-speaker-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speaker-2-line"></i> remixicon-speaker-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speaker-3-line"></i> remixicon-speaker-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-tape-fill"></i> remixicon-tape-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mic-fill"></i> remixicon-mic-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mic-2-fill"></i> remixicon-mic-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-mic-off-fill"></i> remixicon-mic-off-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-volume-down-fill"></i> remixicon-volume-down-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-volume-mute-fill"></i> remixicon-volume-mute-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-volume-up-fill"></i> remixicon-volume-up-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speaker-fill"></i> remixicon-speaker-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speaker-2-fill"></i> remixicon-speaker-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speaker-3-fill"></i> remixicon-speaker-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-broadcast-line"></i> remixicon-broadcast-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-line"></i> remixicon-notification-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-2-line"></i> remixicon-notification-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-3-line"></i> remixicon-notification-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-4-line"></i> remixicon-notification-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-off-line"></i> remixicon-notification-off-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-circle-line"></i> remixicon-play-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pause-circle-line"></i> remixicon-pause-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-record-circle-line"></i> remixicon-record-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stop-circle-line"></i> remixicon-stop-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-broadcast-fill"></i> remixicon-broadcast-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-fill"></i> remixicon-notification-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-2-fill"></i> remixicon-notification-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-3-fill"></i> remixicon-notification-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-4-fill"></i> remixicon-notification-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-off-fill"></i> remixicon-notification-off-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-circle-fill"></i> remixicon-play-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pause-circle-fill"></i> remixicon-pause-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-record-circle-fill"></i> remixicon-record-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stop-circle-fill"></i> remixicon-stop-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eject-line"></i> remixicon-eject-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-line"></i> remixicon-play-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pause-line"></i> remixicon-pause-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stop-line"></i> remixicon-stop-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rewind-line"></i> remixicon-rewind-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speed-line"></i> remixicon-speed-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-back-line"></i> remixicon-skip-back-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-forward-line"></i> remixicon-skip-forward-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-mini-line"></i> remixicon-play-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pause-mini-line"></i> remixicon-pause-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eject-fill"></i> remixicon-eject-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-fill"></i> remixicon-play-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pause-fill"></i> remixicon-pause-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stop-fill"></i> remixicon-stop-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rewind-fill"></i> remixicon-rewind-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speed-fill"></i> remixicon-speed-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-back-fill"></i> remixicon-skip-back-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-forward-fill"></i> remixicon-skip-forward-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-mini-fill"></i> remixicon-play-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-pause-mini-fill"></i> remixicon-pause-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stop-mini-line"></i> remixicon-stop-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rewind-mini-line"></i> remixicon-rewind-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speed-mini-line"></i> remixicon-speed-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-back-mini-line"></i> remixicon-skip-back-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-forward-mini-line"></i> remixicon-skip-forward-mini-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-repeat-line"></i> remixicon-repeat-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-repeat-2-line"></i> remixicon-repeat-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-repeat-one-line"></i> remixicon-repeat-one-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-order-play-line"></i> remixicon-order-play-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shuffle-line"></i> remixicon-shuffle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-stop-mini-fill"></i> remixicon-stop-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rewind-mini-fill"></i> remixicon-rewind-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-speed-mini-fill"></i> remixicon-speed-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-back-mini-fill"></i> remixicon-skip-back-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-skip-forward-mini-fill"></i> remixicon-skip-forward-mini-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-repeat-fill"></i> remixicon-repeat-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-repeat-2-fill"></i> remixicon-repeat-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-repeat-one-fill"></i> remixicon-repeat-one-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-order-play-fill"></i> remixicon-order-play-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shuffle-fill"></i> remixicon-shuffle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-list-line"></i> remixicon-play-list-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-list-add-line"></i> remixicon-play-list-add-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-fullscreen-line"></i> remixicon-fullscreen-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-fullscreen-exit-line"></i> remixicon-fullscreen-exit-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-equalizer-line"></i> remixicon-equalizer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sound-module-line"></i> remixicon-sound-module-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rhythm-line"></i> remixicon-rhythm-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-list-fill"></i> remixicon-play-list-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-play-list-add-fill"></i> remixicon-play-list-add-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-fullscreen-fill"></i> remixicon-fullscreen-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-fullscreen-exit-fill"></i> remixicon-fullscreen-exit-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-equalizer-fill"></i> remixicon-equalizer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-sound-module-fill"></i> remixicon-sound-module-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rhythm-fill"></i> remixicon-rhythm-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->


  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">System</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-apps-line"></i> remixicon-apps-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-apps-2-line"></i> remixicon-apps-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-function-line"></i> remixicon-function-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dashboard-line"></i> remixicon-dashboard-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-menu-line"></i> remixicon-menu-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-menu-2-line"></i> remixicon-menu-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-menu-3-line"></i> remixicon-menu-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-more-line"></i> remixicon-more-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-more-2-line"></i> remixicon-more-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-heart-line"></i> remixicon-heart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-apps-fill"></i> remixicon-apps-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-apps-2-fill"></i> remixicon-apps-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-function-fill"></i> remixicon-function-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-dashboard-fill"></i> remixicon-dashboard-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-menu-fill"></i> remixicon-menu-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-menu-2-fill"></i> remixicon-menu-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-menu-3-fill"></i> remixicon-menu-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-more-fill"></i> remixicon-more-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-more-2-fill"></i> remixicon-more-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-heart-fill"></i> remixicon-heart-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-heart-2-line"></i> remixicon-heart-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-line"></i> remixicon-star-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-s-line"></i> remixicon-star-s-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-line"></i> remixicon-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-2-line"></i> remixicon-settings-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-3-line"></i> remixicon-settings-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-4-line"></i> remixicon-settings-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-5-line"></i> remixicon-settings-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-6-line"></i> remixicon-settings-6-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-list-settings-line"></i> remixicon-list-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-heart-2-fill"></i> remixicon-heart-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-fill"></i> remixicon-star-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-s-fill"></i> remixicon-star-s-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-fill"></i> remixicon-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-2-fill"></i> remixicon-settings-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-3-fill"></i> remixicon-settings-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-4-fill"></i> remixicon-settings-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-5-fill"></i> remixicon-settings-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-6-fill"></i> remixicon-settings-6-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-list-settings-fill"></i> remixicon-list-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-heart-2-line"></i> remixicon-heart-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-line"></i> remixicon-star-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-s-line"></i> remixicon-star-s-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-line"></i> remixicon-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-2-line"></i> remixicon-settings-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-3-line"></i> remixicon-settings-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-4-line"></i> remixicon-settings-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-5-line"></i> remixicon-settings-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-6-line"></i> remixicon-settings-6-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-list-settings-line"></i> remixicon-list-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-heart-2-fill"></i> remixicon-heart-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-fill"></i> remixicon-star-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-star-s-fill"></i> remixicon-star-s-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-fill"></i> remixicon-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-2-fill"></i> remixicon-settings-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-3-fill"></i> remixicon-settings-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-4-fill"></i> remixicon-settings-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-5-fill"></i> remixicon-settings-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-settings-6-fill"></i> remixicon-settings-6-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-list-settings-fill"></i> remixicon-list-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-forbid-line"></i> remixicon-forbid-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-forbid-2-line"></i> remixicon-forbid-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-information-line"></i> remixicon-information-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-error-warning-line"></i> remixicon-error-warning-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-question-line"></i> remixicon-question-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-blank-line"></i> remixicon-checkbox-blank-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-line"></i> remixicon-checkbox-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-indeterminate-line"></i> remixicon-checkbox-indeterminate-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-add-box-line"></i> remixicon-add-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-blank-circle-line"></i> remixicon-checkbox-blank-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-forbid-fill"></i> remixicon-forbid-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-forbid-2-fill"></i> remixicon-forbid-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-information-fill"></i> remixicon-information-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-error-warning-fill"></i> remixicon-error-warning-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-question-fill"></i> remixicon-question-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-blank-fill"></i> remixicon-checkbox-blank-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-fill"></i> remixicon-checkbox-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-indeterminate-fill"></i> remixicon-checkbox-indeterminate-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-add-box-fill"></i> remixicon-add-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-blank-circle-fill"></i> remixicon-checkbox-blank-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-circle-line"></i> remixicon-checkbox-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-indeterminate-circle-line"></i> remixicon-indeterminate-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-add-circle-line"></i> remixicon-add-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-close-circle-line"></i> remixicon-close-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-radio-button-line"></i> remixicon-radio-button-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-check-line"></i> remixicon-check-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-close-line"></i> remixicon-close-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-add-line"></i> remixicon-add-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-subtract-line"></i> remixicon-subtract-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-divide-line"></i> remixicon-divide-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-checkbox-circle-fill"></i> remixicon-checkbox-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-indeterminate-circle-fill"></i> remixicon-indeterminate-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-add-circle-fill"></i> remixicon-add-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-close-circle-fill"></i> remixicon-close-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-radio-button-fill"></i> remixicon-radio-button-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-check-fill"></i> remixicon-check-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-close-fill"></i> remixicon-close-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-add-fill"></i> remixicon-add-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-subtract-fill"></i> remixicon-subtract-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-divide-fill"></i> remixicon-divide-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-up-line"></i> remixicon-arrow-left-up-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-up-line"></i> remixicon-arrow-up-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-up-line"></i> remixicon-arrow-right-up-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-line"></i> remixicon-arrow-right-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-down-line"></i> remixicon-arrow-right-down-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-down-line"></i> remixicon-arrow-down-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-down-line"></i> remixicon-arrow-left-down-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-line"></i> remixicon-arrow-left-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-up-circle-line"></i> remixicon-arrow-up-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-circle-line"></i> remixicon-arrow-right-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-up-fill"></i> remixicon-arrow-left-up-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-up-fill"></i> remixicon-arrow-up-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-up-fill"></i> remixicon-arrow-right-up-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-fill"></i> remixicon-arrow-right-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-down-fill"></i> remixicon-arrow-right-down-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-down-fill"></i> remixicon-arrow-down-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-down-fill"></i> remixicon-arrow-left-down-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-fill"></i> remixicon-arrow-left-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-up-circle-fill"></i> remixicon-arrow-up-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-circle-fill"></i> remixicon-arrow-right-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-down-circle-line"></i> remixicon-arrow-down-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-circle-line"></i> remixicon-arrow-left-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-up-s-line"></i> remixicon-arrow-up-s-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-down-s-line"></i> remixicon-arrow-down-s-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-s-line"></i> remixicon-arrow-right-s-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-s-line"></i> remixicon-arrow-left-s-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-up-line"></i> remixicon-arrow-drop-up-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-right-line"></i> remixicon-arrow-drop-right-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-down-line"></i> remixicon-arrow-drop-down-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-left-line"></i> remixicon-arrow-drop-left-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-down-circle-fill"></i> remixicon-arrow-down-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-circle-fill"></i> remixicon-arrow-left-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-up-s-fill"></i> remixicon-arrow-up-s-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-down-s-fill"></i> remixicon-arrow-down-s-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-right-s-fill"></i> remixicon-arrow-right-s-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-left-s-fill"></i> remixicon-arrow-left-s-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-up-fill"></i> remixicon-arrow-drop-up-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-right-fill"></i> remixicon-arrow-drop-right-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-down-fill"></i> remixicon-arrow-drop-down-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-drop-left-fill"></i> remixicon-arrow-drop-left-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-go-back-line"></i> remixicon-arrow-go-back-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-go-forward-line"></i> remixicon-arrow-go-forward-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-download-line"></i> remixicon-download-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-upload-line"></i> remixicon-upload-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-download-2-line"></i> remixicon-download-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-upload-2-line"></i> remixicon-upload-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-download-cloud-line"></i> remixicon-download-cloud-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-upload-cloud-line"></i> remixicon-upload-cloud-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-login-box-line"></i> remixicon-login-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-logout-box-line"></i> remixicon-logout-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-go-back-fill"></i> remixicon-arrow-go-back-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-arrow-go-forward-fill"></i> remixicon-arrow-go-forward-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-download-fill"></i> remixicon-download-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-upload-fill"></i> remixicon-upload-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-download-2-fill"></i> remixicon-download-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-upload-2-fill"></i> remixicon-upload-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-download-cloud-fill"></i> remixicon-download-cloud-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-upload-cloud-fill"></i> remixicon-upload-cloud-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-login-box-fill"></i> remixicon-login-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-logout-box-fill"></i> remixicon-logout-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-login-circle-line"></i> remixicon-login-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-logout-circle-line"></i> remixicon-logout-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shut-down-line"></i> remixicon-shut-down-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-refresh-line"></i> remixicon-refresh-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-restart-line"></i> remixicon-restart-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shield-line"></i> remixicon-shield-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shield-cross-line"></i> remixicon-shield-cross-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shield-flash-line"></i> remixicon-shield-flash-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-back-line"></i> remixicon-delete-back-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-back-2-line"></i> remixicon-delete-back-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-login-circle-fill"></i> remixicon-login-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-logout-circle-fill"></i> remixicon-logout-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shut-down-fill"></i> remixicon-shut-down-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-refresh-fill"></i> remixicon-refresh-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-restart-fill"></i> remixicon-restart-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shield-fill"></i> remixicon-shield-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shield-cross-fill"></i> remixicon-shield-cross-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-shield-flash-fill"></i> remixicon-shield-flash-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-back-fill"></i> remixicon-delete-back-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-back-2-fill"></i> remixicon-delete-back-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-line"></i> remixicon-delete-bin-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-2-line"></i> remixicon-delete-bin-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-3-line"></i> remixicon-delete-bin-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-4-line"></i> remixicon-delete-bin-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-5-line"></i> remixicon-delete-bin-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-6-line"></i> remixicon-delete-bin-6-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-7-line"></i> remixicon-delete-bin-7-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-line"></i> remixicon-lock-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-2-line"></i> remixicon-lock-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-password-line"></i> remixicon-lock-password-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-fill"></i> remixicon-delete-bin-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-2-fill"></i> remixicon-delete-bin-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-3-fill"></i> remixicon-delete-bin-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-4-fill"></i> remixicon-delete-bin-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-5-fill"></i> remixicon-delete-bin-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-6-fill"></i> remixicon-delete-bin-6-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-delete-bin-7-fill"></i> remixicon-delete-bin-7-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-fill"></i> remixicon-lock-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-2-fill"></i> remixicon-lock-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-password-fill"></i> remixicon-lock-password-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-unlock-line"></i> remixicon-lock-unlock-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eye-line"></i> remixicon-eye-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eye-off-line"></i> remixicon-eye-off-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eye-close-line"></i> remixicon-eye-close-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-search-line"></i> remixicon-search-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-search-2-line"></i> remixicon-search-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-search-eye-line"></i> remixicon-search-eye-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zoom-in-line"></i> remixicon-zoom-in-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zoom-out-line"></i> remixicon-zoom-out-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-find-replace-line"></i> remixicon-find-replace-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-lock-unlock-fill"></i> remixicon-lock-unlock-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eye-fill"></i> remixicon-eye-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eye-off-fill"></i> remixicon-eye-off-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-eye-close-fill"></i> remixicon-eye-close-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-search-fill"></i> remixicon-search-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-search-2-fill"></i> remixicon-search-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-search-eye-fill"></i> remixicon-search-eye-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zoom-in-fill"></i> remixicon-zoom-in-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-zoom-out-fill"></i> remixicon-zoom-out-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-find-replace-fill"></i> remixicon-find-replace-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-line"></i> remixicon-share-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-box-line"></i> remixicon-share-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-circle-line"></i> remixicon-share-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-forward-line"></i> remixicon-share-forward-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-forward-2-line"></i> remixicon-share-forward-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-forward-box-line"></i> remixicon-share-forward-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-side-bar-line"></i> remixicon-side-bar-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rss-line"></i> remixicon-rss-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scan-line"></i> remixicon-scan-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scan-2-line"></i> remixicon-scan-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-fill"></i> remixicon-share-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-box-fill"></i> remixicon-share-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-circle-fill"></i> remixicon-share-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-forward-fill"></i> remixicon-share-forward-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-forward-2-fill"></i> remixicon-share-forward-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-share-forward-box-fill"></i> remixicon-share-forward-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-side-bar-fill"></i> remixicon-side-bar-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-rss-fill"></i> remixicon-rss-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scan-fill"></i> remixicon-scan-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-scan-2-fill"></i> remixicon-scan-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bluetooth-line"></i> remixicon-bluetooth-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bluetooth-connect-line"></i> remixicon-bluetooth-connect-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wifi-line"></i> remixicon-wifi-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-time-line"></i> remixicon-time-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-timer-line"></i> remixicon-timer-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-timer-2-line"></i> remixicon-timer-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-alarm-line"></i> remixicon-alarm-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qr-code-line"></i> remixicon-qr-code-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qr-scan-line"></i> remixicon-qr-scan-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qr-scan-2-line"></i> remixicon-qr-scan-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bluetooth-fill"></i> remixicon-bluetooth-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-bluetooth-connect-fill"></i> remixicon-bluetooth-connect-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-wifi-fill"></i> remixicon-wifi-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-time-fill"></i> remixicon-time-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-timer-fill"></i> remixicon-timer-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-timer-2-fill"></i> remixicon-timer-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-alarm-fill"></i> remixicon-alarm-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qr-code-fill"></i> remixicon-qr-code-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qr-scan-fill"></i> remixicon-qr-scan-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-qr-scan-2-fill"></i> remixicon-qr-scan-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gps-line"></i> remixicon-gps-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-moon-line"></i> remixicon-moon-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-thumb-down-line"></i> remixicon-thumb-down-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-thumb-up-line"></i> remixicon-thumb-up-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-alarm-warning-line"></i> remixicon-alarm-warning-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-badge-line"></i> remixicon-notification-badge-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-base-station-line"></i> remixicon-base-station-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-barcode-line"></i> remixicon-barcode-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-gps-fill"></i> remixicon-gps-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-moon-fill"></i> remixicon-moon-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-thumb-down-fill"></i> remixicon-thumb-down-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-thumb-up-fill"></i> remixicon-thumb-up-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-alarm-warning-fill"></i> remixicon-alarm-warning-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-notification-badge-fill"></i> remixicon-notification-badge-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-base-station-fill"></i> remixicon-base-station-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-barcode-fill"></i> remixicon-barcode-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->

  <div class="row">
    <div class="col-12">
      <div class="card">

        <div class="card-body">
          <h4 class="m-t-0 header-title mb-4">User</h4>

          <div class="row icons-list-demo">
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-line"></i> remixicon-user-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-2-line"></i> remixicon-user-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-3-line"></i> remixicon-user-3-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-4-line"></i> remixicon-user-4-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-5-line"></i> remixicon-user-5-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-smile-line"></i> remixicon-user-smile-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-box-line"></i> remixicon-account-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-circle-line"></i> remixicon-account-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-pin-box-line"></i> remixicon-account-pin-box-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-pin-circle-line"></i> remixicon-account-pin-circle-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-fill"></i> remixicon-user-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-2-fill"></i> remixicon-user-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-3-fill"></i> remixicon-user-3-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-4-fill"></i> remixicon-user-4-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-5-fill"></i> remixicon-user-5-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-smile-fill"></i> remixicon-user-smile-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-box-fill"></i> remixicon-account-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-circle-fill"></i> remixicon-account-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-pin-box-fill"></i> remixicon-account-pin-box-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-account-pin-circle-fill"></i> remixicon-account-pin-circle-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-add-line"></i> remixicon-user-add-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-follow-line"></i> remixicon-user-follow-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-unfollow-line"></i> remixicon-user-unfollow-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-location-line"></i> remixicon-user-location-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-search-line"></i> remixicon-user-search-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-settings-line"></i> remixicon-user-settings-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-star-line"></i> remixicon-user-star-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-admin-line"></i> remixicon-admin-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contacts-line"></i> remixicon-contacts-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-group-line"></i> remixicon-group-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-add-fill"></i> remixicon-user-add-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-follow-fill"></i> remixicon-user-follow-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-unfollow-fill"></i> remixicon-user-unfollow-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-location-fill"></i> remixicon-user-location-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-search-fill"></i> remixicon-user-search-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-settings-fill"></i> remixicon-user-settings-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-star-fill"></i> remixicon-user-star-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-admin-fill"></i> remixicon-admin-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-contacts-fill"></i> remixicon-contacts-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-group-fill"></i> remixicon-group-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-voice-line"></i> remixicon-user-voice-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-line"></i> remixicon-emotion-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-2-line"></i> remixicon-emotion-2-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-happy-line"></i> remixicon-emotion-happy-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-normal-line"></i> remixicon-emotion-normal-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-unhappy-line"></i> remixicon-emotion-unhappy-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-men-line"></i> remixicon-men-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-women-line"></i> remixicon-women-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-open-arm-line"></i> remixicon-open-arm-line
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-user-voice-fill"></i> remixicon-user-voice-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-fill"></i> remixicon-emotion-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-2-fill"></i> remixicon-emotion-2-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-happy-fill"></i> remixicon-emotion-happy-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-normal-fill"></i> remixicon-emotion-normal-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-emotion-unhappy-fill"></i> remixicon-emotion-unhappy-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-men-fill"></i> remixicon-men-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-women-fill"></i> remixicon-women-fill
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3">
              <i class="remixicon-open-arm-fill"></i> remixicon-open-arm-fill
            </div>
          </div>
        </div> <!-- end card-body -->
      </div> <!-- end card -->
    </div> <!-- end col -->
  </div> <!-- end row -->
</div>