<!-- Right Sidebar -->
<div (clickOutside)="hide()" [exclude]="'.right-bar-toggle'" class="right-bar">
    <div class="rightbar-title">
        <a (click)="hide();" class="right-bar-toggle float-right" href="javascript:void(0);">
            <i class="fe-x noti-icon"></i>
        </a>
        <h4 class="m-0 text-white">Chuyển động</h4>
    </div>
    <div appSlimScroll class="slimscroll-menu">
        <!-- User box -->
        <!--    <div class="user-box">-->
        <!--      <div class="user-img">-->
        <!--        <img src="assets/images/users/avatar-1.jpg" alt="user-img" title="Mat Helme" class="rounded-circle img-fluid">-->
        <!--        <a href="javascript:void(0);" class="user-edit"><i class="mdi mdi-pencil"></i></a>-->
        <!--      </div>-->

        <!--      <h5><a href="javascript: void(0);">Nik G. Patel</a> </h5>-->
        <!--      <p class="text-muted mb-0"><small>Admin Head</small></p>-->
        <!--    </div>-->

        <!--    <div class="inbox-widget pl-3 pr-3">-->
        <!--      <h5 class="mt-0">Recent</h5>-->
        <!--      <div class="inbox-item" *ngFor="let inbox of inboxData">-->
        <!--        <div class="inbox-item-img"><img src="{{ inbox.image }}" class="rounded-circle" alt=""> <i-->
        <!--            class="online user-status"></i></div>-->
        <!--        <p class="inbox-item-author"><a href="javascript: void(0);" class="text-dark">{{ inbox.name }}</a></p>-->
        <!--        <p class="inbox-item-text">{{ inbox.message }}</p>-->
        <!--      </div>-->
        <!--    </div>-->

        <div class="row">
            <div class="col-12">
                <div class="timeline" dir="ltr">

                    <article *ngFor="let data of timelineData; let i = index;"
                             [ngClass]="{ 'timeline-item-left': data.align === 'left'}"
                             class="timeline-item">
                        <div *ngIf="data.button">
                            <h2 class="m-0 d-none">&nbsp;</h2>
                            <div *ngIf="data.button" class="time-show">
                                <a class="btn btn-primary width-lg" href="javascript: void(0);">{{data.button}}</a>
                            </div>
                        </div>

                        <div class="timeline-desk">
                            <div *ngIf="!data.button" class="timeline-box">
                                <span [ngClass]="{'arrow-alt': data.align === 'left','arrow': data.align !== 'left'}"
                                      class="arrow"></span>

                                <span class="timeline-icon"><i class="mdi mdi-adjust"></i></span>
                                <button (click)="getRecordVideo(data)" class="btn btn-outline-primary">{{data.time}}</button>
                                <p class="text-muted"><small>{{data.minute}}</small></p>
                                <p class="mb-0">{{data.description}} </p>
                                <div *ngIf="data.image" class="timeline-album">
                                    <a href="javascript: void(0);">
                                        <img alt="" src="{{data.image[0]}}">
                                    </a>
                                    <a href="javascript: void(0);">
                                        <img alt="" src="{{data.image[1]}}">
                                    </a>
                                    <a href="javascript: void(0);">
                                        <img alt="" src="{{data.image[2]}}">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </article>

                </div>
                <!-- end timeline -->
            </div> <!-- end col -->
        </div>
    </div> <!-- end slimscroll-menu-->
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>