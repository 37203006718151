<div class="container-fluid">
  <app-page-title title="File Uploads" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="header-title m-t-0">Dropzone File Upload</h4>
                <p class="text-muted font-13 m-b-30">
                    DropzoneJS is an open source library that provides drag’n’drop file uploads with image previews.
                </p>
                <!-- file upload -->
                <file-upload class="dropzone"></file-upload>
            </div>
        </div>
    </div>
  </div>
</div>