<div class="container-fluid">
    <app-page-title title="Profile" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-lg-4 col-xl-4">
            <div class="card-box text-center">
                <img src="assets/images/users/avatar-1.jpg" class="rounded-circle avatar-xl img-thumbnail"
                    alt="profile-image">

                <h4 class="mb-0">Nik G. Patel</h4>
                <p class="text-muted">@webdesigner</p>

                <button type="button" class="btn btn-success btn-xs waves-effect mb-2 waves-light">Follow</button>
                <button type="button" class="btn btn-danger btn-xs waves-effect mb-2 waves-light">Message</button>

                <div class="text-left mt-3">
                    <h4 class="font-13 text-uppercase">About Me :</h4>
                    <p class="text-muted font-13 mb-3">
                        Hi I'm Johnathn Deo,has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type.
                    </p>
                    <p class="text-muted mb-2 font-13"><strong>Full Name :</strong> <span class="ml-2">Nik G.
                            Patel</span></p>

                    <p class="text-muted mb-2 font-13"><strong>Mobile :</strong><span class="ml-2">(123)
                            123 1234</span></p>

                    <p class="text-muted mb-2 font-13"><strong>Email :</strong> <span
                            class="ml-2 ">user@email.domain</span></p>

                    <p class="text-muted mb-1 font-13"><strong>Location :</strong> <span class="ml-2">USA</span></p>
                </div>

                <ul class="social-list list-inline mt-3 mb-0">
                    <li class="list-inline-item">
                        <a href="javascript: void(0);" class="social-list-item border-purple text-purple"><i
                                class="mdi mdi-facebook"></i></a>
                    </li>
                    <li class="list-inline-item">
                        <a href="javascript: void(0);" class="social-list-item border-danger text-danger"><i
                                class="mdi mdi-google"></i></a>
                    </li>
                    <li class="list-inline-item">
                        <a href="javascript: void(0);" class="social-list-item border-info text-info"><i
                                class="mdi mdi-twitter"></i></a>
                    </li>
                    <li class="list-inline-item">
                        <a href="javascript: void(0);" class="social-list-item border-secondary text-secondary"><i
                                class="mdi mdi-github-circle"></i></a>
                    </li>
                </ul>
            </div> <!-- end card-box -->

            <div class="card-box">
                <h4 class="header-title">Skills</h4>
                <p class="mb-3">Everyone realizes why a new common language would be desirable</p>
                <div class="pt-1">
                    <h6 class="text-uppercase">HTML5 <span class="float-right">90%</span></h6>
                    <ngb-progressbar type="success" [value]="90" height="5px"></ngb-progressbar>
                    <h6 class="text-uppercase mt-3">PHP <span class="float-right">67%</span></h6>
                    <ngb-progressbar type="success" [value]="67" height="5px"></ngb-progressbar>
                    <h6 class="text-uppercase mt-3">WORDPRESS <span class="float-right">48%</span></h6>
                    <ngb-progressbar type="success" [value]="48" height="5px"></ngb-progressbar>
                    <h6 class="text-uppercase mt-3">LARAVEL <span class="float-right">95%</span></h6>
                    <ngb-progressbar type="success" [value]="95" height="5px"></ngb-progressbar>
                    <h6 class="text-uppercase mt-3">REACTJS <span class="float-right">72%</span></h6>
                    <ngb-progressbar type="success" [value]="72" height="5px"></ngb-progressbar>
                </div>

            </div> <!-- end card-box-->

        </div> <!-- end col-->

        <div class="col-lg-8 col-xl-8">
            <div class="card-box">
                <ngb-tabset type="pills">
                    <ngb-tab title="About Me">
                        <ng-template ngbTabContent>
                            <div class="tab-content">

                                <div class="tab-pane show active" id="about-me">

                                    <h5 class="mb-4 text-uppercase"><i class="mdi mdi-briefcase mr-1"></i>
                                        Experience</h5>

                                    <ul class="list-unstyled timeline-sm">
                                        <li class="timeline-sm-item">
                                            <span class="timeline-sm-date">2015 - 19</span>
                                            <h5 class="mt-0 mb-1">Lead designer / Developer</h5>
                                            <p>websitename.com</p>
                                            <p class="text-muted mt-2">Everyone realizes why a new common language
                                                would be desirable: one could refuse to pay expensive translators.
                                                To achieve this, it would be necessary to have uniform grammar,
                                                pronunciation and more common words.</p>

                                        </li>
                                        <li class="timeline-sm-item">
                                            <span class="timeline-sm-date">2012 - 15</span>
                                            <h5 class="mt-0 mb-1">Senior Graphic Designer</h5>
                                            <p>Software Inc.</p>
                                            <p class="text-muted mt-2">If several languages coalesce, the grammar
                                                of the resulting language is more simple and regular than that of
                                                the individual languages. The new common language will be more
                                                simple and regular than the existing European languages.</p>
                                        </li>
                                        <li class="timeline-sm-item">
                                            <span class="timeline-sm-date">2010 - 12</span>
                                            <h5 class="mt-0 mb-1">Graphic Designer</h5>
                                            <p>Coderthemes LLP</p>
                                            <p class="text-muted mt-2 mb-0">The European languages are members of
                                                the same family. Their separate existence is a myth. For science
                                                music sport etc, Europe uses the same vocabulary. The languages
                                                only differ in their grammar their pronunciation.</p>
                                        </li>
                                    </ul>

                                    <h5 class="mb-3 mt-4 text-uppercase"><i class="mdi mdi-cards-variant mr-1"></i>
                                        Projects</h5>
                                    <div class="table-responsive">
                                        <table class="table table-borderless mb-0">
                                            <thead class="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Project Name</th>
                                                    <th>Start Date</th>
                                                    <th>Due Date</th>
                                                    <th>Status</th>
                                                    <th>Clients</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let project of projectData">
                                                    <td>{{project.id}}</td>
                                                    <td>{{project.name}}}</td>
                                                    <td>{{project.startdate}}</td>
                                                    <td>{{project.duedate}}</td>
                                                    <td><span class="badge badge-info" [ngClass]="
                                                        {
                                                            'badge-pink': project.status === 'Done',
                                                            'badge-purple': project.status === 'Pending',
                                                            'badge-warning': project.status === 'Coming soon'
                                                        }">{{project.status}}</span></td>
                                                    <td>{{project.client}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Settings">
                        <ng-template ngbTabContent>
                            <div class="tab-pane" id="settings">
                                <form>
                                    <h5 class="mb-3 text-uppercase bg-light p-2"><i
                                            class="mdi mdi-account-circle mr-1"></i>
                                        Personal Info</h5>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="firstname">First Name</label>
                                                <input type="text" class="form-control" id="firstname"
                                                    placeholder="Enter first name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="lastname">Last Name</label>
                                                <input type="text" class="form-control" id="lastname"
                                                    placeholder="Enter last name">
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->

                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="userbio">Bio</label>
                                                <textarea class="form-control" id="userbio" rows="4"
                                                    placeholder="Write something..."></textarea>
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="useremail">Email Address</label>
                                                <input type="email" class="form-control" id="useremail"
                                                    placeholder="Enter email">
                                                <span class="form-text text-muted"><small>If you want to change email
                                                        please
                                                        <a href="javascript: void(0);">click</a> here.</small></span>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="userpassword">Password</label>
                                                <input type="password" class="form-control" id="userpassword"
                                                    placeholder="Enter password">
                                                <span class="form-text text-muted"><small>If you want to change password
                                                        please <a href="javascript: void(0);">click</a>
                                                        here.</small></span>
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->

                                    <h5 class="mb-3 text-uppercase bg-light p-2"><i
                                            class="mdi mdi-office-building mr-1"></i> Company Info</h5>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="companyname">Company Name</label>
                                                <input type="text" class="form-control" id="companyname"
                                                    placeholder="Enter company name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="cwebsite">Website</label>
                                                <input type="text" class="form-control" id="cwebsite"
                                                    placeholder="Enter website url">
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->

                                    <h5 class="mb-3 text-uppercase bg-light p-2"><i class="mdi mdi-earth mr-1"></i>
                                        Social
                                    </h5>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="social-fb">Facebook</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fab fa-facebook-square"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="social-fb"
                                                        placeholder="Url">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="social-tw">Twitter</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fab fa-twitter"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="social-tw"
                                                        placeholder="Username">
                                                </div>
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="social-insta">Instagram</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fab fa-instagram"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="social-insta"
                                                        placeholder="Url">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="social-lin">Linkedin</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fab fa-linkedin"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="social-lin"
                                                        placeholder="Url">
                                                </div>
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="social-sky">Skype</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fab fa-skype"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="social-sky"
                                                        placeholder="@username">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="social-gh">Github</label>
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i
                                                                class="fab fa-github"></i></span>
                                                    </div>
                                                    <input type="text" class="form-control" id="social-gh"
                                                        placeholder="Username">
                                                </div>
                                            </div>
                                        </div> <!-- end col -->
                                    </div> <!-- end row -->

                                    <div class="text-right">
                                        <button type="submit" class="btn btn-success waves-effect waves-light mt-2"><i
                                                class="mdi mdi-content-save"></i> Save</button>
                                    </div>
                                </form>
                            </div>
                            <!-- end settings content-->
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>
</div>