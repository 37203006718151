<div class="account-pages mt-5 mb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card">
                    <div class="card-body p-4">
                        <app-ui-preloader [display]="loading"></app-ui-preloader>
                        <!-- Logo -->
                        <div class="text-center w-75 m-auto">
                            <a routerLink="/account/login">
                                <img alt="" height="28" src="../../../../assets/images/logo-icon/logo-aivis-dark.png"/>
                            </a>
                            <p class="text-muted mb-4 mt-3">Enter your email address and we'll send you an email with
                                instructions to
                                reset your password.</p>
                        </div>


                        <form (ngSubmit)="onSubmit()" [formGroup]="resetForm"
                              [hidden]="sentAuth" class="needs-validation" name="resetForm"
                              novalidate>

                            <div class="form-group mb-3">
                                <label>Tài khoản<span class="text-danger">*</span></label>

                                <input [ngClass]="{ 'is-invalid': submitted && f.username.errors}" class="form-control"
                                       formControlName="username"
                                       id="username" placeholder="Tên đăng nhập"
                                />

                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <span *ngIf="f.username.errors.required">Please enter user name.</span>
                                    <span *ngIf="f.username.errors.pattern">Please enter user.</span>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label for="pass1">Mật khẩu mới<span class="text-danger">*</span></label>
                                <input [ngClass]="{'is-invalid': submitted && f.newPass.errors}" class="form-control"
                                       formControlName="newPass" id="pass1"
                                       placeholder="Password" type="password">
                                <div *ngIf="submitted && f.newPass.errors" class="invalid-feedback">
                                    <span *ngIf="f.newPass.errors.required">Please enter new newPass.</span>
                                    <span *ngIf="f.newPass.errors.minlength">Password must be at least 6 characters.</span>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label>Xác nhận mật khẩu mới <span class="text-danger">*</span></label>
                                <input [ngClass]="{'is-invalid': submitted && (f.confirmpwd.errors || f.confirmpwd.value !== f.newPass.value)}"
                                       class="form-control" formControlName="confirmpwd"
                                       id="passWord2"
                                       placeholder="Password" type="password">
                                <div *ngIf="submitted" class="invalid-feedback">
                                    <span *ngIf="!f.confirmpwd.value">Please enter confirm password.</span>
                                    <span *ngIf="(f.confirmpwd.value !== f.newPass.value) && f.confirmpwd.value">This value should be the same.</span>
                                </div>
                            </div>

                            <div class="form-group mb-3">
                                <label class="">Loại xác thực<span class="text-danger">*</span></label>
                                <div class="input-group mb-3">
                                    <ng-select
                                            (change)="changeValidators()"
                                            [clearable]="false"
                                            formControlName="type">
                                        <ng-option [value]="'email'">Email</ng-option>
                                        <ng-option [value]="'phone'">Số điện thoại</ng-option>
                                    </ng-select>

                                    <input *ngIf="f.type.value === 'email'"
                                           [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                           aria-describedby="basic-addon1" aria-label="" class="form-control"
                                           formControlName="email" placeholder="Nhập email"
                                           type="text">
                                    <div *ngIf="submitted && f.email.errors && f.type.value === 'email'"
                                         class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>

                                    <input *ngIf="f.type.value === 'phone'"
                                           [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                                           aria-describedby="basic-addon1" aria-label="" class="form-control"
                                           formControlName="phone" placeholder="Nhập số điện thoại"
                                           type="text">
                                    <div *ngIf="submitted && f.phone.errors && f.type.value === 'phone'"
                                         class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Phone is required</div>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group mb-0 text-center">
                                <button class="btn btn-primary btn-block" type="submit">Gửi</button>
                            </div>
                        </form>

                        <form (ngSubmit)="onSubmitToken()" [formGroup]="verifyForm" [hidden]="!sentAuth"
                              class="needs-validation" name="verifyForm"
                              novalidate>
                            <div class="form-group mb-3">
                                <label>Mã xác thực<span class="text-danger">*</span></label>

                                <input [ngClass]="{ 'is-invalid': submitted && vf.token.errors}" class="form-control"
                                       formControlName="token"
                                       placeholder="Nhập mã xác thực"
                                />

                                <div *ngIf="submitted && vf.token.errors" class="invalid-feedback">
                                    <span *ngIf="vf.token.errors.required">Mã xác thực không được trống</span>
                                </div>
                            </div>

                            <div class="form-group mb-1 text-center">
                                <button (click)="onSubmit()" [disabled]="secondWait > 1"
                                        class="btn btn-warning btn-block" type="button">Gửi lại mã xác
                                    nhận {{secondWait > 1 ? 'sau ' : ''}}
                                    <span *ngIf="secondWait > 1" class="text-danger">{{secondWait | number}}s</span>
                                </button>
                            </div>

                            <div class="form-group mb-0 text-center">
                                <button class="btn btn-primary btn-block"
                                        type="submit">Xác nhận
                                </button>
                            </div>
                        </form>

                    </div> <!-- end card-body-->
                </div>
                <!-- end card -->

                <div class="row mt-3">
                    <div class="col-12 text-center">
                        <p class="text-muted">Back to <a class="text-muted ml-1" routerLink="/account/login"><b>Log
                            In</b></a></p>
                    </div> <!-- end col -->
                </div>
                <!-- end row -->

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
    2021 © Aivis - <span class="text-primary">Stay in the corner, control your world</span>
</footer>