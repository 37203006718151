import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {WidgetsComponent} from './widgets/widgets.component';
import {ListCameraViewComponent} from './list-camera-view/list-camera-view.component';
import {VisTimelineComponent} from './vis-timeline/vis-timeline.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {ImageCropperComponent} from './image-cropper/image-cropper.component';
import {CameraSettingComponent} from './camera-setting/camera-setting.component';
import {CameraMonitorComponent} from './camera-monitor/camera-monitor.component';

const routes: Routes = [
    {path: '', redirectTo: '/list-camera', pathMatch: 'full'},
    {path: 'list-camera', component: ListCameraViewComponent},
    {path: 'camera-monitor', component: CameraMonitorComponent},
    {path: 'history-timeline', component: VisTimelineComponent},
    {path: 'user-management', component: UserManagementComponent},
    {path: 'image-cropper', component: ImageCropperComponent},
    {path: 'camera-setting/:id', component: CameraSettingComponent},
    {path: 'dashboard', loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)},
    {path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)},
    {path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UiModule)},
    {path: 'extras', loadChildren: () => import('./extras/extras.module').then(m => m.ExtrasModule)},
    {path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)},
    {path: 'adminUI', loadChildren: () => import('./adminUI/adminUI.module').then(m => m.AdminUIModule)},
    {path: 'form', loadChildren: () => import('./form/form.module').then(m => m.FormModule)},
    {path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule)},
    {path: 'chart', loadChildren: () => import('./chart/chart.module').then(m => m.ChartModule)},
    {path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)},
    {path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)},
    {path: 'widgets', component: WidgetsComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {}
