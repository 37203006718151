<div class="topbar-menu">
    <div class="container-fluid">
        <div [ngbCollapse]="!showMobileMenu" id="navigation">
            <!-- Navigation Menu-->
            <ul *ngIf="prodNavShow" class="navigation-menu">
                <li class="has-submenu">
                    <a routerLink="/list-camera" routerLinkActive="active">
                        <i class="remixicon-layout-line"></i>Danh sách camera
                    </a>
                </li>

                <li *ngIf="!isMobileMode" class="has-submenu">
                    <a routerLink="/camera-monitor" routerLinkActive="active">
                        <i class="remixicon-layout-line"></i>Camera 24/7
                    </a>
                </li>
            </ul>

            <ul *ngIf="!prodNavShow" class="navigation-menu">

                <li class="has-submenu">
                    <a routerLink="/list-camera" routerLinkActive="active">
                        <i class="remixicon-layout-line"></i> {{'camera.list' | translate}}
                    </a>
                </li>

                <li *ngIf="!isMobileMode" class="has-submenu">
                    <a routerLink="/camera-monitor" routerLinkActive="active">
                        <i class="remixicon-layout-line"></i>Camera 24/7
                    </a>
                </li>

                <!--                <li class="has-submenu">-->
                <!--                    <a href="javascript: void(0);" (click)="onMenuClick($event)">-->
                <!--                        <i class="remixicon-dashboard-line"></i>Dashboards-->
                <!--                        <div class="arrow-down"></div>-->
                <!--                    </a>-->
                <!--                    <ul class="submenu">-->
                <!--                        <li>-->
                <!--                            <a routerLink="/dashboard/dashboard1" class="side-nav-link-ref" routerLinkActive="active">Dashboard-->
                <!--                                1</a>-->
                <!--                        </li>-->
                <!--                        <li>-->
                <!--                            <a routerLink="/dashboard/dashboard2" class="side-nav-link-ref"-->
                <!--                               routerLinkActive="active">Dashboard 2</a>-->
                <!--                        </li>-->
                <!--                    </ul>-->
                <!--                </li>-->

                <li class="has-submenu">
                    <a (click)="onMenuClick($event)" href="javascript: void(0);">
                        <i class="remixicon-dashboard-line"></i>Apps
                        <div class="arrow-down"></div>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a class="side-nav-link-ref" routerLink="/apps/kanbanboard" routerLinkActive="active">Kanban
                                Board</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/apps/companies"
                               routerLinkActive="active">Companies</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/apps/calendar"
                               routerLinkActive="active">Calendar</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/apps/file-manager" routerLinkActive="active">File
                                Manager</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/apps/tickets"
                               routerLinkActive="active">Tickets</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/apps/team-members" routerLinkActive="active">Team
                                Members</a>
                        </li>
                        <li class="has-submenu">
                            <a href="javascript: void(0);">Email
                                <div class="arrow-down"></div>
                            </a>
                            <ul class="submenu">
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/email-inbox"
                                       routerLinkActive="active">Inbox</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/email-read" routerLinkActive="active">Read
                                        Email</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/email-compose"
                                       routerLinkActive="active">Compose Email</a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <!--                <li class="has-submenu">-->
                <!--                    <a href="javascript: void(0);" (click)="onMenuClick($event)"> <i-->
                <!--                            class="remixicon-layout-line"></i>Layouts-->
                <!--                        <div class="arrow-down"></div>-->
                <!--                    </a>-->

                <!--                    <ul class="submenu">-->
                <!--                        <li>-->
                <!--                            <a (click)="darkMenubar()" href="javascript: void(0);" class="side-nav-link-ref"-->
                <!--                               routerLinkActive="active">Menubar-->
                <!--                                Dark</a>-->
                <!--                        </li>-->
                <!--                        <li>-->
                <!--                            <a (click)="lightTopbar()" href="javascript: void(0);" class="side-nav-link-ref"-->
                <!--                               routerLinkActive="active">Topbar-->
                <!--                                Light</a>-->
                <!--                        </li>-->
                <!--                        <li>-->
                <!--                            <a (click)="centerMenu()" href="javascript: void(0);" class="side-nav-link-ref"-->
                <!--                               routerLinkActive="active">Center-->
                <!--                                Menu</a>-->
                <!--                        </li>-->
                <!--                        <li>-->
                <!--                            <a (click)="unstickyHeader()" href="javascript: void(0);" class="side-nav-link-ref"-->
                <!--                               routerLinkActive="active">Unsticky-->
                <!--                                Header</a>-->
                <!--                        </li>-->
                <!--                        <li>-->
                <!--                            <a (click)="boxedLayout()" href="javascript: void(0);" class="side-nav-link-ref"-->
                <!--                               routerLinkActive="active">Boxed</a>-->
                <!--                        </li>-->
                <!--                    </ul>-->
                <!--                </li>-->
                <li class="has-submenu">
                    <a (click)="onMenuClick($event)" href="javascript: void(0);"> <i
                            class="remixicon-briefcase-5-line"></i>UI Elements
                        <div class="arrow-down"></div>
                    </a>
                    <ul class="submenu megamenu">
                        <li>
                            <ul>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/buttons"
                                       routerLinkActive="active">Buttons</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/cards"
                                       routerLinkActive="active">Cards</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/portlets"
                                       routerLinkActive="active">Portlets</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/tabs-accordions"
                                       routerLinkActive="active">Tabs & Accordions</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/modals"
                                       routerLinkActive="active">Modals</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/progress"
                                       routerLinkActive="active">Progress</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/notifications"
                                       routerLinkActive="active">Notifications</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/ribbons"
                                       routerLinkActive="active">Ribbons</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/spinners"
                                       routerLinkActive="active">Spinners</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/ui-general"
                                       routerLinkActive="active">General UI</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/typography"
                                       routerLinkActive="active">Typography</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/ui/grid"
                                       routerLinkActive="active">Grid</a>
                                </li>
                            </ul>
                        </li>

                    </ul>
                </li>

                <li class="has-submenu">
                    <a (click)="onMenuClick($event)" href="javascript: void(0);">
                        <i class="remixicon-honour-line"></i>Components
                        <div class="arrow-down"></div>
                    </a>
                    <ul class="submenu">
                        <li class="has-submenu">
                            <a (click)="onMenuClick($event)" href="javascript: void(0);"><i class="fe-anchor mr-1"></i>
                                Admin UI
                                <div class="arrow-down"></div>
                            </a>
                            <ul class="submenu">
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/adminUI/sweet-alert"
                                       routerLinkActive="active">Sweet Alert</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/adminUI/range-slider"
                                       routerLinkActive="active">Range Slider</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/adminUI/carousel"
                                       routerLinkActive="active">Carousel</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/widgets" routerLinkActive="active"><i
                                    class="fe-aperture mr-1"></i> Widgets </a>
                        </li>
                        <li class="has-submenu">
                            <a (click)="onMenuClick($event)" href="javascript: void(0);"><i
                                    class="fe-bookmark mr-1"></i> Forms
                                <div class="arrow-down"></div>
                            </a>
                            <ul class="submenu">
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/form/elements" routerLinkActive="active">General
                                        Elements</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/form/advanced" routerLinkActive="active">Advanced</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/form/validation"
                                       routerLinkActive="active">Validation</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/form/wizard" routerLinkActive="active">Wizard</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/form/summernote"
                                       routerLinkActive="active">Summernote</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/form/uploads" routerLinkActive="active">File
                                        Uploads</a>
                                </li>
                            </ul>
                        </li>
                        <li class="has-submenu">
                            <a (click)="onMenuClick($event)" href="javascript: void(0);"><i class="fe-grid mr-1"></i>
                                Tables
                                <div class="arrow-down">
                                </div>
                            </a>
                            <ul class="submenu">
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/tables/basic" routerLinkActive="active">Basic
                                        Tables</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/tables/advanced"
                                       routerLinkActive="active">Advanced Tables</a>
                                </li>
                            </ul>
                        </li>
                        <li class="has-submenu">
                            <a (click)="onMenuClick($event)" href="javascript: void(0);"><i
                                    class="fe-bar-chart-2 mr-1"></i> Charts
                                <div class="arrow-down"></div>
                            </a>
                            <ul class="submenu">
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/chart/apex" routerLinkActive="active">Apex
                                        Charts</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/chart/chartjs" routerLinkActive="active">Chartjs
                                        Charts</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/chart/chartist" routerLinkActive="active">Chartist
                                        Charts</a>
                                </li>
                            </ul>
                        </li>
                        <li class="has-submenu">
                            <a (click)="onMenuClick($event)" href="javascript: void(0);"><i class="fe-cpu mr-1"></i>
                                Icons
                                <div class="arrow-down">
                                </div>
                            </a>
                            <ul class="submenu">
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/icons/remix" routerLinkActive="active">Remix
                                        Icons</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/icons/feather" routerLinkActive="active">Feather
                                        Icons</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/icons/material" routerLinkActive="active">Material
                                        Design Icons</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/icons/fontawesome"
                                       routerLinkActive="active">Font Awesome</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/icons/themify" routerLinkActive="active">Themify</a>
                                </li>
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/icons/weather" routerLinkActive="active">Weather</a>
                                </li>
                            </ul>
                        </li>
                        <li class="has-submenu">
                            <a (click)="onMenuClick($event)" href="javascript: void(0);"><i class="fe-map mr-1"></i>
                                Maps
                                <div class="arrow-down">
                                </div>
                            </a>
                            <ul class="submenu">
                                <li>
                                    <a class="side-nav-link-ref" routerLink="/maps/google" routerLinkActive="active">Google
                                        Maps</a>
                                </li>

                            </ul>
                        </li>
                    </ul>
                </li>

                <li class="has-submenu">
                    <a (click)="onMenuClick($event)" href="javascript: void(0);"> <i
                            class="remixicon-layout-line"></i>Errors
                        <div class="arrow-down"></div>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a class="side-nav-link-ref" routerLink="/error/error-404" routerLinkActive="active">Error
                                404</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/error/error-500" routerLinkActive="active">Error
                                500</a>
                        </li>
                    </ul>
                </li>

                <li class="has-submenu">
                    <a (click)="onMenuClick($event)" href="javascript: void(0);"> <i
                            class="remixicon-layout-line"></i>Extra Pages
                        <div class="arrow-down"></div>
                    </a>
                    <ul class="submenu">
                        <li>
                            <a class="side-nav-link-ref" routerLink="/extras/profile"
                               routerLinkActive="active">Profile</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/extras/timeline" routerLinkActive="active">Timeline</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/extras/invoice"
                               routerLinkActive="active">Invoice</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/extras/pricing"
                               routerLinkActive="active">Pricing</a>
                        </li>
                        <li>
                            <a class="side-nav-link-ref" routerLink="/extras/gallery"
                               routerLinkActive="active">Gallery</a>
                        </li>
                    </ul>
                </li>

            </ul>
            <!-- End navigation menu -->

            <div class="clearfix"></div>
        </div>
        <!-- end #navigation -->
    </div>
    <!-- end container -->
</div>
<!-- end navbar-custom -->
