<div class="container-fluid">
  <app-page-title title="Welcome!" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <!-- content-->
  <div class="row">
    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left">
          <apx-chart class="apex-chart-widget" [series]="customerChart.series" [chart]="customerChart.chart"
            [colors]="customerChart.color" [plotOptions]="customerChart.plotOptions" [tooltip]="customerChart.tooltip"
            [stroke]="customerChart.stroke" [grid]="customerChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> 268 </h3>
          <p class="text-muted mb-1">New Customers</p>
        </div>
      </div>
    </div><!-- end col -->

    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left" dir="ltr">
          <apx-chart [series]="orderChart.series" [chart]="orderChart.chart" [colors]="orderChart.color"
            [tooltip]="orderChart.tooltip" [stroke]="orderChart.stroke" [grid]="orderChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> 8715 </h3>
          <p class="text-muted mb-1">Online Orders</p>
        </div>
      </div>
    </div><!-- end col -->

    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left" dir="ltr">
          <apx-chart [series]="revenueChart.series" [chart]="revenueChart.chart" [colors]="revenueChart.color"
            [tooltip]="revenueChart.tooltip" [stroke]="revenueChart.stroke" [grid]="revenueChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> $925.78 </h3>
          <p class="text-muted mb-1">Revenue</p>
        </div>
      </div>
    </div><!-- end col -->

    <div class="col-xl-3 col-md-6">
      <div class="card-box">
        <div class="float-left" dir="ltr">
          <apx-chart [series]="averageChart.series" [chart]="averageChart.chart" [colors]="averageChart.color"
            [tooltip]="averageChart.tooltip" [stroke]="averageChart.stroke" [grid]="averageChart.grid">
          </apx-chart>
        </div>
        <div class="text-right">
          <h3 class="mb-1"> $78.58 </h3>
          <p class="text-muted mb-1">Daily Average</p>
        </div>
      </div>
    </div><!-- end col -->

  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-xl-4">
      <div class="card-box">

        <ng-template [ngTemplateOutlet]="CardActions"></ng-template>

        <h4 class="header-title">Total Revenue</h4>

        <div class="mt-3 text-center">

          <!-- total Revenue area chart-->
          <apx-chart [series]="revenueAreaChart.series" [chart]="revenueAreaChart" [tooltip]="revenueAreaChart.tooltip"
            [stroke]="revenueAreaChart.stroke" [dataLabels]="revenueAreaChart.dataLabels"
            [legend]="revenueAreaChart.legend" [grid]="revenueAreaChart.grid" [xaxis]="revenueAreaChart.xaxis"
            [colors]="revenueAreaChart.colors">
          </apx-chart>
          <!-- end revenue chart-->

          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4> $56,214</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4><i class="fe-arrow-up text-success"></i> $840</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4><i class="fe-arrow-down text-danger"></i> $7,845</h4>
            </div>
          </div>
        </div>
      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-xl-4">
      <div class="card-box">

        <ng-template [ngTemplateOutlet]="CardActions"></ng-template>

        <h4 class="header-title">Yearly Sales Report</h4>

        <div class="mt-3 text-center">

          <!-- Yearly Sales Report bar chart-->
          <apx-chart class="chartcolor" [series]="yearlySalesBarChart.series" [chart]="yearlySalesBarChart"
            [tooltip]="yearlySalesBarChart.tooltip" [colors]="yearlySalesBarChart.color"
            [dataLabels]="yearlySalesBarChart.dataLabels" [legend]="yearlySalesBarChart.legend"
            [stroke]="yearlySalesBarChart.stroke" [grid]="yearlySalesBarChart.grid" [xaxis]="yearlySalesBarChart.xaxis"
            [plotOptions]="yearlySalesBarChart.plotOptions"></apx-chart>
          <!-- end yearly sales chart-->

          <div class="row mt-3">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4>$8712</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4><i class="fe-arrow-up text-success"></i> $523</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4><i class="fe-arrow-down text-danger"></i> $965</h4>
            </div>
          </div>

        </div>
      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-xl-4">
      <div class="card-box">
        <ng-template [ngTemplateOutlet]="CardActions"></ng-template>

        <h4 class="header-title">Weekly Sales Report</h4>

        <div class="mt-3 text-center">

          <!-- Weekly sales report pie chart -->
          <apx-chart [series]="weeklySalesPieChart.series" [chart]="weeklySalesPieChart"
            [colors]="weeklySalesPieChart.piechartcolor" [labels]="weeklySalesPieChart.labels"
            [dataLabels]="weeklySalesPieChart.dataLabels" [tooltip]="weeklySalesPieChart.tooltip"
            [legend]="weeklySalesPieChart.legend" [plotOptions]="weeklySalesPieChart.option"
            [grid]="weeklySalesPieChart.grid">
          </apx-chart>
          <!-- end Weekly sales pie chart -->

          <div class="row">
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Target</p>
              <h4> $12,365</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last week</p>
              <h4><i class="fe-arrow-down text-danger"></i> $365</h4>
            </div>
            <div class="col-4">
              <p class="text-muted font-15 mb-1 text-truncate">Last Month</p>
              <h4><i class="fe-arrow-up text-success"></i> $8,501</h4>
            </div>
          </div>

        </div>
      </div> <!-- end card-box -->
    </div> <!-- end col -->

  </div>
  <!-- end row -->

  <div class="row">

    <div class="col-xl-6">
      <div class="card-box">
        <div class="row">
          <div class="col-md-7">
            <div class="row align-items-center">
              <div class="col-6 text-center">
                <h1 class="display-4"><i class="wi wi-day-sleet text-primary"></i></h1>
              </div>
              <div class="col-6">
                <div class="text-muted">
                  <h2 class="mt-1"><b>32°</b></h2>
                  <p>Partly cloudy</p>
                  <p class=" mb-0">15km/h - 37%</p>
                </div>
              </div>
            </div><!-- End row -->
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-4 text-center">
                <h4 class="text-muted mt-0">SAT</h4>
                <h3 class="my-3"><i class="wi wi-night-alt-cloudy text-primary"></i></h3>
                <h4 class="text-muted mb-0">30<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-muted mt-0">SUN</h4>
                <h3 class="my-3"><i class="wi wi-day-sprinkle text-primary"></i></h3>
                <h4 class="text-muted mb-0">28<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-muted mt-0">MON</h4>
                <h3 class="my-3"><i class="wi wi-hot text-primary"></i></h3>
                <h4 class="text-muted mb-0">33<i class="wi wi-degrees"></i></h4>
              </div>
            </div><!-- end row -->
          </div>
        </div><!-- end row -->
      </div><!-- cardbox -->
      <!-- END Weather WIDGET 1 -->

    </div><!-- End col-xl-6 -->

    <div class="col-xl-6">

      <!-- WEATHER WIDGET 2 -->
      <div class="card-box">

        <div class="row">
          <div class="col-md-7">
            <div class="row align-items-center">
              <div class="col-6 text-center">
                <h1 class="display-4"><i class="wi wi-night-sprinkle text-primary"></i></h1>
              </div>
              <div class="col-6">
                <div class="text-muted">
                  <h2 class="mt-1"><b>18°</b></h2>
                  <p>Partly cloudy</p>
                  <p class=" mb-0">15km/h - 37%</p>
                </div>
              </div>
            </div><!-- End row -->
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-4 text-center">
                <h4 class="text-muted mt-0">SAT</h4>
                <h3 class="my-3"><i class="wi wi-day-sprinkle text-primary"></i></h3>
                <h4 class="text-muted mb-0">30<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-muted mt-0">SUN</h4>
                <h3 class="my-3"><i class="wi wi-storm-showers text-primary"></i></h3>
                <h4 class="text-muted mb-0">28<i class="wi wi-degrees"></i></h4>
              </div>
              <div class="col-4 text-center">
                <h4 class="text-muted mt-0">MON</h4>
                <h3 class="my-3"><i class="wi wi-night-alt-cloudy text-primary"></i></h3>
                <h4 class="text-muted mb-0">33<i class="wi wi-degrees"></i></h4>
              </div>
            </div><!-- end row -->
          </div>
        </div><!-- end row -->
      </div><!-- card-box -->
      <!-- END WEATHER WIDGET 2 -->

    </div><!-- /.col-xl-6 -->
  </div>
  <!-- end row -->

  <div class="row">
    <div class="col-xl-4">
      <div class="card-box">
        <ng-template [ngTemplateOutlet]="CardActions"></ng-template>

        <h4 class="header-title">Earning Reports</h4>
        <p class="text-muted">1 Mar - 31 Mar Showing Data</p>
        <h2 class="mb-4"><i class="mdi mdi-currency-usd text-primary"></i>25,632.78</h2>

        <div class="row mb-4">
          <div class="col-6">
            <p class="text-muted mb-1">This Month</p>
            <h3 class="mt-0 font-20">$120,254 <small class="badge badge-light-success font-13">+15%</small>
            </h3>
          </div>

          <div class="col-6">
            <p class="text-muted mb-1">Last Month</p>
            <h3 class="mt-0 font-20">$98,741 <small class="badge badge-light-danger font-13">-5%</small>
            </h3>
          </div>
        </div>

        <h5 class="font-16 mb-5"><i class="mdi mdi-chart-donut text-primary"></i> Weekly Earning Report</h5>

        <!-- Earning report bar chart -->
        <apx-chart [series]="earningReportBarChart.series" [chart]="earningReportBarChart"
          [tooltip]="earningReportBarChart.tooltip" [colors]="earningReportBarChart.colors"
          [dataLabels]="earningReportBarChart.dataLabels" [plotOptions]="earningReportBarChart.plotOptions">
        </apx-chart>
        <!-- end chart -->

      </div> <!-- end card-box -->
    </div> <!-- end col -->
    <div class="col-xl-8">
      <div class="card-box">
        <ng-template [ngTemplateOutlet]="CardActions"></ng-template>

        <h4 class="header-title mb-3">Revenue History</h4>

        <div class="table-responsive">
          <table class="table table-borderless table-hover table-centered m-0">
            <thead class="thead-light">
              <tr>
                <th>Marketplaces</th>
                <th>Date</th>
                <th>US Tax Hold</th>
                <th>Payouts</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of tabledata">
                <td>
                  <h5 class="m-0 font-weight-normal">{{ data.marketplaces }}</h5>
                </td>
                <td>{{ data.date }}</td>
                <td>{{ data.taxhold }}</td>
                <td>{{ data.payout }}</td>
                <td><span class="badge" [ngClass]="{
                                'badge-light-success': data.status === 'Paid',
                                'badge-light-warning': data.status === 'Upcoming',  
                                'badge-light-danger': data.status === 'Overdue'  
                            }">{{ data.status }} </span></td>
                <td>
                  <a href="javascript: void(0);" class="btn btn-xs btn-secondary"><i class="mdi mdi-pencil"></i></a>
                </td>
              </tr>
            </tbody>
          </table>

        </div> <!-- end .table-responsive-->
      </div> <!-- end card-box-->
    </div> <!-- end col -->
  </div>
  <!-- end row -->

</div>
<ng-template #CardActions>
    <div class="dropdown float-right" ngbDropdown>
        <a href="javascript: void(0)" class="dropdown-toggle arrow-none card-drop" data-toggle="dropdown"
            aria-expanded="false" ngbDropdownToggle>
            <i class="mdi mdi-dots-horizontal"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item">Settings</a>
            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item">Download</a>
            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item">Upload</a>
            <!-- item-->
            <a href="javascript:void(0);" class="dropdown-item">Action</a>
        </div>
    </div>
</ng-template>