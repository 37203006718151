<div class="container-fluid">
  <app-page-title title="Companies" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card-box">
        <div class="row">
          <div class="col-lg-8">
            <form class="form-inline">
              <div class="form-group">
                <label class="sr-only">Search</label>
                <input type="search" class="form-control" name="search" placeholder="Search..." [(ngModel)]="term">
              </div>
              <div class="form-group mx-sm-3">
                <label for="status-select" class="mr-2">Sort By</label>
                <select class="custom-select" id="status-select">
                  <option selected="">All</option>
                  <option value="1">Date</option>
                  <option value="2">Name</option>
                  <option value="3">Revenue</option>
                  <option value="4">Employees</option>
                </select>
              </div>
            </form>
          </div>
          <div class="col-lg-4">
            <div class="text-lg-right mt-3 mt-lg-0">
              <button type="button" class="btn btn-success waves-effect waves-light mr-1"><i
                  class="mdi mdi-settings"></i></button>
              <a class="btn text-white btn-danger waves-effect waves-light" (click)="openModal(content)"><i
                  class="mdi mdi-plus-circle mr-1"></i> Add New
              </a>
            </div>
          </div><!-- end col-->
        </div> <!-- end row -->
      </div> <!-- end card-box -->
    </div><!-- end col-->
  </div>
  
  <div class="row">
    <ng-container *ngFor="let company of companiesData |filter:term">

      <div class="col-md-4">
        <div class="card-box">
          <div class="text-center">
            <img src="{{ company.logo }}" alt="logo" class="avatar-xl rounded-circle mb-3">
            <h4 class="mb-1">{{ company.name }} </h4>
            <p class="text-muted  font-14"> {{ company.country }}</p>
          </div>

          <p class="font-14 text-center text-muted">{{ company.about }}</p>

          <div class="text-center">
            <a href="javascript:void(0);" class="btn btn-sm btn-secondary">View more info</a>
          </div>

          <div class="row mt-5 text-center" *ngIf="company.chart">
            <div class="col-6">
              <h5 class="font-weight-normal text-muted">Revenue (USD)</h5>
              <h3 class="m-b-30">{{ company.revenue }} </h3>
            </div>
            <div class="col-6">
              <h5 class="font-weight-normal text-muted">Number of employees</h5>
              <h3 class="m-b-30">{{ company.employees }} </h3>
            </div>
          </div>

          <div class="text-center" *ngIf="company.chart">
            <!-- chart -->
            <apx-chart [chart]="companyChart" [series]="company.chart.series" [colors]="companyChart.colors"
              [colors]="companyChart.colors" [dataLabels]="companyChart.dataLabels" [stroke]="companyChart.stroke"
              [tooltip]="companyChart.tooltip">
            </apx-chart>
            <!-- End chart -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div><!-- end col -->


<!-- Modal  Data-->
<ng-template #content role="document" let-modal="close">

  <div class="modal-header bg-primary">
    <h4 class="modal-title text-white">Add Company</h4>
    <button type="button" class="close text-white" aria-label="Close" (click)="modal('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Modal Body -->
  <div class="modal-body p-3">
    <div class="text-left">
      <form (ngSubmit)="saveData()" [formGroup]="validationform">

        <div class="form-group">
          <label for="name">Company Name</label>
          <input type="text" class="form-control" id="name" placeholder="Enter company name" formControlName="name"
            [ngClass]="{'is-invalid': submitted && form.name.errors}">
          <div *ngIf="submitted && form.name.errors" class="invalid-feedback">
            <span *ngIf="form.name.errors.required">This value is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="location">Location</label>
          <input type="text" class="form-control" id="location" placeholder="Enter location" formControlName="location"
            [ngClass]="{'is-invalid': submitted && form.location.errors}">
          <div *ngIf="submitted && form.location.errors" class="invalid-feedback">
            <span *ngIf="form.location.errors.required">This value is required.</span>
          </div>
        </div>

        <div class="form-group">
          <label for="about-details">About </label>
          <textarea class="form-control" id="about-details" rows="3" placeholder="Enter about" formControlName="about"
            [ngClass]="{'is-invalid': submitted && form.about.errors}"></textarea>
          <div *ngIf="submitted && form.about.errors" class="invalid-feedback">
            <span *ngIf="form.about.errors.required">This value is required.</span>
          </div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success waves-effect waves-light">Save</button>
          <button type="button" class="btn btn-danger waves-effect waves-light ml-1"
            (click)="modal('close click')">Cancel</button>
        </div>
      </form>
    </div>
  </div>
  <!-- End body -->
</ng-template>