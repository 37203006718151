<div class="container-fluid">
  <app-page-title title="Kanban Board" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-lg-4">
      <div class="card-box">
        <ng-template [ngTemplateOutlet]="CardAction"></ng-template> <!-- dropdown -->

        <h4 class="header-title">Upcoming</h4>
        <p class="sub-header">
          Your awesome text goes here. Your awesome text goes here.
        </p>

        <ul class="sortable-list tasklist list-unstyled" id="upcoming" dndDropzone dndEffectAllowed="move"
          (dndDrop)="onDrop($event, pendingTasks, 'pending')">
          <li class="dndPlaceholder" dndPlaceholderRef></li>
          <ng-container *ngFor="let task of pendingTasks">
            <li class="task-medium" [ngClass]=" 
              {
                'task-low': task.task === 'low',
                'task-high': task.task === 'high',
                'task-medium': task.task === 'medium' 
              }" [dndDraggable]="task" dndEffectAllowed="move" (dndMoved)="onDragged(task, pendingTasks)">
              <ng-template [ngTemplateOutlet]="TaskContent" [ngTemplateOutletContext]="{task:task}"></ng-template>
            </li>
          </ng-container>
        </ul>
        <a href="javascript: void(0);" class="btn btn-primary btn-block mt-3 waves-effect waves-light"><i
            class="mdi mdi-plus-circle"></i> Add New</a>
      </div>
    </div> <!-- end col -->

    <div class="col-lg-4">
      <div class="card-box">
        <ng-template [ngTemplateOutlet]="CardAction"></ng-template> <!-- dropdown -->

        <h4 class="header-title">In Progress</h4>
        <p class="sub-header">
          Your awesome text goes here.
        </p>

        <ul class="sortable-list tasklist list-unstyled" id="inprogress" dndDropzone dndEffectAllowed="move"
          (dndDrop)="onDrop($event, inprogressTasks, 'inprogress')">
          <li class="dndPlaceholder" dndPlaceholderRef></li>

          <ng-container *ngFor="let task of inprogressTasks">
            <li class="task-medium" [ngClass]="
                {
                  'task-low': task.task === 'low',
                  'task-high': task.task === 'high',
                  'task-medium': task.task === 'medium' 
                }" [dndDraggable]="task" dndEffectAllowed="move" (dndMoved)="onDragged(task, inprogressTasks)">
              <ng-template [ngTemplateOutlet]="TaskContent" [ngTemplateOutletContext]="{task:task}"></ng-template>
            </li>
          </ng-container>
        </ul>

        <a href="javascript: void(0);" class="btn btn-primary btn-block mt-3 waves-effect waves-light"><i
            class="mdi mdi-plus-circle"></i> Add New</a>
      </div> <!-- end card-box -->
    </div> <!-- end col -->

    <div class="col-lg-4">
      <div class="card-box">
        <ng-template [ngTemplateOutlet]="CardAction"></ng-template> <!-- dropdown -->

        <h4 class="header-title">Completed</h4>
        <p class="sub-header">
          Your awesome text goes here. Your awesome text goes here.
        </p>

        <ul class="sortable-list tasklist list-unstyled" id="completed" dndDropzone dndEffectAllowed="move"
          (dndDrop)="onDrop($event, completedTasks, 'completed')">
          <li class="dndPlaceholder" dndPlaceholderRef></li>
          <ng-container *ngFor="let task of completedTasks">
            <li class="task-medium" [ngClass]="
                  {
                    'task-low': task.task === 'low',
                    'task-high': task.task === 'high',
                    'task-medium': task.task === 'medium' 
                  }" [dndDraggable]="task" dndEffectAllowed="move" (dndMoved)="onDragged(task, completedTasks)">
              <ng-template [ngTemplateOutlet]="TaskContent" [ngTemplateOutletContext]="{task:task}"></ng-template>
            </li>
          </ng-container>
        </ul>
        <a href="javascript: void(0);" class="btn btn-primary btn-block mt-3 waves-effect waves-light"><i
            class="mdi mdi-plus-circle"></i> Add New</a>
      </div> <!-- end card-box -->
    </div> <!-- end col -->

  </div>

</div>

<!-- Card Action Dropdown -->
<ng-template #CardAction>
  <div class="dropdown float-right" ngbDropdown>
    <a href="javascript: void(0);" class="dropdown-toggle arrow-none" data-toggle="dropdown" aria-expanded="false"
      ngbDropdownToggle>
      <i class="mdi mdi-dots-vertical m-0 text-muted h3"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
      <a class="dropdown-item" href="javascript:void(0);">Edit</a>
      <a class="dropdown-item" href="javascript:void(0);">Delete</a>
      <a class="dropdown-item" href="javascript:void(0);">Add Members</a>
      <a class="dropdown-item" href="javascript:void(0);">Add Due Date</a>
    </div>
  </div> <!-- end dropdown -->
</ng-template>

<!-- Task  -->
<ng-template #TaskContent let-task='task'>

  <div class="checkbox checkbox-blue mb-2 checkbox-single float-right">
    <input type="checkbox">
    <label></label>
  </div>
  <h5 class="mt-0"><a href="javascript: void(0);" class="text-dark">{{ task.title }}</a></h5>

  <p>{{ task.description }}</p>
  <div class="clearfix"></div>
  <div class="row">
    <div class="col-auto">

      <a href="javascript: void(0);" class="text-muted">
        <img src="{{ task.user[0] }}" alt="task-user" class="avatar-sm img-thumbnail rounded-circle">
        <img src="{{ task.user[1] }}" alt="task-user" class="avatar-sm img-thumbnail rounded-circle"
          *ngIf="task.user[1]">
      </a>
    </div>

    <div class="col">
      <div class="text-right">
        <p class="font-13 mt-2 mb-0"><i class="mdi mdi-calendar"></i> {{ task.date }}</p>
      </div>
    </div>
  </div>
</ng-template>