<div class="container-fluid">
  <app-page-title title="Form Advanced" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <!-- Color picker -->
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Colorpicker</h4>
        <p class="sub-header">Bootstrap Colorpicker is a modular color picker plugin for Bootstrap 4</p>

        <div class="form-group mb-3">
          <label>Default</label>
          <input type="text" class="colorpicker-default form-control" [value]="color" [(colorPicker)]="color"
            [cpOutputFormat]="'rgba'" [cpPosition]="'bottom'">
        </div>

        <div class="form-group mb-3">
          <label>Hex Color</label>
          <input type="text" class="colorpicker-default form-control" [value]="color" [(colorPicker)]="color"
            [cpOutputFormat]="'hex'" [cpPosition]="'bottom'">
        </div>
        <div class="form-group mb-3">
          <label>As Component</label>

          <div id="component-colorpicker" class="input-group" title="Using format option">
            <input type="text" class="form-control input-lg" [value]="componentcolor">
            <span class="input-group-append">
              <span class="input-group-text colorpicker-input-addon" [style.background]="componentcolor"
                [(colorPicker)]="componentcolor" [cpPosition]="'bottom'"><i></i></span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- End color picker -->

    <!-- Date picker -->
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Date Picker</h4>
        <p class="sub-header">Examples of bootstrap datepicker.</p>

        <div>
          <div class="form-group mb-3">
            <label>Default Clock Picker</label>
            <div class="input-group clockpicker">
              <input ngbDatepicker class="form-control" autoClose="false" placeholder="yyyy-mm-dd"
                #date="ngbDatepicker">
              <div class="input-group-append" (click)="date.toggle()">
                <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label>Auto close</label>
            <div class="input-group clockpicker">
              <input ngbDatepicker class="form-control" placeholder="yyyy-mm-dd" #d="ngbDatepicker">
              <div class="input-group-append" (click)="d.toggle()">
                <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
              </div>
            </div>
          </div>

          <div class="form-group mb-3">
            <label>Date Range</label>

            <ng-template #t let-date let-focused="focused">
              <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
                {{ date.day }}
              </span>
            </ng-template>

            <input type="text" class="form-control date" (click)="hidden=!hidden" name="daterange" autocomplete="off"
              [(ngModel)]="selected" />

            <ngb-datepicker #dp (select)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
              outsideDays="hidden" [hidden]="hidden">
            </ngb-datepicker>

          </div>
        </div>
      </div>
    </div>
    <!-- End datepicker -->
  </div>

  <div class="row">
    <!-- css switch -->
    <div class="col-sm-12">
      <div class="card-box">
        <h4 class="mb-3 header-title">Switchery</h4>
        <div class="row">
          <div class="col-lg-4">
            <h6>Basic</h6>
            <p class="text-muted mb-3 font-13">
              Add an attribute <code>
            data-plugin="switchery" data-color="@colors"</code>
              to your input element and it will be converted into switch.
            </p>
            <!-- Switches -->
            <ui-switch defaultBoColor="#dfdfdf" color="#00b19d" checked></ui-switch>
            <ui-switch defaultBoColor="#dfdfdf" color="#ffaa00" checked class="ml-1"></ui-switch>
            <ui-switch defaultBoColor="#dfdfdf" color="#3bafda" checked class="ml-1"></ui-switch>
            <ui-switch defaultBoColor="#dfdfdf" color="#3DDCF7" checked class="ml-1"></ui-switch>
            <ui-switch defaultBoColor="#dfdfdf" color="#7266ba" checked class="ml-1"></ui-switch>
            <ui-switch defaultBoColor="#dfdfdf" color="#f76397" checked class="ml-1"></ui-switch>
            <ui-switch defaultBoColor="#dfdfdf" color="#4c5667" checked class="ml-1"></ui-switch>
            <ui-switch defaultBoColor="#dfdfdf" color="#98a6ad" checked class="ml-1"></ui-switch>
          </div>
          <div class="col-lg-4">
            <h6>Sizes</h6>
            <p class="text-muted mb-3 font-13">
              Add an attribute <code>
                    data-size="small",data-size="large"</code>
              to your input element and it will be converted into switch.
            </p>
            <div>
              <ui-switch defaultBoColor="#dfdfdf" size="small" color="#00b19d" checked></ui-switch>
              <ui-switch defaultBoColor="#dfdfdf" color="#3DDCF7" checked class="ml-1"></ui-switch>
              <ui-switch defaultBoColor="#dfdfdf" size="large" color="#4c5667" checked class="ml-1"></ui-switch>
            </div>
          </div>
          <div class="col-lg-4">
            <h6>Secondary color</h6>
            <p class="text-muted mb-3 font-13">
              Add an attribute <code>
                data-color="@color" data-secondary-color="@color"</code>
              to your input element and it will be converted into switch.
            </p>

            <div>
              <ui-switch defaultBoColor="#dfdfdf" color="#00b19d" defaultBgColor="#ED5565" checked></ui-switch>
              <ui-switch class="ml-1" defaultBoColor="#dfdfdf" color="#fb6d9d" defaultBgColor="#7266ba" checked>
              </ui-switch>
            </div>
          </div>
        </div>
      </div>
      <!-- End switch -->
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="header-title">Input Masks</h4>
        <p class="sub-header">
          Use the button classes on an <code>&lt;a&gt;</code>, <code>&lt;button&gt;</code>, or
          <code>&lt;input&gt;</code>
          element.
        </p>

        <div class="row">
          <div class="col-md-6">
            <form action="#">
              <div class="form-group">
                <label>Date</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="00/00/0000">
                <span class="font-13 text-muted">e.g "DD/MM/YYYY"</span>
              </div>
              <div class="form-group">
                <label>Hour</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="00:00:00">
                <span class="font-13 text-muted">e.g "HH:MM:SS"</span>
              </div>
              <div class="form-group">
                <label>Date & Hour</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="00/00/0000 00:00:00">
                <span class="font-13 text-muted">e.g "DD/MM/YYYY HH:MM:SS"</span>
              </div>
              <div class="form-group">
                <label>ZIP Code</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="00000-000">
                <span class="font-13 text-muted">e.g "xxxxx-xxx"</span>
              </div>
              <div class="form-group">
                <label>Crazy Zip Code</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="0-00-00-00">
                <span class="font-13 text-muted">e.g "x-xx-xx-xx"</span>
              </div>
              <div class="form-group">
                <label>Money</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="000.000.000.000.000,00"
                  data-reverse="true">
                <span class="font-13 text-muted">e.g "Your money"</span>
              </div>
              <div class="form-group">
                <label>4 digit Group</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="0,0000,0000,0000">
                <span class="font-13 text-muted">e.g. "x,xxxx,xxxx,xxxx"</span>
              </div>

            </form>
          </div> <!-- end col -->

          <div class="col-md-6">
            <form action="#">
              <div class="form-group">
                <label>Telephone</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="0000-0000">
                <span class="font-13 text-muted">e.g "xxxx-xxxx"</span>
              </div>
              <div class="form-group">
                <label>Telephone with Code Area</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="(00) 0000-0000">
                <span class="font-13 text-muted">e.g "(xx) xxxx-xxxx"</span>
              </div>
              <div class="form-group">
                <label>US Telephone</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="(000) 000-0000">
                <span class="font-13 text-muted">e.g "(xxx) xxx-xxxx"</span>
              </div>
              <div class="form-group">
                <label>São Paulo Celphones</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="(00) 00000-0000">
                <span class="font-13 text-muted">e.g "(xx) xxxxx-xxxx"</span>
              </div>
              <div class="form-group">
                <label>CPF</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="000.000.000-00"
                  data-reverse="true">
                <span class="font-13 text-muted">e.g "xxx.xxx.xxxx-xx"</span>
              </div>
              <div class="form-group">
                <label>CNPJ</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="00.000.000/0000-00"
                  data-reverse="true">
                <span class="font-13 text-muted">e.g "xx.xxx.xxx/xxxx-xx"</span>
              </div>
              <div class="form-group">
                <label>IP Address</label>
                <input type="text" class="form-control" data-toggle="input-mask" mask="099.099.099.099"
                  data-reverse="true">
                <span class="font-13 text-muted">e.g "xxx.xxx.xxx.xxx"</span>
              </div>
            </form>
          </div> <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
    <div class="col-lg-6">
      <div class="card-box">
        <h4 class="mb-4 header-title">Select2</h4>
        <h6>Single Select</h6>
        <ng-select [items]="selectValue"></ng-select>
        <h6 class="mt-3">Multiple Select</h6>
        <ng-select [items]="selectValue" [multiple]="true"></ng-select>
      </div>
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">Bootstrap MaxLength</h4>
          <p class="sub-header">Uses the HTML5 attribute "maxlength" to work.</p>

          <label class="mb-1 font-weight-medium text-muted">Default usage</label>
          <p class="text-muted font-13">
            The badge will show up by default when the remaining chars are 10 or less:
          </p>
          <input type="text" class="form-control" maxlength="10" #default (keyup)="0" />
          <div class="text-center">
            <p *ngIf="default.value" class="badge position-absolute" [ngClass]="{'badge-success': default.value.length !== 10,
            'badge-danger': default.value.length == 10}">{{ default.value.length }} / 10</p>
          </div>

          <div>
            <label class="mb-1 mt-3 font-weight-medium text-muted">Threshold value</label>
            <p class="text-muted font-13">
              Do you want the badge to show up when there are 20 chars or less? Use the <code>threshold</code>
              option:
            </p>
            <input type="text" maxlength="25" class="form-control" #data (keyup)="0" />
            <div class="text-center">
              <p *ngIf="data.value" class="badge position-absolute" [ngClass]="{'badge-success': data.value.length !== 25,
              'badge-danger': data.value.length == 25}">{{ data.value.length }} / 25</p>
            </div>
          </div>

          <div>
            <label class="mb-1 mt-3 font-weight-medium text-muted">All the options</label>
            <p class="text-muted font-13">
              Please note: if the <code>alwaysShow</code> option is enabled, the <code>threshold</code> option
              is
              ignored.
            </p>
            <input type="text" class="form-control" maxlength="25" #option (keyup)="0" />
            <div class="text-center">
              <p *ngIf="option.value" class="badge position-absolute" [ngClass]="{'badge-success': option.value.length !== 25,
              'badge-danger': option.value.length == 25}">You typed
                {{ option.value.length }} out of 25 chars available</p>
            </div>
          </div>

          <div>
            <label class="mb-1 mt-3 font-weight-medium text-muted">Textareas</label>
            <p class="text-muted font-13">
              Bootstrap maxlength supports textarea as well as inputs. Even on old IE.
            </p>
            <textarea #textarea class="form-control" maxlength="225" rows="3"
              placeholder="This textarea has a limit of 225 chars."></textarea>
            <div class="text-center">
              <p *ngIf="textarea.value" class="badge position-absolute" [ngClass]="{'badge-success': textarea.value.length !== 225,
            'badge-danger': textarea.value.length == 225}">{{ textarea.value.length }} /
                225</p>
            </div>
          </div>
        </div>
        <!-- End select -->
      </div>

    </div>
  </div>