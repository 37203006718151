import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CommandURL} from '../../shared/constant/ManageURLCommand';
import {CookieService} from '../../core/services/cookie.service';
import {AuthenticationService} from "../../core/services/auth.service";

@Injectable({
    providedIn: 'root'
})
export class VisTimelineService {
    timeline: any;
    token = '';

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService
    ) {
        this.token = this.authService.currentUser().token;
    }

    parseUTCDate(d: Date) {
        return (new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(),
            d.getHours(), d.getMinutes(), d.getSeconds())).getTime() / 1000).toString();
    }

    getTimeline() {
        return this.timeline;
    }

    setTimeline(timeline: any) {
        return this.timeline = timeline;
    }

    getListVideo(cameraId: string, time: number) {
        const timestamp = this.parseUTCDate(new Date(time));
        return this.http.post<any>(CommandURL.GET_LIST_VIDEO_URL, {cameraId, timestamp});
    }

    getRecordVideo(cameraId: string, filename: string) {
        const params = new HttpParams().set('cameraId', cameraId).set('filename', filename).set('token', this.token);
        return this.http.get<any>(CommandURL.GET_RECORD_VIDEO_URL, {params});
    }

    postRecordVideo(cameraId: string, filename: string) {
        return this.http.post<any>(CommandURL.GET_RECORD_VIDEO_URL, {cameraId, filename});
    }

    prepareDetectEventTimeout(cameraId: string, time: number) {
        const timestamp = this.parseUTCDate(new Date(time));
        return this.http.post<any>(CommandURL.PREPARE_DETECT_OBJ_FOR_EVENT_URL, {cameraId, timestamp});
    }

    getEventList(cameraId: string, time: number) {
        const timestamp = this.parseUTCDate(new Date(time));
        return this.http.post<any>(CommandURL.GET_EVENT_LIST_URL, {cameraId, timestamp});
    }

    getEventListFilter(cameraId: string, time: number, objectName: string) {
        const timestamp = this.parseUTCDate(new Date(time));
        return this.http.post<any>(CommandURL.GET_EVENT_LIST_FILTER_URL, {cameraId, timestamp, objectName});
    }

    getEarliestRecordDate(cameraId: string) {
        return this.http.post<any>(CommandURL.GET_EARLIEST_RECORD_DATE_URL, {cameraId});
    }

    loadModeVideoList(cameraId: string, filename: string, type: string) {
        if (type === 'before') {
            return this.http.post<any>(CommandURL.GET_VIDEO_LIST_BEFORE_URL, {cameraId, filename});
        } else {
            return this.http.post<any>(CommandURL.GET_VIDEO_LIST_AFTER_URL, {cameraId, filename});
        }
    }

}
